import {
  useToggleEminuteStatusMutation,
  useEditEminuteMutation,
  useDeleteEminuteMutation,
  useAddEminuteMutation,
} from "../../../store/admin/eminutesSlice";
import { toast } from "react-hot-toast";
import { useNavigate, useLocation } from "react-router-dom";

const useEminuteAction = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [toggleStatus] = useToggleEminuteStatusMutation();
  const [editEminute] = useEditEminuteMutation();
  const [deleteEminute] = useDeleteEminuteMutation();
  const [addEminute] = useAddEminuteMutation();

  const viewEminute = (id) => {
    navigate(`${location.pathname}/${id}`);
  };

  const notify = (error, data, toastId) => {
    if (error && error.status === 401) {
      toast.error("Login to continue", {
        id: toastId,
      });
      return navigate("/login");
    }
    if (error) {
      return toast.error(error?.data.message || "An error occured", {
        id: toastId,
      });
    }
    if (data.status === false) {
      return toast.error(data.message || "An error occured", { id: toastId });
    }
    return toast.success(data.message || "success", { id: toastId });
  };

  const changeStatus = async (id) => {
    const toastId = toast.loading("Updating");
    const { data, error } = await toggleStatus(id);
    notify(error, data, toastId);
  };

  const createEMinute = async (reqBody, resetForm) => {
    const toastId = toast.loading("Submitting");
    const { data, error } = await addEminute(reqBody);
    if (data.status === true) {
      resetForm();
    }
    notify(error, data, toastId);
  };

  const updateMinute = async (reqBody) => {
    const toastId = toast.loading("Updating");
    const { data, error } = await editEminute(reqBody);
    notify(error, data, toastId);
  };

  const removeEminute = async (id) => {
    const toastId = toast.loading("Deleting");
    const { data, error } = await deleteEminute(id);
    notify(error, data, toastId);
  };

  return {
    changeStatus,
    viewEminute,
    updateMinute,
    removeEminute,
    createEMinute,
  };
};

export default useEminuteAction;
