export const getAuthToken = () => {
  const parsedToken = JSON.parse(localStorage.getItem("access_token"));

  return parsedToken;
};

export const convertToTitleCase = (str) => {
  return str.toLowerCase().replace(/(?:^|\s)\w/g, function (match) {
    return match.toUpperCase();
  });
};

export const setAuthToken = (token) =>
  localStorage.setItem("adminNewSideCXHSJSJJSJSJSJSJS:token", token);

export const getUserToken = () => {
  return localStorage.getItem("adminNewSideuserJSJSJSJS:user");
};

export const setUserToken = (token) =>
  localStorage.setItem("adminNewSideuserJSJSJSJS:user", JSON.stringify(token));

export const setLocationHistory = (location) =>
  sessionStorage.setItem("user:redirect:location", JSON.stringify(location));

export const getLocationHistory = () => {
  return JSON.parse(sessionStorage.getItem("user:redirect:location"));
};

export const dateFromString = (dateInString, reverse = false) => {
  const parsedDate = new Date(dateInString);
  const dateYear = parsedDate.getFullYear();
  const dateMonth = parsedDate.getMonth() + 1;
  const dateDay = parsedDate.getDate();
  if (reverse) {
    return `${dateYear}-${dateMonth < 10 ? "0" : ""}${dateMonth}-${
      dateDay < 10 ? "0" : ""
    }${dateDay}`;
  }
  return `${dateDay < 10 ? "0" : ""}${dateDay}-${
    dateMonth < 10 ? "0" : ""
  }${dateMonth}-${dateYear}`;
};
