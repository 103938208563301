import React from "react";
import { Card } from "react-bootstrap";
import { AiOutlinePlus } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import styled from "styled-components";
// import { EstateDetail, SecurityDetail } from "../../utils";
import { TableDropDown } from "../TableDropDown";
import { ButtonComp } from "./ButtonComp";
import { DropDownComp2 } from "./selectComp";
import { CheckComp } from "./TextInputComp";

export const ContainerComp = ({ children, containerClass, bodyClassName }) => {
  return (
    <Card className={`border-0  ${containerClass}`}>
      <Card.Body className={bodyClassName}>{children}</Card.Body>
    </Card>
  );
};

export const DetailsCard = ({
  containerClassName,
  imagePath,
  title,
  data,
  dataColor,
  bgColor,
  desc,
}) => {
  return (
    <div
      className={`px-3 py-4 mb-2 rounded ${containerClassName}`}
      style={{ backgroundColor: bgColor }}
    >
      <div className="d-flex align-items-center">
        {imagePath && (
          <div className="me-4">
            <img src={imagePath} alt="" />
          </div>
        )}
        <div>
          <h6>{title}</h6>
          <h4 className="fw-bold" style={{ color: `${dataColor}` }}>
            {data}
          </h4>
        </div>
      </div>
      {desc && (
        <>
          {" "}
          <div>
            <hr className="mt-4 mb-3" />
          </div>
          <div className="ms-2 h50 text-muted">{desc}</div>
        </>
      )}
    </div>
  );
};
export const DetailsCard2 = ({
  containerClassName,
  imagePath1,
  imagePath2,
  title1,
  title2,
  data1,
  dataColor1,
  data2,
  dataColor2,
  bgColor,
  desc,
}) => {
  return (
    <div className={`h-100`}>
      <div
        className={`px-1 py-4 mb-2  h-100 rounded ${containerClassName}`}
        style={{ backgroundColor: bgColor }}
      >
        <div className={`d-flex justify-content-around`}>
          <div>
            <div className="mb-3">
              <img src={imagePath1} alt="" />
            </div>
            <h6>{title1}</h6>
            <h4 style={{ color: `${dataColor1}` }}>{data1}</h4>
          </div>
          <div>
            <div className="mb-3">
              <img src={imagePath2} alt="" />
            </div>
            <h6>{title2}</h6>
            <h4 style={{ color: `${dataColor2}` }}>{data1}</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

//DashBoard Card
export const SesaCard = ({ amount, walletType, SesaContainer }) => {
  return (
    <div
      style={{ backgroundColor: "#6200EA" }}
      className={` rounded-4  sesacard position-relative 
    ${SesaContainer ? SesaContainer : " p-3"}`}
    >
      <div className="logo1">
        <img src="/carddesign1.svg" alt="" />
      </div>
      <div className="logo2">
        <img src="/carddesign2.svg" alt="" />
      </div>
      <div className="logo3">
        <img src="/carddesign3.svg" alt="" />
      </div>
      <div className="d-flex justify-content-between align-items-center mb-3 position-relative">
        <div>
          {walletType ? (
            <h4 className="text-white fw-bold">
              <img src="/sesaicon.svg" alt="" className="me-1" />
              SESA
            </h4>
          ) : (
            <img src="/sesaicon.svg" alt="" />
          )}
        </div>
        <div>{!walletType && <h4 className="text-white fw-bold">SESA</h4>}</div>
      </div>
      <div className="text-center  text-white mb-1 position-relative">
        {!walletType && <div className="h40 mb-4">Pool Wallet</div>}
        <h3 className={`${!walletType ? "mb-4" : "my-4"}`}>{amount}</h3>
        {walletType && <p className="text-end mb-0">{walletType}</p>}
      </div>
    </div>
  );
};
const EstateCardStyle = styled.div`
  .option {
    right: 10px;
    top: 10px;
  }
`;

//Event Cardexport
export const EstateCard = ({ Items }) => {
  return (
    <EstateCardStyle className="mb-4">
      <ContainerComp containerClass={"px-4 py-3 position-relative"}>
        <div className="position-absolute option">
          <DropDownComp2
            arrayComp={<TableDropDown Items={Items} />}
            input="name"
            DropDownText={
              <span>
                <BsThreeDotsVertical />
              </span>
            }
          />
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-4 col-xl-3">
            <img src={"/house.svg"} alt="" />
          </div>
          <div className="col-md-6 col-lg-4 col-xl-3">
            <div className="mb-3">
              <h6 className="text-1">Estate Name</h6>
              <h6 className="text-2 fw-3">Iba Housing Estate</h6>
            </div>
            <div className="mb-3">
              <h6 className="text-1">Estate Manager</h6>
              <h6 className="text-2 fw-3">Sladin Ama</h6>
            </div>
            <div className="mb-3">
              <h6 className="text-1">Security Company</h6>
              <h6 className="text-2 fw-3">Proton</h6>
            </div>
            <div className="mb-3">
              <h6 className="text-1">Status</h6>
              <h6 className="text-2 fw-2 text-success">Active</h6>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 col-xl-3">
            <div className="mb-3">
              <h6 className="text-1">Estate Name</h6>
              <h6 className="text-2 fw-3">Iba Housing Estate</h6>
            </div>
            <div className="mb-3">
              <h6 className="text-1">Estate Manager</h6>
              <h6 className="text-2 fw-3">Sladin Ama</h6>
            </div>
            <div className="mb-3">
              <h6 className="text-1">Security Company</h6>
              <h6 className="text-2 fw-3">Proton</h6>
            </div>
            <div className="mb-3">
              <h6 className="text-1">Sign Out Required</h6>
              <h6 className="text-2 fw-2 text-success">
                <CheckComp />
              </h6>
            </div>
          </div>
        </div>
      </ContainerComp>
    </EstateCardStyle>
  );
};
//Secuirty Card
export const SecuityCard = ({ Items }) => {
  return (
    <EstateCardStyle className="mb-4">
      <ContainerComp containerClass={"px-4 py-3 position-relative"}>
        <div className="position-absolute option">
          <DropDownComp2
            arrayComp={<TableDropDown Items={Items} />}
            input="name"
            DropDownText={
              <span>
                <BsThreeDotsVertical />
              </span>
            }
          />
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-4 col-xl-3">
            <img src={"/securityImage.svg"} alt="" />
          </div>
          <div
            className="art
        d-6 col-lg-4 col-xl-3"
          >
            <div className="mb-3">
              <h6 className="text-1">Name</h6>
              <h6 className="text-2 fw-3">Proton Security</h6>
            </div>
            <div className="mb-3">
              <h6 className="text-1">Address</h6>
              <h6 className="text-2 fw-3">-</h6>
            </div>
            <div className="mb-3">
              <h6 className="text-1">Date of Oboarding</h6>
              <h6 className="text-2 fw-3">-</h6>
            </div>
            <div className="mb-3">
              <h6 className="text-1">Status</h6>
              <h6 className="text-2 fw-2 text-success">Active</h6>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 col-xl-3">
            <div className="mb-3">
              <h6 className="text-1">Wallet Balance</h6>
              <h6 className="text-2 fw-3">₦5,000</h6>
            </div>
            <div className="mb-3">
              <h6 className="text-1">No. of Security Guards</h6>
              <h6 className="text-2 fw-3">3400</h6>
            </div>
            {/* <div className='mb-3'>
            <h6 className='text-1'>Security Company</h6>
            <h6 className='text-2 fw-3'>Proton</h6>
          </div>
          <div className='mb-3'>
            <h6 className='text-1'>Sign Out Required</h6>
            <h6 className='text-2 fw-2 text-success'><CheckComp/></h6>
          </div> */}
          </div>
        </div>
      </ContainerComp>
    </EstateCardStyle>
  );
};

//Empty Table
export const EmptyTable = ({ addPath, name }) => {
  return (
    <div className="px-lg-5 py-4 mx-lg-5  my-5 d-flex justify-content-center align-items-center">
      <div className="col-11 col-lg-8">
        <ContainerComp containerClass={"w-100"}>
          <div className="py-5 px-lg-5 d-flex justify-content-center align-items-center">
            <div>
              <div className="mb-5 text-center pb-3">
                <img src="/empty.svg" alt="" />
              </div>
              <div className="mb-4 text-center">
                Ooops you have not added any {name} yet
              </div>
              <div className="text-center">
                <ButtonComp
                  btnText={
                    <span>
                      <span className="me-2">
                        <AiOutlinePlus />
                      </span>
                      Add {name}
                    </span>
                  }
                  btnClassName="bg-blue text-white py-2 px-4 rounded"
                />
              </div>
            </div>
          </div>
        </ContainerComp>
      </div>
    </div>
  );
};

export const FormContainer = ({ children }) => {
  return (
    <div className="bg-white mx-3 mx-lg-5 px-4 px-lg-5 py-5 my-5">
      {children}
    </div>
  );
};

export const SecuirtyDetailCard = ({ image, title, no, desc }) => {
  return (
    <div className=" border rounded  p-3">
      <div className="row">
        <div className="col-3">
          <img src={image} alt="" />
        </div>
        <div className="col-8">
          <div className="fw-0 h51 mb-2">{title}</div>
          <h4 className="fw-3">{no}</h4>
        </div>
      </div>
      <div>
        {" "}
        <hr className="mt-4 mb-2 " />
      </div>
      <div className="px-lg-3">
        <p className="text-success h50">{desc}</p>
      </div>
    </div>
  );
};
