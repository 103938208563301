import AOS from "aos";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { AppRouter } from "./routes";
import "bootstrap/dist/css/bootstrap.min.css";
import "aos/dist/aos.css";
import "animate.css"; // You can also use <link> for styles
import "react-datepicker/dist/react-datepicker.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "react-datepicker/dist/react-datepicker.css";
import { ProSidebarProvider } from "react-pro-sidebar";
import ScrollToTop from "./components/Common/SrcollToTop";
import "./App.css";

const App = () => {
  const queryClient = new QueryClient();
  AOS.init();
  return (
    <ProSidebarProvider>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <AppRouter />
          <ScrollToTop />
        </QueryClientProvider>
      </BrowserRouter>
    </ProSidebarProvider>
  );
};

export default App;
