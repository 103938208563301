import { API } from "../api";
const BASE_URL = "/cooperatives/birthday_message";
const BIRTHDAY_URL = "/cooperatives/automated_birthdays";

const authomatedBirthdayExtendedApi = API.injectEndpoints({
  endpoints: (builder) => ({
    getBirthdayMessages: builder.query({
      query: (page = 1) => `${BASE_URL}/pull?guard=staff&page=${page}`,
      transformResponse: (responseData) => responseData.data,
      providesTags: (result, error, arg) => [
        { type: "AutoBirthday", id: "LIST" },
      ],
    }),
    createBirthdayMessage: builder.mutation({
      query: (body) => ({
        url: `${BASE_URL}/add?guard=staff`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: [{ type: "AutoBirthday", id: "LIST" }],
    }),
    updateBirthdayMessage: builder.mutation({
      query: (body) => ({
        url: `${BASE_URL}/${body.id}/edit?guard=staff`,
        method: "PATCH",
        body: body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "AutoBirthday", id: "LIST" },
        { type: "AutoBirthday", id: arg.id },
      ],
    }),
    getBirthdays: builder.query({
      query: (page = 1) => `${BIRTHDAY_URL}?guard=staff&page=${page}`,
      transformResponse: (responseData) => responseData.data,
    }),
  }),
});

export const {
  useGetBirthdayMessagesQuery,
  useGetBirthdaysQuery,
  useCreateBirthdayMessageMutation,
  useUpdateBirthdayMessageMutation,
} = authomatedBirthdayExtendedApi;
