import React from "react";
import { useState } from "react";
import {
  ButtonComp,
  CardComp,
  ModalCompAdvanceUpdate,
  TabsComp,
} from "../../../components";
import FilterFind from "../../../components/FilterFind";
import Message from "../../../components/MessageModal";
import AllLoanStatus from "./modules/AllLoanStatus";
import {
  useGetPendingLoansRequestssQuery,
  useGetApprovedLoansListQuery,
  useGetDeclinedLoansListQuery,
  useGetDisbursedLoansListQuery,
  useApproveLoanRequestMutation,
} from "../../../store/admin/loan";
import toast from "react-hot-toast";
import DisburseLoanModal from "./modal/disburse-loan-modal";
import DeclineLoanModal from "./modal/decline-loan-modal";
import { useNavigate } from "react-router-dom";

export default function LoanStatus() {
  const navigate = useNavigate();
  const [modalPage, setModalPage] = useState("");
  const [requestId, setRequestId] = useState("");
  const [pendingLoanPage, setPendingLoanPage] = useState(1);
  const [approvedLoanPage, setApprovedLoanPage] = useState(1);
  const [declinedLoanPage, setDeclineLoanPage] = useState(1);
  const [disbursedLoanPage, setDisbursedLoanPage] = useState(1);

  const {
    data: pendingLoans,
    isLoading: isLoadingPendingLoans,
    isFetching,
  } = useGetPendingLoansRequestssQuery(pendingLoanPage);

  const { data: approvedLoans, isLoading: isLoadingApproveLoans } =
    useGetApprovedLoansListQuery(approvedLoanPage);

  const { data: declinedLoans, isLoading: isLoadingDeclineLoans } =
    useGetDeclinedLoansListQuery(declinedLoanPage);

  const { data: disbursedLoans, isLoading: isLoadingDisburseLoans } =
    useGetDisbursedLoansListQuery(disbursedLoanPage);

  const [isApproveRequest, { isLoading: isApprovingLoans }] =
    useApproveLoanRequestMutation();

  const handleApproveLoanRequest = async (requestId) => {
    const response = await isApproveRequest(requestId);
    const { error, message, status } = response?.data;
    if (!status) return toast.error(message);
    toast.success(message);
    setModalPage();
    if (error) return toast.error(error?.data?.message);
  };

  const ButtonArrayPendingLoan = (row) => {
    return (
      <div className="row justify-content-between px-3">
        <div className="col-6">
          <ButtonComp
            onClick={() => {
              setModalPage("Approve Modal");
              setRequestId(row.id);
            }}
            btnText={"Approve"}
            btnClassName="w-100 bg-primary1 px-3 fw-2 h6 py-2 text-white rounded"
          />
        </div>
        <div className="col-6">
          <ButtonComp
            onClick={() => {
              setModalPage("Decline Modal");
              setRequestId(row.id);
            }}
            btnText={"Decline"}
            btnClassName="bg-white2  fw-2 h6 text-grey px-3  py-2  rounded"
          />
        </div>
      </div>
    );
  };
  const ButtonArrayApproveLoan = (row) => {
    return (
      <div className="d-flex justify-content-center px-3 w-100">
        <div className="text-center">
          <ButtonComp
            onClick={() => {
              setModalPage("Disburse Modal");
              setRequestId(row.id);
            }}
            btnText={"Disburse loan"}
            btnClassName="w-100 bg-primary1 px-3 fw-2 h6 py-2 text-white rounded-2"
          />
        </div>
      </div>
    );
  };
  const ButtonArrayDeclineLoan = (row) => {
    return (
      <div className="d-flex justify-content-center px-3 w-100">
        <div className="text-center">
          <ButtonComp
            onClick={() => {
              setModalPage("Reconsider Modal");
              setRequestId(row.id);
            }}
            btnText={"Reconsider loan"}
            btnClassName="w-100 bg-primary1 px-3 fw-2 h6 py-2 text-white rounded-2"
          />
        </div>
      </div>
    );
  };
  const ButtonArrayDisbursedLoan = (row) => {
    return (
      <div className="d-flex justify-content-center px-3 w-100">
        <div className="text-center">
          <ButtonComp
            onClick={() => {
              // setRequestId(row.id);
              navigate(`/super_admin/loan_management/loan_repayment/${row.id}`);
            }}
            btnText={"View repayment plan"}
            btnClassName="w-100 bg-primary1 px-3 fw-2 h6 py-2 text-white rounded-2"
          />
        </div>
      </div>
    );
  };

  const useModal = [
    {
      name: "Approve Modal",
      component: (
        <Message
          title={"Confirm"}
          loading={isApprovingLoans}
          desc={"Are  you sure you want to approve this loan?"}
          onBack={() => setModalPage()}
          onProceed={() => handleApproveLoanRequest(requestId)}
        />
      ),
    },
    {
      name: "Reconsider Modal",
      component: (
        <Message
          title={"Confirm"}
          loading={isApprovingLoans}
          desc={"Are  you sure you want to reconsider this loan for approval?"}
          onBack={() => setModalPage()}
          onProceed={() => handleApproveLoanRequest(requestId)}
        />
      ),
    },
    {
      name: "Decline Modal",
      component: (
        <DeclineLoanModal requestId={requestId} setModalPage={setModalPage} />
      ),
    },
    {
      name: "Disburse Modal",
      component: (
        <DisburseLoanModal requestId={requestId} setModalPage={setModalPage} />
      ),
    },
  ];
  const useTab = [
    {
      name: "Pending loan",
      component: (
        <AllLoanStatus
          name="pending loan"
          ButtonArray={ButtonArrayPendingLoan}
          TableData={pendingLoans?.data?.company_loan_requests || []}
          pagination={pendingLoans?.data?.pagination}
          loading={isLoadingPendingLoans || isFetching}
          setPageNumber={setPendingLoanPage}
        />
      ),
    },
    {
      name: "Approved loan",
      component: (
        <AllLoanStatus
          name="approved loan"
          ButtonArray={ButtonArrayApproveLoan}
          TableData={approvedLoans?.data?.company_loan_requests || []}
          pagination={approvedLoans?.data?.pagination}
          loading={isLoadingApproveLoans}
          setPageNumber={setApprovedLoanPage}
        />
      ),
    },
    {
      name: "Declined loan",
      component: (
        <AllLoanStatus
          name="declined loan"
          ButtonArray={ButtonArrayDeclineLoan}
          TableData={declinedLoans?.data?.company_loan_requests || []}
          pagination={declinedLoans?.data?.pagination}
          loading={isLoadingDeclineLoans}
          setPageNumber={setDeclineLoanPage}
        />
      ),
    },
    {
      name: "Disbursed loan",
      component: (
        <AllLoanStatus
          name="disbursed loan"
          ButtonArray={ButtonArrayDisbursedLoan}
          modalPage={modalPage}
          useModal={useModal}
          TableData={disbursedLoans?.data?.company_loan_requests || []}
          pagination={disbursedLoans?.data?.pagination}
          loading={isLoadingDisburseLoans}
          setPageNumber={setDisbursedLoanPage}
        />
      ),
    },
  ];

  return (
    //
    <section className=" px-lg-3 min-vh-100 ">
      <FilterFind title={"Loan Status"} rightSide={false} />

      <CardComp>
        <div className="py-4"></div>
        <ModalCompAdvanceUpdate
          pageName={modalPage}
          arrayComp={useModal}
          handleClose={() => setModalPage()}
          centered
          size={"md"}
        />
        <TabsComp
          TabArray={useTab}
          input="name"
          justifyContent={"justify-content-start"}
          tabClassName="px-0"
          TabArrayClassName="px-4"
        />
      </CardComp>
    </section>
  );
}
