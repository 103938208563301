import React, { useState } from "react";
import { ButtonComp, TextInputComp } from "../../Ui";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
export default function ForgetPasswordForm({ onNext }) {
  const [showModal, setShowModal] = useState(false)
  const navigate = useNavigate();
  const initialValues = {
    email: "",
  };

  // Yup validations schema
  const validationSchema = Yup.object({
    email: Yup.string().email().required("Email is required"),
  });

  async function handleForgotPassword(values) {

    // onNext();
    // setShowModal(true)
  }

  return (
    <div className="bg-white py-10 px-10 rounded-3xl flex flex-col relative">
      <div className="absolute -top-12 -right-5">
        <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.0001 0.200348L20.8884 12.1674H33.4713L23.2915 19.5635L27.1799 31.5305L17.0001 24.1345L6.82026 31.5305L10.7086 19.5635L0.528801 12.1674H13.1117L17.0001 0.200348Z" fill="#8B313A" />
        </svg>
      </div>
      <div className="absolute top-0 -right-10">
        <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M23.7024 0.128797L14.3185 21.944L0.117944 2.90972L23.7024 0.128797Z" fill="#B13E4A" />
        </svg>
      </div>
      {/* <div className="text-center mb-5 pb-5">
        <div
          className="bg-primary1 d-inline-block rounded-2"
          style={{ width: "40px", height: "40px" }}
        ></div>
        <p className="mb-1 fw-bold ">Ifesowapo Cooperative, LTC</p>
        <div className="h50">6&7, Alaka Express, Gbagada, Surulere, Lagos</div>
        <div className="h50">+234 814 0307 009</div>
      </div> */}

      {/* <div className="pt-4 pb-3   container rounded-4 mb-3 text-center "> */}
      <div className="flex flex-col items-center bg-white rounded-3xl text-center lg:hidden">
        <img src="../Images/Login/sign-up-image.png" alt="profile" className="w-[100px] h-[100px]" />
        <p className="font-[700] text-[32px]">Ifesowapo Cooperative, LTC</p>
        <p className="text-[#858585] text-lg">6&7, Alaka Express, Gbagada, Surulere, Lagos</p>
      </div>
      <div className="text-left">
        <h3 className="font-[700] mb-2 text-[24px]">Forgot Password</h3>
        <div className="">
          Enter the email you used to create your account so we can send you
          instructions on how to reset your password.
        </div>
      </div>
      <div className="mb-5">
        <div></div>
      </div>
      <Modal show={showModal} centered>
        {/* <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header> */}
        <Modal.Body className="p-5">
          <button onClick={() => setShowModal(false)} className="">
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.5 2.0997L13.09 0.689697L7.5 6.2797L1.91 0.689697L0.5 2.0997L6.09 7.6897L0.5 13.2797L1.91 14.6897L7.5 9.0997L13.09 14.6897L14.5 13.2797L8.91 7.6897L14.5 2.0997Z" fill="black" />
            </svg>
          </button>
          <div className="flex flex-col gap-3 items-center justify-center">
            <p className="font-[700] text-[24px]">OTP Code</p>
            <p className="text-[#858585] text-center">Enter the 6 digit OTP code sent to your email address <span className="text-[#1E1E1E]">ala********@gmail.com</span></p>
            <input type="text" placeholder="Enter OTP code" className="border-[#858585] border-[1px] p-3 rounded-lg w-full" />
            <p className="text-[#858585]">Resend OTP <span className="text-[#FF4343]">0:59</span></p>
            <button className="bg-[#8B313A] w-full py-3 rounded-2xl text-[16px] text-white font-[600]" onClick={() => navigate("/reset_password")}>
              Confirm OTP
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleForgotPassword}
      >
        <Form>
          <div className="col-lg-11 w-full">
            <div className="mb-5">
              <TextInputComp
                LabelClassName={"fw-normal"}
                borderColor={"#A1A0A0"}
                borderWidth="1px"
                InputClassName={"border  border-1 py-2"}
                labelText={"Email Address"}
                placeholder="Enter Email Address"
                name="email"
                type="text"
              />
            </div>

            {/* <div className="fw-3 mt-3 mb-5  pointer">Forget password?</div> */}
            <div className="mb-4">
              <ButtonComp
                btnText={"Reset password"}
                type="submit"
                onClick={() => navigate("/reset_password")}
                btnClassName="w-100 bg-primary1 text-white border-0 py-3 rounded-4 fw-bold"
              />
            </div>
            <div className="mb-4">
              <ButtonComp
                BorderColor={"#8B313A"}
                BorderWidth={"1.5px"}
                btnText={"Back to Login"}
                onClick={() => navigate("/login")}
                btnClassName="w-100 text-primary1   py-3 rounded-4 fw-bold"
              />
            </div>

            {/* <h6 class="background text-center mb-4">
              <span>OR</span>
            </h6>
            <div className="text-center mb-5">
              <ButtonComp
                BorderColor={"#8B313A"}
                BorderWidth={"1px"}
                btnClassName={
                  "border border-1 rounded border-secondary-1 py-2 px-3 bg-white"
                }
                btnText={
                  <span>
                    <span className="pe-2">
                      <FcGoogle size={25} />
                    </span>
                    Continue with Google
                  </span>
                }
              />
            </div>
            <div>
              <div className="text-center h50 mb-4">
                Don’t have an account?{" "}
                <span className="fw-3 text-1 h40">Sign up</span>
              </div>
            </div> */}
          </div>
        </Form>
      </Formik>
    </div>
  );
}
