import { useState } from "react";
import { BsDot } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import {
  ButtonComp,
  CardComp,
  ModalCompAdvance,
  TextInputComp,
  FormikSelectComp,
} from "../../../components";
import FilterFind from "../../../components/FilterFind";
import { Formik, Form } from "formik";
import { useViewMemberQuery } from "../../../store/admin/memberManagement/memberSlice";
import Loader from "../../../components/Ui/Loader";
import useRtkFetchError from "../../../components/Common/useRtkFetchError";
import { useGetBranchesSelectableQuery } from "../../../store/selectableSlice";
import useMemberRegAction from "./useMemberRegAction";

export const ChangeBranchMember = () => {
  const { isChangingBranch, changeMemberBranch } = useMemberRegAction();
  const navigate = useNavigate();
  const [modalToggle, setModalToggle] = useState(false);
  const [modalPage] = useState(0);
  const { data: allBranches = [], isFetching: isLoadingBranches } =
    useGetBranchesSelectableQuery();
  const { id } = useParams();
  const {
    data: memberDetails = {},
    errorFetching,
    // isFetching,
  } = useViewMemberQuery(id);
  const initialValues = {
    from: memberDetails?.branch?.id,
    to: "",
    name: memberDetails.first_name + " " + memberDetails.last_name,
    prev: memberDetails?.branch?.name,
  };
  const [formikValues, setFormikValues] = useState(initialValues);

  const initiateSubmit = (formValues) => {
    setFormikValues(formValues);
    setModalToggle(true);
  };

  const submitForm = () => {
    const reqBody = {
      from: formikValues?.from,
      to: parseInt(formikValues?.to) || formikValues.to,
    };
    changeMemberBranch(id, reqBody);
    setModalToggle(false);
  };

  const useModal = [
    {
      name: "Suspend Modal",
      component: (
        <div>
          <div className="py-3"></div>
          <div className="text-center">
            <h4 className="text-black text-center fw-bold">
              Change member branch
            </h4>
            <p className="mb-5 text-black fw-2">
              Are you sure you want to change the member branch
            </p>
            <div className="row justify-content-around">
              <div className="col-5">
                <ButtonComp
                  btnText={"No"}
                  btnClassName={"border-0 text-white bg-primary1 w-100 py-3"}
                  onClick={() => setModalToggle(false)}
                />
              </div>
              <div className="col-5">
                <ButtonComp
                  btnText={"Yes"}
                  btnClassName={" text-1 btn w-100 py-3"}
                  BorderColor={"#8B313A"}
                  onClick={submitForm}
                />
              </div>
            </div>
          </div>
          <div className="py-3"></div>
        </div>
      ),
    },
  ];
  useRtkFetchError({ error: errorFetching });
  return (
    <>
      {isLoadingBranches ? (
        <div className="bg-white">
          <Loader />
        </div>
      ) : (
        <>
          <section className="mx-2 mx-lg-4 min-vh-100 ">
            <FilterFind
              title={"Change branch"}
              back
              backBtn={() => navigate("/super_admin/member_management/members")}
              rightSide={false}
            />
            <Formik initialValues={initialValues} onSubmit={initiateSubmit}>
              <Form>
                <CardComp cardClassName={"border-0 px-lg-4 mb-3 pt-5 pb-2"}>
                  <div className="row g-5 mb-4">
                    <div className="col-lg-6">
                      <div className="pe-lg-5">
                        <div className="col-lg-12 border-lg-end mb-4">
                          <TextInputComp
                            labelText={"Member Name"}
                            placeholder={"Alabi Adeola Martins"}
                            name="name"
                            LabelClassName={"fw-bold"}
                            value={
                              memberDetails.first_name +
                              " " +
                              memberDetails.last_name
                            }
                          />
                        </div>
                        <div className="col-lg-12 border-lg-end mb-3">
                          <TextInputComp
                            labelText={"Current Branch"}
                            placeholder={"Current branch"}
                            value={memberDetails?.branch?.name}
                            name="prev"
                            LabelClassName={"fw-bold"}
                          />
                        </div>
                        <div className="col-lg-12 border-lg-end mb-5">
                          <FormikSelectComp
                            labelClassName={"fw-bold"}
                            fieldName={`to`}
                            labelText={"Branch"}
                            options={[
                              ...allBranches.map(({ name, id }) => ({
                                label: name,
                                value: id,
                              })),
                            ]}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 ">
                      <CardComp cardClassName="border-0 shadow py-1">
                        <h5 className="text-black mb-2 fw-bold text-center">
                          Instructions
                        </h5>
                        <div className="d-flex align-items-center mb-1">
                          <span>
                            <BsDot color="#8B313A" size={40} />
                          </span>
                          <p>
                            Select the date corresponding to the attendance
                            schedule to mark members attendance{" "}
                          </p>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                          <span>
                            <BsDot color="#8B313A" size={40} />
                          </span>
                          <p>
                            Select the date corresponding to the attendance
                            schedule to mark members attendance{" "}
                          </p>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                          <span>
                            <BsDot color="#8B313A" size={40} />
                          </span>
                          <p>
                            Select the date corresponding to the attendance
                            schedule to mark members attendance{" "}
                          </p>
                        </div>
                      </CardComp>
                    </div>
                  </div>
                </CardComp>
                <div className="col-md-3 border-lg-end">
                  <ButtonComp
                    type={"submit"}
                    btnText={"Save changes"}
                    btnClassName={
                      "w-100 text-white bg-primary1 w-100 border-0 py-3 h6 rounded"
                    }
                    loading={isChangingBranch}
                  />
                </div>
              </Form>
            </Formik>
          </section>
          <div className="my-5"></div>
          <ModalCompAdvance
            modalBody={modalPage}
            show={modalToggle}
            arrayComp={useModal}
            handleClose={() => setModalToggle(false)}
            centered
            size={"md"}
          />
        </>
      )}
    </>
  );
};
