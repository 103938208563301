import { fetchBaseQuery, retry, createApi } from "@reduxjs/toolkit/query/react";
import { storage } from "../utils/storage";

// Create our baseQuery instance
export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = getState().auth.token;

    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    headers.set("Accept", "application/json"); // add Accept header
    return headers;
  },
  validateStatus: (status) => {
    if (status.status === 401) {
      storage["localStorage"].remove("coop_details_profile");
      storage["localStorage"].remove("company_id");
      storage["localStorage"].remove("user_details_profile");
      storage["localStorage"].remove("user_type");
      storage["cookieStorage"].remove("access_token");

      // eslint-disable-next-line no-restricted-globals
      location.assign("/");
      // throw new Error("Unauthorized");
    }

    return status;
    // return status >= 200 && status < 300;
  },
});

export const baseQueryWithRetry = retry(baseQuery, { maxRetries: 1 });

export const API = createApi({
  reducerPath: "Api", // optional
  baseQuery: baseQueryWithRetry,
  endpoints: (builder) => ({}),
  tagTypes: [
    "Branch",
    "Prefix",
    "RegFee",
    "BankInfo",
    "Member",
    "PendingMember",
    "Staff",
    "Staff-list",
    "AdminEminute",
    "AdminEvent",
    "AdminAttendance",
    "GenerateMemberId",
    "AutoBirthday",
    "CommGroupMgt",
    "contribution-records",
    "contribution-category",
    "CommGroupMessage",
    "member-contributions-history",
    "member-contributions-history-by-category-id",
    "MemberEvent",
    "pending-loans-list",
    "approved-loans-list",
    "declined-loans-list",
    "disbursed-loans-list",
    "loan-types-list",
    "selectable-loan-types-list",
    "guarantors-list",
    "upcoming-loan-repayment",
    "due-loan-repayment",
    "overdue-loan-repayment",
    "loan-request-breakdown-by-id",
    "single-staff",
    "member-pending-loans-list",
    "member-approved-loans-list",
    "member-declined-loans-list",
    "member-disbursed-loans-list",
    "member-upcoming-repayment-list",
    "member-due-repayment-list",
    "member-overdue-repayment-list",
  ],
});

export const ApiPrefixes = {
  staffManagement: "cooperatives/staff_managements",
  memberManagement: "/cooperatives/member_managements",
  adminSettings: "/cooperatives/settings",
  adminSelectables: "/cooperatives/selectables",
  adminBranchSettings: "/cooperatives/settings/branches",
  adminBankSettings: "/cooperatives/settings/banks",
  adminCompanyPrefixesSettings: "/cooperatives/settings/branches/prefixes",
  adminRegFeesSettings: "/cooperatives/settings/registration-fees",
  adminPortalSettings: "/cooperatives/settings/portal",
  generalSelectables: "/generals",
  contribution: "cooperatives/member_contributions",
  loanManagement: "cooperatives/loans",
  membersLoan: "members/loans",
  companyBank: "cooperatives/settings/banks",
};
