import { PageTitleWithNav } from "../modules/PageHeaders";
import EMinuteEditor from "./modules/EMinuteEditor";
import { useNavigate, useParams } from "react-router-dom";
import { useGetSingleEminuteQuery } from "../../../store/admin/eminutesSlice";
import { dateFromString } from "../../../utils";
import useEminuteAction from "./useAdminEminuteActions";
import { useState } from "react";

export const EminutePage = () => {
  const { updateMinute } = useEminuteAction();
  const navigate = useNavigate();
  const { id } = useParams();
  const { data = {}, isLoading, error } = useGetSingleEminuteQuery(id);
  const [dd, mm, yyyy] = dateFromString(data.created_at).split("-");
  const initialFormValues = {
    title: data.title || "",
    content: data.content || "",
    date: `${yyyy}-${mm}-${dd}` || "",
  };
  const [content, setContent] = useState(data.content || "");
  const handleSubmit = (formikValues) => {
    updateMinute({ ...formikValues, content: content, id });
  };
  if (error && error.status === 401) {
    navigate("/login");
  }
  return (
    <>
      {isLoading ? (
        <div className="py-4">loading...</div>
      ) : (
        <section className="mx-2 mx-lg-4 min-vh-100">
          <div className="py-1"></div>
          <PageTitleWithNav
            title={"Edit"}
            highlightText={data.title || ""}
            action={() => {
              navigate(-1);
            }}
          />
          <div>
            <EMinuteEditor
              initialValues={initialFormValues}
              handleSubmit={handleSubmit}
              handleContentChange={setContent}
            />
          </div>
        </section>
      )}
    </>
  );
};
