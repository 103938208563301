import styled from "styled-components";
import { Form } from "react-bootstrap";
import TextEditor from "../../modules/TextEditor";
import { useState } from "react";
import {
  ButtonComp,
  SelectComp2,
  ModalCompAdvanceUpdate,
} from "../../../../components";
import { useGetGroupsNamesQuery } from "../../../../store/admin/communicationSlice";
import Message from "../../../../components/MessageModal";
import {
  useGetCommunicationMessageRecipientTypesQuery,
  useGetCommunicationMessageSendingModesQuery,
  useGetAllMembersQuery,
} from "../../../../store/selectableSlice";
// import Select from "react-select";

const NewMessage = ({
  message,
  setMessage,
  formValues,
  sendMessage = () => {},
  updateForm = () => {},
  updateRecipients = () => {},
}) => {
  const { data: groupsData = {} } = useGetGroupsNamesQuery();
  const communication_groups = groupsData?.communication_groups || [];
  const { data: receiptTypes = [] } =
    useGetCommunicationMessageRecipientTypesQuery();
  const { data: sendingModes = {} } =
    useGetCommunicationMessageSendingModesQuery();
  const { data: allMembers = [] } = useGetAllMembersQuery();
  const [modalName, setModalName] = useState();
  const receipt_type_name =
    receiptTypes.find(({ id }) => String(id) === formValues.receipt_type_id)
      ?.name || "";

  const closeModal = () => {
    setModalName();
  };

  const procecessMessage = (endpoint) => {
    setModalName();
    sendMessage(endpoint);
  };

  const modalItems = [
    {
      name: "Save",
      component: (
        <Message
          title={"Save"}
          desc={"Save message as draft?"}
          onBack={closeModal}
          onProceed={() => procecessMessage("saveToDraft")}
        />
      ),
    },
    {
      name: "Send",
      component: (
        <Message
          title={"Send"}
          desc={"Save and send message?"}
          onBack={closeModal}
          onProceed={() => {
            procecessMessage("send");
          }}
        />
      ),
    },
  ];
  return (
    <Wrapper>
      <div className="row gx-0 p-4 justify-content-between bg-white form-fields gap-4">
        <Form.Group>
          <Form.Label className="fw-bold mb-2">Message title</Form.Label>
          <Form.Control
            value={formValues.header}
            onChange={(e) => updateForm(e)}
            name="header"
            type="input"
          ></Form.Control>
        </Form.Group>
        <div className="col-12 col-lg-5">
          <Form.Group>
            <Form.Label className="fw-bold mb-2">Recipient type</Form.Label>
            <Form.Select
              name="receipt_type_id"
              value={formValues.receipt_type_id}
              onChange={(e) => updateForm(e)}
            >
              <option>Select....</option>
              {receiptTypes.map((item, index) => (
                <option key={index} value={item.id} className="">
                  {item.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </div>
        {receipt_type_name !== "Individual" ? (
          <div className="col-12 col-lg-5">
            <Form.Group>
              <Form.Label className="fw-bold mb-2">Group member</Form.Label>
              <Form.Select
                name="group_id"
                value={formValues.group_id}
                onChange={(e) => updateForm(e)}
              >
                <option value={""} className="">
                  Select...
                </option>
                {communication_groups.map(({ id, name }, index) => {
                  return (
                    <option key={index} value={id} className="">
                      {name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          </div>
        ) : (
          <div className="col-12 col-lg-5">
            <SelectComp2
              labelText={"Recipient members"}
              LabelClassName={"fw-bold"}
              isMulti={true}
              setSelectedOption={updateRecipients}
              selectText={"Select recipients"}
              arrayComp={[
                ...allMembers.map((member) => ({
                  label: member.first_name + " " + member.last_name,
                  value: member.member_id,
                })),
              ]}
            />
          </div>
        )}
        <div className="col-12 col-lg-5">
          <Form.Group>
            <Form.Label className="fw-bold mb-2">Sending mode</Form.Label>
            <Form.Select
              name="sending_mode"
              value={formValues.sending_mode}
              onChange={(e) => updateForm(e)}
            >
              <option>Select....</option>
              {Object.entries(sendingModes).map(([key, value]) => {
                return (
                  <option key={key} value={value} className="">
                    {key}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
        </div>
      </div>
      <div className="border-line"></div>
      <TextEditor initialValue={message} handleContentChange={setMessage} />
      <div className="mt-4 d-flex gap-4">
        <ButtonComp
          btnText={"Send"}
          btnClassName={"bg-primary1 py-2 px-2 text-white rounded"}
          type={"button"}
          onClick={() => setModalName("Send")}
        />
        <ButtonComp
          btnText={"Save as draft"}
          btnClassName={"p-2 save fw-bold"}
          ButtonInlineStyle={{}}
          type={"button"}
          onClick={() => setModalName("Save")}
        />
      </div>
      <ModalCompAdvanceUpdate
        centered={true}
        pageName={modalName}
        arrayComp={modalItems}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .form-fields {
    border-radius: 10px;
    margin-top: 4px;
  }
  select,
  .form-select,
  .form-control {
    background-color: #fafafa !important;
    border: 1px solid #a1a0a0 !important;
    border-radius: 5px;
    outline: none !important;
    display: block;
    width: 100%;
    padding: 0.5rem;
  }
  select:focus,
  .form-select {
    outline: none !important;
  }
  .border-line {
    border: 1px solid #e0e0e0;
    margin-block: 16px;
  }
  button {
    min-width: 200px;
    border-radius: 10px;
    border: 1px solid #8b313a;
  }
  button.save {
    color: #8b313a;
  }
`;

export default NewMessage;
