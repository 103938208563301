import { useGetAttendanceStatusesQuery } from "../../../../store/selectableSlice";
import { DropDownComp2 } from "../../../../components";
import FallbackImage from "../../../../components/Common/ImageWrapper";

export const status_icon = {
  1: "/Icon/tick-circle-success.svg",
  2: "/Icon/tick-circle-warning.svg",
  3: "/Icon/tick-circle-info.svg",
  4: "/Icon/tick-circle-danger.svg",
};

const AttendanceFilter = () => {
  const { data: attendance_statuses = [] } = useGetAttendanceStatusesQuery();
  return (
    <div className="d-flex justify-content-between align-items-center gap-2 mb-4 flex-wrap ">
      <div className="d-flex flex-wrap mb-3 mb-xl-0">
        {attendance_statuses.map((status, index) => {
          return (
            <div className="me-3 d-flex align-items-center" key={index}>
              {" "}
              <FallbackImage
                src={status_icon[status?.id]}
                className="me-2"
              />{" "}
              {status?.name}
            </div>
          );
        })}
      </div>
      <div className="border rounded">
        <DropDownComp2
          DropDownText={<span className="">January, 2022</span>}
          dropLabelClassName={"border-0 px-2 py-1 opacity-75"}
          show
        />
      </div>
    </div>
  );
};

export default AttendanceFilter;
