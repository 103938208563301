import { useState, useRef } from "react";
import { BsDot } from "react-icons/bs";
import { CardComp } from "../cardComp";
import { ButtonComp, ModalCompAdvance } from "../Ui";
import { ReusableFileUploaderComp } from "../Ui/reusable-file-uploader";
import { useAuth } from "../../hooks";
import toast from "react-hot-toast";

export const BatchUploadComp = () => {
  const [loading, setLoading] = useState(false);
  const token = useAuth();

  const fileUploadButton = useRef(null);
  const button2Ref = useRef(null);

  const handleClickButton1 = () => {
    // Programmatically click button 2
    fileUploadButton.current.click();
  };

  const [modalToggle, setModalToggle] = useState(false);
  const [modalPage] = useState(0);

  const handleUpload = (selectedFile) => {
    setLoading(true);
    console.log(selectedFile, "selectedFile");
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      const authToken = token;

      // Create headers and set the authorization token
      const baseUrl = process.env.REACT_APP_BASE_URL;
      const headers = new Headers();
      headers.append("Authorization", `Bearer ${authToken}`);
      headers.append("Accept", "application/json");
      // headers.append("Content-Type", "multipart/form-data");

      const requestOptions = {
        method: "POST",
        headers,
        body: formData,
      };

      // Replace 'your-upload-url' with your actual upload URL
      fetch(
        `${baseUrl}/cooperatives/staff_managements/batch/upload?guard=staff`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          console.log("Upload successful", data);
          setLoading(false);
          if (!data.status) {
            toast.error(data.message);
            return;
          }
          toast.success(data.message);
        })
        .catch((error) => {
          console.error("Upload failed", error);
          toast.error(error.message);
          setLoading(false);
        });
    }
  };

  const handleDownload = () => {
    const downloadLink = document.createElement("a");
    downloadLink.href = "/docs/sample-doc.xlsx"; // Path to your Excel file
    downloadLink.download = "sample.xlsx"; // Desired file name
    downloadLink.click();

    toast.success("File downloaded successfully!", {
      position: "top-right",
      autoClose: 3000,
    });
  };

  //

  const useModal = [
    {
      name: "Suspend Modal",
      component: (
        <div>
          <div className="py-3"></div>
          <div className="text-center">
            <h4 className="text-black text-center fw-bold">Batch Upload</h4>
            <p className="mb-5 text-black fw-2">
              Are you sure you want to delete this staff
            </p>
            <div className="row justify-content-around">
              <div className="col-5">
                <ButtonComp
                  btnText={"No"}
                  btnClassName={" text-1 btn w-100 py-3"}
                  BorderColor={"#8B313A"}
                  onClick={() => setModalToggle(false)}
                />
              </div>
              <div className="col-5">
                <ButtonComp
                  btnText={"Yes"}
                  btnClassName={"border-0 text-white  w-100 py-3"}
                  ButtonInlineStyle={{ background: "#FF4343" }}
                />
              </div>
            </div>
          </div>
          <div className="py-3"></div>
        </div>
      ),
    },
  ];

  return (
    <>
      <section className="mx-lg-4 min-vh-100 ">
        <CardComp cardClassName={"border-0 px-lg-4 mb-3 "}>
          <div className="d-flex justify-content-between align-items-center ">
            <h4 className="fw-bold mb-0 text-black1">Batch Upload</h4>
            <div>
              <button
                className="px-4 bg-new-primary rounded text-white h-10 border-0 hover:scale-105"
                onClick={handleDownload}
              >
                Download format
              </button>
            </div>
          </div>
        </CardComp>

        {/*  */}
        <CardComp cardClassName={"border-0 px-lg-4 mb-3 pt-4"}>
          <div className="row g-5 mb-4 justify-content-between">
            <div className="col-lg-5">
              <div className="col-lg-12 border-lg-end mb-4">
                <ReusableFileUploaderComp
                  handleUpload={handleUpload}
                  loading={loading}
                  bodyText={
                    <div
                      className="py-5 w-100 rounded"
                      style={{ background: "#FAFAFA" }}
                      ref={fileUploadButton}
                      disabled={loading}
                    >
                      <div className="py-2"></div>
                      <p className="text-center text-black fw-bold">
                        click or Drag & Drop CSV file here
                      </p>
                      <div className="py-2"></div>
                    </div>
                  }
                />
              </div>

              <button
                className="col-lg-12 border-lg-end mb-5 border text-center h-10 flex  items-center justify-center rounded border-[#A1A0A0] bg-[#FAFAFA] hover:scale-105 cursor-pointer text-black select-none px-4"
                ref={button2Ref}
                onClick={handleClickButton1}
              >
                Select local folder
              </button>
            </div>
            <div className="col-lg-6 ps-lg-5">
              <CardComp cardClassName="border-0 shadow py-3">
                <h5 className="text-black mb-4 fw-bold text-center">
                  Instructions
                </h5>
                <div className="d-flex align-items-center mb-1">
                  <span>
                    <BsDot color="#8B313A" size={40} />
                  </span>
                  <p>
                    Select the date corresponding to the attendance schedule to
                    mark members attendance{" "}
                  </p>
                </div>
                <div className="d-flex align-items-center mb-1">
                  <span>
                    <BsDot color="#8B313A" size={40} />
                  </span>
                  <p>
                    Select the date corresponding to the attendance schedule to
                    mark members attendance{" "}
                  </p>
                </div>
                <div className="d-flex align-items-center mb-1">
                  <span>
                    <BsDot color="#8B313A" size={40} />
                  </span>
                  <p>
                    Select the date corresponding to the attendance schedule to
                    mark members attendance{" "}
                  </p>
                </div>
              </CardComp>
            </div>
          </div>
        </CardComp>
        <div className="col-lg-12 border-lg-end">
          <ButtonComp
            onClick={() => setModalToggle(true)}
            btnText={"Upload"}
            btnClassName={
              "col-6 col-md-5 col-lg-2 text-white bg-primary1 border-0 py-3 h6 rounded"
            }
          />
        </div>
      </section>
      <div className="my-5"></div>
      <ModalCompAdvance
        modalBody={modalPage}
        show={modalToggle}
        arrayComp={useModal}
        handleClose={() => setModalToggle(false)}
        centered
        size={"md"}
      />
    </>
  );
};
