import React from "react";
import { ButtonComp } from "../../../components";

export default function ConfirmModal({
  title,
  desc,
  setModalToggle,
  confimAction,
  isLoading = false,
}) {
  return (
    <div>
      <div className="py-3"></div>
      <div className="text-center">
        <h4 className="text-black text-center fw-bold">{title}</h4>
        <p className="mb-5 text-black fw-2">{desc}</p>
        <div className="row justify-content-around">
          <div className="col-5">
            <ButtonComp
              btnText={"No"}
              btnClassName={"border-0 text-white bg-primary1 w-100 py-3"}
              onClick={() => setModalToggle(false)}
            />
          </div>
          <div className="col-5">
            <ButtonComp
              btnText={"Yes"}
              btnClassName={" text-1 btn w-100 py-3"}
              BorderColor={"#8B313A"}
              onClick={confimAction}
              disabled={isLoading}
            />
          </div>
        </div>
      </div>
      <div className="py-3"></div>
    </div>
  );
}
