import React, { useEffect, useState } from "react";
import styledComponents from "styled-components";
import { useUserLoginMutation, useGetCoopQuery } from "../store/auth/authApi";
import { useDispatch } from "react-redux";
import {
  setCoopData,
  setToken,
  setCompanyId,
  setUserType,
  setEnums,
  setUserData,
} from "../store/auth/index.js";
import { toast } from "react-hot-toast";
import LoginPage from "../components/Modules/Login";
import Loader from "../components/Ui/Loader";
import { useGetEnumsQuery } from "../store/generalSelections/generalSelection";
import LogRocket from "logrocket";

// import { storage } from "../utils/storage";

export const Login = () => {
  const dispatch = useDispatch();
  const [fullhost, setFullhost] = useState("");

  useEffect(() => {
    let host = window.location.host;
    console.log(host, "fullhost");
    setFullhost(host);
  }, []);

  // get the company information details
  const { data, isLoading, isSuccess } = useGetCoopQuery(
    { domain_url: fullhost }
    // { skip: !fullhost }
  );
  // console.log(error, "error");
  console.log(
    data,
    isLoading,
    isSuccess,
    // error,
    " data, isLoading, isSuccess, error "
  );
  const companyDetails = data?.data;
  if (isSuccess) {
    dispatch(setCoopData(companyDetails));
    dispatch(setCompanyId(companyDetails?.company_id));
  } else {
    console.log(`Account not found: ${fullhost}`);
  }

  const [login, { isLoading: isLogging }] = useUserLoginMutation();

  async function handleLoginSubmit(values) {
    const loginResponse = await login(values);
    const { data, message, status } = loginResponse.data;
    let userProfile = data?.profile;
    let userAppId = userProfile?.staff_id;
    let userAppName = userProfile?.name;
    let userAppEmail = userProfile?.email;
    let userAppActiveBranch = userProfile?.active_branch_id;
    let companyDomain = userProfile?.company?.domain_url;
    let companyName = userProfile?.company?.name;
    let userAppRole = userProfile?.role?.name;

    let logRocketObject = {
      name: userAppName,
      email: userAppEmail,
      activeBranch: userAppActiveBranch,
      companyDomain: companyDomain,
      companyName: companyName,
      userAppRole: userAppRole,
    };
    console.log(logRocketObject, "logRocketObject");
    dispatch(setToken(data?.access_token));
    dispatch(setUserType(data?.user_type));
    dispatch(setUserData(data?.profile));
    LogRocket.identify(userAppId, logRocketObject);
    if (!status) return toast.error(message);
    toast.success(message);
  }

  const { data: enumData, isSuccess: enumFetched } = useGetEnumsQuery();
  const enumDataObject = {};
  enumData?.data?.forEach((item) => {
    enumDataObject[item.enum] = item.data;
  });
  enumData?.data?.forEach((item) => {
    const newData = item.data.map((entry) => ({
      label: entry.name, // Change "name" to "label"
      value: entry.value,
    }));
    enumDataObject[item.enum] = newData;
  });
  if (enumFetched) {
    dispatch(setEnums(enumDataObject));
  }

  return (
    <>
      {isLoading ? (
        <FullScreenContainer>
          <Loader />
        </FullScreenContainer>
      ) : (
        <>
          <Style>
            <LoginPage
              handleLoginSubmit={handleLoginSubmit}
              companyDetails={companyDetails}
              isLoading={isLogging}
            />
          </Style>
        </>
      )}
    </>
  );
};

const FullScreenContainer = styledComponents.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Style = styledComponents.div`
// height:100vh;

img{
    //  width:100%;
     filter: brightness(0.5);
}
.carousel-caption{
  text-align:left;
}
.carousel-indicators{
display:none;
}


h6.background {
  position: relative;
  z-index: 1;
  
  &:before {
      border-top: 1px solid #6B6B6B;
      content:"";
      margin: 0 auto; /* this centers the line to the full width specified */
      position: absolute; /* positioning must be absolute here, and relative positioning must be applied to the parent */
      top: 50%; left: 0; right: 0; bottom: 0;
      width: 95%;
      z-index: -1;
  }

  span { 
      /* to hide the lines from behind the text, you have to set the background color the same as the container */ 
      background: #fff; 
      padding: 0 15px; 
  }
}
@media only screen and (max-width: 991px) {
  .carousel-inner{
    height:50vh;
  }
}
@media only screen and (max-width: 491px) {
  .carousel-inner{
    height:30vh;
  }
  p{
    font-size:14px;
  }
}
`;
