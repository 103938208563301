import React from "react";
import { TableCompData } from "../../../components";
import EmptyTable from "../../../components/Common/EmptyTable";
import {
  SettingCompanyPrefixHeader,
  ViewStaffData,
} from "../../../utils/TableHeader";

export default function AllTables({
  TableDropDown,
  onSubmit,
  tableHeader,
  tableData,
  emptyMessage,
  btnText,
  marginBottom,
  cellPadding = "10px",
}) {
  return (
    <div>
      <div className="mb-5">
        <TableCompData
          cellPadding={cellPadding}
          columns={
            tableHeader
              ? tableHeader
              : SettingCompanyPrefixHeader(TableDropDown)
          }
          data={tableData ? tableData : ViewStaffData}
          marginBottom={marginBottom}
          message={
            <EmptyTable
              Message={emptyMessage || "No prefix has been created"}
              btnText={btnText || "Create new"}
              onClick={onSubmit}
            />
          }
        />
      </div>
    </div>
  );
}
