import React from "react";
import {
  CardComp,
  ModalCompAdvanceUpdate,
  TabsComp,
} from "../../../components";
import FilterFind from "../../../components/FilterFind";
import LoanRepaymentComp from "./modules/LoanRepaymentComp";
import LoanRepaymentRecord from "./LoanRepaymentRecord";
import {
  useGetDueRepaymentQuery,
  useGetOverdueRepaymentQuery,
  useGetUpcomingRepaymentQuery,
} from "../../../store/admin/loanRepayment";
import { useNavigate } from "react-router-dom";

export default function LoanRepayment() {
  const navigate = useNavigate();
  const [modalPage, setModalPage] = React.useState("");
  const [loanRepaymentDetails, setLoanRepaymentDetails] = React.useState("");
  const [upcomingRepaymentPage, setUpcomingRepaymentPage] = React.useState(1);
  const [dueRepaymentPage, setDueRepaymentPage] = React.useState(1);
  const [overdueRepaymentPage, setOverdueRepaymentPage] = React.useState(1);
  const {
    data: upcomingRepayments,
    isLoading: isLoadingUpcomingRepayments,
    isFetching: isFetchingUpcomingRepayments,
  } = useGetUpcomingRepaymentQuery(upcomingRepaymentPage);

  const {
    data: dueRepayments,
    isLoading: isLoadingDueRepayments,
    isFetching: isFetchingDueRepayments,
  } = useGetDueRepaymentQuery(dueRepaymentPage);

  const {
    data: overdueRepayments,
    isLoading: isLoadingOverdueRepayments,
    isFetching: isFetchingOverdueRepayments,
  } = useGetOverdueRepaymentQuery(overdueRepaymentPage);

  const useModal = [
    {
      name: "Record Loan",
      component: (
        <LoanRepaymentRecord
          Back={() => setModalPage()}
          loanRepaymentDetails={loanRepaymentDetails}
        />
      ),
    },
  ];
  const getTableDropDown = (row) => {
    const TableDropDown = [
      {
        name: "Record loan",
        action: (data) => {
          setLoanRepaymentDetails(row);
          setModalPage("Record Loan");
        },
      },
    ];
    return TableDropDown;
  };

  const useTab = [
    {
      name: "Upcoming repayment",
      component: (
        <LoanRepaymentComp
          title={"Loan Status"}
          getTableDropDown={getTableDropDown}
          TableData={upcomingRepayments?.data?.company_loan_requests || []}
          pagination={upcomingRepayments?.data?.pagination}
          setPageNumber={setUpcomingRepaymentPage}
          loading={isLoadingUpcomingRepayments || isFetchingUpcomingRepayments}
          name={"upcoming repayments"}
        />
      ),
    },
    {
      name: "Due repayment",
      component: (
        <LoanRepaymentComp
          title={"Loan Status"}
          getTableDropDown={getTableDropDown}
          TableData={dueRepayments?.data?.company_loan_requests || []}
          pagination={dueRepayments?.data?.pagination}
          setPageNumber={setDueRepaymentPage}
          loading={isLoadingDueRepayments || isFetchingDueRepayments}
          name={"due repayments"}
        />
      ),
    },
    {
      name: "Overdue repayment",
      component: (
        <LoanRepaymentComp
          title={"Loan Status"}
          dateClassName="bg-danger2"
          getTableDropDown={getTableDropDown}
          TableData={overdueRepayments?.data?.company_loan_requests || []}
          pagination={overdueRepayments?.data?.pagination}
          setPageNumber={setOverdueRepaymentPage}
          loading={isLoadingOverdueRepayments || isFetchingOverdueRepayments}
          name={"overdue repayments"}
        />
      ),
    },
  ];

  return (
    <section className=" px-lg-3 min-vh-100 mb-3">
      <FilterFind rightSide={false} title={"Loan Repayment "} />

      <CardComp>
        <div className="py-4"></div>
        <TabsComp
          TabArray={useTab}
          input="name"
          justifyContent={"justify-content-start"}
          tabClassName="px-0"
          TabArrayClassName="px-4"
          btn
          btnOnClick={() =>
            navigate("/super_admin/loan_management/repayment_by_member")
          }
          btnWrapper={" w-[12.5rem]"}
        />
      </CardComp>
      <ModalCompAdvanceUpdate
        pageName={modalPage}
        arrayComp={useModal}
        handleClose={() => setModalPage()}
        centered
        size={"lg"}
      />
    </section>
  );
}
