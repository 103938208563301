import { TableCompData } from "../../../../components";
import EmptyTable from "../../../../components/Common/EmptyTable";
import { SettingFineSettingHeader } from "../../../../utils/TableHeader";
import { useGetFinesQuery } from "../../../../store/admin/settings/fineSlice";

export default function FineSettingComp({
  TableDropDown,
  onSubmit,
  emptyTable,
}) {
  const { data, error } = useGetFinesQuery();
  if (error) {
    console.log(error);
  }
  console.log(data);
  return (
    <div>
      <div className="mb-5">
        <TableCompData
          marginBottom={"0"}
          columns={SettingFineSettingHeader(TableDropDown)}
          // data={ViewStaffData}
          data={[]}
          message={
            <EmptyTable
              Message={emptyTable || "No prefix has been created"}
              btnText={"Create new fine"}
              onClick={onSubmit}
            />
          }
        />
      </div>
    </div>
  );
}
