import React from "react";

import styled from "styled-components";
import { LoginCarouselData } from "../../../utils";
import FallbackImage from "../../Common/ImageWrapper";
import SwiperComp from "../../Common/SwiperComp";

import ResetPasswordForm from "./ResetPasswordForm";
import { useState } from "react";
import CheckEmail from "./CheckEmail";
import Failed from "./Failed";

const backgroundImageStyleLeft = {
  backgroundImage: `url("../Images/Login/sign-up-left.png")`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat'
};

const backgroundImageStyleRight = {
  backgroundImage: `url("../Images/Login/sign-up-right.png")`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat'
};

export default function ResetPasswordComp() {
  const [pageName, setPageName] = useState("ResetPasswordForm");
  const pages = [
    {
      name: "ResetPasswordForm",
      component: <ResetPasswordForm setPageName={setPageName} />,
    },
    {
      name: "Success",
      component: <CheckEmail />,
    },
    {
      name: "Failed",
      component: <Failed setPageName={setPageName} />,
    },
  ];
  return (
    <div className="Login">
      {/* <NavbarPage /> */}

      <div className="">
        <div className="flex flex-col lg:flex-row lg:gap-0 lg:h-screen">
          {/* <div className="col-lg-7 bg-white sideA d-block">
            <SwiperComp Data={LoginCarouselData} Page={PageSide} />
          </div> */}
          {/* <div className="lg:w-1/2 p-10 lg:p-20 min-h-screen flex flex-col items-center justify-center" style={backgroundImageStyleLeft}> */}
          <div className="lg:w-1/2 p-10 lg:px-[125px] lg:py-[195px] min-h-screen flex flex-col items-center justify-center bg-[#8B313A1A]">
            <div className="absolute top-0 left-0">
              <svg width="150" height="172" viewBox="0 0 150 172" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="-15.1161" cy="6.96153" r="164.683" transform="rotate(-36.1373 -15.1161 6.96153)" fill="#8B313A" fill-opacity="0.1" />
              </svg>
            </div>
            <div className="absolute top-0 left-0">
              <svg width="111" height="126" viewBox="0 0 111 126" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="-35.8583" cy="-21.4443" r="146.75" transform="rotate(-36.1373 -35.8583 -21.4443)" fill="#8B313A" />
              </svg>
            </div>
            <div className="absolute bottom-5 left-5">
              <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_dd_4708_45168)">
                  <circle cx="30.2743" cy="30.4064" r="17.4447" fill="#8B313A" />
                </g>
                <defs>
                  <filter id="filter0_dd_4708_45168" x="0.82959" y="0.961731" width="58.8894" height="58.8894" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="2" dy="2" />
                    <feGaussianBlur stdDeviation="5" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4708_45168" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="-2" dy="-2" />
                    <feGaussianBlur stdDeviation="5" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                    <feBlend mode="normal" in2="effect1_dropShadow_4708_45168" result="effect2_dropShadow_4708_45168" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4708_45168" result="shape" />
                  </filter>
                </defs>
              </svg>
            </div>
            {pages?.find((item) => item?.name === pageName)?.component}
          </div>
          <div className="lg:w-1/2 lg:flex lg:flex-col items-center justify-center h-screen lg:h-auto hidden" style={backgroundImageStyleRight}>
            <div className="flex flex-col items-center bg-white p-10 rounded-3xl">
              <img src="../Images/Login/sign-up-image.png" alt="profile" className="w-[100px] h-[100px]" />
              <p className="font-[700] text-[32px]">Ifesowapo Cooperative, LTC</p>
              <p className="text-[#858585] text-lg">6&7, Alaka Express, Gbagada, Surulere, Lagos</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// const PageSide = (item) => {
//   return (
//     <PageSideStyle className="position-relative bg-white text-black h-100">
//       <div className="d-none d-md-flex" style={{ height: "40vh" }}>
//         <FallbackImage src="/Login/top.png" width={246} height={246} />
//       </div>
//       <div className="mb-md-5 pb-5 container col-lg-10">
//         <h1 className="text-center fw-bolder h10 col-md-11 mb-md-4 ">
//           {item?.title}
//         </h1>
//         <p className="text-start d-none d-lg-block lh-lg">{item?.desc}</p>
//         <p className="text-center  d-md-none">
//           Lorem ipsum dolor sit amet, consectetur adipiscing elit. Consectetur
//           nunc, erat auctor commodo eu nu
//         </p>
//       </div>
//       <div className="py-md-5 my-md-5"></div>
//       <div className="d-none d-md-flex align-items-end position-absolute bottom-0 w-100">
//         <FallbackImage src="/Login/bottom.svg" width={"100%"} />
//       </div>
//       <div className="d-flex justify-content-center"></div>
//     </PageSideStyle>
//   );
// };

// const PageSideStyle = styled.div`
//   z-index: 3;
//   height: 100% !important;
// `;
