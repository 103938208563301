import React from "react";
import { BsArrowLeft } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import EmptyTable from "../../../components/Common/EmptyTable";
import { DropDownComp, TableCompData, ToolTips } from "../../../components";
import { formatMoney } from "../../../utils";
import moment from "moment";
import { BsCalendar2Week, BsThreeDotsVertical } from "react-icons/bs";

const AllLoanRepaymentByMember = ({ hash }) => {
  const memberDetail = JSON.parse(localStorage.getItem("member"));
  const navigate = useNavigate();
  const getTableDropDown = (row) => {
    const TableDropDown = [
      {
        name: "View breakdown",
        action: (data) => {
          navigate(`/super_admin/loan_management/loan_repayment/${row?.id}`);
        },
      },
    ];
    return TableDropDown;
  };
  const column = [
    {
      name: "S/N",
      selector: (_, index) => <span className="text-muted">{index + 1}</span>,
      width: "70px",
    },

    {
      name: "LOAN NAME",
      selector: (row) => <span>{`${row?.loan_type?.name}`}</span>,
      minWidth: "200px",
      maxWidth: "250px",
      wrap: true,
    },
    {
      name: "LOAN AMOUNT",
      selector: (row) => formatMoney(row?.amount),
      minWidth: "200px",
      maxWidth: "250px",
    },
    {
      name: "GUARANTOR",
      selector: (row) => (
        <div className=" bg-[rgba(255,255,255,0.19)] flex flex-wrap gap-3  py-2 items-center">
          {row?.guarantors?.map((item, index) => {
            return (
              <>
                {item.status === "CONFIRMED" ? (
                  <span
                    className={`flex items-center ${
                      item.status === "CONFIRMED" ? "opacity-100" : "opacity-0"
                    } opacity-75`}
                  >
                    <span className="me-3 ">
                      <img
                        style={{
                          width: "30px",
                          height: "30px",
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                        src="https://i.pinimg.com/originals/42/ee/db/42eedb02a8e9772664170124784428f6.jpg"
                        alt=""
                        className=""
                      />
                    </span>
                    {item.name}
                  </span>
                ) : (
                  <ToolTips
                    showText={`${item.name} is yet to consent to being the guarantor`}
                    text={
                      <span
                        // onMouseEnter={()=> set}
                        className={`flex items-center ${
                          item.status === "CONFIRMED"
                            ? "opacity-100"
                            : "opacity-0"
                        } opacity-75`}
                      >
                        <span className="me-3 ">
                          <img
                            style={{
                              width: "30px",
                              height: "30px",
                              borderRadius: "50%",
                              objectFit: "cover",
                            }}
                            src="https://i.pinimg.com/originals/42/ee/db/42eedb02a8e9772664170124784428f6.jpg"
                            alt=""
                            className=""
                          />
                        </span>
                        {item.name}
                      </span>
                    }
                  />
                )}
              </>
            );
          })}
        </div>
      ),
      minWidth: "230px",
    },

    {
      name: "NEXT PAYMENT",
      selector: (row) => {
        const selectedDateStr = row?.next_repayment;
        const selectedDate = moment(selectedDateStr, "YYYY-MM-DD");
        const today = moment();
        const daysDifference = selectedDate.diff(today, "days");
        const daysDifferenceString = daysDifference.toString();
        const formattedDifference = daysDifferenceString.startsWith("-")
          ? daysDifferenceString.substring(1)
          : daysDifferenceString;

        return (
          <div className="px-3 d-flex align-items-center">
            <span className="me-3">
              <BsCalendar2Week size={25} />
            </span>{" "}
            {row?.next_repayment === "N/A"
              ? "Not available"
              : moment(row?.next_repayment).format("DD-MMM-YYYY")}
            {row?.next_repayment === "N/A" ? (
              ""
            ) : (
              <div
                className={`ms-3 ${
                  row.breakdown_status === "OVERDUE"
                    ? "bg-danger2"
                    : "bg-success2"
                } py-2 px-3 rounded-pill`}
              >
                {`${formattedDifference} days`}
              </div>
            )}
          </div>
        );
      },
      width: "300px",
    },

    {
      name: "ACTION",
      cell: (row) => (
        <div className="text-center">
          <DropDownComp
            dropLabelClassName={"bg-white border-0 px-3 py-1 rounded-4 text-2"}
            arrayComp={getTableDropDown(row)}
            ItemClassName="pe-5 text-2"
            input="name"
            row={row}
            DropDownText={
              <span>
                <BsThreeDotsVertical size={20} />
              </span>
            }
          />
        </div>
      ),
      width: "100px",
    },
  ];

  return memberDetail?.map((member, index) => {
    return (
      <>
        <div key={index} className="space-y-6">
          <div className="space-x-2 w-full bg-white rounded-[10px] py-3 px-10 flex items-center col-lg-6 mb-3 mb-lg-0">
            <Link to={hash}>
              <BsArrowLeft size={30} className=" pointer" />
            </Link>
            <h4 className=" text-2xl text-[#8B313A] font-bold ">
              {`${member?.first_name} ${member?.last_name} `}
            </h4>
            <h4 className="font-bold mb-0 text-black1 text-[1.5rem]">
              Loan Repayment
            </h4>
          </div>
          <div className="flex flex-wrap gap-6">
            <div className="flex-1 bg-white flex space-x-2 items-center rounded-[10px] p-[2.5rem] col-lg-6 mb-3 mb-lg-0">
              <div className=" w-[6.25rem] h-[6.25rem] mr-2 rounded-[1rem]">
                <img
                  src={member?.photo}
                  alt="profile_photo"
                  className="w-full h-full rounded-[1rem]  object-cover "
                />
              </div>
              <div>
                <h4 className=" text-2xl text-[#000] font-bold ">
                  {`${member?.first_name} ${member?.last_name} `}
                </h4>
                <p className="text-base text-[#858585] font-normal ">
                  {`  Member ID: ${member?.member_no || "N/A"}`}
                </p>
                <p className="text-base text-[#858585] font-normal ">
                  {` ${member?.phone} | ${member?.email}`}
                </p>
              </div>
            </div>

            <div className="flex-1 bg-white flex justify-between items-center  rounded-[10px] p-[2.5rem] ">
              <div className="space-y-4 mr-2">
                <p className=" text-sm text-[#858585] font-normal  ">
                  Overdue repayment
                </p>
                <div className="flex items-center space-x-2">
                  <span className="text-[2rem] text-[#FF4343] font-bold  ">
                    {member?.overdue_repayment || 0}
                  </span>
                  <p className=" text-2xl text-[#000] font-bold ">Overdue</p>
                </div>
                <p className=" text-xs text-[#858585] font-normal  ">
                  {`Last payment date: 
                  ${
                    member?.last_repayment === "N/A"
                      ? "N/A"
                      : moment(member?.last_repayment).format("DD-MM-YYYY")
                  }`}
                </p>
              </div>
              <div className="border-r w-[1px]  h-full" />
              <div className="space-y-4 ml-2">
                <p className=" text-sm text-[#858585] font-normal  ">
                  Pending repayment
                </p>
                <div className="flex items-center space-x-2">
                  <span className="text-[2rem] text-[#000] font-bold  ">
                    {member?.pending_repayment || 0}
                  </span>
                  <p className=" text-2xl text-[#000] font-bold ">Pending</p>
                </div>
                <p className=" text-xs text-[#858585] font-normal  ">
                  {`Next payment date: 
                  ${
                    member?.next_repayment === "N/A"
                      ? "N/A"
                      : moment(member?.next_repayment).format("DD-MM-YYYY")
                  }`}
                </p>
              </div>
            </div>
          </div>
          <div className="w-full bg-white flex justify-between overflow-x-auto items-center flex-wrap rounded-[10px] col-lg-6 mb-3 mb-lg-0">
            <TableCompData
              columns={column}
              data={member?.disbursed_loans}
              message={
                <EmptyTable
                  Message={`There are currently no loan request`}
                  // btnText={"Add Staff"}
                />
              }
            />
          </div>
        </div>
      </>
    );
  });
};

export default AllLoanRepaymentByMember;
