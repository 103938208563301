import styled from "styled-components";
import {
  CardComp,
  TableCompData,
  ModalCompAdvanceUpdate,
  ButtonComp,
} from "../../../components";
import EmptyTable from "../../../components/Common/EmptyTable";
import Message from "../../../components/MessageModal";
import GroupForm from "./modules/GroupForm";
import AddGroupMemberModal from "./modules/AddGroupMemberModal";
import { BsArrowLeft } from "react-icons/bs";
import { useParams, useNavigate } from "react-router-dom";
import { useState, useMemo } from "react";
import {
  useGetGroupMembersQuery,
  useGetGroupByIdQuery,
} from "../../../store/admin/communicationSlice";
import { toast } from "react-hot-toast";
import { CommunicationGroupMembersHeader } from "../../../utils/TableHeader";
import { useCommunicationActions } from "./useCommunicationActions";
import TablePagination from "../../../components/Table/Pagination";

export const ViewGroup = () => {
  const navigate = useNavigate();
  const [currentMemberId, setCurrentMemberId] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [modalName, setModalName] = useState();
  const { groupId } = useParams();
  const { editGroup, addMembersToGroup } = useCommunicationActions();
  const { data: groupData = {} } = useGetGroupByIdQuery(groupId);
  const {
    data: membersData = {},
    error,
    isLoading,
  } = useGetGroupMembersQuery({
    page: pageNumber,
    groupId: groupId,
  });
  const { groupMembers, pagination } = useMemo(() => {
    const { communication_group_members = [], pagination } = membersData;
    const perPage = pagination?.perPage || 5;
    const items = communication_group_members.map((item, index) => ({
      ...item,
      serialNumber: index + 1 + (pageNumber - 1) * perPage,
    }));
    return { groupMembers: items, pagination };
  }, [membersData, pageNumber]);

  const updateGroupName = (newGroupName) => {
    setModalName();
    editGroup({ id: groupId, name: newGroupName });
  };

  const addMembers = (members) => {
    setModalName();
    addMembersToGroup(groupId, members);
  };

  const removeMember = () => {
    console.log(currentMemberId);
    setModalName();
  };

  if (error) {
    toast.error(error?.data?.message || "An error occured, kindly restart app");
  }

  const tableAction = (memberId) => {
    setModalName("Remove Member");
    setCurrentMemberId(memberId);
  };
  const modalItems = [
    {
      name: "Edit Group",
      component: (
        <GroupForm
          initialValue={groupData?.name}
          submitText="Update group name"
          onBack={() => setModalName()}
          onProceed={updateGroupName}
          title={"Edit Group Name"}
        />
      ),
    },
    {
      name: "Remove Member",
      component: (
        <Message
          title={`Remove member from Group?`}
          onBack={() => setModalName()}
          onProceed={removeMember}
        />
      ),
    },
    {
      name: "Add Member",
      component: (
        <AddGroupMemberModal
          onBack={() => setModalName()}
          onProceed={addMembers}
        />
      ),
    },
  ];

  return (
    <>
      <Wrapper className="mx-2 mx-lg-4">
        <CardComp
          cardClassName={"pt-4"}
          cardBodyClassName={"py-0 d-flex align-items-center gap-2"}
        >
          <span onClick={() => navigate(-1)}>
            <BsArrowLeft size={30} />
          </span>
          <h5 className="mb-0 fw-bold">{groupData?.name}</h5>
          <img
            role="button"
            src="/Icon/edit2.png"
            alt="edit"
            onClick={() => setModalName("Edit Group")}
          />
        </CardComp>
        <div className="d-flex align-items-center justify-content-between gap-p flex-wrap mb-3 mt-4">
          <h5 className="fw-bold">Member List</h5>
          {groupMembers.length > 0 && (
            <ButtonComp
              btnClassName={"bg-primary1 px-3 py-2 text-white rounded"}
              btnText={"Add new member"}
              onClick={() => setModalName("Add Member")}
            />
          )}
        </div>
        <TableCompData
          marginBottom={"5rem"}
          data={groupMembers}
          columns={CommunicationGroupMembersHeader(tableAction)}
          message={
            isLoading ? (
              <p className="py-4">Loading...</p>
            ) : (
              <EmptyTable
                Message={"No members in this group"}
                btnText={"Add member"}
                onClick={() => setModalName("Add Member")}
              />
            )
          }
        />
        {groupMembers.length > 0 && (
          <TablePagination handlePageChange={setPageNumber} {...pagination} />
        )}
        <ModalCompAdvanceUpdate
          pageName={modalName}
          arrayComp={modalItems}
          centered={true}
        />
      </Wrapper>
    </>
  );
};

const Wrapper = styled.section`
  .modal-content {
    border-radius: 30px;
  }
`;

export default ViewGroup;
