import { API } from "../api";
const BASE_URL = "/members/calendar_events";

const memberEventsExtentedApi = API.injectEndpoints({
  endpoints: (builder) => ({
    getMemberEvents: builder.query({
      query: () => `${BASE_URL}?guard=member`,
      providesTags: (result) => {
        return [{ type: "MemberEvent", id: "LIST" }];
      },
      transformResponse: (responseData) => responseData.data,
    }),
    getMemberEventById: builder.query({
      query: (eventId) => `${BASE_URL}/${eventId}?guard=member`,
      providesTags: (result, error, arg) => {
        return [{ type: "MemberEvent", id: arg }];
      },
      transformResponse: (responseData) => responseData.data,
    }),
  }),
});

export const { useGetMemberEventsQuery, useGetMemberEventByIdQuery } =
  memberEventsExtentedApi;
