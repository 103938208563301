import styled from "styled-components";
import { ButtonComp, CardComp } from "../../../../components";
import { Form, Formik } from "formik";
import { TextInputComp, FormikDatePicker } from "../../../../components";
import TextEditor from "../../modules/TextEditor";
const EMinuteEditor = ({
  initialValues,
  handleContentChange,
  handleSubmit,
}) => {
  return (
    <Wrapper>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form>
          <CardComp
            cardClassName={"pt-4"}
            cardBodyClassName={"py-0 row justify-content-between"}
          >
            <div className="col-12 col-lg-5">
              <TextInputComp
                name="title"
                labelText={"E-Minutes Title"}
                LabelClassName={"fw-bold"}
              />
            </div>
            <div className="col-12 col-lg-5">
              <FormikDatePicker
                fieldName={"date"}
                labelClassName={"fw-bold"}
                labelText={"Minutes date"}
              />
            </div>
          </CardComp>
          <TextEditor
            initialValue={initialValues?.content}
            handleContentChange={handleContentChange}
          />
          <div className="d-flex flex-wrap gap-4 mt-4">
            <ButtonComp
              btnText={"Save & Publish"}
              btnClassName={"bg-primary1 py-2 px-4 text-white rounded"}
              type={"submit"}
            />
            <button className="save fw-bold">Save</button>
          </div>
        </Form>
      </Formik>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  input {
    background: #fafafa;
    border: 1px solid #a1a0a0;
    border-radius: 5px;
  }
  button {
    min-width: 200px;
    border-radius: 10px;
    border: 1px solid #8b313a;
  }
  button.save {
    color: #8b313a;
  }
`;

export default EMinuteEditor;
