import styled from "styled-components";
import { PageTitle } from "../modules/PageHeaders";
import { useState } from "react";
import { PageTab } from "../../../components";
import NewMessage from "./modules/NewMessage";
import { AnimatePresence } from "framer-motion";
import { useCreateMessageMutation } from "../../../store/admin/communicationSlice";
import { toast } from "react-hot-toast";
import toastNotify from "../modules/toastNotify";
import { useNavigate } from "react-router-dom";
import DraftedMessages from "./modules/DraftedMessages";
import SentMessages from "./modules/SentMessages";
import { useGetCommunicationMessageRecipientTypesQuery } from "../../../store/selectableSlice";

const initialValues = {
  group_id: "",
  receipt_type_id: "",
  header: "",
  sending_mode: "",
  recipients: [],
};

export const Communications = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [formValues, setFormValues] = useState(initialValues);

  const { data: receiptTypes = [] } =
    useGetCommunicationMessageRecipientTypesQuery();

  const updateForm = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const updateRecipients = (selectedRecipients = []) => {
    setFormValues((prev) => ({
      ...prev,
      recipients: selectedRecipients.map((recipient) => ({
        member_id: recipient.value,
      })),
    }));
  };

  const [createMessage] = useCreateMessageMutation();
  const sendMessage = async (endpoint) => {
    const reqBody = {
      ...formValues,
      group_id: parseInt(formValues.group_id) || formValues.group_id,
      receipt_type_id:
        parseInt(formValues.receipt_type_id) || formValues.receipt_type_id,
      body: message,
      endpoint: endpoint,
    };
    const receipt_type_name =
      receiptTypes.find(({ id }) => String(id) === formValues.receipt_type_id)
        ?.name || "";
    console.log(receipt_type_name);
    if (receipt_type_name === "Individual") {
      delete reqBody.group_id;
    } else {
      delete reqBody.recipients;
    }
    const toastId = toast.loading("Creating message");
    const { data, error } = await createMessage(reqBody);
    toastNotify(error, data, toastId, navigate);
  };

  const tabs = [
    {
      name: "Compose new message",
      component: (
        <NewMessage
          message={message}
          setMessage={setMessage}
          formValues={formValues}
          sendMessage={sendMessage}
          updateForm={updateForm}
          updateRecipients={updateRecipients}
        />
      ),
    },
    {
      name: "Draft/outbox",
      component: <DraftedMessages />,
    },
    {
      name: "Sent items",
      component: <SentMessages />,
    },
  ];
  const [tabName, setTab] = useState(tabs[0].name);
  return (
    <>
      <Wrapper className="mx-2 mx-lg-4">
        <PageTitle title={"Communication"} />
        <div className="tab-header">
          {tabs.map(({ name }) => {
            return (
              <p
                key={name}
                className={`tab-name ${
                  tabName === name ? "active-tab" : ""
                } mb-0`}
                onClick={() => setTab(name)}
              >
                {name}
              </p>
            );
          })}
        </div>
        <AnimatePresence exitBeforeEnter>
          <PageTab page={tabName} PageArray={tabs} />
        </AnimatePresence>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.section`
  .tab-header {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 2rem 2rem 1rem 2rem;
    background-color: #ffffff;
    border-radius: 10px;
  }
  .tab-name {
    padding: 0 1rem;
    color: #858585;
    font-weight: 400;
    cursor: pointer;
    transition: all 300ms linear;
  }
  .active-tab {
    color: #8b313a;
    border-bottom: 2px solid #8b313a;
    font-weight: 700;
  }
`;

export default Communications;
