import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PageSwitch, ModalCompAdvanceUpdate } from "../../../components";
// import AdminLayout from "../../../Layout/AdminLayout";
// import AllBranch from "./modules/AllBranch";
import AllCompanyPrefix from "./modules/AllCompanyPrefix";
// import CreateBranch from "./modules/CreateBranch";
import CreateCompanyPrefix from "./modules/CreateCompanyPrefix";
import {
  useGetPrefixesQuery,
  useDeletePrefixMutation,
  selectAllPrefixes,
} from "../../../store/admin/settings/companyPrefixSlice";
import { dateFromString } from "../../../utils";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import ConfirmModal from "../modules/ConfirmModal";

export default function CompanyPrefix() {
  const [modalName, setModalName] = useState("");
  const [pageName, setPageName] = useState("Cooperative Prefix");
  const navigate = useNavigate();
  const location = useLocation();
  const [prefixId, setPrefixId] = useState(null);
  const [deletePrefix, { isLoading: IsDeletingPrefix }] =
    useDeletePrefixMutation();
  const removePrefix = async () => {
    const toastId = toast.loading("Deleting...");
    try {
      const { data } = await deletePrefix(prefixId);
      if (data.status === false) {
        toast.error(data?.message || "An error occurred", {
          id: toastId,
        });
      }
      if (data.status === true) {
        toast.success(data?.message || "Prefix deleted successfully", {
          id: toastId,
        });
      }
    } catch (error) {
      toast.error(
        error?.message || error?.data?.message || "An error occurred",
        { id: toastId }
      );
      console.log(error);
    } finally {
      setModalName(null);
      setPrefixId("");
    }
  };

  const { isFetching, isError, error, isSuccess } = useGetPrefixesQuery();
  const allPrefixes = useSelector(selectAllPrefixes);
  const formattedPrefixes = allPrefixes.map((item, index) => {
    return {
      id: item.id,
      prefixId: item.id,
      serialNumber: index + 1, // to be used as S/N
      branchName: item?.branch.name,
      prefixNumber: item.number || "no number",
      prefixCount: item.count,
      date: dateFromString(item.created_at),
    };
  });
  if (isFetching) {
  }
  if (isError) {
    if (error.status === 401) {
      return navigate("/");
    }
    console.log(error);
  }
  if (isSuccess) {
    // console.log(formattedPrefixes);
  }

  const TableDropDown = [
    {
      name: "Edit",
      action: (data) => {
        navigate(`${location?.pathname}/${data?.id}`);
      },
    },
    {
      name: "Delete",
      action: ({ prefixId }) => {
        setPrefixId(prefixId);
        setModalName("Delete Prefix");
      },
    },
  ];

  const usePage = [
    {
      name: "Cooperative Prefix",
      component: (
        <AllCompanyPrefix
          marginBottom={"72px"}
          isFetching={isFetching}
          tableData={formattedPrefixes}
          TableDropDown={TableDropDown}
          onSubmit={() => setPageName("Create Cooperative Prefix")}
        />
      ),
    },
    {
      name: "Create Cooperative Prefix",
      component: (
        <CreateCompanyPrefix onNext={() => setPageName("Cooperative Prefix")} />
      ),
    },
  ];

  const modals = [
    {
      name: "Delete Prefix",
      component: (
        <ConfirmModal
          title={"Delete Prefix"}
          desc={"Are you sure you want to delete this prefix?"}
          setModalToggle={() => setModalName(false)}
          confimAction={() => removePrefix()}
          isLoading={IsDeletingPrefix}
        />
      ),
    },
  ];

  return (
    <>
      <section className="px-2 px-lg-4 min-vh-100">
        <div className="py-1"></div>
        <PageSwitch pageArray={usePage} pageName={pageName} />
      </section>
      <ModalCompAdvanceUpdate
        arrayComp={modals}
        pageName={modalName}
        handleClose={() => setModalName("")}
        centered={true}
        size={"md"}
      />
    </>
  );
}
