import { createSlice } from "@reduxjs/toolkit";
import { storage } from "../../utils/storage";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    coopData: storage["localStorage"].get("coop_details_profile") || null,
    companyId: storage["localStorage"].get("company_id") || null,
    userData: storage["localStorage"].get("user_details_profile") || null,
    token: storage["cookieStorage"].get("access_token") || null,
    user_type: storage["localStorage"].get("user_type") || null,
    enums: storage["localStorage"].get("enums"),
  },
  reducers: {
    setCoopData: (state, { payload }) => {
      state.coopData = payload;
      storage["localStorage"].set("coop_details_profile", payload);
    },
    setCompanyId: (state, { payload }) => {
      state.companyId = payload;
      storage["localStorage"].set("company_id", payload);
    },
    setToken: (state, { payload }) => {
      state.token = payload;
      storage["cookieStorage"].set("access_token", payload);
    },
    setUserType: (state, { payload }) => {
      state.user_type = payload;
      storage["localStorage"].set("user_type", payload);
    },
    setUserData: (state, { payload }) => {
      state.userData = payload;
      storage["localStorage"].set("user_details_profile", payload);
    },
    setEnums: (state, { payload }) => {
      state.enum = payload;
      storage["localStorage"].set("enums", payload);
    },
    logout: (state) => {
      state.coopData = null;
      state.userData = null;
      state.companyId = null;
      state.token = null;
      state.user_type = null;
      storage["localStorage"].remove("coop_details_profile");
      storage["localStorage"].remove("company_id");
      storage["localStorage"].remove("user_details_profile");
      storage["localStorage"].remove("user_type");
      storage["cookieStorage"].remove("access_token");
      storage["localStorage"].remove("enums");
    },
  },
});

export const { reducer, actions } = authSlice;
export const {
  logout,
  setCoopData,
  setUserData,
  setToken,
  setUserType,
  setCompanyId,
  setEnums,
} = actions;
export const authState = reducer;
export const selectCurrentCoopData = (state) => state.auth.coopData;
export const selectCurrentUserData = (state) => state.auth.userData;
export const selectCurrentCompanyId = (state) => state.auth.companyId;
export const selectToken = (state) => state.auth.token;
export const selectUserType = (state) => state.auth.user_type;
export const selectEnums = (state) => state.auth.enums;
