import { API } from "../../api";
const BASE_URL = "/settings/cooperatives/roles";

const extendedRoleApi = API.injectEndpoints({
  endpoints: (builder) => ({
    getRoles: builder.query({
      query: () => `${BASE_URL}?guard=staff`,
    }),
  }),
});

export const { useGetRolesQuery } = extendedRoleApi;
