import React, { useState } from "react";
import { PageSwitch } from "../../../components";
import LoanTypePage from "./modules/LoanTypePage";
import CreateLoanType from "./modules/CreateLoanType";
import LoanTypeEdit from "./LoanTypeEdit";

const LoanType = () => {
  const [pageName, setPageName] = useState("loan_type");
  const [detailsToEdit, setDetailsToEdit] = useState();
  const usePage = [
    {
      name: "loan_type",
      component: (
        <LoanTypePage
          setPageName={setPageName}
          setDetailsToEdit={setDetailsToEdit}
          detailsToEdit={detailsToEdit}
        />
      ),
    },
    {
      name: "create_loan_type",
      component: <CreateLoanType setPageName={setPageName} />,
    },
    {
      name: "edit_loan_type",
      component: (
        <LoanTypeEdit setPageName={setPageName} detailsToEdit={detailsToEdit} />
      ),
    },
  ];

  return <PageSwitch pageArray={usePage} pageName={pageName} />;
};

export default LoanType;
