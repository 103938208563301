// import AdminLayout from "../../../Layout/AdminLayout";
import PrefixForm from "./modules/PrefixForm";
import { useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import {
  useEditPrefixMutation,
  selectPrefixById,
  useGetPrefixesQuery,
} from "../../../store/admin/settings/companyPrefixSlice";
import { useState } from "react";
import { useSelector } from "react-redux";

const EditCompanyPrefix = () => {
  const { id } = useParams();
  const { isLoading: isLoadingPrefixes } = useGetPrefixesQuery();
  const prefix = useSelector((state) => selectPrefixById(state, id));
  const initialValues = {
    name: prefix?.name || "",
    count: prefix?.count || "",
    branch_id: prefix?.branch_id,
    branch_name: prefix?.branch?.name,
  };
  const [branchId, setBranchId] = useState(initialValues.branch_id);
  const [editPrefix, { isLoading: isSubmittingForm }] = useEditPrefixMutation();

  const handleFormSubmit = async (formValues, { setSubmitting, resetForm }) => {
    const reqBody = {
      ...formValues,
      count: Number(formValues.count),
      branch_id: branchId,
      prefixId: String(id),
    };
    // console.log(reqBody);
    const toastId = toast.loading("Processing...");
    try {
      const payload = await editPrefix(reqBody).unwrap();
      if (payload.status === false) {
        toast.error(payload.message || "An error occured", {
          id: toastId,
        });
      }
      if (payload.status === true) {
        toast.success(payload.message || "Success", {
          id: toastId,
        });
      }
    } catch (error) {
      toast.error(error?.message || error.data?.message || "An error occured", {
        id: toastId,
      });
      // console.log(error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <section className="px-2 px-lg-4 min-vh-100">
      <div className="py-4"></div>
      <div className="mb-4">
        <h4 className=" text-black1 fw-bold">{"Edit Prefix"}</h4>
      </div>
      {isLoadingPrefixes ? (
        <div className="py-4">
          <p>Loading...</p>
        </div>
      ) : (
        <PrefixForm
          initialValues={initialValues}
          handleFormSubmit={handleFormSubmit}
          branchId={branchId}
          setBranchId={setBranchId}
          isSubmitting={isSubmittingForm}
        />
      )}
    </section>
  );
};

export default EditCompanyPrefix;
