import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import {
  CardComp,
  ModalCompAdvance,
  TableCompData,
} from "../../../../components";
import EmptyTable from "../../../../components/Common/EmptyTable";
import { ContributionHistoryHeader } from "../../../../utils/TableHeader";
import { useGetMembersContributionHistoryQuery } from "../../../../store/admin/contribution";
import TablePagination from "../../../../components/Table/Pagination";

export const ContributionHistoryList = ({
  title,
  TableDropDown,
  modalPage,
  setModalToggle,
  useModal,
  modalToggle,
}) => {
  const navigate = useNavigate();
   const [pageNumber, setPageNumber] = useState(1);

  const { data, isFetching, isSearching } =
    useGetMembersContributionHistoryQuery(pageNumber);
  const tableData = data?.data?.categories;
  const pagination = data?.data?.pagination

  return (
    <section className=" px-lg-3 ">
      <CardComp cardClassName={"border-0 ps-3 mb-3"}>
        <div>
          <h3 className=" text-black1 fw-bold mb-0">{title || "View Staff"}</h3>
        </div>
      </CardComp>
      <CardComp cardClassName={"border-0 ps-3"}>
        <div className="py-4"></div>
        <main>
          <div className="mb-5">
            <TableCompData
              marginBottom={"50px"}
              columns={ContributionHistoryHeader(TableDropDown)}
              data={tableData}
              message={
                isFetching || isSearching ? (
                  <div className=" py-4 ">
                    <h3>Loading...</h3>
                  </div>
                ) : (
                  <EmptyTable
                    Message={
                      <h3 className="text-muted pb-4 h40 fw-light">
                        There are currently no active contributions
                      </h3>
                    }
                    btnText={"Create Contribution"}
                    onClick={() =>
                      navigate("/super_admin/contribution/create_contribution")
                    }
                  />
                )
              }
            />
            {tableData?.length > 0 && (
              <TablePagination
                {...pagination}
                handlePageChange={setPageNumber}
              />
            )}
          </div>
          <div></div>
        </main>
      </CardComp>

      <ModalCompAdvance
        modalBody={modalPage}
        show={modalToggle}
        arrayComp={useModal}
        handleClose={() => setModalToggle(false)}
        centered
        size={"md"}
      />
    </section>
  );
};
