import React from 'react'
import { BsChevronRight } from 'react-icons/bs'
import FallbackImage from '../../Common/ImageWrapper'

export default function TopNavNotification({title,desc,time}) {
  return (
    <div style={{background:'#E0E0E0'}} className="py-2 px-3 h60 rounded d-flex align-items-center justify-content-between mb-3">
    <div className="me-2">
      <FallbackImage
      src='/Icon/message.svg'
      height='40'
      width='40'
      />
    </div>
    <div>
      <div className="h50 fw-2">Welcome on board</div>
      <div className="h60">We’re glad you join us, expect more goodies</div>
      <div className="h60">15 mins ago</div>
    </div>
    <div><BsChevronRight size={20}/></div>
  </div>
  )
}
