import styled from "styled-components";
import { CardComp, ButtonComp, SelectComp2 } from "../../../../components";
import { useState } from "react";

const AddGroupMembers = ({ marginTop }) => {
  const [addedMembers, setAddedMembers] = useState([]);
  const updateAddedMembers = (value) => {
    console.log(value);
    setAddedMembers([]);
  };
  return (
    <Wrapper marginTop={marginTop}>
      <CardComp cardClassName={"pt-4"} cardBodyClassName={"py-0"}>
        <div className="row justify-content-between align-items-start">
          <div className="col-6">
            <div>
              <label htmlFor="name" className="fw-bold mb-2">
                Group name
              </label>
              <input id="name" name="name" className="w-100 py-2" />
            </div>
            <article className="d-flex flex-wrap mt-4 added-members">
              {addedMembers.map((member, index) => (
                <div key={index}></div>
              ))}
            </article>
          </div>
          <div className="col-5">
            <SelectComp2
              labelText={"Group member"}
              selectClassName={"select"}
              LabelClassName={"fw-bold"}
              placeholder={"Select member"}
              setSelectedOption={(value) => updateAddedMembers(value)}
            />
          </div>
        </div>
      </CardComp>
      <ButtonComp
        btnText={"Save Group"}
        btnClassName={" bg-primary1 px-4 py-2 text-white rounded"}
        BorderColor={"#8B313A"}
        // onClick={() => onBack()}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: ${(props) => (props?.marginTop ? props.marginTop : "5rem")};
  input,
  .select {
    background: #fafafa;
    border: 1px solid #a1a0a0;
    border-radius: 5px;
  }
`;

export default AddGroupMembers;
