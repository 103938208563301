import React from "react";
import { BsArrowLeft, BsDot } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import {
  ButtonComp,
  CardComp,
  TextInputComp,
  FormikSelectComp,
} from "../../../components";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import {
  useGetSingleStaffQuery,
  useChangeBranchMutation,
} from "../../../store/admin/staff.js";
import { toast } from "react-hot-toast";
import Loader from "../../../components/Ui/Loader";

export const ChangeBranch = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    data: staffDetails,
    isLoading,
    isFetching,
  } = useGetSingleStaffQuery(id);

  const initialValues = {
    from: staffDetails?.active_branch_id,
    to: null,
  };

  const validationSchema = Yup.object({
    staff_name: Yup.string(),
    from: Yup.string(),
    to: Yup.string("Please Select a branch"),
  });

  const [changeBranch, { isLoading: isChangingBranch }] =
    useChangeBranchMutation();

  const handleFormSubmit = async (formikValues, { resetForm }) => {
    delete formikValues.staff_name;
    const updatedFormikValues = {
      payload: {
        ...formikValues,
        to: parseInt(formikValues.to),
        from: staffDetails?.active_branch_id,
      },
      staffId: id,
    };
    const response = await changeBranch(updatedFormikValues);
    const { message, status } = response?.data;
    resetForm();
    if (!status) {
      toast.error(message);
    } else {
      toast.success(message);
    }
  };

  return (
    <>
      {isLoading || isFetching ? (
        <CardComp cardClassName={"border-0 px-lg-4 mb-3 py-2"}>
          <Loader />
        </CardComp>
      ) : (
        <>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
          >
            <Form className=" px-3 px-lg-4 min-vh-100 ">
              <CardComp cardClassName={"border-0 px-lg-4 mb-3 py-1"}>
                <div className="d-flex ">
                  <BsArrowLeft
                    onClick={() =>
                      navigate("/super_admin/staff_registration/view_staff")
                    }
                    size={30}
                    className="me-3 pointer"
                  />
                  <h4 className="fw-bold mb-0 text-black1">Change branch</h4>
                </div>
              </CardComp>

              <CardComp cardClassName={"border-0 px-lg-4 mb-3 pt-5 pb-2"}>
                <div className="row g-5 mb-4">
                  <div className="col-lg-6">
                    <div className="pe-lg-5">
                      <div className="col-lg-12 border-lg-end mb-4">
                        <TextInputComp
                          labelText={"Staff Name"}
                          placeholder={"Alabi Adeola Martins"}
                          value={`${staffDetails?.first_name} ${staffDetails?.last_name}`}
                          name="staff_name"
                        />
                      </div>
                      <div className="col-lg-12 border-lg-end mb-3">
                        <TextInputComp
                          labelText={"Staff Name"}
                          placeholder={"Alabi Adeola Martins"}
                          value={staffDetails?.active_branch?.name}
                          name="from"
                        />
                      </div>
                      <div className="col-lg-12 border-lg-end mb-5">
                        <FormikSelectComp
                          labelClassName={"fw-2"}
                          fieldName={`to`}
                          labelText={"Branch"}
                          options={staffDetails?.branches?.map((branch) => ({
                            label: branch?.branch?.name,
                            value: branch?.branch_id,
                          }))}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 ">
                    <CardComp cardClassName="border-0 shadow py-1">
                      <h5 className="text-black mb-2 fw-bold text-center">
                        Instructions
                      </h5>
                      <div className="d-flex align-items-center mb-1">
                        <span>
                          <BsDot color="#8B313A" size={40} />
                        </span>
                        <p>
                          Select the date corresponding to the attendance
                          schedule to mark members attendance{" "}
                        </p>
                      </div>
                      <div className="d-flex align-items-center mb-1">
                        <span>
                          <BsDot color="#8B313A" size={40} />
                        </span>
                        <p>
                          Select the date corresponding to the attendance
                          schedule to mark members attendance{" "}
                        </p>
                      </div>
                      <div className="d-flex align-items-center mb-1">
                        <span>
                          <BsDot color="#8B313A" size={40} />
                        </span>
                        <p>
                          Select the date corresponding to the attendance
                          schedule to mark members attendance{" "}
                        </p>
                      </div>
                    </CardComp>
                  </div>
                </div>
              </CardComp>
              <div className="col-md-3 border-lg-end">
                <ButtonComp
                  loading={isChangingBranch}
                  type="submit"
                  btnText={"Save changes"}
                  btnClassName={
                    "w-100 text-white bg-primary1 w-100 border-0 py-3 h6 rounded"
                  }
                />
              </div>
            </Form>
          </Formik>
          <div className="my-5"></div>
        </>
      )}
    </>
  );
};
