import { useState } from "react";
import { useGetCommunicationMessagesQuery } from "../../../../store/admin/communicationSlice";
import EmptyTable from "../../../../components/Common/EmptyTable";
import { TableCompData } from "../../../../components";
import styled from "styled-components";
import { CommunicationMessagesHeader } from "../../../../utils/TableHeader";
import TablePagination from "../../../../components/Table/Pagination";

const SentMessages = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const { data = {}, isLoading } = useGetCommunicationMessagesQuery({
    page: pageNumber,
    status: "SENT",
  });
  const { communication_group_messages = [], pagination = {} } = data;
  return (
    <Wrapper>
      <div>
        <TableCompData
          marginBottom={"2rem"}
          columns={CommunicationMessagesHeader()}
          data={[
            ...communication_group_messages.map((item, index) => ({
              ...item,
              serialNumber:
                index + 1 + (pageNumber - 1) * pagination.perPage || 20,
            })),
          ]}
          message={
            isLoading ? (
              <p className="py-4">Loading...</p>
            ) : (
              <EmptyTable Message={"No sent group messages record found"} />
            )
          }
        />
        {communication_group_messages.length > 0 && (
          <TablePagination handlePageChange={setPageNumber} {...pagination} />
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 4px;
`;

export default SentMessages;
