/* eslint-disable react/prop-types */
/* eslint-disable sort-keys */
/* eslint-disable react/display-name */
import React from "react";
import DataTable from "react-data-table-component";

import styled from "styled-components";
import { CardComp } from "../cardComp";
const Style = styled.div`
  .rdt_TableHeadRow {
    font-size: 14px !important;
    color: #fff;
    font-weight: 600;
    background: #383838;
    border-start-end-radius: 10px;
    border-start-start-radius: 10px;
  }

  .rdt_TableBody {
    margin-bottom: ${(props) =>
      props?.MarginBottom ? props?.MarginBottom : `20rem`};
  }
  .rdt_TableCell {
    border: 1px solid #e0e0e0;
    padding: ${(props) => (props?.cellPadding ? props.cellPadding : "10px")};
    font-size: 14px !important;
  }
`;

export const TableCompData = ({
  title,
  data,
  pagination,
  columns,
  selectableRows,
  progressPending = false,
  message,
  marginBottom,
  cellPadding,
}) => {
  return (
    <Style cellPadding={cellPadding} MarginBottom={marginBottom}>
      <CardComp cardBodyClassName={"py-0"} cardClassName={"pt-4"}>
        <DataTable
          responsive={true}
          title={title}
          columns={columns}
          data={data ? data : []}
          defaultSortFieldId={1}
          // sortIcon={<SortIcon />}
          pagination={pagination ? true : false}
          // // onSelectedRowsChange={handleRowSelected}
          selectableRows={selectableRows ? true : false}
          progressPending={progressPending}
          persistTableHead={true}
          noDataComponent={message ? message : false}
          // selectableRows
        />
      </CardComp>
    </Style>
  );
};

export const PlainTable = ({
  title,
  data,
  pagination,
  columns,
  selectableRows,
  progressPending = false,
  message,
  marginBottom,
  cellPadding,
}) => {
  return (
    <Style cellPadding={cellPadding} MarginBottom={marginBottom}>
      <DataTable
        responsive={true}
        title={title}
        columns={columns}
        data={data ? data : []}
        defaultSortFieldId={1}
        // sortIcon={<SortIcon />}
        pagination={pagination ? true : false}
        // // onSelectedRowsChange={handleRowSelected}
        selectableRows={selectableRows ? true : false}
        progressPending={progressPending}
        persistTableHead={true}
        noDataComponent={message ? message : false}
        // selectableRows
      />
    </Style>
  );
};
