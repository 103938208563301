import { useParams, useNavigate } from "react-router-dom";
import { useGetAttendanceByMemberIdQuery } from "../../../store/admin/attendanceSlice";
import AttendanceFilter from "./modules/AttendanceFilter";
import { CardComp, PlainTable } from "../../../components";
import EmptyTable from "../../../components/Common/EmptyTable";
import { useEffect, useMemo } from "react";
import { AttendanceHistoryHeader } from "../../../utils/TableHeader";
import { useState } from "react";
import { status_icon } from "./modules/AttendanceFilter";
import TablePagination from "../../../components/Table/Pagination";
import Profile from "./modules/Profile";
import { PageTitleWithNav } from "../modules/PageHeaders";
import { useViewMemberQuery } from "../../../store/admin/memberManagement/memberSlice";
import { toast } from "react-hot-toast";

const MemberAttendanHistory = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const { memberId } = useParams();
  const navigate = useNavigate();

  const { data: memberData = {} } = useViewMemberQuery(memberId);

  const {
    data = {},
    error,
    isFetching,
  } = useGetAttendanceByMemberIdQuery({
    member_id: memberId,
    page: pageNumber,
  });

  const { attendance_history, pagination, barProgress, barPercent } =
    useMemo(() => {
      let totalPresent = 0;
      let totalAbsent = 0;
      const { company_attendances = [], pagination = {} } = data;
      const perPage = pagination.perPage || 5;
      const items = company_attendances?.map((attendance, index) => {
        const { attendanceStatus = [], attendanceCount = {} } = attendance;
        totalPresent += attendanceCount.present || 0;
        totalAbsent += attendanceCount.absent || 0;
        const status = attendanceStatus.map((sta) => {
          const [day, , dt] = new Date(sta?.created_at)
            .toDateString()
            .split(" ");
          return {
            ...sta,
            icon: status_icon[sta?.attendance_status_id],
            week_day: day,
            date: dt,
          };
        });
        return {
          ...attendance,
          serialNumber: index + 1 + (pageNumber - 1) * perPage,
          attendanceStatus: status,
        };
      });
      return {
        pagination,
        attendance_history: items,
        barProgress: (totalPresent / (totalAbsent + totalPresent)) * 360,
        barPercent: (totalPresent / (totalAbsent + totalPresent)) * 100,
      };
    }, [data, pageNumber]);

  useEffect(() => {
    if (error?.status === 401) {
      toast.error(error?.data?.message || "Login to continue");
      navigate("/login");
    }
  }, [error, navigate]);

  return (
  
      <section className="mx-lg-4 min-vh-100 pb-5 mb-5">
        <PageTitleWithNav
          action={() => navigate(-1)}
          highlightText={`${memberData.first_name} ${memberData.last_name}`}
          title={"Monthly Attendance History"}
        />
        <Profile
          barProgress={barProgress}
          barPercent={barPercent}
          memberData={memberData}
        />
        <CardComp cardClassName={"pt-4"} cardBodyClassName={"py-0"}>
          <AttendanceFilter />
          <div>
            <PlainTable
              marginBottom={"0px"}
              message={
                isFetching ? (
                  <p className="py-4">Loading...</p>
                ) : (
                  <EmptyTable Message={"No record found"} />
                )
              }
              columns={AttendanceHistoryHeader(true)}
              data={attendance_history}
            />
          </div>
        </CardComp>
        {attendance_history.length > 0 && (
          <div className="bg-white py-4 mt-2">
            <TablePagination
              marginTop={"0"}
              handlePageChange={setPageNumber}
              {...pagination}
            />
          </div>
        )}
      </section>
    
  );
};

export default MemberAttendanHistory;
