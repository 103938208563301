import "react-calendar/dist/Calendar.css";
import { CardComp, PlainTable } from "../../../components";
import { AttendanceHistoryHeader } from "../../../utils/TableHeader";
import FilterFind from "../../../components/FilterFind";
import EmptyTable from "../../../components/Common/EmptyTable";
import { useGetMarkedMembersAttendanceQuery } from "../../../store/admin/attendanceSlice";
import { useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import TablePagination from "../../../components/Table/Pagination";
import AttendanceFilter from "./modules/AttendanceFilter";
import { status_icon } from "./modules/AttendanceFilter";

export default function AttendanceHistory() {
  const [pageNumber, setPageNumber] = useState(1);
  const navigate = useNavigate();
  const location = useLocation();
  const perPage = 20;
  const {
    data: markedAttendance = {},
    error,
    isFetching,
  } = useGetMarkedMembersAttendanceQuery({
    page: pageNumber,
    limit: perPage,
  });

  const { company_attendances, pagination } = useMemo(() => {
    const { company_attendances = [], pagination = {} } = markedAttendance;
    const items = company_attendances.map((attendance, index) => {
      const { attendanceStatus = [] } = attendance;
      const status = attendanceStatus.map((sta) => {
        const [day, , dt] = new Date(sta?.created_at).toDateString().split(" ");
        return {
          ...sta,
          icon: status_icon[sta?.attendance_status_id],
          week_day: day,
          date: dt,
        };
      });
      return {
        ...attendance,
        serialNumber:
          index + 1 + (pageNumber - 1) * pagination.perPage || perPage,
        attendanceStatus: status,
        viewAll: () => {
          navigate(`${location.pathname}/${attendance?.member?.id}`);
        },
      };
    });
    return {
      pagination,
      company_attendances: items,
    };
  }, [markedAttendance, pageNumber, location.pathname, navigate]);
  if (error & (error?.status === 401)) {
    navigate("/login");
  }
  return (
    <section className="mx-lg-4 min-vh-100 pb-5 mb-5">
      <FilterFind title={"Attendance History"} />
      <CardComp>
        <div className="py-3"></div>
        <AttendanceFilter />
        <div>
          <PlainTable
            marginBottom={"0px"}
            message={
              isFetching ? (
                <p className="py-4">Loading...</p>
              ) : (
                <EmptyTable
                  Message={"No record found"}
                  btnText={"Mark attendance"}
                />
              )
            }
            columns={AttendanceHistoryHeader()}
            data={company_attendances}
          />
        </div>
      </CardComp>
      {company_attendances.length > 0 && (
        <>
          <div className="py-4"></div>
          <div className="bg-white py-4 mt-4">
            <TablePagination
              marginTop={"0"}
              handlePageChange={setPageNumber}
              {...pagination}
            />
          </div>
        </>
      )}
    </section>
  );
}
