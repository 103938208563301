/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { RiSearch2Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { ButtonComp } from "../../../components";
import { useSearchMembersQuery } from "../../../store/admin/memberManagement/memberSlice";

export default function SearchLoanRepaymentByMember({ hash }) {
  const [searchTerm, setSearchTerm] = useState("???>..");

  useEffect(() => {
    if (searchTerm === "") {
      setSearchTerm("???>..");
    }
  }, [searchTerm]);

  const navigate = useNavigate();
  const onSearch = (e) => {
    setSearchTerm(e);
  };

  const {
    data: searchResult,
    isLoading,
    isFetching,
  } = useSearchMembersQuery({
    page: 1,
    search: searchTerm,
  });

  return (
    <div className="space-y-6">
      <div className="w-full bg-white rounded-[10px] py-3 px-10 flex items-center col-lg-6 mb-3 mb-lg-0">
        <BsArrowLeft
          size={30}
          onClick={() =>
            navigate("/super_admin/loan_management/loan_repayment")
          }
          className="me-3 pointer"
        />
        <h4 className="font-bold mb-0 text-black1 text-[1.5rem]">
          Loan Repayment
        </h4>
      </div>
      <div className="w-full bg-white rounded-[10px] p-[2.5rem] space-y-6 col-lg-6 mb-3 mb-lg-0">
        <h4 className="font-bold mb-0 text-black1 text-[2rem]">
          Search member by <span className="text-primary1">Name</span> or{" "}
          <span className="text-primary1">ID</span> to see <br /> repayment
          actions
        </h4>
        <div className="input-group  search me-3">
          <span className="input-group-text bg-black2 px-4 py-0 my-0">
            <RiSearch2Line size={25} color={"#fff"} />
          </span>
          <input
            type="text"
            className="form-control py-3 bg-gary3"
            placeholder="Search your query here"
            onKeyUp={(event) => {
              onSearch(event.target.value);
            }}
          />
        </div>
      </div>

      {isLoading || isFetching ? (
        <div className="w-full h-[40vh] grid place-items-center"> Loading</div>
      ) : searchResult?.length === 0 ? (
        <div className="w-full bg-white flex justify-between items-center flex-wrap rounded-[10px] p-[2.5rem] col-lg-6 mb-3 mb-lg-0">
          <div className="flex space-x-2 items-center">
            <div className=" w-[6.25rem] h-[6.25rem] bg-[#D9D9D9] rounded-[1rem]"></div>
            <div>
              <h4 className=" text-2xl text-[#000] font-bold ">
                No user found
              </h4>
              <p className="text-base text-[#858585] font-normal ">
                Retry your query
              </p>
            </div>
          </div>
        </div>
      ) : (
        searchResult?.members?.map((member, index) => {
          return (
            <div
              key={index}
              className="w-full bg-white flex justify-between items-center flex-wrap rounded-[10px] p-[2.5rem] col-lg-6 mb-3 mb-lg-0"
            >
              <div className="flex space-x-2 items-center">
                <div className=" w-[6.25rem] h-[6.25rem] mr-2 rounded-[1rem]">
                  <img
                    src={member?.photo}
                    alt="profile_photo"
                    className="w-full h-full rounded-[1rem]  object-cover "
                  />
                </div>
                <div>
                  <h4 className=" text-2xl text-[#000] font-bold ">
                    {`${member?.first_name} ${member?.last_name} `}
                  </h4>
                  <p className="text-base text-[#858585] font-normal ">
                    {`  Member ID: ${member?.member_no || "N/A"}`}
                  </p>
                  <p className="text-base text-[#858585] font-normal ">
                    {` ${member?.phone} | ${member?.email}`}
                  </p>
                </div>
              </div>
              <div className="w-[7rem]">
                <ButtonComp
                  onClick={() => {
                    localStorage.setItem("member", JSON.stringify([member]));
                    navigate(
                      // eslint-disable-next-line no-undef
                      `/super_admin/loan_management/repayment_by_member${hash}`
                    );
                  }}
                  btnText={"View loan"}
                  btnClassName={
                    "w-100 bg-primary1 py-[10px] text-white border-0 rounded fw-3"
                  }
                />
              </div>
            </div>
          );
        })
      )}
    </div>
  );
}
