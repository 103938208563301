/* eslint-disable array-callback-return */
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { MdOutlineClose } from "react-icons/md";
import { ScrollComp } from "../Ui";
import { ProSidebarProvider } from "react-pro-sidebar";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";

export const SidebarAdmin = ({ hideSider, setHideSider, sideBarData = [] }) => {
  const location = useLocation();
  const navigate = useNavigate();


  return (
    <StyledSideBar HideSider={hideSider}>
      <aside id="sidebar-main" className="sidebar-main overflow-hidden ">
        <div className="mb-4 ">
          <div className="  d-flex justify-content-center align-items-center my-2   px-3 py-2">
            <img src="/Images/logo1.svg" alt="" />
          </div>
          <p className="text-center text-1 fw-bold h40">
            Ifesowapo Cooperative, <br />
            Lagos Nigeria
          </p>

          <div className="d-xl-none">
            <MdOutlineClose
              className="pointer"
              size={30}
              onClick={() => setHideSider(!hideSider)}
            />
          </div>
        </div>
        <ScrollComp>
          <ProSidebarProvider>
            <div
              className=""
              style={{ display: "flex", height: "100%", width: "100%" }}
            >
              <Sidebar backgroundColor="#fff" width="100%">
                <Menu
                  renderMenuItemStyles={({
                    level,
                    disabled,
                    active,
                    open,
                  }) => ({
                    ".menu-icon": {
                      backgroundColor: "",
                    },
                    "& .menu-anchor:hover": {
                      color: "white !important",
                      backgroundColor: "#383838 !important",
                      fontWeight: "bold",
                      borderRadius: "0.25rem",
                    },
                    "&.menu-item.open > a": {
                      backgroundColor: active ? "#383838" : "initial",
                      color: active ? "white" : "black",
                      borderRadius: "0.25rem",
                    },
                    "&.menu-item.active > a": {
                      backgroundColor: active ? "#383838" : "initial",
                      color: active ? "white" : "black",
                      borderRadius: "0.25rem",
                    },

                    ".sub-menu-content > ul > .menu-item.active > a": {
                      backgroundColor: "transparent",
                      color: active ? "#383838" : "initial",
                      fontWeight: active ? "bold" : "normal",
                    },
                  })}
                >
                  {sideBarData?.map((item, index) => {
                    if (item?.dropDown === "none") {
                      return (
                        <MenuItem
                          onFocus={() => location.pathname.includes(item?.path)}
                          active={location.pathname.includes(item?.path)}
                          onClick={() =>
                            item?.path && navigate(`${item?.path}`)
                          }
                          key={index}
                          icon={
                            <span className="h0">
                              {item?.icon ? (
                                item?.icon
                              ) : (
                                <img
                                  src={
                                    location.pathname.includes(item?.path)
                                      ? item?.image1 || item?.image
                                      : item?.image || item?.image1
                                  }
                                  alt={item?.name}
                                />
                              )}
                            </span>
                          }
                          className="h50 mb-0 mt-1 fw-2"
                        >
                          {item?.name}
                        </MenuItem>
                      );
                    } else {
                      return (
                        <SubMenu
                          onFocus={() => location.pathname.includes(item?.path)}
                          key={index}
                          suffix={false}
                          defaultOpen={
                            location.pathname.includes(item?.path) && true
                          }
                          icon={
                            <span className="h50">
                              {item?.icon ? (
                                item?.icon
                              ) : (
                                <img
                                  src={
                                    location.pathname.includes(item?.path)
                                      ? item?.image1 || item?.image
                                      : item?.image || item?.image1
                                  }
                                  alt={item?.name}
                                />
                              )}
                            </span>
                          }
                          active={location.pathname.includes(item?.path)}
                          color={
                            location.pathname.includes(item?.path)
                              ? "white"
                              : "black"
                          }
                          label={
                            <div className="d-flex align-items-center h50 fw-2">
                              {item?.name}
                            </div>
                          }
                        >
                          {item?.subMenu?.map((subItem, i) => (
                            <MenuItem
                              active={location.pathname === subItem?.path}
                              onClick={() =>
                                subItem?.path && navigate(`${subItem?.path}`)
                              }
                              key={i}
                              className="h60 mb-0 mt-1"
                            >
                              {" "}
                              {subItem?.name}{" "}
                            </MenuItem>
                          ))}
                        </SubMenu>
                      );
                    }
                  })}
                </Menu>
              </Sidebar>
            </div>
          </ProSidebarProvider>
        </ScrollComp>
      </aside>
    </StyledSideBar>
  );
};

const StyledSideBar = styled.div`
  .sidebar-main {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 300px;
    z-index: 996;
    transition: all 0.3s;
    /* padding: 20px; */
    overflow-y: auto;
    scrollbar-width: thin !important;
    scrollbar-color: #aab7cf transparent;
    box-shadow: 0px 0px 20px rgba(1, 41, 112, 0.1);
    background-color: #fff;
  }
  @media (max-width: 1199px) {
    .sidebar-main {
      left: ${(props) => (props?.HideSider ? "0" : "-300px")};
    }
  }
  li {
    margin-bottom: 10px;
    padding: 0 7px;
  }
  .sidebar-main::-webkit-scrollbar {
    width: 5px !important;
    height: 8px;
    background-color: #fff;
  }
  .sidebar-main::-webkit-scrollbar-thumb {
    background-color: #aab7cf !important;
  }

  @media (min-width: 1200px) {
    #main,
    #footer {
      margin-left: 300px;
    }
  }
  @media (max-width: 1199px) {
    .toggle-sidebar .sidebar {
      left: 0;
    }
  }
  @media (min-width: 1200px) {
    .toggle-sidebar #main,
    .toggle-sidebar #footer {
      margin-left: 0;
    }
    .toggle-sidebar .sidebar {
      left: -300px;
    }
  }

  .sidebar-nav {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  .sidebar-nav li {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  .sidebar-nav .nav-item {
    margin-bottom: 5px;
  }
  .sidebar-nav .nav-heading {
    font-size: 11px;
    text-transform: uppercase;
    color: #899bbd;
    font-weight: 600;
    margin: 10px 0 5px 15px;
  }
  .sidebar-nav .nav-link {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 600;
    color: #8900a8;
    transition: 0.3;
    background: #f6f9ff;
    padding: 10px 15px;
    border-radius: 4px;
  }
  .sidebar-nav .nav-link i {
    font-size: 16px;
    margin-right: 10px;
    color: #8900a8;
  }
  .sidebar-nav .nav-link.collapsed {
    /* color: #012970; */
    background: #fff;
  }
  .sidebar-nav .nav-link.collapsed i {
    color: #899bbd;
  }
  .sidebar-nav .nav-link:hover {
    color: #8900a8;
    background: #f6f9ff;
  }
  .sidebar-nav .nav-link:hover i {
    color: #8900a8;
  }
  .sidebar-nav .nav-link .bi-chevron-down {
    margin-right: 0;
    transition: transform 0.2s ease-in-out;
  }
  .sidebar-nav .nav-link:not(.collapsed) .bi-chevron-down {
    transform: rotate(180deg);
  }
  .sidebar-nav .nav-content {
    padding: 5px 0 0 0;
    margin: 0;
    list-style: none;
  }
  .sidebar-nav .nav-content a {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    color: #012970;
    transition: 0.3;
    padding: 10px 0 10px 40px;
    transition: 0.3s;
  }
  .sidebar-nav .nav-content a i {
    font-size: 6px;
    margin-right: 8px;
    line-height: 0;
    border-radius: 50%;
  }
  .sidebar-nav .nav-content a:hover,
  .sidebar-nav .nav-content a.active {
    color: #8900a8;
  }
  .sidebar-nav .nav-content a.active i {
    background-color: #8900a8;
  }
`;
