import React from "react";
import FilterFind from "../../../components/FilterFind";
import { useNavigate } from "react-router-dom";
import { storage } from "../../../utils/storage";
import { CardComp, TableCompData } from "../../../components";
import { formatMoney } from "../../../utils";

const LoanCalculationResult = () => {
  const navigate = useNavigate();
  const results = storage["localStorage"].get("loan_calculator_result");


  const column = [
    {
      name: "S/N",
      selector: (_, index) => <span className="text-muted">{index + 1}</span>,
      width: "70px",
    },
    {
      name: "Repayment scheme",
      selector: (row) => <span className="">{row.repayment_type}</span>,
    },
    {
      name: "Opening balance",
      selector: (row) => (
        <span className="">{formatMoney(row.starting_balance)}</span>
      ),
    },
    {
      name: "Interest",
      selector: (row) => <span className="">{formatMoney(row.interest)}</span>,
    },
    {
      name: "Principal",
      selector: (row) => <span className="">{formatMoney(row.amount)}</span>,
    },
    {
      name: "Closing balance",
      selector: (row) => (
        <span className="">{formatMoney(row.ending_balance)}</span>
      ),
    },
    {
      name: "Total interest",
      selector: (row) => <span className="">{formatMoney(row.interest)}</span>,
    },
  ];

  return (
    <>
      <FilterFind
        back={true}
        backBtn={() => navigate(-1)}
        rightSide={false}
        title="Loan Calculator"
      />
      <CardComp cardClassName={"border-0 pt-4 pb-5"}>
        <main>
          <TableCompData
            marginBottom={"1rem"}
            columns={column}
            data={results}
            message={
              ""
              //   loading ? (
              //     <div className=" py-4 ">
              //       <h3>Loading...</h3>
              //     </div>
              //   ) : (
              //     <EmptyTable Message={`There are currently no ${name}`} />
              //   )
            }
          />
          {/* {pagination && (
            <TablePagination
              marginTop={"0"}
              handlePageChange={setPageNumber}
              {...pagination}
            />
          )} */}
        </main>
      </CardComp>
    </>
  );
};

export default LoanCalculationResult;
