export const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: "1px solid #E6EAEE",
    outline: "none",
    cursor: "none",
    height: "44px",
  }),
  //   menu: (provided, state) => ({
  //     ...provided,
  //     backgroundColor: "lightgray",
  //     // Add any other custom styles you want for the menu here
  //   }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "rgba(139, 49, 58, 1)" : "white",
    // Add any other custom styles you want for the options here
  }),
};
