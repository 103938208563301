import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Cancel } from "../../../assets/svgs/cancel.svg";
import { useGetMembersQuery } from "../../../store/admin/memberManagement/memberSlice";
import {
  ModalCompAdvanceUpdate,
  SelectCompWithFormattedLabel,
} from "../../../components";
import * as Yup from "yup";
import RecordContributionForm from "./modules/record-contribution-form";
import { useFormik } from "formik";
import {
  useCreateMemberContributionMutation,
  useGetContributionCategoriesQuery,
} from "../../../store/admin/contribution";
import toast from "react-hot-toast";
import { formatMoney } from "../../../utils";

const Record_ContributionMain = () => {
  const { data: membersList } = useGetMembersQuery(1);
  const navigate = useNavigate();

  const [showWarning, setShowWarning] = useState(true);
  const [pageName, setPageName] = useState("");

  // formik initial values
  const initialValues = {
    member_id: "",
    formParts: [
      {
        contribution_category_id: "",
        amount: "",
        payment_type_id: "",
        payment_date: "",
        transaction_code: "",
      },
    ],
  };

  // formik validation schema
  const validationSchema = Yup.object().shape({
    member_id: Yup.string().required("Member Name is required"),
    formParts: Yup.array().of(
      Yup.object().shape({
        contribution_category_id: Yup.string().required(
          "Contribution Name is required"
        ),
        amount: Yup.number().required("Amount is required"),
        payment_type_id: Yup.string().required("Payment Method is required"),
        payment_date: Yup.string().required("Payment date is required"),
        transaction_code: Yup.string().when("payment_type_id", {
          is: (value) => ["1", "3", "4"].includes(value),
          then: Yup.string().required("Transaction code is required"),
          otherwise: Yup.string(),
        }),
      })
    ),
  });

  // formik config
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {
      setPageName("Review");
    },
  });
  // add form
  const handleAddForm = () => {
    formik.setFieldValue("formParts", [
      ...formik.values.formParts,
      {
        contribution_category_id: "",
        amount: "",
        payment_type_id: "",
        payment_date: "",
        transaction_code: "",
      },
    ]);
  };
  // remove form
  const handleRemove = (indexToRemove) => {
    formik.setFieldValue(
      "formParts",
      formik.values.formParts.filter((_, index) => index !== indexToRemove)
    );
  };
  //getting all members
  const { allMembers } = React.useMemo(() => {
    const fetchedMembers = membersList?.members || [];
    const formattedMembers = fetchedMembers.map((item, index) => {
      return {
        name: item.first_name + " " + item.last_name,
        photo: item.photo,
        userId: item.member_no,
        value: item.id,
      };
    });
    return { allMembers: formattedMembers };
  }, [membersList]);

  const { data: contributionCategoriesList } =
    useGetContributionCategoriesQuery();

  const formattedContributionCategories =
    contributionCategoriesList?.categories?.map((item) => {
      return {
        label: `${item.name} | ${formatMoney(item.total_amount)}`,
        value: item.id,
      };
    });

  const [createMemberContribution, { isLoading: isCreating }] =
    useCreateMemberContributionMutation();

  const handleFormSubmit = async (values) => {
    const transformedData = {
      member_id: values.member_id,
      fields: values.formParts.map((formPart) => ({
        contribution_category_id: formPart.contribution_category_id,
        payment_type_id: formPart.payment_type_id,
        transaction_code: formPart.transaction_code,
        payment_date: formPart.payment_date,
        amount: parseInt(formPart.amount), // Convert amount to integer
      })),
    };
    const response = await createMemberContribution(transformedData);
    const { message, status } = response.data;
    if (!status) return toast.error(message);
    console.log(transformedData, "transformedData");
    navigate(`/super_admin/contribution/contribution_history`);
    setPageName();
    formik.resetForm();
    toast.success(message);
  };

  // names
  const member = allMembers?.find(
    (member) => member.value === formik.values.member_id
  );

  // Extract the name from the matched member object
  const memberName = member ? member.name : "";

  const contributions = formik.values.formParts.map((part) => {
    const contributionCategory = formattedContributionCategories?.find(
      (category) => category.value === part.contribution_category_id
    );
    const contributionName = contributionCategory
      ? contributionCategory.label
      : "";
    const contributionAmount = part.amount || ""; // Get the amount from formParts

    return {
      name: contributionName,
      amount: contributionAmount,
    };
  });

  const totalAmount = contributions?.reduce((total, contribution) => {
    // Parse the amount string to a number and add it to the total
    return total + parseFloat(contribution.amount || 0);
  }, 0);

  const useModal = [
    {
      name: "Review",
      component: (
        <div className="w-full py-5 px-2 min-h-[200px]">
          <div className="w-full justify-center items-center flex gap-2 pb-4">
            <img
              src="/Icon/cancel.svg"
              role="button"
              onClick={() => setPageName()}
              alt="close"
              className="close-img  h-5 absolute right-5 top-5"
            />
            <p className="text-[#1E1E1E] font-bold text-2xl ">
              <span className="text-[#8B313A]">{memberName}</span>
              &nbsp;Contribution Record
            </p>
          </div>

          <div className="flex flex-col gap-3 border-y py-3">
            {contributions.map((item, i) => (
              <div key={i} className="flex justify-between">
                <p className="text-sm text-[#858585]">{item.name}</p>
                <p className="font-bold text-sm text-[#1E1E1E]">
                  {formatMoney(item.amount)}
                </p>
              </div>
            ))}
          </div>
          <div className="flex justify-between w-full mt-4">
            <p className="text-sm text-[#858585]">Total</p>
            <p className="font-bold text-sm text-[#8B313A]">
              {formatMoney(totalAmount, "NGN")}
            </p>
          </div>
          <div className="w-full flex justify-center items-center mt-4">
            <button
              onClick={() => handleFormSubmit(formik.values)}
              className="h-12 w-[200px] rounded-[10px] bg-[#8B313A] text-white"
            >
              {isCreating ? "Submitting Information..." : "Submit information"}
            </button>
          </div>
        </div>
      ),
    },
  ];
  return (
    <main className=" w-full flex flex-col gap-10 pb-10">
      <div className="bg-white rounded-[10px] px-4 lg:px-10">
        <p className="text-[#45464E] text-2xl font-bold h-20 flex items-center">
          Record Contribution
        </p>
      </div>

      {showWarning && (
        <div className="flex rounded-[10px] gap-3 bg-white h-[104px]">
          <div className="h-full w-2 bg-[#31778C] rounded-l-[10px]" />
          <div className="w-full h-full flex justify-between pr-6 pt-3">
            <div>
              <p className="font-semibold text-lg text-[#383838]">
                Please note
              </p>
              <p className="text-[#383838]">
                You can add more than one contribution at a time.{" "}
              </p>
            </div>

            <Cancel onClick={() => setShowWarning(false)} />
          </div>
        </div>
      )}

      <section className="w-full  ">
        <form className="w-full " onSubmit={formik.handleSubmit}>
          <section className="w-full flex flex-col gap-4 rounded-[10px] bg-white p-5 lg:p-10">
            <div className="w-full grid grid-cols-1 lg:grid-cols-2 pb-4 border-b">
              <SelectCompWithFormattedLabel
                setOption={(value) =>
                  formik.setFieldValue("member_id", value.value)
                }
                labelText="Member name"
                LabelClassName="fw-2"
                arrayComp={allMembers}
              />

              {formik.errors.member_id && (
                <p className="mt-1 text-red-500">{formik.errors.member_id}</p>
              )}
            </div>

            {/* <RecordContributionForm /> */}
            {formik.values.formParts.map((form, index) => (
              <RecordContributionForm
                key={index}
                index={index}
                formik={formik}
                handleRemove={handleRemove}
                formattedContributionCategories={
                  formattedContributionCategories
                }
                fieldNames={{
                  contribution_category_id: "contribution_category_id",
                  amount: "amount",
                  payment_type_id: "payment_type_id",
                  payment_date: "payment_date",
                  transaction_code: "",
                }}
              />
            ))}

            <button
              type="button"
              className="bg-[#8B313A] w-[200px] disabled:opacity-50 rounded-lg h-12 text-white"
              onClick={handleAddForm}
              disabled={!(formik.isValid && formik.dirty)}
            >
              Add New
            </button>
          </section>
          <div className="mt-5">
            <button
              type="submit"
              className="bg-[#8B313A] w-[200px] rounded-lg h-12 text-white"
            >
              Save record
            </button>
          </div>
        </form>
      </section>

      <ModalCompAdvanceUpdate
        size={"md"}
        pageName={pageName}
        centered
        arrayComp={useModal}
        handleClose={() => setPageName()}
      />
    </main>
  );
};

export default Record_ContributionMain;
