import EventForm from "./EventForm";
import useEventActions from "../useEventActions";
export default function CreateEventCalendar({ onNext }) {
  const { createEvent } = useEventActions();
  const initialFormValues = {
    start_date: "",
    end_date: "",
    name: "",
    start_time: "",
    description: "",
    address: "",
    duration: "",
    reminder_count: "",
    attendees: "",
  };
  const handleFormSubmit = (formikValues) => {
    const reqBody = {
      ...formikValues,
    };
    createEvent(reqBody);
  };
  return (
    <>
      <EventForm
        onNext={handleFormSubmit}
        btnText={"Create new event"}
        initialFormValues={initialFormValues}
      />
    </>
  );
}
