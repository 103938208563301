import React from "react";
import {
  ButtonComp,
  MoneyInputComp,
  TextInputComp,
  FormikDatePicker,
} from "../../../components";
import PageWrapper from "../modules/PageWrapper";
import { Formik, Form } from "formik";
import * as Yup from "yup";

export default function MemberCreateContribution() {
  const initialValues = {
    amount: "",
    start_date: "",
    end_date: "",
    description: "",
    contribution_name: "",
    contribution_type: "",
  };
  const validationSchema = Yup.object({
    amount: Yup.string().required("Required"),
    start_date: Yup.string().required("Required"),
    end_date: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    contribution_name: Yup.string().required("Required"),
    contribution_type: Yup.string().required("Required"),
  });
  const handleFormSubmit = async (formikValues, { resetForm }) => {
    console.log(formikValues, "formik values");
    //  const updatedValues = {
    //    ...formikValues,
    //    amount: parseInt(formikValues.amount),
    //    company_bank_id: parseInt(formikValues.company_bank_id),
    //  };
    //  const response = await createContributionCategory(updatedValues);
    //  console.log(response);
    //  const { message, status } = response.data;
    //  if (!status) return toast.error(message);
    //  navigate(`/super_admin/contribution/contribution_history`);
    //  toast.success(message);
    resetForm();
  };

  return (
    <PageWrapper
      mainClassName="bg-white"
      title={"Personal contribution creation"}
      back
    >
      <section className="px-3  px-lg-4">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          <Form className="mt-5 row justify-content-between gy-4">
            <div className="col-lg-5">
              <TextInputComp
                LabelText={"Contribution name"}
                borderColor="#A1A0A0"
                LabelClassName={"fw-2"}
                placeholder={"Input contribution name"}
                name={"contribution_name"}
                type="text"
              />
            </div>
            <div className="col-lg-5">
              {/* <TextInputComp
                  LabelText={"Contribution description"}
                  LabelClassName={"fw-2"}
                  placeholder="detailed information about this description"
                /> */}
              <TextInputComp
                LabelText={"Contribution description"}
                borderColor="#A1A0A0"
                LabelClassName={"fw-2"}
                placeholder={"detailed information about this description"}
                name={"description"}
                type="text"
              />
            </div>
            <div className="col-lg-5">
              <MoneyInputComp
                LabelText={"Amount Paid"}
                borderColor="#A1A0A0"
                LabelClassName={"fw-2"}
                placeholder={"Enter Amount"}
                descClassName="text-danger"
                name={`amount`}
              />
            </div>
            <div className="col-lg-5">
              {/* <DatePickerInputComp
                  LabelText={"Start date"}
                  LabelClassName={"fw-2"}
                  placeholder="Select end date"
                /> */}
              <FormikDatePicker
                fieldName={"start_date"}
                labelText={"Start date"}
                labelClassName={"fw-2"}
                placeholder="Select start date"
              />
            </div>
            <div className="col-lg-5">
              <TextInputComp
                LabelText={"Contribution type"}
                borderColor="#A1A0A0"
                LabelClassName={"fw-2"}
                placeholder={"Create contribution threshold"}
                name={"contribution_type"}
                type="text"
              />
              {/* <TextInputComp
                  LabelText={"Contribution type"}
                  LabelClassName={"fw-2"}
                  placeholder="Create contribution threshold"
                /> */}
            </div>
            <div className="col-lg-5 mb-5">
              {/* <DatePickerInputComp
                  LabelClassName={"fw-2"}
                  LabelText={"End date"}
                  placeholder="Select end date"
                /> */}
              <FormikDatePicker
                fieldName={"end_date"}
                labelText={"End date"}
                labelClassName={"fw-2"}
                placeholder="Select end date"
              />
            </div>
            <div className="col-lg-12 d-flex justify-content-center">
              <div className="col-8 col-lg-5">
                <ButtonComp
                  type="submit"
                  btnText={"Create"}
                  btnClassName={"bg-primary1 text-white w-100 py-2 rounded"}
                />
              </div>
            </div>
          </Form>
        </Formik>
      </section>
    </PageWrapper>
  );
}
