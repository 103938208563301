import React from "react";
import { ButtonComp, CardComp, DropDownComp } from "../../../components";
import FallbackImage from "../../../components/Common/ImageWrapper";
import { ViewMemberDashboardCards } from "../../../utils";
import CardInfo from "../../Admin/Home/components/CardInfo";
import ActivitiesRow from "../../Admin/Home/components/ActivitiesRow";
import {
  LateContributions,
  RecentTransactions,
} from "../../../utils/dummbyData";
import { AiOutlineClockCircle } from "react-icons/ai";
import { BsCalendar3 } from "react-icons/bs";
import PageWrapper from "../modules/PageWrapper";
import { useMemberDashboardQuery } from "../../../store/member/memberDashboard/memberDashboardSlice";
import { Link } from "react-router-dom";

export default function MemberHome() {

  const { data } = useMemberDashboardQuery();
  const memberDashboardData = data ? data.data : null;

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-NG', {
      style: 'currency',
      currency: 'NGN',
      minimumFractionDigits: 2
    }).format(value);
  };

  return (
    <PageWrapper title={"Dashboard"}>

      <div className="grid lg:flex gap-3 mb-7">
        <div className="bg-white rounded-md p-4 w-full lg:w-1/2 flex items-center">
          <div className="flex gap-5 flex-wrap">
            <div className="flex items-center gap-3">
              <div className="bg-[#A8E1C8] p-2 rounded-full">
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.21 23.3361C6.27996 23.3361 1.45996 18.5161 1.45996 12.5861C1.45996 6.65606 6.27996 1.83606 12.21 1.83606C12.62 1.83606 12.96 2.17606 12.96 2.58606C12.96 2.99606 12.62 3.33606 12.21 3.33606C7.10996 3.33606 2.95996 7.48606 2.95996 12.5861C2.95996 17.6861 7.10996 21.8361 12.21 21.8361C17.31 21.8361 21.46 17.6861 21.46 12.5861C21.46 12.1761 21.8 11.8361 22.21 11.8361C22.62 11.8361 22.96 12.1761 22.96 12.5861C22.96 18.5161 18.14 23.3361 12.21 23.3361Z" fill="#19BC31" />
                  <path d="M21.21 8.33606H17.21C16.8 8.33606 16.46 7.99606 16.46 7.58606V3.58606C16.46 3.17606 16.8 2.83606 17.21 2.83606C17.62 2.83606 17.96 3.17606 17.96 3.58606V6.83606H21.21C21.62 6.83606 21.96 7.17606 21.96 7.58606C21.96 7.99606 21.62 8.33606 21.21 8.33606Z" fill="#19BC31" />
                  <path d="M17.2099 8.33551C17.0199 8.33551 16.8299 8.26551 16.6799 8.11551C16.3899 7.82551 16.3899 7.34551 16.6799 7.05551L21.6799 2.05551C21.9699 1.76551 22.4499 1.76551 22.7399 2.05551C23.0299 2.34551 23.0299 2.82551 22.7399 3.11551L17.7399 8.11551C17.5899 8.26551 17.3999 8.33551 17.2099 8.33551Z" fill="#19BC31" />
                  <path d="M8.99057 16.5861V8.88512H10.1567L14.0182 14.6828V8.88512H15.1843V16.5861H14.0182L10.1567 10.7884V16.5861H8.99057Z" fill="black" />
                  <line x1="8.20947" y1="11.336" x2="16.2104" y2="11.336" stroke="black" stroke-width="1.00012" />
                  <line x1="8.20947" y1="13.336" x2="16.2104" y2="13.336" stroke="black" stroke-width="1.00012" />
                </svg>
              </div>
              <div>
                <p className="text-[22px] font-[500]">{formatCurrency(memberDashboardData?.totalContribution)}</p>
                <p className="text-sm">Total Contribution made</p>
              </div>
            </div>
            <div className="flex items-center gap-3">
              <div className="bg-[#E0A8AE] p-2 rounded-full">
                <svg width="25" height="25" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.2006 12.9958H4.34059C3.99392 12.9958 3.66724 12.9625 3.37391 12.8959C3.22058 12.8759 3.03392 12.8225 2.84059 12.7492C1.95392 12.4159 0.907257 11.5959 0.907257 9.55587V6.12252C0.907257 3.94252 2.16059 2.68921 4.34059 2.68921H10.2006C12.0406 2.68921 13.2273 3.56919 13.5473 5.16919C13.6073 5.46252 13.6339 5.77585 13.6339 6.12252V9.55587C13.6339 11.7492 12.3873 12.9958 10.2006 12.9958ZM4.34724 3.70256C2.70724 3.70256 1.91391 4.49586 1.91391 6.13586V9.56921C1.91391 10.7625 2.33392 11.4959 3.19392 11.8226C3.32725 11.8692 3.45392 11.9025 3.57392 11.9225C3.82726 11.9759 4.07391 12.0025 4.34724 12.0025H10.2072C11.8472 12.0025 12.6406 11.2092 12.6406 9.56921V6.13586C12.6406 5.85586 12.6206 5.60919 12.5739 5.38253C12.3473 4.24919 11.5739 3.70256 10.2072 3.70256H4.34724Z" fill="#383838" />
                  <path d="M12.2005 14.9959H6.34054C5.77387 14.9959 5.2672 14.9159 4.83387 14.7493C3.85387 14.3826 3.21386 13.6093 2.99386 12.5026C2.96053 12.3359 3.01388 12.1626 3.13388 12.0493C3.25388 11.9293 3.42721 11.8826 3.59388 11.9226C3.81388 11.9693 4.06054 11.9959 4.34054 11.9959H10.2005C11.8405 11.9959 12.6339 11.2026 12.6339 9.56261V6.12926C12.6339 5.84926 12.6139 5.60259 12.5672 5.37592C12.5339 5.20926 12.5872 5.0426 12.7005 4.9226C12.8205 4.8026 12.9872 4.74925 13.1539 4.78925C14.7539 5.11592 15.6339 6.30259 15.6339 8.12926V11.5626C15.6339 13.7493 14.3872 14.9959 12.2005 14.9959ZM4.22055 12.9959C4.43388 13.3826 4.75387 13.6559 5.19387 13.8159C5.51387 13.9359 5.90053 13.9959 6.34719 13.9959H12.2072C13.8472 13.9959 14.6405 13.2026 14.6405 11.5626V8.12926C14.6405 7.07593 14.3139 6.37593 13.6405 6.00927C13.6405 6.04927 13.6405 6.08926 13.6405 6.12926V9.56261C13.6405 11.7426 12.3872 12.9959 10.2072 12.9959H4.34719C4.30053 12.9959 4.26055 12.9959 4.22055 12.9959Z" fill="#383838" />
                  <path d="M7.27371 10.1093C6.02705 10.1093 5.0137 9.09591 5.0137 7.84924C5.0137 6.60258 6.02705 5.58923 7.27371 5.58923C8.52038 5.58923 9.53372 6.60258 9.53372 7.84924C9.53372 9.09591 8.52038 10.1093 7.27371 10.1093ZM7.27371 6.58923C6.58038 6.58923 6.0137 7.15591 6.0137 7.84924C6.0137 8.54258 6.58038 9.10925 7.27371 9.10925C7.96705 9.10925 8.53372 8.54258 8.53372 7.84924C8.53372 7.15591 7.96705 6.58923 7.27371 6.58923Z" fill="#292D32" />
                  <path d="M3.46066 9.81592C3.18733 9.81592 2.96066 9.58925 2.96066 9.31592V6.38257C2.96066 6.10924 3.18733 5.88257 3.46066 5.88257C3.734 5.88257 3.96066 6.10924 3.96066 6.38257V9.31592C3.96066 9.58925 3.74066 9.81592 3.46066 9.81592Z" fill="#292D32" />
                  <path d="M11.0804 9.81592C10.8071 9.81592 10.5804 9.58925 10.5804 9.31592V6.38257C10.5804 6.10924 10.8071 5.88257 11.0804 5.88257C11.3538 5.88257 11.5804 6.10924 11.5804 6.38257V9.31592C11.5804 9.58925 11.3604 9.81592 11.0804 9.81592Z" fill="#292D32" />
                </svg>
              </div>
              <div>
                <p className="text-[22px] font-[500]">{formatCurrency(memberDashboardData?.loanBalance)}</p>
                <p className="text-sm">Loan Balance</p>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-md p-4 w-full lg:w-1/2">
          <div className="flex gap-3 items-center mb-2 lg:mb-0">
            <p className="lg:text-[21px] font-[600]">Loan Repayment</p>
            <p className="bg-[#FF43431A] text-[#FF4343] font-[600] text-sm rounded-full p-2">{formatCurrency(memberDashboardData?.loanRepayment.starting_balance)}</p>
          </div>
          {memberDashboardData?.loanRepayment.starting_balance > 0 && (
            <div className="flex flex-wrap lg:flex-nowrap lg:items-center items-start gap-3 lg:gap-0 justify-between">
              <div className="flex items-center gap-3">
                <p>Due date:</p>
                <div className="flex items-center gap-1">
                  <div className="bg-[#318C641A] p-[5px] rounded-full">
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5.5433 4.33337C5.26996 4.33337 5.0433 4.10671 5.0433 3.83337V1.83337C5.0433 1.56004 5.26996 1.33337 5.5433 1.33337C5.81663 1.33337 6.0433 1.56004 6.0433 1.83337V3.83337C6.0433 4.10671 5.81663 4.33337 5.5433 4.33337Z" fill="#292D32" />
                      <path d="M10.8766 4.33337C10.6033 4.33337 10.3766 4.10671 10.3766 3.83337V1.83337C10.3766 1.56004 10.6033 1.33337 10.8766 1.33337C11.15 1.33337 11.3766 1.56004 11.3766 1.83337V3.83337C11.3766 4.10671 11.15 4.33337 10.8766 4.33337Z" fill="#292D32" />
                      <path d="M5.87663 10.1667C5.78996 10.1667 5.70329 10.1467 5.62329 10.1133C5.53663 10.08 5.46996 10.0333 5.40329 9.97333C5.28329 9.84667 5.20996 9.68 5.20996 9.5C5.20996 9.41334 5.22996 9.32667 5.2633 9.24667C5.29663 9.16667 5.34329 9.09334 5.40329 9.02668C5.46996 8.96668 5.53663 8.92 5.62329 8.88666C5.86329 8.78666 6.16329 8.84001 6.34996 9.02668C6.46996 9.15334 6.54329 9.32667 6.54329 9.5C6.54329 9.54 6.53663 9.58668 6.52996 9.63335C6.52329 9.67335 6.50996 9.71334 6.48996 9.75334C6.47663 9.79334 6.45663 9.83334 6.42996 9.87334C6.40996 9.90667 6.37663 9.94 6.34996 9.97333C6.22329 10.0933 6.04996 10.1667 5.87663 10.1667Z" fill="#292D32" />
                      <path d="M8.20996 10.1667C8.1233 10.1667 8.03663 10.1467 7.95663 10.1134C7.86996 10.08 7.8033 10.0334 7.73663 9.97337C7.61663 9.8467 7.5433 9.68004 7.5433 9.50004C7.5433 9.41337 7.5633 9.3267 7.59663 9.2467C7.62996 9.1667 7.67663 9.09338 7.73663 9.02671C7.8033 8.96671 7.86996 8.92003 7.95663 8.8867C8.19663 8.78003 8.49663 8.84004 8.6833 9.02671C8.8033 9.15338 8.87663 9.3267 8.87663 9.50004C8.87663 9.54004 8.86996 9.58671 8.8633 9.63338C8.85663 9.67338 8.8433 9.71337 8.8233 9.75337C8.80996 9.79337 8.78996 9.83337 8.7633 9.87337C8.7433 9.9067 8.70996 9.94003 8.6833 9.97337C8.55663 10.0934 8.3833 10.1667 8.20996 10.1667Z" fill="#292D32" />
                      <path d="M10.5433 10.1667C10.4566 10.1667 10.37 10.1467 10.29 10.1134C10.2033 10.08 10.1366 10.0334 10.07 9.97337C10.0433 9.94003 10.0166 9.9067 9.98996 9.87337C9.96329 9.83337 9.94329 9.79337 9.92996 9.75337C9.90996 9.71337 9.89663 9.67338 9.88996 9.63338C9.88329 9.58671 9.87663 9.54004 9.87663 9.50004C9.87663 9.3267 9.94996 9.15338 10.07 9.02671C10.1366 8.96671 10.2033 8.92003 10.29 8.8867C10.5366 8.78003 10.83 8.84004 11.0166 9.02671C11.1366 9.15338 11.21 9.3267 11.21 9.50004C11.21 9.54004 11.2033 9.58671 11.1966 9.63338C11.19 9.67338 11.1766 9.71337 11.1566 9.75337C11.1433 9.79337 11.1233 9.83337 11.0966 9.87337C11.0766 9.9067 11.0433 9.94003 11.0166 9.97337C10.89 10.0934 10.7166 10.1667 10.5433 10.1667Z" fill="#292D32" />
                      <path d="M5.87663 12.5C5.78996 12.5 5.70329 12.4801 5.62329 12.4467C5.54329 12.4134 5.46996 12.3667 5.40329 12.3067C5.28329 12.18 5.20996 12.0067 5.20996 11.8334C5.20996 11.7467 5.22996 11.66 5.2633 11.58C5.29663 11.4934 5.34329 11.42 5.40329 11.36C5.64996 11.1134 6.10329 11.1134 6.34996 11.36C6.46996 11.4867 6.54329 11.66 6.54329 11.8334C6.54329 12.0067 6.46996 12.18 6.34996 12.3067C6.22329 12.4267 6.04996 12.5 5.87663 12.5Z" fill="#292D32" />
                      <path d="M8.20996 12.5C8.03663 12.5 7.8633 12.4267 7.73663 12.3067C7.61663 12.18 7.5433 12.0067 7.5433 11.8334C7.5433 11.7467 7.5633 11.66 7.59663 11.58C7.62996 11.4934 7.67663 11.42 7.73663 11.36C7.9833 11.1134 8.43663 11.1134 8.6833 11.36C8.7433 11.42 8.78996 11.4934 8.8233 11.58C8.85663 11.66 8.87663 11.7467 8.87663 11.8334C8.87663 12.0067 8.8033 12.18 8.6833 12.3067C8.55663 12.4267 8.3833 12.5 8.20996 12.5Z" fill="#292D32" />
                      <path d="M10.5433 12.5001C10.37 12.5001 10.1966 12.4267 10.07 12.3067C10.01 12.2467 9.96329 12.1734 9.92996 12.0867C9.89663 12.0067 9.87663 11.9201 9.87663 11.8334C9.87663 11.7467 9.89663 11.6601 9.92996 11.5801C9.96329 11.4934 10.01 11.4201 10.07 11.3601C10.2233 11.2067 10.4566 11.1334 10.67 11.1801C10.7166 11.1867 10.7566 11.2001 10.7966 11.2201C10.8366 11.2334 10.8766 11.2534 10.9166 11.2801C10.95 11.3001 10.9833 11.3334 11.0166 11.3601C11.1366 11.4867 11.21 11.6601 11.21 11.8334C11.21 12.0067 11.1366 12.1801 11.0166 12.3067C10.89 12.4267 10.7166 12.5001 10.5433 12.5001Z" fill="#292D32" />
                      <path d="M13.8766 7.05994H2.5433C2.26996 7.05994 2.0433 6.83327 2.0433 6.55994C2.0433 6.2866 2.26996 6.05994 2.5433 6.05994H13.8766C14.15 6.05994 14.3766 6.2866 14.3766 6.55994C14.3766 6.83327 14.15 7.05994 13.8766 7.05994Z" fill="#292D32" />
                      <path d="M10.8766 15.6667H5.54329C3.10996 15.6667 1.70996 14.2667 1.70996 11.8334V6.16671C1.70996 3.73337 3.10996 2.33337 5.54329 2.33337H10.8766C13.31 2.33337 14.71 3.73337 14.71 6.16671V11.8334C14.71 14.2667 13.31 15.6667 10.8766 15.6667ZM5.54329 3.33337C3.63663 3.33337 2.70996 4.26004 2.70996 6.16671V11.8334C2.70996 13.74 3.63663 14.6667 5.54329 14.6667H10.8766C12.7833 14.6667 13.71 13.74 13.71 11.8334V6.16671C13.71 4.26004 12.7833 3.33337 10.8766 3.33337H5.54329Z" fill="#292D32" />
                    </svg>
                  </div>
                  {/* <p>{new Date(memberDashboardData?.loanRepayment.due_at).toLocaleString()}</p> */}
                  <p>{new Date(memberDashboardData?.loanRepayment.due_at).toLocaleDateString()}</p>
                </div>
              </div>
              <div>
                <p className="font-[600] text-base bg-[#8B313A] py-[10px] px-5 rounded-[10px] text-white">Pay now</p>
              </div>
            </div>
          )}
        </div>

      </div>

      <div className="flex flex-col md:flex-row justify-between items-center bg-[#383838] rounded-md p-10 text-white">
        <div className="flex items-center gap-2">
          <div className="relative">
            <div className="absolute -top-20 left-8">
              <svg width="142" height="141" viewBox="0 0 142 141" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="70.9411" cy="71.4894" r="70.6726" fill="white" fill-opacity="0.1" />
              </svg>
            </div>
            <div className="bg-[#E0A8AE] p-4 rounded-full">
              <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.1002 18.22H6.31015C5.79015 18.22 5.30014 18.17 4.86014 18.07C4.63014 18.04 4.35015 17.96 4.06015 17.85C2.73015 17.35 1.16016 16.12 1.16016 13.06V7.90997C1.16016 4.63997 3.04015 2.76001 6.31015 2.76001H15.1002C17.8602 2.76001 19.6401 4.07998 20.1201 6.47998C20.2101 6.91998 20.2502 7.38997 20.2502 7.90997V13.06C20.2502 16.35 18.3802 18.22 15.1002 18.22ZM6.32013 4.28003C3.86013 4.28003 2.67014 5.46999 2.67014 7.92999V13.08C2.67014 14.87 3.30015 15.97 4.59015 16.46C4.79015 16.53 4.98016 16.58 5.16016 16.61C5.54016 16.69 5.91013 16.73 6.32013 16.73H15.1101C17.5701 16.73 18.7601 15.54 18.7601 13.08V7.92999C18.7601 7.50999 18.7302 7.13999 18.6602 6.79999C18.3202 5.09999 17.1601 4.28003 15.1101 4.28003H6.32013Z" fill="#383838" />
                <path d="M18.1001 21.22H9.31005C8.46005 21.22 7.70004 21.1 7.05004 20.85C5.58004 20.3 4.62003 19.14 4.29003 17.48C4.24003 17.23 4.32005 16.97 4.50005 16.8C4.68005 16.62 4.94006 16.55 5.19006 16.61C5.52006 16.68 5.89005 16.72 6.31005 16.72H15.1001C17.5601 16.72 18.7501 15.53 18.7501 13.07V7.91997C18.7501 7.49997 18.72 7.12996 18.65 6.78996C18.6 6.53996 18.6801 6.28997 18.8501 6.10997C19.0301 5.92997 19.2801 5.84996 19.5301 5.90996C21.9301 6.39996 23.2501 8.17997 23.2501 10.92V16.07C23.2501 19.35 21.3801 21.22 18.1001 21.22ZM6.13006 18.22C6.45006 18.8 6.93005 19.21 7.59005 19.45C8.07005 19.63 8.65003 19.72 9.32003 19.72H18.11C20.57 19.72 21.76 18.53 21.76 16.07V10.92C21.76 9.33997 21.27 8.28997 20.26 7.73997C20.26 7.79997 20.26 7.85997 20.26 7.91997V13.07C20.26 16.34 18.38 18.22 15.11 18.22H6.32003C6.25003 18.22 6.19006 18.22 6.13006 18.22Z" fill="#383838" />
                <path d="M10.7098 13.89C8.83984 13.89 7.31982 12.37 7.31982 10.5C7.31982 8.63 8.83984 7.10999 10.7098 7.10999C12.5798 7.10999 14.0999 8.63 14.0999 10.5C14.0999 12.37 12.5798 13.89 10.7098 13.89ZM10.7098 8.60999C9.66984 8.60999 8.81982 9.46 8.81982 10.5C8.81982 11.54 9.66984 12.39 10.7098 12.39C11.7498 12.39 12.5999 11.54 12.5999 10.5C12.5999 9.46 11.7498 8.60999 10.7098 8.60999Z" fill="#292D32" />
                <path d="M4.99023 13.4501C4.58023 13.4501 4.24023 13.1101 4.24023 12.7001V8.30005C4.24023 7.89005 4.58023 7.55005 4.99023 7.55005C5.40023 7.55005 5.74023 7.89005 5.74023 8.30005V12.7001C5.74023 13.1101 5.41023 13.4501 4.99023 13.4501Z" fill="#292D32" />
                <path d="M16.4199 13.4501C16.0099 13.4501 15.6699 13.1101 15.6699 12.7001V8.30005C15.6699 7.89005 16.0099 7.55005 16.4199 7.55005C16.8299 7.55005 17.1699 7.89005 17.1699 8.30005V12.7001C17.1699 13.1101 16.8399 13.4501 16.4199 13.4501Z" fill="#292D32" />
              </svg>
            </div>
            <div className="absolute -top-5 -left-1">
              <svg width="142" height="141" viewBox="0 0 142 141" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="70.9411" cy="71.4894" r="70.6726" fill="white" fill-opacity="0.1" />
              </svg>
            </div>
          </div>
          <p className="text-sm md:text-lg lg:text-2xl font-[700]">Get suitable and affordable loan within 5 minutes</p>
        </div>
        <Link to="/member/loan_management/application">
          <p className="font-[600] bg-[#8B313A] text-sm lg:text-base py-[10px] px-7 lg:px-12 rounded-xl">Apply now</p>
        </Link>
      </div>

      <div className="mt-10">
        <h3 className="fw-bold text-black1 mb-4">Recent Transactions</h3>
        <CardComp cardClassName={"border-0"}>
          <div>
            {memberDashboardData?.recentTransactions?.length > 0 ? (
              memberDashboardData.recentTransactions.map((item, i) => (
                <ActivitiesRow
                  key={i}
                  item={item}
                  itemPosition={i}
                  activities={i}
                  arrayLength={memberDashboardData.recentTransactions.length}
                />
              ))
            ) : (
              <p>No recent transactions available.</p>
            )}
          </div>
        </CardComp>
      </div>


    </PageWrapper>
  );
}
