// export const userDetails =()=>{
//     const ResidentData =[
//         {
//             name:''
//         }
//     ]
// }


export const BirthdayData = [
    {
        name:'Adeleke Benji Sulaimon',
        age:'70 years old'
    },
    {
        name:'Adeleke Benji Sulaimon',
        age:'70 years old'
    },
    {
        name:'Adeleke Benji Sulaimon',
        age:'70 years old'
    },
    {
        name:'Adeleke Benji Sulaimon',
        age:'70 years old'
    },
    {
        name:'Adeleke Benji Sulaimon',
        age:'70 years old'
    },

]


export const LoanData = [
    {
        name:'Chinmay Sarasvati',
        date:'23 Sep 2019',
        time:'02:52PM',
        amount:144000,
        payment:'Repayment'
    },
    {
        name:'Chinmay Sarasvati',
        date:'23 Sep 2019',
        time:'02:52PM',
        amount:144000,
        payment:'Disbursed',
        img:'https://img.freepik.com/premium-photo/young-sexy-nurse-femele-doctor-blue-uniform-posing-modern-hallway-clinic-healthcare_359031-2291.jpg?w=360'
    },
    {
        name:'Chinmay Sarasvati',
        date:'23 Sep 2019',
        time:'02:52PM',
        amount:144000,
        payment:'Disbursed',
        img:'https://i.pinimg.com/564x/cd/bc/1e/cdbc1e205831acf9419db692dbc35073.jpg'
    },
]
export const OthersData = [
    {
        name:'Chinmay Sarasvati',
        date:'23 Sep 2019',
        time:'02:52PM',
        amount:144000,
        does:'Apply for Loan'
    },
    {
        name:'Chinmay Sarasvati',
        date:'23 Sep 2019',
        time:'02:52PM',
        amount:144000,
        does:'Sign up',
        img:'https://img.freepik.com/premium-photo/young-sexy-nurse-femele-doctor-blue-uniform-posing-modern-hallway-clinic-healthcare_359031-2291.jpg?w=360'
    },
    {
        name:'Chinmay Sarasvati',
        date:'23 Sep 2019',
        time:'02:52PM',
        amount:144000,
        payment:'Disbursed',
        does:'Approved Loan',
        img:'https://i.pinimg.com/564x/cd/bc/1e/cdbc1e205831acf9419db692dbc35073.jpg'
    },
]

export const RecentTransactions = [
    {
        name:'Personal contribution',
        date:'23 Sep 2019',
        time:'02:52PM',
        amount:144000,
        amountClassName:'h6 text-success1',
        img:'/MemberSide/Icons/recent1.svg'
    },
    {
        name:'Bus contribution',
        date:'23 Sep 2019',
        time:'02:52PM',
        amount:144000,
        amountClassName:'h6 text-success1',
        img:'/MemberSide/Icons/recent2.svg'
    },
    {
        name:'House contribution',
        date:'23 Sep 2019',
        time:'02:52PM',
        amount:144000,
        amountClassName:'h6 text-success1',
        img:'/MemberSide/Icons/recent3.svg'
    },
]


export const LateContributions = ()=>{
    return[
      {
        name:'You have 5 late contribution to be made',
        contributions:"Late contribution",
        image:'/MemberSide/Icons/dashboard3.svg',
        percentage:'80%',
        bgColor:'#A8E1C8',
        color:'#318C64'
      },
      {
        name:'You have 4 upcoming contribution to be made',
        contributions:"Upcoming contribution",
        image:'/MemberSide/Icons/dashboard4.svg',
        percentage:'80%',
        bgColor:'#E0A8AE ',
        color:'#8B313A'
      },
      {
        contributions:'Loan Repayment',
        name:<div className="d-flex align-items-center gap-3 mt-3"><div className="px-4 py-1 rounded-pill bg-danger2 text-primary1">NGN 50,000.00</div><div><span className="me-3">
            <img src='/MemberSide/Icons/calendar.svg' alt="calendar" />
            </span>27/09/2022</div></div>,
        image:'/MemberSide/Icons/dashboard5.svg',
        percentage:'80%',
        bgColor:'#E0A8AE ',
        color:'#8B313A'
      },
     
    ]
  }

  export const MemberLateContributions = ()=>{
    return[
      {
        name:'Check your recently applied loan status',
        contributions:"Loan status",
        image:'/MemberSide/Icons/dashboard3.svg',
        percentage:'80%',
        bgColor:'#A8E1C8',
        color:'#318C64',
        onclick:'/member/loan_management/loan_status'
      },
      {
        name:'You have 2 pending loan repayment',
        contributions:"Loan Repayment",
        image:'/MemberSide/Icons/dashboard5.svg',
        percentage:'80%',
        bgColor:'#E0A8AE ',
        color:'#8B313A',
        onclick:'/member/loan_management/loan_repayment'
      },
     
     
    ]
  }
  export const MemberLoanRepaymentData = ()=>{
    return[
      {
        name:'You have no overdue repayment',
        contributions:"Overdue Repayment",
        image:'/MemberSide/Icons/dashboard6.svg',
        percentage:'80%',
        bgColor:'#A8E1C8',
        color:'#318C64',
        // onclick:'/member/loan_management/loan_status'
      },
      {
        name:'You have no overdue repayment',
        contributions:"Upcoming Repayment",
        image:'/MemberSide/Icons/dashboard5.svg',
        percentage:'80%',
        bgColor:'#E0A8AE ',
        color:'#8B313A',
        // onclick:'/member/loan_management/loan_repayment'
      },
     
     
    ]
  }
  export const MemberContributionData = ()=>{
    return[
      {
        name:'You have no late contribution',
        contributions:"Late contribution",
        image:'/MemberSide/Icons/dashboard3.svg',
        percentage:'80%',
        bgColor:'#A8E1C8',
        color:'#318C64',
         onclick:'/member/contribution/late_contribution'
      },
      {
        name:'You have no upcoming contribution',
        contributions:"Upcoming contribution",
        image:'/MemberSide/Icons/dashboard4.svg',
        percentage:'80%',
        bgColor:'#E0A8AE ',
        color:'#8B313A',
        onclick:'/member/contribution/upcoming_contribution'
      },
     
     
    ]
  }
  export const MemberEventData = ()=>{
    return[
      {
        name:'You have 4 unattended events this year',
        contributions:"Unattended Events",
        image:'/MemberSide/Icons/dashboard7.svg',
        percentage:'80%',
        bgColor:'#A8E1C8',
        color:'#318C64',
        nameClassName:'col-lg-8',
         onclick:'/member/contribution/late_contribution'
      },
      {
        name:'You have attended a total number of 100 events in this year',
        contributions:"Attended Events",
        image:'/MemberSide/Icons/dashboard8.svg',
        percentage:'80%',
        bgColor:'#E0A8AE ',
        color:'#8B313A',
        nameClassName:'col-lg-8',
        onclick:'/member/contribution/upcoming_contribution'
      },
     
     
    ]
  }