import {
  ButtonComp,
  SelectComp2,
  TextInputComp,
  MoneyInputComp,
  CardComp,
} from "../../../../components";
import { Formik, Form } from "formik";
export default function CreateFineSetting({ onNext }) {
  return (
    <>
      <div className="py-1"></div>
      <CardComp cardBodyClassName={"py-0"} cardClassName={"pt-4"}>
        <h4 className=" text-black1 fw-bold">Create FIne</h4>
      </CardComp>
      <div>
        <Formik
          onSubmit={() => alert("we are working on it")}
          initialValues={{ name: "" }}
        >
          <Form>
            <CardComp
              cardBodyClassName={"py-0 row gx-5 gy-3 justify-content-between"}
              cardClassName={"pt-4"}
            >
              <div className="col-12 col-lg-5">
                <div className="mb-3">
                  <TextInputComp
                    LabelText={"Name of fine"}
                    LabelClassName={"fw-bold"}
                    placeholder={"Input fees name"}
                    name="name"
                  />
                </div>
                <div className="mb-3">
                  <SelectComp2
                    LabelText={"Fine category"}
                    LabelClassName={"fw-bold"}
                    placeholder={"Input fees name"}
                  />
                </div>
              </div>
              <div className="col-12 col-lg-5">
                <div className="mb-3">
                  <MoneyInputComp
                    LabelText={"Amount"}
                    LabelClassName={"fw-bold"}
                    placeholder={"Input fees name"}
                  />
                </div>
              </div>
            </CardComp>
            <div className="col-12 py-1"></div>
            <div className="d-flex">
              <ButtonComp
                btnText={"Submit"}
                onClick={() => {}}
                btnClassName={
                  "w-100 bg-primary1 py-3 px-4 text-white border-0 rounded"
                }
                type={"submit"}
              />
            </div>
          </Form>
        </Formik>
      </div>
    </>
  );
}
