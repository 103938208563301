import { useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ModalCompAdvanceUpdate } from "../../../components";
import Message from "../../../components/MessageModal";
import {
  useGetMembersQuery,
  useSearchMembersQuery,
} from "../../../store/admin/memberManagement/memberSlice";
import StaffMemberTable from "../modules/StaffMemberTable";
import { ViewMembersHeader } from "../../../utils/TableHeader";
import useMemberRegAction from "./useMemberRegAction";

export default function ViewMembers() {
  const navigate = useNavigate();
  const location = useLocation();
  const [getModalName, setModalName] = useState();
  const [memberId, setMemeberId] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const { deleteMember, unSuspendMember, suspendMember } = useMemberRegAction();

  const removeMember = async (memberId) => {
    setModalName();
    if (!memberId) return;
    deleteMember(memberId);
  };

  const { data: searchResult = {}, isFetching: isSearching } =
    useSearchMembersQuery({
      page: 1,
      search: searchTerm,
    });
  const {
    data: membersQuerResult = {},
    isFetching: isFetchingData,
    error,
  } = useGetMembersQuery(1);

  const { allMembers, pagination } = useMemo(() => {
    const fetchedMembers = membersQuerResult?.members || [];
    const pagination = membersQuerResult?.pagination || {};
    const perPage = pagination.perPage || 5;
    const formattedMembers = fetchedMembers.map((item, index) => {
      return {
        ...item,
        name: item.first_name + " " + item.last_name,
        phone: item.phone,
        status: item.status,
        branch: item.branch.name,
        photo: item.photo,
        serialNumber: index + 1 + (pageNumber - 1) * perPage,
        id: item.id,
        is_active: item.is_activated,
      };
    });
    return { allMembers: formattedMembers, pagination };
  }, [membersQuerResult, pageNumber]);

  // const suspendStaffHandler = async (staffId) => {
  //   const credentials = {
  //     payload: {
  //       reason: "no reason",
  //     },
  //     staffId: staffId,
  //   };

  //   const response = await handleSuspendStaff(credentials);
  //   // console.log(response);
  //   const { message, status } = response?.data;
  //   // navigate("/super_admin/staff_registration/view_staff");
  //   // resetForm();

  //   if (!status) {
  //     toast.error(message);
  //   } else {
  //     toast.success(message);
  //     setModalName();
  //   }
  // };

  const onSearch = (e) => {
    setPageNumber(1);
    setSearchTerm(e);
  };
  const { searchedMembers, searchedPagination } = useMemo(() => {
    const fetchedMembers = searchResult?.members || [];
    const pagination = searchResult?.pagination || {};
    const perPage = pagination.perPage || 5;
    const formattedMembers = fetchedMembers.map((item, index) => {
      return {
        ...item,
        name: item.first_name + " " + item.last_name,
        phone: item.phone,
        status: item.status,
        branch: item.branch.name,
        photo: item.photo,
        serialNumber: index + 1 + (pageNumber - 1) * perPage,
        id: item.id,
        is_active: item.is_activated,
      };
    });
    return {
      searchedMembers: formattedMembers,
      searchedPagination: pagination,
    };
  }, [searchResult, pageNumber]);

  if (error && error.status === 401) {
    return navigate("/login");
  }
  const getTableDropdown = (row) => {
    const dropdown = [
      {
        name: "View profile",
        action: (data) => {
          navigate(`${location?.pathname}/${data?.id}`);
        },
      },
      {
        name: row.is_suspended ? "Unsuspend member" : "Suspend Member",
        action: (data) => {
          if (row.is_suspended) {
            setMemeberId(data?.id);
            setModalName("Unsuspend Member");
          } else {
            setMemeberId(data?.id);
            setModalName("Suspend Member");
            // navigate(
            //   `${location?.pathname}/${data?.id}/suspend?name=${data.name}`
            // );
          }
        },
      },
      // {
      //   name: "Change branch",
      //   // disabled: true,
      //   action: (data) => {
      //     navigate(`${location?.pathname}/${data?.id}/change_branch`);
      //   },
      // },
      // {
      //   name: "Edit Member",
      //   action: (data) => {
      //     navigate(
      //       `${location?.pathname}/${data?.id}/update?name=${data.name}`
      //     );
      //   },
      // },
      {
        name: "Archive Member",
        action: (data) => {
          setMemeberId(data?.id);
          setModalName("Delete Member");
        },
      },
    ];
    return dropdown;
  };
  const useModal = [
    {
      name: "Delete Member",
      component: (
        <Message
          title={"Delete Member"}
          desc={"Do you want to Archive this member account?"}
          onBack={() => setModalName()}
          onProceed={() => removeMember(memberId)}
        />
      ),
    },
    {
      name: "Unsuspend Member",
      component: (
        <Message
          title={"Unsuspend Member"}
          desc={"Do you want to unsuspend this member account?"}
          onBack={() => setModalName()}
          onProceed={() => {
            setModalName();
            unSuspendMember(memberId);
          }}
        />
      ),
    },
    {
      name: "Suspend Member",
      component: (
        <Message
          title={"Suspend Member"}
          desc={"Do you want to suspend this member account?"}
          onBack={() => setModalName()}
          onProceed={() => {
            setModalName();
            // suspendMember(memberId, "no reason");
            suspendMember({ memberId, reason: "no reason" });
          }}
        />
      ),
    },
  ];

  return (
    <>
      <StaffMemberTable
        title={"View Members"}
        tableFor="members"
        tableData={searchTerm ? searchedMembers : allMembers}
        isFetchingData={isFetchingData || isSearching}
        tableColumns={ViewMembersHeader(getTableDropdown)}
        addNew={() => navigate("/super_admin/member_management")}
        pagination={searchTerm ? searchedPagination : pagination}
        onPageChange={setPageNumber}
        onSearch={onSearch}
      />
      <ModalCompAdvanceUpdate
        centered
        arrayComp={useModal}
        pageName={getModalName}
        handleClose={() => setModalName()}
      />
    </>
  );
}
