import {
  BatchUpload,
  ChangeBranch,
  Home,
  SuspendStaff,
  ViewStaff,
} from "../pages";
import AttendanceHistory from "../pages/Admin/Attendance/history";
import AttendanceHome from "../pages/Admin/Attendance/home";
import MemberAttendanceHistory from "../pages/Admin/Attendance/MemberAttendanceHistory";
import AutomatedBirthdayHome from "../pages/Admin/AutomatedBirthday/Home";
import SettingBranch from "../pages/Admin/Configuration&Settings/Branch";
import CompanyPrefix from "../pages/Admin/Configuration&Settings/CompanyPrefix";
import EditCompanyPrefix from "../pages/Admin/Configuration&Settings/EditCompanyPrefix";
import SettingEditBranch from "../pages/Admin/Configuration&Settings/Editbranch";
import SettingEditNewRegistrationFee from "../pages/Admin/Configuration&Settings/EditMemberRegistrationFee";
import FineSettings from "../pages/Admin/Configuration&Settings/FineSettings";
import NewFine from "../pages/Admin/Configuration&Settings/NewFine";
import ConfigurationHome from "../pages/Admin/Configuration&Settings/Home";
import LoanApplicationSetting from "../pages/Admin/Configuration&Settings/LoanApplicationSetting";
import SettingMemberRegistrationFee from "../pages/Admin/Configuration&Settings/MemberRegistrationFee";
import SettingRoleManagement from "../pages/Admin/Configuration&Settings/RoleManagement";
import NewRole from "../pages/Admin/Configuration&Settings/NewRole";
import SocietyBankInfo from "../pages/Admin/Configuration&Settings/SocietyBankInfo";
import NewBankInfo from "../pages/Admin/Configuration&Settings/NewBankInfo";
import Contribution_History from "../pages/Admin/Contribution/Contribution_History";
import Contribution_HistoryDetail from "../pages/Admin/Contribution/Contribution_HistoryDetail";
import Create_Contribution from "../pages/Admin/Contribution/contribution-category";
// import Record_Contribution from "../pages/Admin/Contribution/Record_Contribution";
import Record_ContributionMain from "../pages/Admin/Contribution/Record_ContributionMain";
import EventCalendarCreate from "../pages/Admin/EventCalendar/create";
import EditEventCalendar from "../pages/Admin/EventCalendar/EditEventCalendar";
import EventCalendar from "../pages/Admin/EventCalendar/Home";
import LoanRepayment from "../pages/Admin/LoanManagement/LoanRepayment";
import LoanRepaymentRecord from "../pages/Admin/LoanManagement/LoanRepaymentRecord";
import LoanStatus from "../pages/Admin/LoanManagement/LoanStatus";
import NewApplication from "../pages/Admin/LoanManagement/NewApplication";

// import RecordLoanRepayment from "../pages/Admin/LoanManagement/SearchLoanRepaymentByMember";
import {
  ChangeBranchMember,
  MemberBatchUpload,
  ViewSingleMember,
  MemberHistory,
} from "../pages/Admin/MemberRegistration";
import MemberRegHome from "../pages/Admin/MemberRegistration/Home";
import PendingMember from "../pages/Admin/MemberRegistration/PendingMember";
import SuspendMember from "../pages/Admin/MemberRegistration/SuspendMember";
import UpdateMember from "../pages/Admin/MemberRegistration/UpdateMember";
import ViewMembers from "../pages/Admin/MemberRegistration/ViewMembers";
import { StaffRegistrationHome } from "../pages/Admin/StaffRegistration/Home";
import { ViewSingleStaff } from "../pages/Admin/StaffRegistration/ViewSingleStaff";
import MemberAttendance from "../pages/Members/Attendance/Attendance";
import MemberContribution from "../pages/Members/Contribution/Contribution";
import MemberCreateContribution from "../pages/Members/Contribution/CreateContribution";
import MemberLateContribution from "../pages/Members/Contribution/LateContribution";
import MemberUpcomingContribution from "../pages/Members/Contribution/UpcomingContribution";
import MemberEvent from "../pages/Members/Event/Event";
import MemberHome from "../pages/Members/Home";
import LoanApplication from "../pages/Members/LoanManagement/LoanApplication";
import LoanManagement from "../pages/Members/LoanManagement/LoanManagement";
import LoanCalculationResult from "../pages/Members/LoanManagement/LoanCalculationResult";
import MemberLoanRepayment from "../pages/Members/LoanManagement/LoanRepayment";
import MemberLoanStatus from "../pages/Members/LoanManagement/LoanStatus";
import { EminuteHome, NewEminute, EminutePage } from "../pages/Admin/Eminute";
import {
  Summary as CommunicationSummary,
  GroupManagement,
  ViewGroup,
  AddMembersToGroup,
  Communications,
} from "../pages/Admin/Communication";
import LoanType from "../pages/Admin/LoanManagement/LoanType";
// import RepaymentSettings from "../pages/Admin/LoanManagement/LoanRepaymentByMemberSearchPage";
// import LoanRepaymentMemberBreakdown from "../pages/Admin/LoanManagement/AllLoanRepaymentByMember";
import LoanRepaymentByMemberSearchPage from "../pages/Admin/LoanManagement/LoanRepaymentByMemberSearchPage";
import { SingleLoanRepaymentBreakdownByMember } from "../pages/Admin/LoanManagement/SingleLoanRepaymentBreakdownbyMember";
import ContributionCategory from "../pages/Admin/Contribution/contribution-category";
export const routeList = [
  {
    children: [
      {
        component: Home,
        exact: true,
        name: "Home",
        path: "/super_admin/dashboard",
        protected: true,
      },
    ],
    component: Home,
    exact: true,
    name: "Home",
    // path: "/",
    protected: true,
  },
  {
    children: [
      {
        component: BatchUpload,
        exact: true,
        name: "Home",
        path: "/super_admin/staff_registration/batch_upload",
        protected: true,
      },
      {
        component: ChangeBranch,
        exact: true,
        name: "Home",
        path: "/super_admin/staff_registration/change_branch/:id",
        protected: true,
      },
      {
        component: SuspendStaff,
        exact: true,
        name: "Home",
        path: "/super_admin/staff_registration/suspend_staff/:id",
        protected: true,
      },
      {
        component: ViewSingleStaff,
        exact: true,
        name: "Home",
        path: "/super_admin/staff_registration/view_staff/:id",
        protected: true,
      },
      {
        component: ViewStaff,
        exact: true,
        name: "Home",
        path: "/super_admin/staff_registration/view_staff",
        protected: true,
      },
      {
        component: StaffRegistrationHome,
        exact: true,
        name: "Home",
        path: "/super_admin/staff_registration",
        protected: true,
      },
    ],
    component: Home,
    exact: true,
    name: "Staff Registration",
    // path: "/",
    protected: true,
  },
  {
    children: [
      {
        component: MemberBatchUpload,
        exact: true,
        name: "Home",
        path: "/super_admin/member_management/batch_upload",
        protected: true,
      },
      {
        component: ChangeBranchMember,
        exact: true,
        name: "Home",
        path: "/super_admin/member_management/members/:id/change_branch",
        protected: true,
      },
      {
        component: PendingMember,
        exact: true,
        name: "Home",
        path: "/super_admin/member_management/pending_member",
        protected: true,
      },
      {
        component: ViewSingleMember,
        exact: true,
        name: "Home",
        path: "/super_admin/member_management/members/:id",
        protected: true,
      },
      {
        component: MemberHistory,
        exact: true,
        name: "Home",
        path: "/super_admin/member_management/members/:id/history",
        protected: true,
      },
      {
        component: SuspendMember,
        exact: true,
        name: "Home",
        path: "/super_admin/member_management/members/:id/suspend",
        protected: true,
      },
      {
        component: UpdateMember,
        exact: true,
        name: "Home",
        path: "/super_admin/member_management/members/:id/update",
        protected: true,
      },
      {
        component: ViewMembers,
        exact: true,
        name: "Home",
        path: "/super_admin/member_management/members",
        protected: true,
      },
      {
        component: MemberRegHome,
        exact: true,
        name: "Home",
        path: "/super_admin/member_management",
        protected: true,
      },
    ],
    component: Home,
    exact: true,
    name: "Member Registration",
    // path: "/",
    protected: true,
  },
  {
    children: [
      {
        component: CommunicationSummary,
        exact: true,
        name: "Home",
        path: "/super_admin/communication/summary",
        protected: true,
      },
      {
        component: GroupManagement,
        exact: true,
        name: "Home",
        path: "/super_admin/communication/group_management",
        protected: true,
      },
      {
        component: ViewGroup,
        exact: true,
        name: "Home",
        path: "/super_admin/communication/group_management/:groupId",
        protected: true,
      },
      {
        component: AddMembersToGroup,
        exact: true,
        name: "Home",
        path: "/super_admin/communication/group_management/:groupId/add_members",
        protected: true,
      },
      {
        component: Communications,
        exact: true,
        name: "Home",
        path: "/super_admin/communication",
        protected: true,
      },
    ],
    component: Home,
    exact: true,
    name: "Communications",
    protected: true,
  },
  {
    children: [
      {
        component: ContributionCategory,
        exact: true,
        name: "Home",
        path: "/super_admin/contribution/contribution-category",
        protected: true,
      },
      {
        component: Record_ContributionMain,
        exact: true,
        name: "Home",
        path: "/super_admin/contribution/record_contribution/:id",
        protected: true,
      },
      {
        component: Record_ContributionMain,
        exact: true,
        name: "Home",
        path: "/super_admin/contribution/record_contribution/",
        protected: true,
      },
      {
        component: Contribution_HistoryDetail,
        exact: true,
        name: "Home",
        path: "/super_admin/contribution/contribution_history/:id",
        protected: true,
      },
      {
        component: Contribution_History,
        exact: true,
        name: "Home",
        path: "/super_admin/contribution/contribution_history",
        protected: true,
      },
      {
        component: Create_Contribution,
        exact: true,
        name: "Home",
        path: "/super_admin/contribution/create_contribution",
        protected: true,
      },
    ],
    component: Home,
    exact: true,
    name: "Contribution",
    // path: "/",
    protected: true,
  },
  {
    children: [
      // {
      //   component: RecordLoanRepayment,
      //   exact: true,
      //   name: "Home",
      //   path: "/super_admin/loan_management/record_loan_repayment",
      //   protected: true,
      // },
      {
        component: SingleLoanRepaymentBreakdownByMember,
        exact: true,
        name: "Home",
        path: "/super_admin/loan_management/loan_repayment/:loanRequestId",
        protected: true,
      },
      // {
      //   component: LoanRepaymentMemberBreakdown,
      //   exact: true,
      //   name: "Home",
      //   path: "/super_admin/loan_management/loan_repayment/:memberId",
      //   protected: true,
      // },
      {
        component: LoanRepayment,
        exact: true,
        name: "Home",
        path: "/super_admin/loan_management/loan_repayment",
        protected: true,
      },
      {
        component: LoanStatus,
        exact: true,
        name: "Home",
        path: "/super_admin/loan_management/loan_status",
        protected: true,
      },
      {
        component: LoanRepaymentRecord,
        exact: true,
        name: "Home",
        path: "/super_admin/loan_management/record_loan_repayment/:id",
        protected: true,
      },
      {
        component: NewApplication,
        exact: true,
        name: "Home",
        path: "/super_admin/loan_management/new_application",
        protected: true,
      },
      {
        component: LoanType,
        exact: true,
        name: "Home",
        path: "/super_admin/loan_management/loan_type",
        protected: true,
      },
      {
        component: LoanRepaymentByMemberSearchPage,
        exact: true,
        name: "Home",
        path: "/super_admin/loan_management/repayment_by_member",
        protected: true,
      },
    ],
    component: Home,
    exact: true,
    name: "Contribution",
    // path: "/",
    protected: true,
  },
  {
    children: [
      {
        component: FineSettings,
        exact: true,
        name: "Home",
        path: "/super_admin/settings/fine_settings",
        protected: true,
      },
      {
        component: NewFine,
        exact: true,
        name: "Home",
        path: "/super_admin/settings/fine_settings/new",
        protected: true,
      },
      {
        component: SettingEditNewRegistrationFee,
        exact: true,
        name: "Home",
        path: "/super_admin/settings/member_registration_fee/:id",
        protected: true,
      },
      {
        component: SettingMemberRegistrationFee,
        exact: true,
        name: "Home",
        path: "/super_admin/settings/member_registration_fee",
        protected: true,
      },
      {
        component: SocietyBankInfo,
        exact: true,
        name: "Home",
        path: "/super_admin/settings/society_bank_info",
        protected: true,
      },
      {
        component: NewBankInfo,
        exact: true,
        name: "Home",
        path: "/super_admin/settings/society_bank_info/new",
        protected: true,
      },
      {
        component: LoanApplicationSetting,
        exact: true,
        name: "Home",
        path: "/super_admin/settings/loan_application_settings",
        protected: true,
      },
      {
        component: SettingRoleManagement,
        exact: true,
        name: "Home",
        path: "/super_admin/settings/role_management",
        protected: true,
      },
      {
        component: NewRole,
        exact: true,
        name: "Home",
        path: "/super_admin/settings/role_management/new",
        protected: true,
      },
      {
        component: CompanyPrefix,
        exact: true,
        name: "Home",
        path: "/super_admin/settings/company_prefix",
        protected: true,
      },
      {
        component: EditCompanyPrefix,
        exact: true,
        name: "Home",
        path: "/super_admin/settings/company_prefix/:id",
        protected: true,
      },
      {
        component: SettingEditBranch,
        exact: true,
        name: "Home",
        path: "/super_admin/settings/branch_management/:id",
        protected: true,
      },
      {
        component: SettingBranch,
        exact: true,
        name: "Home",
        path: "/super_admin/settings/branch_management",
        protected: true,
      },
      {
        component: ConfigurationHome,
        exact: true,
        name: "Home",
        path: "/super_admin/settings/portal_settings",
        protected: true,
      },
    ],
    component: Home,
    exact: true,
    name: "Configuration And Settings",
    // path: "/",
    protected: true,
  },
  {
    children: [
      {
        component: EditEventCalendar,
        exact: true,
        name: "Home",
        path: "/super_admin/event_calendar/:id",
        protected: true,
      },
      {
        component: EventCalendarCreate,
        exact: true,
        name: "Home",
        path: "/super_admin/event_calendar/create",
        protected: true,
      },
      {
        component: EventCalendar,
        exact: true,
        name: "Home",
        path: "/super_admin/event_calendar",
        protected: true,
      },
    ],
    component: Home,
    exact: true,
    name: "Event Calendar",
    // path: "/",
    protected: true,
  },
  {
    children: [
      {
        component: AttendanceHistory,
        exact: true,
        name: "Home",
        path: "/super_admin/attendance/history",
        protected: true,
      },
      {
        component: MemberAttendanceHistory,
        exact: true,
        name: "Home",
        path: "/super_admin/attendance/history/:memberId",
        protected: true,
      },
      {
        component: AttendanceHome,
        exact: true,
        name: "Home",
        path: "/super_admin/attendance",
        protected: true,
      },
    ],
    component: Home,
    exact: true,
    name: "Attendance",
    // path: "/",
    protected: true,
  },
  {
    children: [
      {
        component: AutomatedBirthdayHome,
        exact: true,
        name: "Home",
        path: "/super_admin/automated_birthday",
        protected: true,
      },
    ],
    component: Home,
    exact: true,
    name: "Automated Birthday ",
    // path: "/",
    protected: true,
  },
  {
    children: [
      {
        component: MemberHome,
        exact: true,
        name: "Home",
        path: "/member/dashboard",
        protected: true,
      },
    ],
    component: Home,
    exact: true,
    name: "Automated Birthday ",
    // path: "/",
    protected: true,
  },
  {
    children: [
      {
        component: MemberLoanRepayment,
        exact: true,
        name: "Home",
        path: "/member/loan_management/loan_repayment",
        protected: true,
      },
      {
        component: MemberLoanStatus,
        exact: true,
        name: "Home",
        path: "/member/loan_management/loan_status",
        protected: true,
      },
      {
        component: LoanApplication,
        exact: true,
        name: "Home",
        path: "/member/loan_management/application",
        protected: true,
      },
      {
        component: LoanCalculationResult,
        exact: true,
        name: "Home",
        path: "/member/loan_management/calculation",
        protected: true,
      },
      {
        component: LoanManagement,
        exact: true,
        name: "Home",
        path: "/member/loan_management",
        protected: true,
      },
    ],
    component: Home,
    exact: true,
    name: "Automated Birthday ",
    // path: "/",
    protected: true,
  },
  {
    children: [
      {
        component: MemberCreateContribution,
        exact: true,
        name: "Home",
        path: "/member/contribution/create",
        protected: true,
      },
      {
        component: MemberLateContribution,
        exact: true,
        name: "Home",
        path: "/member/contribution/late_contribution",
        protected: true,
      },
      {
        component: MemberUpcomingContribution,
        exact: true,
        name: "Home",
        path: "/member/contribution/upcoming_contribution",
        protected: true,
      },

      {
        component: MemberContribution,
        exact: true,
        name: "Home",
        path: "/member/contribution",
        protected: true,
      },
    ],
    component: Home,
    exact: true,
    name: "Contribution ",
    // path: "/",
    protected: true,
  },
  {
    children: [
      {
        component: MemberEvent,
        exact: true,
        name: "Home",
        path: "/member/event",
        protected: true,
      },
    ],
    component: Home,
    exact: true,
    name: "Contribution ",
    // path: "/",
    protected: true,
  },
  {
    children: [
      {
        component: MemberAttendance,
        exact: true,
        name: "Home",
        path: "/member/attendance",
        protected: true,
      },
    ],
    component: Home,
    exact: true,
    name: "Attendance ",
    // path: "/",
    protected: true,
  },
  {
    children: [
      {
        component: EminuteHome,
        exact: true,
        name: "Home",
        path: "/super_admin/eminutes",
        protected: true,
      },
      {
        component: NewEminute,
        exact: true,
        name: "Home",
        path: "/super_admin/eminutes/new",
        protected: true,
      },
      {
        component: EminutePage,
        exact: true,
        name: "Home",
        path: "/super_admin/eminutes/:id",
        protected: true,
      },
    ],
    component: Home,
    exact: true,
    name: "Member Registration",
    // path: "/",
    protected: true,
  },
];
