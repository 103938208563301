import { OverlayTrigger, ProgressBar, Tooltip } from "react-bootstrap";

export const ToolTips = ({ showText, text }) => {
  return (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id="button-tooltip-2">{showText}</Tooltip>}
    >
      {({ ref, ...triggerHandler }) => (
        <span {...triggerHandler} className="d-inline-flex align-items-center">
          <span ref={ref} roundedCircle>
            {text}
          </span>
        </span>
      )}
    </OverlayTrigger>
  );
};

export const ProgressComp = ({ percentage = "0" }) => {
  return <ProgressBar now={percentage} label={`${percentage}%`} />;
};
