import {
  ButtonComp,
  SelectComp2,
  TextInputComp,
  CardComp,
} from "../../../../components";
import { Formik, Form } from "formik";
import { useGetBanksQuery } from "../../../../store/selectableSlice";
import * as Yup from "yup";

export default function CreateSocietyBank({
  onNext,
  handleBankSelect,
  selectErrors,
}) {
  const {
    data: banks = [],
    // isLoading: isLoadingBanks,
    isError: isErrorBanks,
    error: errorLoadingBanks,
  } = useGetBanksQuery();

  const validationSchema = Yup.object({
    account_name: Yup.string().required("Account name is required"),
    account_number: Yup.string()
      .matches(/^[0-9]{10}$/, "Input ten digits account number")
      .required("Account number is required"),
  });

  if (isErrorBanks) {
    console.log(errorLoadingBanks);
  }
  return (
    <>
      <div className="py-1"></div>
      <div>
        <Formik
          initialValues={{
            account_number: "",
            account_name: "",
          }}
          validationSchema={validationSchema}
          onSubmit={onNext}
        >
          <Form>
            <CardComp
              cardClassName={"pt-4"}
              cardBodyClassName={"py-0 row gx-5 gy-3 justify-content-between"}
            >
              <div className="col-12 col-lg-5">
                <div className="mb-3">
                  <SelectComp2
                    LabelClassName={"fw-2"}
                    labelText={"Bank name"}
                    placeholder="Select bank name"
                    errors={selectErrors}
                    setSelectedOption={handleBankSelect}
                    arrayComp={[
                      ...banks.map((bank) => ({
                        value: bank.code,
                        label: bank.title,
                      })),
                    ]}
                  />
                </div>
                <div className="mb-3">
                  <TextInputComp
                    LabelText={"Account number"}
                    borderColor="#A1A0A0"
                    LabelClassName={"fw-2"}
                    placeholder={"Input account number"}
                    name="account_number"
                  />
                </div>
              </div>
              <div className="col-12 col-lg-5">
                <div className="mb-3">
                  <TextInputComp
                    LabelText={"Account name"}
                    borderColor="#A1A0A0"
                    LabelClassName={"fw-2"}
                    placeholder={"Ifesowapo Cooperatives"}
                    name="account_name"
                  />
                </div>
              </div>
            </CardComp>
            <div className="d-flex">
              <div className="">
                <ButtonComp
                  btnText={"Submit bank info"}
                  type={"submit"}
                  btnClassName={
                    "w-100 bg-primary1 py-3 px-4 fw-3 text-white border-0 rounded"
                  }
                />
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </>
  );
}
