import {
  CardComp,
  DropDownComp2,
  TabsComp,
  PlainTable,
} from "../../../components";
import CalendarComp from "../../../components/Common/CalendarComp";
import FallbackImage from "../../../components/Common/ImageWrapper";
import { MemberEventData } from "../../../utils/dummbyData";
import { MemberEventHeader } from "../../../utils/TableHeader";
import CardInfo from "../../Admin/Home/components/CardInfo";
import PageWrapper from "../modules/PageWrapper";
import styled from "styled-components";
import EmptyTable from "../../../components/Common/EmptyTable";
import { useGetMemberEventsQuery } from "../../../store/member/events";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { dateFromString } from "../../../utils";

export default function MemberEvent() {
  const navigate = useNavigate();
  const todayDate = dateFromString(new Date().toDateString(), true);
  const { data = {}, error, isLoading } = useGetMemberEventsQuery();
  const { event_calendars = [] } = data;

  const past_events = event_calendars.filter(({ end_date }) => {
    const end = new Date(end_date).getTime();
    const current = new Date(todayDate).getTime();
    return end < current;
  });
  const ongoing_events = event_calendars.filter(({ start_date, end_date }) => {
    const start = new Date(start_date).getTime();
    const end = new Date(end_date).getTime();
    const current = new Date(todayDate).getTime();
    return current >= start && current <= end;
  });
  const upcoming_events = event_calendars.filter(({ start_date }) => {
    const start = new Date(start_date).getTime();
    const current = new Date(todayDate).getTime();
    return current < start;
  });
  useEffect(() => {
    if (error) {
      if (error.status === 401) {
        toast.error("login to continue", { duration: 3000 });
        return navigate("/login");
      }
      console.log(error);
    }
  }, [error, navigate]);

  const TableDropDown = [
    {
      name: "I'm available",
      action: () => {},
    },
    {
      name: "I'm unavailable",
      action: () => {},
    },
  ];
  const useTab = [
    {
      name: "Ongoing events",
      component: (
        <PlainTable
          marginBottom={"0rem"}
          message={
            isLoading ? (
              <p className="py-4">Loading...</p>
            ) : (
              <EmptyTable Message={""} />
            )
          }
          columns={MemberEventHeader(TableDropDown)}
          data={ongoing_events}
        />
      ),
    },
    {
      name: "Upcoming events",
      component: (
        <PlainTable
          marginBottom={"0rem"}
          message={
            isLoading ? (
              <p className="py-4">Loading...</p>
            ) : (
              <EmptyTable Message={""} />
            )
          }
          columns={MemberEventHeader(TableDropDown)}
          data={upcoming_events}
        />
      ),
    },
    {
      name: "Past events",
      component: (
        <PlainTable
          marginBottom={"0rem"}
          message={
            isLoading ? (
              <p className="py-4">Loading...</p>
            ) : (
              <EmptyTable Message={""} />
            )
          }
          columns={MemberEventHeader(TableDropDown)}
          data={past_events}
        />
      ),
    },
  ];

  return (
    <Wrapper>
      <PageWrapper mainClassName="" title={"Event Calendar"}>
        <section className="mb-2 headline">
          <div className="">
            <div className="rounded-4">
              <div className="calender-container abc">
                <div className="bg-primary1 rounded-4 mb-lg-0 h-100">
                  <div className="">
                    <div className="border py-2 bg-white rounded-pill mt-5 mb-5">
                      <DropDownComp2
                        dropLabelClassName={"w-100 h4 mb-0  fw-bold border-0 "}
                        DropDownText={
                          <div className="d-flex justify-content-center align-items-center">
                            <h4 className="mb-0 fw-bold me-2">February</h4>
                            <span>
                              <FallbackImage src="/MemberSide/Icons/arrowdown.svg" />
                            </span>
                          </div>
                        }
                      />
                    </div>
                    <div className="pb-5">
                      <h3 className="text-center text-white fw-3 mb-0 h4">
                        You have no events to attend this month
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="h-100">
                  <div className="bg-white rounded-4 h-100 d-flex flex-column justify-content-center">
                    <CalendarComp outLine height={"100%"} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <div className="">
              {MemberEventData()?.map((item, i) => (
                <div className="rounded-4 shadow3" key={i}>
                  <CardInfo
                    total={item?.total}
                    name={item?.name}
                    contribution={item?.contributions}
                    key={i}
                    image={item?.image}
                    bgColor={item?.bgColor}
                    color={item?.color}
                    item={item}
                    // arrow
                    onclick={item?.onclick}
                  />
                </div>
              ))}
            </div>
          </div>
        </section>
        <section>
          <h4 className="fw-bold text-black1 mb-3">Events List</h4>
          <CardComp cardClassName={"border-0 pt-3 px-3"}>
            <div>
              <TabsComp
                tabClassName="w-100"
                lineHeight={"2px"}
                TabArray={useTab}
                borderWidth="3px"
                lineBottom={"24px"}
                input="name"
              />
            </div>
          </CardComp>
        </section>
      </PageWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .headline,
  .abc {
    display: grid;
    gap: 1rem;
  }
  @media screen and (min-width: 768px) {
    .abc {
      grid-template-columns: 34% 1fr;
    }
  }
  @media screen and (min-width: 1024px) {
    .headline {
      grid-template-columns: 1fr 1fr;
    }
  }
`;
