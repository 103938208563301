import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RiSearch2Line } from "react-icons/ri";
import {
  ButtonComp,
  CardComp,
  ModalCompAdvanceUpdate,
  TableCompData,
} from "../../../../components";
import EmptyTable from "../../../../components/Common/EmptyTable";
import {
  useGetStaffListQuery,
  useSearchStaffListQuery,
  useSuspendStaffMutation,
  useUnSuspendStaffMutation,
} from "../../../../store/admin/staff.js";
import TablePagination from "../../../../components/Table/Pagination";
import { ViewStaffHeader } from "../../../../utils/TableHeader";
import { toast } from "react-hot-toast";

export default function AllStaffs({ title, modalToggle }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [staffId, setStaffId] = useState();
  const [getModalName, setModalName] = useState();

  const { data, isFetching } = useGetStaffListQuery(1);

  const { data: searchResults = {}, isFetching: isSearching } =
    useSearchStaffListQuery({ page: pageNumber, search: searchTerm });

  const tableData = searchTerm ? searchResults?.staffs : data?.staffs;
  const pagination = searchTerm ? searchResults?.pagination : data?.pagination;

  const [unSuspend, { isLoading: unSuspending }] = useUnSuspendStaffMutation();

  const handleUnsuspend = async (staffId) => {
    const response = await unSuspend(staffId);
    const { message, status } = response?.data;
    if (!status) {
      toast.error(message);
      setModalName();
    } else {
      toast.success(message);
      setModalName();
    }
  };

  const [handleSuspendStaff, { isLoading: isSuspending }] =
    useSuspendStaffMutation();

  const suspendStaffHandler = async (staffId) => {
    const credentials = {
      payload: {
        reason: "no reason",
      },
      staffId: staffId,
    };

    const response = await handleSuspendStaff(credentials);
    // console.log(response);
    const { message, status } = response?.data;
    // navigate("/super_admin/staff_registration/view_staff");
    // resetForm();

    if (!status) {
      toast.error(message);
    } else {
      toast.success(message);
      setModalName();
    }
  };

  const onSearch = (e) => {
    setPageNumber(1);
    setSearchTerm(e);
  };

  const getTableDropDown = (row) => {
    const dropdown = [
      {
        name: "View profile",
        action: (data) => {
          navigate(`${location?.pathname}/${data?.id}`);
        },
      },
      {
        name: row.is_suspended ? "Unsuspend Staff" : "Suspend Staff",
        action: (data) => {
          if (row.is_suspended) {
            setStaffId(data?.id);
            setModalName("Unsuspend Staff");
          } else {
            setStaffId(data?.id);
            setModalName("Suspend Staff");
          }
        },
      },
      // {
      //   name: "Change branch",
      //   action: (data) => {
      //     navigate(`/super_admin/staff_registration/change_branch/${data?.id}`);
      //   },
      // },
      {
        name: "Archive staff",
        action: (data) => {
          setStaffId(data?.id);
          setModalName("Delete Staff");
        },
      },
    ];
    return dropdown;
  };

  const useModal = [
    {
      name: "Unsuspend Staff",
      component: (
        <div>
          <div className="py-3"></div>
          <div className="text-center">
            <h4 className="text-black text-center fw-bold">Unsuspend Staff</h4>
            <p className="mb-5 text-black fw-2">
              Do you want to unsuspend this staff account?
            </p>
            <div className="row justify-content-around">
              <div className="col-5">
                <ButtonComp
                  btnText={"No"}
                  btnClassName={" text-1 btn w-100 py-3"}
                  BorderColor={"#8B313A"}
                  onClick={() => setModalName()}
                />
              </div>

              <div className="col-5">
                <ButtonComp
                  loading={unSuspending}
                  btnText={"Yes"}
                  ButtonInlineStyle={{ backgroundColor: "#FF4343" }}
                  btnClassName={"border-0 text-white  w-100 py-3"}
                  onClick={() => handleUnsuspend(staffId)}
                />
              </div>
            </div>
          </div>
          <div className="py-3"></div>
        </div>
      ),
    },
    {
      name: "Suspend Staff",
      component: (
        <div>
          <div className="py-3"></div>
          <div className="text-center">
            <h4 className="text-black text-center fw-bold">Staff Suspension</h4>
            <p className="mb-5 text-black fw-2">
              Do you want to suspend this staff account?
            </p>
            <div className="row justify-content-around">
              <div className="col-5">
                <ButtonComp
                  btnText={"No"}
                  btnClassName={" text-1 btn w-100 py-3"}
                  BorderColor={"#8B313A"}
                  onClick={() => setModalName()}
                />
              </div>

              <div className="col-5">
                <ButtonComp
                  loading={isSuspending}
                  btnText={"Yes"}
                  ButtonInlineStyle={{ backgroundColor: "#FF4343" }}
                  btnClassName={"border-0 text-white  w-100 py-3"}
                  onClick={() => suspendStaffHandler(staffId)}
                />
              </div>
            </div>
          </div>
          <div className="py-3"></div>
        </div>
      ),
    },
    {
      name: "Delete Staff",
      component: (
        <div>
          <div className="py-3"></div>
          <div className="text-center">
            <h4 className="text-black text-center fw-bold">Archive Staff</h4>
            <p className="mb-5 text-black fw-2">
              Do you want to delete this staff account?
            </p>
            <div className="row justify-content-around">
              <div className="col-5">
                <ButtonComp
                  btnText={"No"}
                  btnClassName={" text-1 btn w-100 py-3"}
                  BorderColor={"#8B313A"}
                  onClick={() => setModalName()}
                />
              </div>

              <div className="col-5">
                <ButtonComp
                  btnText={"Yes"}
                  ButtonInlineStyle={{ backgroundColor: "#FF4343" }}
                  btnClassName={"border-0 text-white  w-100 py-3"}
                />
              </div>
            </div>
          </div>
          <div className="py-3"></div>
        </div>
      ),
    },
  ];
  return (
    <section className=" px-2 px-lg-5 min-vh-100 pb-5 mb-5 rounded">
      <CardComp cardClassName={"border-0 px-lg-4 mb-3"}>
        <div className="row justify-content-between align-items-center flex-warp">
          <div className="col-lg-6">
            <h3 className=" text-black1 fw-bold">{title || "Staff list"}</h3>
          </div>
          <div className="col-lg-6">
            <div class="input-group  search">
              <span className="input-group-text bg-black2 px-4">
                <RiSearch2Line size={20} color={"#fff"} />
              </span>
              <input
                type="text"
                className="form-control "
                placeholder="Search your query here"
                style={{ padding: "15px 10px" }}
                onKeyUp={(event) => onSearch(event.target.value)}
              />
            </div>
          </div>
        </div>
      </CardComp>
      <CardComp cardClassName={"border-0 px-lg-4 mb-3"}>
        <div className="py-4"></div>
        <main>
          <div className="">
            <TableCompData
              marginBottom={"120px"}
              columns={ViewStaffHeader(getTableDropDown)}
              data={tableData}
              message={
                isFetching || isSearching ? (
                  <div className=" py-4 ">
                    <h3>Loading...</h3>
                  </div>
                ) : (
                  <EmptyTable
                    Message={"You don't have active staff lists, please create"}
                    btnText={"Add new staff"}
                    onClick={() => navigate(`/super_admin/staff_registration`)}
                  />
                )
              }
            />
          </div>
        </main>
      </CardComp>

      <div className="py-2"></div>
      <div>
        <p className="text-black1 mb-3 fw-semibold">Need staff records?</p>
        <div className="d-flex">
          <ButtonComp
            btnText={"Download CSV"}
            btnClassName={
              "py-3 p fw-semibold bg-primary1 border-0 text-white rounded px-3 px-lg-5 me-4 "
            }
          />
          <ButtonComp
            btnText={"Download PDF"}
            btnClassName={
              "py-3 p fw-semibold bg-primary1 border-0 text-white rounded px-3 px-lg-5 "
            }
          />
        </div>
      </div>
      {tableData?.length > 0 && (
        <TablePagination {...pagination} handlePageChange={setPageNumber} />
      )}
      <ModalCompAdvanceUpdate
        centered
        arrayComp={useModal}
        pageName={getModalName}
        handleClose={() => setModalName()}
      />
    </section>
  );
}
