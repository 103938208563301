import React from "react";
import { TableCompData } from "../../../components";
import EmptyTable from "../../../components/Common/EmptyTable";
import TablePagination from "../../../components/Table/Pagination";
import { MemberLoanStatusHeader } from "../../../utils/TableHeader";

export default function LoanStatusTable({
  name,
  loading,
  setPageNumber,
  pagination,
  TableData,
}) {
  return (
    <>
      <main>
        <div className="mb-5">
          <TableCompData
            marginBottom={"1rem"}
            columns={MemberLoanStatusHeader()}
            data={TableData}
            message={
              loading ? (
                <div className=" py-4 ">
                  <h3>Loading...</h3>
                </div>
              ) : (
                <EmptyTable Message={`There are currently no ${name}`} />
              )
            }
          />
          {pagination && (
            <TablePagination
              marginTop={"0"}
              handlePageChange={setPageNumber}
              {...pagination}
            />
          )}
        </div>
      </main>
    </>
  );
}
