import { API, ApiPrefixes } from "../../api";
const BASE_URL = ApiPrefixes["adminRegFeesSettings"];

const regFeeExtendedApi = API.injectEndpoints({
  endpoints: (builder) => ({
    getRegFees: builder.query({
      query: (page = 1) => ({
        url: `${BASE_URL}?guard=staff&page=${page}`,
      }),
      transformResponse: (responseData) => {
        const { fees = [], pagination = {} } = responseData.data;
        return { fees, pagination };
      },
      providesTags: (result, error, arg) => {
        if (!result?.fees) return [{ type: "RegFee", id: "LIST" }];
        return [
          { type: "RegFee", id: "LIST" },
          ...result.fees.map(({ regFeeId }) => ({
            type: "RegFee",
            id: regFeeId,
          })),
        ];
      },
    }),
    getSingleRegFee: builder.query({
      query: (feeId) => `${BASE_URL}/${feeId}/view?guard=staff`,
      providesTags: (result, error, arg) => [{ type: "RegFee", id: arg }],
      transformResponse: (responseData) => responseData.data,
    }),
    createRegFee: builder.mutation({
      query: (body) => ({
        url: `${BASE_URL}/add?guard=staff`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: [{ type: "RegFee", id: "LIST" }],
    }),
    editRegFee: builder.mutation({
      query: (body) => ({
        url: `${BASE_URL}/${body.id}/edit?guard=staff`,
        method: "PATCH",
        body: body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "RegFee", id: arg.id }],
    }),
    deleteRegFee: builder.mutation({
      query: (id) => ({
        url: `${BASE_URL}/${id}/delete?guard=staff`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [{ type: "RegFee", id: arg }],
    }),
    getCompulsoryFee: builder.query({
      query: () => `${BASE_URL}/get_compulsory_fee?guard=staff`,
      transformResponse: (responseData) => responseData.data,
    }),
  }),
});

export const {
  useGetRegFeesQuery,
  useCreateRegFeeMutation,
  useDeleteRegFeeMutation,
  useEditRegFeeMutation,
  useGetSingleRegFeeQuery,
  useGetCompulsoryFeeQuery,
} = regFeeExtendedApi;
