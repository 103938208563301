import styled from "styled-components";
import { ButtonComp } from "../../../../components";
import { useGetMembersQuery } from "../../../../store/admin/memberManagement/memberSlice";
import { toast } from "react-hot-toast";
import { useEffect, useMemo, useState, useRef } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";

const AddGroupMemberModal = ({
  onBack,
  onProceed,
  submitText = "Add member",
  title = "Add a new member",
}) => {
  const [selectedMember, setSelectedMember] = useState({
    label: "",
    member_id: "",
  });
  const [pageNumber] = useState(1);
  const { data = {}, error } = useGetMembersQuery(pageNumber);
  const { members } = useMemo(() => {
    const { members: items = [] } = data;
    const members = items.map((member) => ({
      label: `${member?.first_name + " " + member?.last_name}`,
      value: member?.id,
      photo: member?.photo,
    }));
    return { members: members };
  }, [data]);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef();
  const listRef = useRef(null);
  useEffect(() => {
    const listHeight = listRef.current?.getBoundingClientRect().height;
    if (isOpen) {
      dropdownRef.current.style.height = `${listHeight}px`;
    } else {
      dropdownRef.current.style.height = `0px`;
    }
  }, [isOpen]);
  if (error) {
    toast.error(error?.data?.message || "An error occured");
  }
  return (
    <Wrapper>
      <div className="mb-4">
        <img
          src="/Icon/cancel.svg"
          role="button"
          onClick={onBack}
          alt="close"
          className="close-img"
        />
        <h5 className="fw-bold text-center">{title}</h5>
      </div>
      <div className="py-2"></div>
      <div className="px-2">
        <p className="mb-0 select-box d-flex justify-content-between align-items-center">
          <span>{selectedMember?.label || "select member"}</span>
          <span
            role="button"
            onClick={() => setIsOpen(!isOpen)}
            className={`${isOpen ? "turn" : ""}`}
          >
            <MdKeyboardArrowDown size={20} />
          </span>
        </p>
        <div ref={dropdownRef} className="members-dropdown">
          <ul ref={listRef} className="member-options">
            {members.map(({ label, value, photo }) => (
              <li
                onClick={() => setSelectedMember({ label, member_id: value })}
                key={label}
              >
                <img src={photo} alt="member" className="me-2 member-img" />
                {label} ID: {value}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="row justify-content-between mt-4">
        <div className="col-5">
          <ButtonComp
            btnText={"Cancel"}
            ButtonInlineStyle={{ borderRadius: "10px" }}
            btnClassName={" text-1 btn w-100 py-2"}
            BorderColor={"#8B313A"}
            onClick={() => onBack()}
          />
        </div>
        <div className="col-5">
          <ButtonComp
            btnText={submitText}
            ButtonInlineStyle={{
              backgroundColor: "#8B313A",
              borderRadius: "10px",
            }}
            btnClassName={"border-0 text-white w-100 py-2"}
            onClick={() => onProceed([selectedMember])}
          />
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 2rem;
  max-width: 600px;
  position: relative;
  .close-img {
    position: absolute;
    left: 8px;
  }
  .select-box {
    background: #fafafa;
    border: 1px solid #a1a0a0;
    border-radius: 5px;
    padding: 0.7rem 1rem;
    margin-bottom: 2px;
    .turn {
      transform: rotate(180deg);
      transition: all 300ms linear;
    }
  }
  .members-dropdown {
    height: 0;
    transition: all 300ms linear;
    max-height: 245px;
    overflow-y: scroll;
  }
  .member-options {
    background-color: #fafafa;
    list-style: none;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .member-img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
`;

export default AddGroupMemberModal;
