import { API, ApiPrefixes } from "../../api";
const BASE_URL = ApiPrefixes["adminBankSettings"];
const societyBankExtendedApi = API.injectEndpoints({
  endpoints: (builder) => ({
    getAllBankInfo: builder.query({
      query: (pageNumber = 1) => `${BASE_URL}?guard=staff&page=${pageNumber}`,
      transformResponse: (responseData) => {
        const { company_banks = [], pagination = {} } = responseData.data;
        return { company_banks, pagination };
      },
      providesTags: (result = {}) => {
        if (!result?.company_banks) return [{ type: "BankInfo", id: "LIST" }];
        return [
          { type: "BankInfo", id: "LIST" },
          ...result?.company_banks.map(({ bank_id }) => ({
            type: "BankInfo",
            id: bank_id,
          })),
        ];
      },
    }),
    createBankInfo: builder.mutation({
      query: (body) => ({
        url: `${BASE_URL}/add?guard=staff`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: [{ type: "BankInfo", id: "LIST" }],
    }),
    editBankInfo: builder.mutation({
      query: (body) => ({
        url: `${BASE_URL}/${body.id}/edit?guard=staff`,
        method: "PATCH",
        body: {
          account_name: body.account_name,
          account_number: body.account_number,
          bank_name: body.bank_name,
          bank_code: body.bank_code,
        },
      }),
      invalidatesTags: [{ type: "BankInfo", id: "LIST" }],
    }),
    deleteBankInfo: builder.mutation({
      query: (id) => ({
        url: `${BASE_URL}/${id}/delete?guard=staff`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [{ type: "BankInfo", id: arg }],
    }),
  }),
});

export const {
  useCreateBankInfoMutation,
  useDeleteBankInfoMutation,
  useEditBankInfoMutation,
  useGetAllBankInfoQuery,
} = societyBankExtendedApi;
