import React from "react";
import { ButtonComp } from "../Ui";

export default function Message({
  setModalToggle,
  title,
  desc,
  onBack,
  onProceed,
  loading,
}) {
  return (
    <div>
      <div className="py-3"></div>
      <div className="text-center">
        <h4 className="text-black text-center fw-bold">{title}</h4>
        <p className="pt-3 pb-4 text-black fw-2">{desc}</p>
        <div className="row justify-content-around">
          <div className="col-5">
            <ButtonComp
              btnText={"No"}
              btnClassName={" text-1 btn w-100 py-2"}
              BorderColor={"#8B313A"}
              onClick={() => onBack()}
            />
          </div>

          <div className="col-5">
            <ButtonComp
              btnText={"Yes"}
              ButtonInlineStyle={{ backgroundColor: "#FF4343" }}
              btnClassName={"border-0 text-white  w-100 py-2 rounded"}
              onClick={() => onProceed()}
              loading={loading}
            />
          </div>
        </div>
      </div>
      <div className="py-3"></div>
    </div>
  );
}
