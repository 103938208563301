import React from "react";
import FallbackImage from "./ImageWrapper";

export default function BranchComp({ branch }) {
  return (
    <div className="mb-3">
      <div
        className="py-2 text-center "
        style={{
          background: "#E0E0E0",
          color: "#1E1E1E",
          borderRadius: "10px 10px 0 0",
        }}
      >
        {branch?.branch?.name}
      </div>
      <div style={{}} className="bg-white shadow  pt-4 pb-3  ps-4 pe-4 ">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div
            style={{ background: "#A8D4E1" }}
            className="me-3 px-4 py-1 rounded-pill"
          >
            <p className="fw-2 mb-0 h60" style={{ color: "#31778C" }}>
              {branch?.branch?.name}
            </p>
          </div>
          <div onClick={() => console.log(branch?.branch?.id)}>
            <FallbackImage
              width={24}
              height={24}
              src="/Staff/delete.svg"
              alt=""
            />
          </div>
        </div>
        <div>
          <h6>Address</h6>
          <p className="h60"> {branch?.branch?.address}</p>
        </div>
      </div>
    </div>
  );
}
