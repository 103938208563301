import React, { useState, useEffect } from "react";
import {
  MoneyInputComp,
  TextInputComp,
  FormikSelectComp,
  ToolTips,
} from "../../../../components";
import { useGetPaymentMethodsQuery } from "../../../../store/selectableSlice";

const formatCurrency = (amount) => {
  return `₦${parseFloat(amount)
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
};

export default function Payment({
  amount,
  amountBreakdown,
  onSelectionChange,
}) {
  console.log("Amount:", amount);
  console.log("Amount Breakdown:", amountBreakdown);
  const { data: payment_methods = [] } = useGetPaymentMethodsQuery();

  const [checkedItems, setCheckedItems] = useState(() =>
    amountBreakdown.reduce((acc, item) => {
      acc[item.id] = item.category === "Compulsory";
      return acc;
    }, {})
  );
  const [totalAmount, setTotalAmount] = useState(() =>
    amountBreakdown.reduce((sum, item) => {
      if (item.category === "Compulsory") {
        return sum + parseFloat(item.amount);
      }
      return sum;
    }, 0)
  );

  useEffect(() => {
    const newTotalAmount = amountBreakdown.reduce((sum, item) => {
      if (checkedItems[item.id]) {
        return sum + parseFloat(item.amount);
      }
      return sum;
    }, 0);
    setTotalAmount(newTotalAmount);

    // Update the break_down array
    const selectedIds = amountBreakdown
      .filter((item) => checkedItems[item.id])
      .map((item) => item.id);
    onSelectionChange(selectedIds);
  }, [checkedItems, amountBreakdown, onSelectionChange]);

  const handleCheckboxChange = (id) => {
    setCheckedItems((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  return (
    <div>
      <div className="row gx-5 gy-3 justify-content-between">
        <div className="col-12 col-lg-5">
          <TextInputComp
            LabelText={"Payment type"}
            LabelClassName={"fw-bold"}
            placeholder={"Registration & Form fee"}
            name="payment"
            disabled
          />
        </div>
        <div className="col-12 col-lg-5">
          <FormikSelectComp
            fieldName={"payment_type_id"}
            labelText={"Payment Method"}
            labelClassName={"fw-bold"}
            selectText={"Select payment method"}
            options={payment_methods.map((payment_method) => ({
              label: payment_method.name,
              // value: payment_method.id,
              value: payment_method.name,
            }))}
          />
        </div>
        <div className="col-12 col-lg-5">
          <MoneyInputComp
            LabelText={"Amount"}
            LabelClassName={"fw-bold"}
            placeholder={formatCurrency(totalAmount)}
            value={totalAmount.toFixed(2)}
            disabled
            desc=""
            descClassName="text-danger"
            name="amount"
          />
          <div className="mt-1">
            <div className="grid grid-cols-2 gap-4">
              {amountBreakdown.map((item, index) => {
                const isChecked = checkedItems[item.id];
                return (
                  <div key={index} className="flex items-center">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="mr-2"
                      checked={isChecked}
                      onChange={() => handleCheckboxChange(item.id)}
                      disabled={item.category === "Compulsory"}
                    />
                    <ToolTips
                      showText={item.category}
                      text={
                        <small
                          style={{ lineHeight: "100%" }}
                          className="mb-0 text-primary1 fw-bold text-capitalize"
                        >
                          {item.name}: {formatCurrency(item.amount)}
                        </small>
                      }
                    ></ToolTips>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
