import { useState } from "react";
import { ButtonComp } from "../../../../components";
import styled from "styled-components";

const GroupForm = ({
  onBack,
  onProceed,
  initialValue = "",
  title,
  submitText = "Submit",
}) => {
  const [groupName, setGroupName] = useState(initialValue);
  return (
    <Wrapper className="text-center px-4 py-4">
      <img src="/Icon/cancel.svg" role="button" onClick={onBack} alt="close" />
      <div className="mb-4">
        <h5 className="fw-bold text-center">{title}</h5>
      </div>
      <div className="py-2"></div>
      <div className="px-3">
        <input
          className="w-100 py-2 px-2"
          value={groupName}
          onChange={(event) => setGroupName(event.target.value)}
          style={{
            border: "1px solid #a1a0a0",
            backgroundColor: "#fafafa",
            borderRadius: "5px",
          }}
        />
      </div>
      <div className="row justify-content-between mt-4">
        <div className="col-5">
          <ButtonComp
            btnText={"Cancel"}
            ButtonInlineStyle={{ borderRadius: "10px" }}
            btnClassName={" text-1 btn w-100 py-2"}
            BorderColor={"#8B313A"}
            onClick={() => onBack()}
          />
        </div>
        <div className="col-6">
          <ButtonComp
            btnText={submitText}
            ButtonInlineStyle={{
              backgroundColor: "#FF4343",
              borderRadius: "10px",
            }}
            btnClassName={"border-0 text-white w-100 py-2"}
            onClick={() => onProceed(groupName)}
          />
        </div>
      </div>
      <div className="py-2"></div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 600px;
  position: relative;
  img {
    position: absolute;
    left: 16px;
  }
`;

export default GroupForm;
