import React from 'react'
import { RiSearch2Line } from 'react-icons/ri'

export default function SearchBar({placeholder}) {
  return (
    <div class="input-group mb-3 search">
    <span className="input-group-text bg-primary1 px-4">
      <RiSearch2Line size={20} color={"#fff"} />
    </span>
    <input
      type="text"
      className="form-control bg-gary3 border-0 p"
      style={{padding:'10px'}}
      placeholder={placeholder}
    />
  </div>
  )
}
