import React, { useState } from "react";
import { ButtonComp, ModalCompAdvance } from "../../../components";
import PendingMemberComp from "./modules/PendingMember";
import { useGetPendingMembersQuery } from "../../../store/admin/memberManagement/memberSlice";
import { useMemo } from "react";
// import TablePagination from "../../../components/Table/Pagination";
import useMemberRegAction from "./useMemberRegAction";

export default function PendingMember() {
  const [modalToggle, setModalToggle] = useState(false);
  const [modalPage, setModalPage] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [memberId, setMemeberId] = useState(null);
  const { acceptMember, rejectMember } = useMemberRegAction();

  const { error, data, isFetching } = useGetPendingMembersQuery(pageNumber);
  const { allMembers = [], pagination = {} } = useMemo(() => {
    const fetchedMembers = data?.members || [];
    const pagination = data?.pagination || {};
    const perPage = pagination?.perPage || 5;
    const formattedMembers = fetchedMembers.map((item, index) => {
      return {
        name: item.first_name + " " + item.last_name,
        phone: item.phone,
        status: item.status,
        branch: item.branch.name,
        photo: item.photo,
        serialNumber: index + 1 + (pageNumber - 1) * perPage,
        id: item.id,
        is_active: item.is_activated,
      };
    });
    return { allMembers: formattedMembers, pagination };
  }, [data, pageNumber]);

  if (error) {
    console.log(error);
  }
  const useModal = [
    {
      name: "Confirm Modal",
      component: (
        <div>
          <div className="py-3"></div>
          <div className="text-center">
            <h4 className="text-black text-center fw-bold">Confirm</h4>
            <p className="mb-5 text-black fw-2">
              Are you sure you want to add this member to branch name?
            </p>
            <div className="row justify-content-around">
              <div className="col-5">
                <ButtonComp
                  onClick={() => setModalToggle(false)}
                  btnText={"No"}
                  btnClassName={" text-1 btn w-100 py-3"}
                  BorderColor={"#8B313A"}
                />
              </div>
              <div className="col-5">
                <ButtonComp
                  ButtonInlineStyle={{ background: "#FF4343" }}
                  btnText={"Yes"}
                  btnClassName={"border-0 text-white rounded w-100 py-3"}
                  onClick={() => {
                    acceptMember(memberId).finally(() => {
                      setModalToggle(false);
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="py-3"></div>
        </div>
      ),
    },
    {
      name: "Decline Modal",
      component: (
        <div>
          <div className="py-3"></div>
          <div className="text-center">
            <h4 className="text-black text-center fw-bold">Suspend member</h4>
            <p className="mb-5 text-black fw-2">
              Are you sure you want to suspend this member?
            </p>
            <div className="row justify-content-around">
              <div className="col-5">
                <ButtonComp
                  onClick={() => setModalToggle(false)}
                  btnText={"No"}
                  btnClassName={" text-1 btn w-100 py-3"}
                  BorderColor={"#8B313A"}
                />
              </div>
              <div className="col-5">
                <ButtonComp
                  ButtonInlineStyle={{ background: "#FF4343" }}
                  btnText={"Yes"}
                  btnClassName={"border-0 text-white rounded w-100 py-3"}
                  onClick={() => {
                    rejectMember(memberId).finally(() => setModalToggle(false));
                  }}
                />
              </div>
            </div>
          </div>
          <div className="py-3"></div>
        </div>
      ),
    },
  ];
  return (
    <>
      <ModalCompAdvance
        modalBody={modalPage}
        show={modalToggle}
        arrayComp={useModal}
        handleClose={() => setModalToggle(false)}
        centered
        size={"md"}
      />
      <PendingMemberComp
        modalPage={modalPage}
        setModalPage={setModalPage}
        useModal={useModal}
        setModalToggle={setModalToggle}
        title={"Pending members"}
        tableData={allMembers}
        isFetching={isFetching}
        pagination={pagination}
        setPageNumber={setPageNumber}
        setMemberId={setMemeberId}
      />
    </>
  );
}
