import { useState } from "react";
import { BsArrowLeft, BsDot } from "react-icons/bs";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  ButtonComp,
  CardComp,
  ModalCompAdvance,
  TextAreaComp,
  TextInputComp,
} from "../../../components";
// import AdminLayout from "../../../Layout/AdminLayout";
import { Formik, Form } from "formik";
import useMemberRegAction from "./useMemberRegAction";

const SuspendMember = () => {
  const navigate = useNavigate();
  const [modalToggle, setModalToggle] = useState(false);
  const [modalPage] = useState(0);
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const name = searchParams.get("name");
  const [reason, setReason] = useState("");
  const { suspendMember } = useMemberRegAction();

  const handleSubmit = () => {
    suspendMember({ id, reason });
    setModalToggle(false);
  };

  const useModal = [
    {
      name: "Suspend Modal",
      component: (
        <div>
          <div className="py-3"></div>
          <div className="text-center">
            <h4 className="text-black text-center fw-bold">Suspend staff</h4>
            <p className="mb-5 text-black fw-2">
              Are you sure you want to suspend this staff
            </p>
            <div className="row justify-content-around">
              <div className="col-5">
                <ButtonComp
                  btnText={"No"}
                  btnClassName={"border-0 text-white bg-primary1 w-100 py-3"}
                  onClick={() => setModalToggle(false)}
                  type={"button"}
                />
              </div>
              <div className="col-5">
                <ButtonComp
                  btnText={"Yes"}
                  btnClassName={" text-1 btn w-100 py-3"}
                  BorderColor={"#8B313A"}
                  type={"submit"}
                  onClick={() => {
                    handleSubmit();
                  }}
                />
              </div>
            </div>
          </div>
          <div className="py-3"></div>
        </div>
      ),
    },
  ];

  return (
    <Formik initialValues={{ name: name, reason: "" }}>
      <Form>
        <section className=" min-vh-100  d-flex justify-content-center">
          <div className=" px-lg-4 py-4 rounded ">
            <CardComp cardClassName={"border-0 px-lg-4 mb-3 py-2"}>
              <div className="d-flex ">
                <BsArrowLeft
                  onClick={() =>
                    navigate("/super_admin/member_management/members")
                  }
                  size={30}
                  className="me-3 pointer"
                />
                <h4 className="fw-bold mb-0 text-black1">Member suspension</h4>
              </div>
            </CardComp>

            {/*  */}
            <CardComp cardClassName={"border-0 px-lg-4 mb-3 py-4"}>
              <div className="row gx-6 mb-4">
                <div className="col-lg-6">
                  <div className="col-lg-12 border-lg-end mb-4">
                    <TextInputComp
                      labelText={"Staff Name"}
                      placeholder={"Alabi Adeola Martins"}
                      LabelClassName="fw-bold"
                      name={"name"}
                    />
                  </div>
                  <div className="col-lg-12 border-lg-end mb-5">
                    <TextAreaComp
                      rows={10}
                      LabelText={"Reason for staff suspension"}
                      placeholder={"Enter message"}
                      labelClassName="fw-bold"
                      name={"reason"}
                      value={reason}
                      onChange={(event) => setReason(event?.target?.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 ">
                  <CardComp cardClassName="border-0 shadow py-4">
                    <h5 className="text-black mb-4 fw-bold text-center">
                      Instructions
                    </h5>
                    <div className="d-flex align-items-center mb-3">
                      <span>
                        <BsDot color="#8B313A" size={40} />
                      </span>
                      <p>
                        Select the date corresponding to the attendance schedule
                        to mark members attendance{" "}
                      </p>
                    </div>
                    <div className="d-flex align-items-center mb-3">
                      <span>
                        <BsDot color="#8B313A" size={40} />
                      </span>
                      <p>
                        Select the date corresponding to the attendance schedule
                        to mark members attendance{" "}
                      </p>
                    </div>
                    <div className="d-flex align-items-center mb-3">
                      <span>
                        <BsDot color="#8B313A" size={40} />
                      </span>
                      <p>
                        Select the date corresponding to the attendance schedule
                        to mark members attendance{" "}
                      </p>
                    </div>
                  </CardComp>
                </div>
              </div>
            </CardComp>
            <div className="col-lg-12 border-lg-end">
              <ButtonComp
                btnText={"Submit"}
                btnClassName={
                  "px-5 text-white bg-primary1 col-6 col-md-3 border-0 py-3 h6 rounded-3"
                }
                onClick={() => setModalToggle(true)}
                type={"button"}
              />
            </div>
          </div>
        </section>
        <div className="my-5"></div>
        <ModalCompAdvance
          modalBody={modalPage}
          show={modalToggle}
          arrayComp={useModal}
          handleClose={() => setModalToggle(false)}
          centered
          size={"md"}
        />
      </Form>
    </Formik>
  );
};

export default SuspendMember;
