import { useAuth, useUserType } from "../hooks";
import { Navigate, useLocation } from "react-router-dom";

const PRIVATE = ["super_admin", "member"];
const PUBLIC = ["/login", "/reset_password", "/forget_password"];

export const AuthGuard = ({ children }) => {
  const path = useLocation().pathname;
  const auth = useAuth();
  const userType = useUserType();
  const isLoggedIn = !!auth;

  const isPrivate = PRIVATE.some((route) => path.includes(route));

  if (isPrivate && !isLoggedIn) return <Navigate to="/login" replace />;
  if (!isPrivate && isLoggedIn && userType?.includes("staff")) {
    return <Navigate to="/super_admin/dashboard" replace />;
  }
  if (PUBLIC.includes(path) && isLoggedIn && userType?.includes("member"))
    return <Navigate to="/member/dashboard" replace />;

  return <>{children}</>;
};
