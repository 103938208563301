import React from "react";
// import { Carousel } from "react-bootstrap";
import { FcGoogle } from "react-icons/fc";
import styled from "styled-components";
// import { LoginCarouselData } from "../../../utils";
import FallbackImage from "../../Common/ImageWrapper";
// import SwiperComp from "../../Common/SwiperComp";
import { ButtonComp, TextInputComp } from "../../Ui";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { selectCurrentCompanyId } from "../../../store/auth/index.js";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const backgroundImageStyleLeft = {
  backgroundImage: `url("../Images/Login/sign-up-left.png")`,
  backgroundSize: 'cover',
  // backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat'
};

const backgroundImageStyleRight = {
  backgroundImage: `url("../Images/Login/sign-up-right.png")`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat'
};


export default function LoginPage({
  handleLoginSubmit,
  companyDetails,
  isLoading,
}) {
  const navigate = useNavigate();
  const PageSideStyle = styled.div`
    z-index: 3;
    height: 100% !important;
  `;
  // fetch the company Id
  const coopId = useSelector(selectCurrentCompanyId);
  const initialValues = {
    email: "",
    password: "",
    company_id: coopId,
  };

  // Yup validations schema
  const validationSchema = Yup.object({
    email: Yup.string().email().required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  /*
  const PageSide = (item) => {
    return (
      <PageSideStyle className="position-relative bg-white text-black h-100">
        <div className="d-none d-md-flex" style={{ height: "40vh" }}>
          <FallbackImage src="/Login/top.png" width={246} height={246} />
        </div>
        <div className="mb-md-5 pb-5 container col-lg-10">
          <h1 className="text-center fw-bolder h10 col-md-11 mb-md-4 ">
            {item?.title}
          </h1>
          <p className="text-start d-none d-lg-block lh-lg">{item?.desc}</p>
          <p className="text-center  d-md-none">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Consectetur
            nunc, erat auctor commodo eu nu
          </p>
        </div>
        <div className="py-md-5 my-md-5"></div>
        <div className="d-none d-md-flex align-items-end position-absolute bottom-0 w-100">
          <FallbackImage src="/Login/bottom.svg" width={"100%"} />
        </div>
        <div className="d-flex justify-content-center"></div>
      </PageSideStyle>
    );
  };
  */

  return (
    <>
      <div className="Login">
        {/* <NavbarPage /> */}
        {/* <div className="px-0 container-lg mt-7"> */}
        <div className="">
          <div className="flex flex-col lg:flex-row lg:gap-0 lg:h-screen">
            {/* <div className="col-lg-5 pt-lg-4 rounded overflow-hidden" */}
            {/* <div className="lg:w-1/2 p-10 lg:p-20 min-h-screen flex flex-col items-center justify-center" style={backgroundImageStyleLeft}> */}
            <div className="lg:w-1/2 p-10 lg:px-[125px] lg:py-[195px] min-h-screen flex flex-col items-center justify-center bg-[#8B313A1A]">
              <div className="absolute top-0 left-0">
                <svg width="150" height="172" viewBox="0 0 150 172" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="-15.1161" cy="6.96153" r="164.683" transform="rotate(-36.1373 -15.1161 6.96153)" fill="#8B313A" fill-opacity="0.1" />
                </svg>
              </div>
              <div className="absolute top-0 left-0">
                <svg width="111" height="126" viewBox="0 0 111 126" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="-35.8583" cy="-21.4443" r="146.75" transform="rotate(-36.1373 -35.8583 -21.4443)" fill="#8B313A" />
                </svg>
              </div>
              <div className="absolute bottom-5 left-5">
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g filter="url(#filter0_dd_4708_45168)">
                    <circle cx="30.2743" cy="30.4064" r="17.4447" fill="#8B313A" />
                  </g>
                  <defs>
                    <filter id="filter0_dd_4708_45168" x="0.82959" y="0.961731" width="58.8894" height="58.8894" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dx="2" dy="2" />
                      <feGaussianBlur stdDeviation="5" />
                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4708_45168" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dx="-2" dy="-2" />
                      <feGaussianBlur stdDeviation="5" />
                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                      <feBlend mode="normal" in2="effect1_dropShadow_4708_45168" result="effect2_dropShadow_4708_45168" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_4708_45168" result="shape" />
                    </filter>
                  </defs>
                </svg>
              </div>
              {/* <div className="mx-auto col-xl-11 py-3 bg-white px-4 "> */}
              <div className="bg-white py-10 px-10 rounded-3xl flex flex-col relative">
                  <div className="absolute -top-12 -right-5">
                    <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M17.0001 0.200348L20.8884 12.1674H33.4713L23.2915 19.5635L27.1799 31.5305L17.0001 24.1345L6.82026 31.5305L10.7086 19.5635L0.528801 12.1674H13.1117L17.0001 0.200348Z" fill="#8B313A" />
                    </svg>
                  </div>
                  <div className="absolute top-0 -right-10">
                    <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M23.7024 0.128797L14.3185 21.944L0.117944 2.90972L23.7024 0.128797Z" fill="#B13E4A" />
                    </svg>
                  </div>
                {/* <div className="text-center mb-5 ">
                  <div
                    className="bg-primary1 d-inline-block rounded-2"
                    style={{ width: "40px", height: "40px"}}
                  ></div>
                  <p className="mb-1 fw-bold ">{companyDetails?.name}</p>
                  <div className="h50">{companyDetails?.address}</div>
                  <div className="h50">+234 814 0307 009</div>
                </div> */}

                {/* <div className="pt-4 pb-3 container rounded-4 mb-3 text-left "> */}

                <div className="flex flex-col items-center bg-white rounded-3xl text-center lg:hidden">
                  <img
                    src={companyDetails?.logo_url}
                    alt="profile"
                    className="w-[100px] h-[100px] rounded-full "
                  />
                  <p className="font-[700] text-[32px] mt-2 ">
                    {companyDetails?.name}
                  </p>
                  <p className="text-[#858585] text-lg">
                    {companyDetails?.address}
                  </p>
                </div>

                <div className="text-left lg:mt-5 lg:mb-5 mt-3 mb-3">
                  <h2 className="font-[700] mb-2 text-[24px]">Welcome✨</h2>
                  <div>
                    Sign in with your credetials to experience seamless
                    cooperative activities
                  </div>
                </div>
                {/* <div className="mb-5">
                  <div></div>
                </div> */}
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleLoginSubmit}
                >
                  <Form>
                    {/* <div className="col-lg-11"> */}
                    <div className="mb-2">
                      {/* <div className="flex justify-center border-[1px]">hello</div> */}
                      <TextInputComp
                        LabelClassName={"fw-normal"}
                        borderColor={"#A1A0A0"}
                        borderWidth="1px"
                        InputClassName={"border  !border-1 py-2"}
                        labelText={"Email Address"}
                        placeholder="Enter Email Address"
                        name="email"
                        type="text"
                      />
                    </div>
                    <div className="mb-2">
                      <TextInputComp
                        LabelClassName={"fw-normal"}
                        borderColor={"#A1A0A0"}
                        borderWidth="1px"
                        InputClassName={"border  border-1 py-2"}
                        labelText={"Password"}
                        placeholder="Enter Password"
                        name="password"
                        type="password"
                      />
                    </div>
                    <div
                      onClick={() => navigate("/forget_password")}
                      className="fw-3 mt-3 lg:mb-5 mb-2  pointer"
                    >
                      Forget password?
                    </div>
                    <div className="mb-4">
                      <ButtonComp
                        loading={isLoading}
                        btnText={"Login"}
                        type="submit"
                        btnClassName="w-100 bg-primary1 text-white border-0 py-3 rounded-4 fw-bold"
                      />
                    </div>

                    <h6 className="background text-center mb-4">
                      <span>OR</span>
                    </h6>
                    <div className="text-center mb-5">
                      <ButtonComp
                        // BorderColor={"#8B313A"}
                        BorderWidth={"1px"}
                        btnClassName={
                          "border border-1 rounded border-secondary-1 py-2 px-3 bg-white"
                        }
                        btnText={
                          <span className="flex">
                            <span className="pe-2">
                              <FcGoogle size={25} />
                            </span>
                            Continue with Google
                          </span>
                        }
                      />
                    </div>
                    <div>
                      <div className="text-center h50 lg:mb-5">
                        Don’t have an account?
                        <span className="fw-3 text-1 h40 ml-2">Sign up</span>
                      </div>
                    </div>
                    {/* </div> */}
                  </Form>
                </Formik>
              </div>
            </div>
            {/* <div className="col-lg-7 bg-white sideA d-block">
              <SwiperComp Data={LoginCarouselData} Page={PageSide} />
            </div> */}
            <div
              className="lg:w-1/2 lg:flex lg:flex-col items-center justify-center lg:h-auto hidden"
              style={backgroundImageStyleRight}
            >
              <div className="w-[75%] flex flex-col items-center bg-white p-10 rounded-2xl">
                <img
                  src={companyDetails?.logo_url}
                  alt="profile"
                  className="w-[100px] h-[100px] rounded-full "
                />
                <p className="font-[700] text-[32px] mt-2 ">
                  {companyDetails?.name}
                </p>
                <p className="text-[#858585] text-lg">
                  {companyDetails?.address}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
