import axios from "axios";

export const uploadImage = async (imgFile) => {
  const photoData = new FormData();
  photoData.append("file", imgFile);
  photoData.append("upload_preset", "svmprhvf");
  return await axios
    .post("https://api.cloudinary.com/v1_1/afrilocales/upload", photoData)
    .then((response) => {
      return response?.data?.secure_url;
    })
    .catch(() => {
      return "https://www.gravatar.com/avatar/a208380389f40731d3ac10839ec143a2?d=identicon&r=pg&s=100";
    });
};

export default uploadImage;
