import { API } from "../../api";


export const dashboardExtendedApi = API.injectEndpoints({
    endpoints: (builder) => ({
        dashboard: builder.query({
            query: (weekly = false) => {
                return {
                    url: `/cooperatives/dashboard?guard=staff&filter=${weekly ? "weekly": "monthly"}&meetingFilter=today`,
                    method: "GET",
                };
            }
        })
    })
})


export const {
    useDashboardQuery
} = dashboardExtendedApi;
