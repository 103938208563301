import React, { useState } from "react";

import { PageSwitch } from "../../../components";
import NewApplicationForm from "./modules/newApplicationForm";

export default function NewApplication() {
  const [pageName, setPageName] = useState("new_applicant");

  const usePage = [
    {
      name: "new_applicant",
      component: (
        <NewApplicationForm
          onNext={() => {
            setPageName("non-member Guarantor");
          }}
        />
      ),
    },
  ];

  return <PageSwitch pageArray={usePage} pageName={pageName} />;
}
