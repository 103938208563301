import { API, ApiPrefixes } from "../api";

export const adminLoanApi = API.injectEndpoints({
  endpoints: (builder) => ({
    getPendingLoansRequestss: builder.query({
      query: (page) => ({
        url:
          ApiPrefixes["loanManagement"] +
          `/requests/pending?guard=staff&page=${page}&limit=20`,
      }),
      providesTags: (result, error, arg) => [
        { type: "pending-loans-list", id: arg },
      ],
      cacheStrategy: "cache-and-network",
    }),

    getApprovedLoansList: builder.query({
      query: (page) => ({
        url:
          ApiPrefixes["loanManagement"] +
          `/requests/approved?guard=staff&page=${page}&limit=20`,
      }),
      // providesTags: "approved-loans-list",
      providesTags: (result, error, arg) => [
        { type: "approved-loans-list", id: arg },
      ],
    }),

    getDeclinedLoansList: builder.query({
      query: (page) => ({
        url:
          ApiPrefixes["loanManagement"] +
          `/requests/declined?guard=staff&page=${page}&limit=20`,
      }),
      // providesTags: "declined-loans-list",
      providesTags: (result, error, arg) => [
        { type: "declined-loans-list", id: arg },
      ],
    }),

    getDisbursedLoansList: builder.query({
      query: (page) => ({
        url:
          ApiPrefixes["loanManagement"] +
          `/requests/disbursed?guard=staff&page=${page}&limit=20`,
      }),
      // providesTags: "disbursed-loans-list",
      providesTags: (result, error, arg) => [
        { type: "disbursed-loans-list", id: arg },
      ],
    }),

    createLoanRequest: builder.mutation({
      query: (payload) => ({
        url: ApiPrefixes["loanManagement"] + `/requests/add?guard=staff`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: [{ type: "pending-loans-list" }],
    }),

    approveLoanRequest: builder.mutation({
      query: (requestId) => ({
        url:
          ApiPrefixes["loanManagement"] +
          `/requests/${requestId}/approve?guard=staff`,
        method: "PUT",
      }),
      invalidatesTags: [
        { type: "pending-loans-list" },
        { type: "approved-loans-list" },
        { type: "declined-loans-list" },
      ],
    }),

    declineLoanRequest: builder.mutation({
      query: ({ requestId, decline_reason }) => ({
        url:
          ApiPrefixes["loanManagement"] +
          `/requests/${requestId}/decline?guard=staff`,
        method: "PUT",
        body: { decline_reason: decline_reason },
      }),
      invalidatesTags: [
        { type: "pending-loans-list" },
        { type: "declined-loans-list" },
      ],
    }),

    disburseRequestedLoan: builder.mutation({
      query: (payload) => ({
        url:
          ApiPrefixes["loanManagement"] +
          `/requests/${payload.requestId}/disburse?guard=staff`,
        method: "PUT",
        body: payload.payload,
      }),
      invalidatesTags: [
        { type: "disbursed-loans-list" },
        { type: "approved-loans-list" },
      ],
    }),

    getLoanTypes: builder.query({
      query: (page) => ({
        url: ApiPrefixes["loanManagement"] + `/types?guard=staff&page=${page}`,
      }),
      // providesTags: "loan-types-list",
      providesTags: (result, error, arg) => [
        { type: "loan-types-list", id: arg },
      ],
    }),

    getSelectableLoanTypes: builder.query({
      query: () => ({
        url: ApiPrefixes["adminSelectables"] + `/loan-settings?guard=staff`,
      }),
      // providesTags: "loan-types-list",
      providesTags: (result, error, arg) => [
        { type: "selectable-loan-types-list", id: arg },
      ],
    }),

    createLoanType: builder.mutation({
      query: (payload) => ({
        url: ApiPrefixes["loanManagement"] + `/types/add?guard=staff`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: [
        { type: "loan-types-list" },
        { type: "selectable-loan-types-list" },
      ],
    }),

    editLoanType: builder.mutation({
      query: ({ payload, loanTypeId }) => ({
        url:
          ApiPrefixes["loanManagement"] +
          `/types/${loanTypeId}/edit?guard=staff`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: [{ type: "loan-types-list" }],
    }),

    deleteLoanType: builder.mutation({
      query: ({ loanTypeId }) => ({
        url:
          ApiPrefixes["loanManagement"] +
          `/types/${loanTypeId}/delete?guard=staff`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "loan-types-list" }],
    }),

    getGuarantors: builder.query({
      query: () => ({
        url: ApiPrefixes["loanManagement"] + `/guarantors?guard=staff`,
      }),
      providesTags: (result, error, arg) => [
        { type: "guarantors-list", id: arg },
      ],
    }),
  }),
});

export const {
  useApproveLoanRequestMutation,
  useDeclineLoanRequestMutation,
  useDisburseRequestedLoanMutation,
  useCreateLoanRequestMutation,
  useGetApprovedLoansListQuery,
  useGetDeclinedLoansListQuery,
  useGetDisbursedLoansListQuery,
  useGetPendingLoansRequestssQuery,
  useGetLoanTypesQuery,
  useGetSelectableLoanTypesQuery,
  useCreateLoanTypeMutation,
  useEditLoanTypeMutation,
  useDeleteLoanTypeMutation,
  useGetGuarantorsQuery,
} = adminLoanApi;
