import { useState } from "react";
import styledComponents from "styled-components";

const Style = styledComponents.div`

width:${(prop) => prop.Width};
height:${(prop) => prop.Height};

img{
  object-fit:${(prop) =>
    prop.objectFit ? prop.objectFit : "cover"} !important;
  filter: ${(prop) => (prop.brightness ? "brightness(0.5)" : "none")};
}
 span{
  height:100% !important;
}
@media (max-width: 786px) {
  height:${(prop) => (prop.Height2 ? prop.Height2 : "auto")};
 width: auto !important;
}

`;

export const ImageFrame = ({
  width,
  height,
  height2,
  ImageComp,
  objectFit,
  brightness,
  imgClass,
}) => {
  return (
    <Style
      Height={height}
      Height2={height2}
      Width={width}
      objectFit={objectFit}
      brightness={brightness}
    >
      {ImageComp}
    </Style>
  );
};

export const ImageLoaderUpdate = ({ src, imageWrapperClassName, ...props }) => {
  const defaultImage = "";
  const [isImageLoading, setImageLoading] = useState(src ? src : defaultImage);

  return (
    <div className={`${imageWrapperClassName} `}>
      <img
        src={isImageLoading || src}
        alt=""
        className={`${props.imgClass}`}
        {...props}
        onLoad={(e) => {
          setTimeout(() => {}, 3000);
        }}
        onError={(e) => setImageLoading("/loading.jpg")}
      />
    </div>
  );
};
