import { useState } from "react";
import { TextAreaComp, ButtonComp } from "../../../../components";

const MessageForm = ({ title, onBack, onProceed, initialMessage = "" }) => {
  const [message, setMessage] = useState(initialMessage);
  return (
    <div className="py-4">
      <h5 className="fw-bold mb-4 text-center">{title}</h5>
      <TextAreaComp
        rows={10}
        value={message}
        onChange={(event) => setMessage(event.target.value)}
      />
      <div className="row justify-content-between mt-4">
        <div className="col-5">
          <ButtonComp
            btnText={"Cancel"}
            ButtonInlineStyle={{ borderRadius: "10px" }}
            btnClassName={" text-1 btn w-100 py-3"}
            BorderColor={"#8B313A"}
            onClick={() => onBack()}
          />
        </div>
        <div className="col-5">
          <ButtonComp
            btnText={"Submit"}
            ButtonInlineStyle={{
              backgroundColor: "#FF4343",
              borderRadius: "10px",
            }}
            btnClassName={"border-0 text-white w-100 py-3"}
            onClick={() => onProceed(message)}
          />
        </div>
      </div>
    </div>
  );
};

export default MessageForm;
