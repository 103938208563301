import React from "react";
import {
  ButtonComp,
  FormikDatePicker,
  FormikTextAreaComp,
  // TextInputComp,
} from "../../../../components";
import { Form, Formik } from "formik";
import { useDisburseRequestedLoanMutation } from "../../../../store/admin/loan";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { AiOutlineClose } from "react-icons/ai";

const DisburseLoanModal = ({ requestId, setModalPage }) => {
  const [isDisburseRequest, { isLoading: isDisbursingLoans }] =
    useDisburseRequestedLoanMutation();

  const handleDisburseLoanRequest = async (values, { resetForm }) => {
    const payload = { payload: values, requestId: requestId };
    const response = await isDisburseRequest(payload);
    if ("error" in response) {
      const { data, message } = response?.error;
      toast.error(data.message);
      toast.error(message);
      return;
    }
    const { message, status } = response.data;
    if (!status) return toast.error(message);
    toast.success(message);
    setModalPage();
    resetForm();
  };
  return (
    <div className="relative p-4">
      <Formik
        initialValues={{
          disbursement_description: "",
          disbursement_date: "",
        }}
        validationSchema={Yup.object({
          disbursement_date: Yup.string().required("Required"),
          disbursement_description: Yup.string().required("Required"),
        })}
        onSubmit={handleDisburseLoanRequest}
      >
        <Form className="">
          <AiOutlineClose
            onClick={() => setModalPage()}
            className="absolute top-10 left-5"
          />
          <h4 className="border-b fw-bold py-2 mb-4 text-[1.5rem] !text-primary text-center w-full ">
            Loan Disbursement
          </h4>
          <FormikDatePicker
            fieldName={"disbursement_date"}
            labelText={"Disbursement date"}
            labelClassName={"fw-2"}
          />
          <FormikTextAreaComp
            rows={7}
            fieldName={"disbursement_description"}
            labelClassName={"fw-2"}
            LabelText={"Description(Optional)"}
            placeholder={"Write a description"}
          />
          <div className="row justify-content-around pt-8">
            <div className="col-5">
              <ButtonComp
                btnText={"No"}
                btnClassName={" text-1 btn w-100 py-2"}
                BorderColor={"#8B313A"}
                onClick={() => setModalPage()}
              />
            </div>

            <div className="col-5">
              <ButtonComp
                loading={isDisbursingLoans}
                type="submit"
                btnText={"Yes"}
                ButtonInlineStyle={{ backgroundColor: "#FF4343" }}
                btnClassName={"border-0 text-white  w-100 py-2 rounded"}
              />
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default DisburseLoanModal;
