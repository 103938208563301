import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import { ButtonComp, TextInputComp } from "../../../../components";
import { Formik, Form } from "formik";
import * as Yup from "yup";
export default function NonmemberGuarantorForm({
  Back,
  setNonMemberGuarantorDetails,
}) {
  const initialValues = {
    name: "",
    phone: "",
    email: "",
    occupation: "",
    home_address: "",
    office_address: "",
  };
  const validationSchema = Yup.object({
    name: Yup.string().required("Required"),
    phone: Yup.string().required("Required"),
    email: Yup.string().email().required("Required"),
    occupation: Yup.string().required("Required"),
    home_address: Yup.string().required("Required"),
    office_address: Yup.string().required("Required"),
  });
  const handleFormSubmit = async (values, { resetForm }) => {
    setNonMemberGuarantorDetails((initial) => [...initial, values]);
    resetForm();
    Back();
  };

  return (
    <section className="w-full px-4">
      <div className="flex mb-4 py-3  justify-center items-center relative border-b ">
        <AiOutlineClose
          size={20}
          onClick={Back}
          className=" pointer absolute top-6 left-4  "
        />
        <h4 className="fw-bold mb-0 text-black1 text-[1.5rem]">
          Non-member Guarantor
        </h4>
      </div>

      <div className="w-full">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
          className="w-full"
        >
          {() => (
            <Form className="w-full">
              <div className="grid md:grid-cols-2 gap-y-2 gap-x-6">
                <div className="">
                  <TextInputComp
                    LabelText={"Full name"}
                    borderColor="#A1A0A0"
                    LabelClassName={"fw-3"}
                    placeholder={"Olafare Smith Julius"}
                    name="name"
                    type="text"
                  />
                </div>
                <div className="">
                  <TextInputComp
                    LabelText={"Phone number"}
                    borderColor="#A1A0A0"
                    LabelClassName={"fw-3"}
                    placeholder={"Enter phone number"}
                    name="phone"
                    type="number"
                  />
                </div>
                <div className="">
                  <TextInputComp
                    LabelText={"Email address"}
                    borderColor="#A1A0A0"
                    LabelClassName={"fw-3"}
                    placeholder={"Enter email address"}
                    name="email"
                    type="text"
                  />
                </div>
                <div className="">
                  <TextInputComp
                    LabelText={"Occupation"}
                    borderColor="#A1A0A0"
                    LabelClassName={"fw-3"}
                    placeholder={"Enter guarantor's occupation "}
                    name="occupation"
                    type="text"
                  />
                </div>
                <div className="">
                  <TextInputComp
                    LabelText={"Home address"}
                    borderColor="#A1A0A0"
                    LabelClassName={"fw-3"}
                    placeholder={"Enter home address"}
                    name="home_address"
                    type="text"
                  />
                </div>
                <div className="">
                  <TextInputComp
                    LabelText={"Office address"}
                    borderColor="#A1A0A0"
                    LabelClassName={"fw-3"}
                    placeholder={"Enter office address"}
                    name="office_address"
                    type="text"
                  />
                </div>
              </div>
              <div className="my-4 w-full text-center ">
                <small className="text-[#858585] text-base font-semibold ">
                  A mail will be sent to the information for confirmation
                </small>
              </div>
              <div className="d-flex justify-content-center  pb-4">
                <div className="col-4">
                  <ButtonComp
                    btnText={"Submit information"}
                    btnClassName={
                      "w-100 bg-primary1 py-[12px] text-white border-0 rounded-[0.625rem] fw-3"
                    }
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </section>
  );
}
