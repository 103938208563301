import React, { useState } from "react";
// import AdminLayout from "../../../Layout/AdminLayout";
import { CardComp } from "../../../components";
import CreateSocietyBank from "./modules/CreateSocietyBank";
import { useCreateBankInfoMutation } from "../../../store/admin/settings/societyBankSlice";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const NewBankInfo = () => {
  const navigate = useNavigate();
  const [addNewBankInfo] = useCreateBankInfoMutation();
  const [bankSelected, setBankSelected] = useState(null);
  const [selectErrors, setSelectErrors] = useState([""]);
  const handleBankSelect = (optionSelected) => {
    const { value, label } = optionSelected;
    if (!value) {
      setBankSelected(null);
      setSelectErrors(["required"]);
    } else {
      setSelectErrors([]);
      setBankSelected({
        bank_code: value,
        bank_name: label,
      });
    }
  };
  const createBankInfo = async (formikValues, { setSubmitting, resetForm }) => {
    if (!bankSelected) {
      setSelectErrors(["required"]);
      setSubmitting(false);
      return;
    }
    const reqBody = {
      ...formikValues,
      ...bankSelected,
    };
    const toastId = toast.loading("Processing");
    try {
      setSubmitting(true);
      const payload = await addNewBankInfo(reqBody).unwrap();
      console.log(payload);
      if (payload.status === false) {
        const errMsg =
          payload.message || payload.data.message || "An error occured";
        toast.error(errMsg, { id: toastId });
      }
      if (payload.status === true) {
        const successMsg = payload.message || payload.data.message || "Success";
        toast.success(successMsg, { id: toastId });
        resetForm();
      }
    } catch (error) {
      if (error.status === 401) {
        navigate("/login");
      }
      toast.error(error?.message || error?.data.message || "An error occured", {
        id: toastId,
      });
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <section className="px-2 px-lg-4 min-vh-100">
      <div className="py-1"></div>
      <CardComp cardClassName={"pt-4"} cardBodyClassName={"py-0"}>
        <h4 className=" text-black1 fw-bold">
          Create Society Bank Information
        </h4>
      </CardComp>
      <CreateSocietyBank
        onNext={createBankInfo}
        handleBankSelect={handleBankSelect}
        selectErrors={selectErrors}
      />
    </section>
  );
};

export default NewBankInfo;
