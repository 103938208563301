import { API, ApiPrefixes } from "../api";

const generalSelectionsApi = API.injectEndpoints({
  endpoints: (builder) => ({
    paymentMethods: builder.query({
      query: () => ({
        url: ApiPrefixes["generalSelectables"] + `/payment_methods?guard=staff`,
      }),
    }),
    getEnums: builder.query({
      query: (body) => ({
        url: "/enums",
        method: "GET",
        body,
      }),
    }),
  }),
});

export const { usePaymentMethodsQuery, useGetEnumsQuery } = generalSelectionsApi;
