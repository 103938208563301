import { API, ApiPrefixes } from "../../api";
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";

const BASE_URL = ApiPrefixes["adminCompanyPrefixesSettings"];

const prefixesAdapter = createEntityAdapter();
const initialState = prefixesAdapter.getInitialState();

export const companyPrefixExtendedApi = API.injectEndpoints({
  endpoints: (builder) => ({
    getPrefixes: builder.query({
      query: () => `${BASE_URL}?guard=staff`,
      providesTags: (result, error, arg) => {
        if (!result?.ids) return [{ type: "Prefix", id: "LIST" }];
        return [
          { type: "Prefix", id: "LIST" },
          ...result.ids.map((id) => ({ type: "Prefix", id: String(id) })),
        ];
      },
      transformResponse: (responseData) => {
        return prefixesAdapter.setAll(initialState, responseData.data);
      },
    }),
    addPrefix: builder.mutation({
      query: (body) => ({
        url: `${BASE_URL}/create_prefix?guard=staff`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: [{ type: "Prefix", id: "LIST" }],
    }),
    editPrefix: builder.mutation({
      query: (body) => ({
        url: `${BASE_URL}/${body.prefixId}/update_prefix?guard=staff`,
        method: "PATCH",
        body: body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Prefix", id: arg.prefixId },
      ],
    }),
    deletePrefix: builder.mutation({
      query: (prefixId) => ({
        url: `${BASE_URL}/${prefixId}/delete?guard=staff`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Prefix", id: String(arg) },
      ],
    }),
  }),
});

const selectPrefixesResult =
  companyPrefixExtendedApi.endpoints.getPrefixes.select();

const selectPrefixesData = createSelector(
  selectPrefixesResult,
  (result) => result.data
);

export const { selectAll: selectAllPrefixes, selectById: selectPrefixById } =
  prefixesAdapter.getSelectors(
    (state) => selectPrefixesData(state) ?? initialState
  );

export const {
  useGetPrefixesQuery,
  useAddPrefixMutation,
  useEditPrefixMutation,
  useDeletePrefixMutation,
} = companyPrefixExtendedApi;
