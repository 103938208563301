import {
  // ModalCompAdvance,
  TableCompData,
} from "../../../../components";
import EmptyTable from "../../../../components/Common/EmptyTable";
import FilterFind from "../../../../components/FilterFind";
import { pendingMemberHeader } from "../../../../utils/TableHeader";
import TablePagination from "../../../../components/Table/Pagination";

export default function PendingMemberComp({
  // title,
  // TableDropDown,
  // modalPage,
  setModalPage,
  setModalToggle,
  // useModal,
  // modalToggle,
  tableData,
  isFetching,
  pagination,
  setPageNumber,
  setMemberId,
}) {
  return (
    <section className="mx-lg-4 min-vh-100">
      <FilterFind title={"Pending members"} />
      <div className="mb-5">
        <TableCompData
          marginBottom={"0"}
          columns={pendingMemberHeader(
            setModalToggle,
            setModalPage,
            setMemberId
          )}
          data={tableData}
          message={
            isFetching ? (
              <p className="py-4">Loading</p>
            ) : (
              <EmptyTable
                Message={"There are currently no pending members"}
                // btnText={"Add Staff"}
              />
            )
          }
        />
      </div>
      {tableData.length > 0 && (
        <TablePagination handlePageChange={setPageNumber} {...pagination} />
      )}
    </section>
  );
}
