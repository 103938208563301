import styled from "styled-components";
import { PageTitle } from "../modules/PageHeaders";
import BalanceCard, { DeliveryCard } from "./modules/BalanceCard";

export const Summary = () => {
  return (
    <>
      <Wrapper className="mx-2 mx-lg-4">
        <PageTitle title={"Summary"} />
        <div className="balance-cards">
          <BalanceCard
            used={400}
            total={1000}
            title={"SMS Balance"}
            btnText={"Top up"}
            progressBgColor={"rgba(168, 212, 225, 0.1)"}
            progressBarColor={"#19BC31"}
            image={"/Icon/sms.svg"}
          />
          <BalanceCard
            used={500}
            total={1000}
            title={"Email Balance"}
            progressBgColor={"#E0A8AE"}
            progressBarColor={"#8B313A"}
            image={"/Icon/emailBal.svg"}
          />
        </div>
        <div className="sent-items">
          <DeliveryCard
            title={"SMS Sent"}
            figure={600}
            image={"/Icon/smsSent.svg"}
          />
          <DeliveryCard
            title={"Email Sent"}
            figure={400}
            image={"/Icon/smsSent.svg"}
          />
          <DeliveryCard
            title={"Communication Groups"}
            figure={25}
            image={"/Icon/smsSent.svg"}
          />
        </div>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.section`
  .balance-cards {
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 1rem;
  }
  .balance-cards > .card {
    max-width: 460px;
  }
  .balance-cards > .card:nth-child(2) {
    max-width: 310px;
  }
  .sent-items > .card {
    min-width: 300px;
  }
  .sent-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  @media (min-width: 768px) {
    .balance-cards {
      display: grid;
      grid-template-columns: auto auto;
      gap: 100px;
    }
    .sent-items > .card {
      max-width: 300px;
    }
  }
`;

export default Summary;
