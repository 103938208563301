import { API } from "../../api";

// const BASE_URL = "/cooperatives/fines";
// const BASE_URL_CAT = "/cooperatives/fine-categories";

const BASE_URL = "/cooperatives/settings/fines";
const BASE_URL_CAT = "/cooperatives/settings/fine-categories";

export const fineSettingsExtendedApi = API.injectEndpoints({
  endpoints: (builder) => ({
    getFines: builder.query({
      query: () => `${BASE_URL}?guard=staff`,
    }),
    getFineCategories: builder.query({
      query: () => `${BASE_URL_CAT}?guard=staff`,
    }),
  }),
});

export const { useGetFinesQuery, useGetFineCategoriesQuery } =
  fineSettingsExtendedApi;
