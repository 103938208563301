import { toast } from "react-hot-toast";
import {
  useAddEventMutation,
  useEditEventMutation,
  useDeleteEventMutation,
} from "../../../store/admin/eventSlice";
import { useNavigate } from "react-router-dom";
const useEventActions = () => {
  const navigate = useNavigate();
  const [addEvent] = useAddEventMutation();
  const [editEvent] = useEditEventMutation();
  const [deleteEvent] = useDeleteEventMutation();
  const notify = (error, data, toastId) => {
    if (error && error.status === 401) {
      toast.error("Login to continue", {
        id: toastId,
      });
      return navigate("/login");
    }
    if (error) {
      return toast.error(error?.data.message || "An error occured", {
        id: toastId,
      });
    }
    if (data.status === false) {
      return toast.error(data.message || "An error occured", { id: toastId });
    }
    return toast.success(data.message || "success", { id: toastId });
  };
  const createEvent = async (reqBody) => {
    const toastId = toast.loading("Submitting");
    const { data, error } = await addEvent(reqBody);
    notify(error, data, toastId);
  };
  const updateEvent = async (reqBody) => {
    const toastId = toast.loading("Updating");
    const { data, error } = await editEvent(reqBody);
    notify(error, data, toastId);
  };
  const removeEvent = async (id) => {
    const toastId = toast.loading("Deleting");
    const { data, error } = await deleteEvent(id);
    notify(error, data, toastId);
  };
  return { createEvent, updateEvent, removeEvent };
};

export default useEventActions;
