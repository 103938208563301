import { useState } from "react";
import { useParams } from "react-router-dom";

import { BsArrowLeft } from "react-icons/bs";
import { ReactComponent as Cancel } from "../../../../assets/svgs/cancel.svg";
import {
  ImageLoaderUpdate,
  ModalCompAdvanceUpdate,
} from "../../../../components";
import { useNavigate } from "react-router-dom";
import CopyComp from "../../../../components/Common/CopyComp";
import bgImg from "../../../../assets/images/bg-img.webp";
import { ReactComponent as Edit } from "../../../../assets/svgs/edit-icon.svg";
import { ReactComponent as Delete } from "../../../../assets/svgs/delete.svg";
import CustomSelect from "../../../../components/custom-select/custom-select";
import { customStyles } from "../../../../components/custom-select/custom-styles";
import { useGetBranchesQuery } from "../../../../store/admin/settings/branchSlice";
import { useAssignBranchMutation } from "../../../../store/admin/staff";
import toast from "react-hot-toast";
export default function SingleStaff({
  // onNext,
  staffDetails,
}) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [getModalName, setModalName] = useState();
  const { isLoading, data } = useGetBranchesQuery();
  const [newBranch, setNewBranch] = useState([]);
  const options =
    data?.map((option) => ({
      value: option.id,
      label: option.name,
    })) || [];

  // useAddBranchMutation
  const [assignBranch, { isLoading: addingBranch }] = useAssignBranchMutation();
  const handleSubmit = async () => {
    const updatedValues = {
      payload: {
        branches: newBranch,
      },
      staffId: id,
    };

    const response = await assignBranch(updatedValues);
    if ("error" in response) {
      const { data } = response?.error;
      toast.error(data.message);
      return;
    }
    const { message } = response?.data;
    toast.success(message);
    setNewBranch([]);
    setModalName();
    // refetch();
    // onBack();
  };
  const handleDelete = async (branchId) => {
    // console.log(id, "testing");
    const filteredData = staffDetails?.branches?.filter(
      (item) => item.id !== branchId
    );
    const formattedBranches = filteredData.map((item) => ({
      branch_id: item.branch_id,
    }));
    const updatedValues = {
      payload: {
        branches: formattedBranches,
      },
      staffId: id,
    };

    const response = await assignBranch(updatedValues);
    if ("error" in response) {
      const { data } = response?.error;
      toast.error(data.message);
      return;
    }
    const { message } = response?.data;
    toast.success(message);
    return;
    // setNewBranch([]);
    // setModalName();
    // refetch();
    // onBack();
  };
  const useModal = [
    {
      name: "Add New Branch",
      component: (
        <div className="w-full h-[350px] p-10">
          <div className="border-b relative text-center font-bold w-full text-2xl text-[#1E1E1E]">
            <Cancel className="absolute" onClick={() => setModalName()} />
            Add New Branch
          </div>
          <div className="py-4 flex flex-col gap-2">
            <p className="text-[#858585] font-bold text-lg">
              You can add multiple branch at a time
            </p>
            <CustomSelect
              options={options}
              styles={customStyles}
              isSearchable={false}
              label={"Branch"}
              isLoading={isLoading}
              onChange={(selectedOptions) =>
                setNewBranch(
                  selectedOptions.map((option) => ({ branch_id: option.value }))
                )
              }
              isMulti
              placeholder="Select contribution branch"
            />

            <div className="w-full flex justify-center mt-10">
              <button
                className="w-[200px] h-[50px] bg-[#8B313A] rounded-[10px] text-white font-semibold disabled:opacity-50"
                onClick={handleSubmit}
                disabled={addingBranch}
              >
                {addingBranch ? "Adding branch.." : "Add branch"}
              </button>
            </div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <section className="w-full flex flex-col gap-4">
      <div className="w-full h-[82px] flex gap-2 items-center pl-10 bg-white rounded-[10px]">
        <BsArrowLeft
          size={30}
          onClick={() => navigate(-1)}
          className="me-3 pointer"
        />
        <h4 className=" text-2xl font-bold text-[#45464E]">Profile details</h4>
      </div>
      <div className="w-full flex flex-col lg:flex-row gap-6">
        <div className="w-full lg:w-[40%] bg-white rounded-3xl px-4">
          <div className="w-full relative border-b pb-4">
            <div className="w-full h-[180px] rounded-b-[50px] relative">
              <ImageLoaderUpdate
                src={bgImg}
                imageWrapperClassName="w-full h-full"
                imgClass="w-full h-full rounded-b-[50px] "
              />
            </div>
            <div className="w-full flex flex-col items-center -mt-[60px]">
              <div className="h-[150px] w-[150px] rounded-full  relative ">
                <ImageLoaderUpdate
                  src={staffDetails?.photo}
                  width="150"
                  height="150"
                  imgClass="rounded-full"
                />
                <div className="absolute -right-4 h-10 w-10 rounded-full bg-white top-[50%] grid place-content-center cursor-pointer">
                  <Edit />
                </div>
              </div>
              <p className="text-[#1E1E1E] font-semibold">{`${staffDetails?.first_name}   ${staffDetails?.last_name}`}</p>
              <p className="text-[#858585] text-sm">
                Member ID: {staffDetails?.id}
              </p>
            </div>
          </div>
          <div className="py-4 flex flex-col gap-4 border-b">
            <p className="text-[#1E1E1E] font-semibold">Contact Information</p>
            <div>
              <p className="text-[#858585] text-sm">Email address</p>

              <div className="flex justify-between w-full">
                <p className="text-[#1E1E1E] font-semibold">
                  {staffDetails?.email}
                </p>
                <CopyComp text={staffDetails?.email} iconClassName="ms-2" />
              </div>
            </div>
            <div>
              <p className="text-[#858585] text-sm">Phone number</p>
              <div className="flex justify-between w-full">
                <p className="text-[#1E1E1E] font-semibold">
                  {staffDetails?.phone}
                </p>
                <CopyComp text={staffDetails?.phone} iconClassName="ms-2" />
              </div>
            </div>
          </div>
          <div className="w-full py-4 flex flex-col gap-4">
            <h4 className=" text-base font-semibold text-[#1E1E1E]">
              Address Information
            </h4>
            <div className="">
              <p className="text-[#1E1E1E] font-semibold">Full address</p>
              <p className="text-[#858585] text-sm">{staffDetails?.address}</p>
            </div>
            <div className="flex justify-between flex-wrap">
              <div className="">
                <p className="text-[#1E1E1E] font-semibold">Country</p>
                <p className="text-[#858585] text-sm">
                  {staffDetails?.country?.name}
                </p>
              </div>
              <div className="">
                <p className="text-[#1E1E1E] font-semibold">State</p>
                <p className="text-[#858585] text-sm">
                  {staffDetails?.city?.name}
                </p>
              </div>
              <div className="">
                <p className="text-[#1E1E1E] font-semibold">Landmark</p>
                <p className="text-[#858585] text-sm">
                  {staffDetails?.city?.landmark || "Nil"}
                </p>
              </div>
            </div>
          </div>
          <div className="py-4 flex w-full">
            <button className="w-[103px] h-9 rounded text-white bg-[#8B313A]">
              Edit Profile
            </button>
          </div>
        </div>

        <div className="w-full bg-white rounded-[20px] p-4 lg:p-10">
          <div className="flex justify-between border-b pb-4">
            <p className="text-text-primary font-bold text-2xl">Branches</p>
            <button
              className="w-[180px] h-9 rounded text-white bg-[#8B313A]"
              onClick={() => setModalName("Add New Branch")}
            >
              Add New Branch
            </button>
          </div>
          <div className="py-4 grid grid-cols-1 lg:grid-cols-2 gap-x-10 gap-y-5">
            {staffDetails?.branches.map((item, i) => (
              <div
                className="w-full rounded-[20px] bg-white"
                style={{
                  boxShadow:
                    "2px 2px 10px 0px rgba(0, 0, 0, 0.05), -2px -2px 10px 0px rgba(0, 0, 0, 0.05)",
                }}
                key={i}
              >
                <div className="w-full flex justify-center items-center bg-[#FAFAFA] py-4">
                  <p className="text-sm text-text-primary font-bold">
                    {item?.branch?.name}
                  </p>
                </div>
                <div className="w-full p-4 flex flex-col gap-4">
                  <div className="w-full relative flex justify-center items-center">
                    <div className="py-2  px-3 rounded-[30px] bg-[rgba(49,119,140,0.10)]">
                      <p className="text-[#31778C] text-sm font-semibold">
                        {item?.branch?.name}
                      </p>
                    </div>
                    <div className="absolute right-0 h-6 w-6 rounded-full bg-[#FAFAFA] grid place-content-center">
                      <Delete onClick={() => handleDelete(item.id)} />
                    </div>
                  </div>

                  <div className="w-full text-center">
                    <p className="text-[#383838] font-semibold text-sm">
                      Address
                    </p>
                    <p className="text-text-secondary text-xs">
                      {item?.branch?.address}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <ModalCompAdvanceUpdate
        centered
        arrayComp={useModal}
        pageName={getModalName}
        handleClose={() => setModalName()}
      />
    </section>
  );
}
