import { RiSearch2Line } from "react-icons/ri";
import { CardComp, ButtonComp, TableCompData } from "../../../components";
import EmptyTable from "../../../components/Common/EmptyTable";
import TablePagination from "../../../components/Table/Pagination";

const StaffMemberTable = ({
  tableData = [],
  tableColumns,
  title,
  tableFor = "member",
  isFetchingData = false,
  addNew,
  pagination,
  onSearch = () => {},
  onPageChange = () => {},
}) => {
  return (
    <section className="mx-2 mx-lg-4 min-vh-100 pb-5 mb-5 rounded">
      <CardComp cardClassName={"border-0 px-lg-4 mb-3"}>
        <div className="row justify-content-between align-items-center flex-warp">
          <div className="col-lg-6">
            <h3 className=" text-black1 fw-bold">{title || "Staff list"}</h3>
          </div>
          <div className="col-lg-6">
            <div className="input-group  search">
              <span className="input-group-text bg-black2 px-4">
                <RiSearch2Line size={20} color={"#fff"} />
              </span>
              <input
                type="text"
                className="form-control "
                placeholder="Search your query here"
                style={{ padding: "15px 0" }}
                onKeyUp={(event) => onSearch(event.target.value)}
              />
            </div>
          </div>
        </div>
      </CardComp>
      <div className="py-4"></div>
      <main>
        <div className="">
          <TableCompData
            marginBottom={"120px"}
            columns={tableColumns}
            data={tableData}
            message={
              isFetchingData ? (
                <div className="py-4">
                  <p>Loading...</p>
                </div>
              ) : (
                <EmptyTable
                  Message={`You have no active ${tableFor} list, please create`}
                  btnText={`Add new ${tableFor}`}
                  onClick={addNew}
                />
              )
            }
          />
        </div>
      </main>
      <div className="py-2"></div>
      <div>
        <p className="text-black1 mb-3 fw-semibold">Need staff records?</p>
        <div className="flex w-2/4 gap-4">
          <ButtonComp
            btnText={"Download CSV"}
            btnClassName={
              "py-3 p fw-semibold bg-primary1 border-0 text-white rounded px-3 px-lg-5 me-4 h-10"
            }
          />
          <ButtonComp
            btnText={"Download PDF"}
            btnClassName={
              "py-3 p fw-semibold bg-primary1 border-0 text-white rounded px-3 px-lg-5 h-10"
            }
          />
        </div>
      </div>
      {tableData.length > 0 && (
        <TablePagination {...pagination} handlePageChange={onPageChange} />
      )}
    </section>
  );
};

export default StaffMemberTable;
