import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useMarkAttendanceMutation } from "../../../store/admin/attendanceSlice";

const useAdminAttendanceActions = () => {
  const navigate = useNavigate();
  const [markAttendance, { isLoading: ismarkingAttendance }] =
    useMarkAttendanceMutation();

  const toastNotify = (error, data, toastId) => {
    if (error && error.status === 401) {
      toast.error("Login to continue", {
        id: toastId,
      });
      return navigate("/login");
    }
    if (error) {
      return toast.error(error?.data.message || "An error occured", {
        id: toastId,
      });
    }
    if (data.status === false) {
      return toast.error(data.message || "An error occured", { id: toastId });
    }
    return toast.success(data.message || "success", { id: toastId });
  };

  const newAttendance = async (reqBody) => {
    const toastId = toast.loading("Processing");
    const { data, error } = await markAttendance(reqBody);
    toastNotify(error, data, toastId);
  };
  return { newAttendance, ismarkingAttendance };
};

export default useAdminAttendanceActions;
