import { TableCompData } from "../../../../components";
import { EminutesHeader } from "../../../../utils/TableHeader";
import EmptyTable from "../../../../components/Common/EmptyTable";
import useEminuteAction from "../useAdminEminuteActions";

const EminutesList = ({ tableData, isLoading }) => {
  const { changeStatus, viewEminute, removeEminute } = useEminuteAction();
  const publishedDropDown = [
    {
      name: "View",
      action: (data) => {
        viewEminute(data.id);
      },
    },
    {
      name: "Send as email",
      action: (data) => {},
      disabled: true,
    },
  ];
  const TableDropDown = [
    {
      name: "View",
      action: (data) => {
        viewEminute(data.id);
      },
    },
    {
      name: "Publish now",
      action: (data) => {
        changeStatus(data.id);
      },
    },
    {
      name: "Send as email",
      action: (data) => {},
      disabled: true,
    },
    {
      name: "Delete",
      action: (data) => {
        removeEminute(data.id);
      },
    },
  ];
  const prepareDropdown = (row) => {
    if (row.status === "PUBLISHED") {
      return publishedDropDown;
    }
    return TableDropDown;
  };
  return (
    <div>
      <h4 className=" text-black1 fw-bold">E-Minutes List</h4>
      <TableCompData
        data={tableData}
        marginBottom={"120px"}
        columns={EminutesHeader(prepareDropdown)}
        message={
          isLoading ? (
            <div className="py-4">loading...</div>
          ) : (
            <div className="pt-4">
              <EmptyTable
                Message={
                  "E-minute lists is empty, your created minutes will appear here"
                }
              />
            </div>
          )
        }
      />
    </div>
  );
};

export default EminutesList;
