import { API, ApiPrefixes } from "../api";

export const adminLoanRepaymentApi = API.injectEndpoints({
  endpoints: (builder) => ({
    getUpcomingRepayment: builder.query({
      query: (page) => ({
        url:
          ApiPrefixes["loanManagement"] +
          `/repayments/upcoming?guard=staff&page=${page}&limit=20`,
      }),
      providesTags: (result, error, arg) => [
        { type: "upcoming-loan-repayment", id: arg },
      ],
      cacheStrategy: "cache-and-network",
    }),
    getDueRepayment: builder.query({
      query: (page) => ({
        url:
          ApiPrefixes["loanManagement"] +
          `/repayments/due?guard=staff&page=${page}&limit=20`,
      }),
      providesTags: (result, error, arg) => [
        { type: "due-loan-repayment", id: arg },
      ],
      cacheStrategy: "cache-and-network",
    }),
    getOverdueRepayment: builder.query({
      query: (page) => ({
        url:
          ApiPrefixes["loanManagement"] +
          `/repayments/overdue?guard=staff&page=${page}&limit=20`,
      }),
      providesTags: (result, error, arg) => [
        { type: "overdue-loan-repayment", id: arg },
      ],
      cacheStrategy: "cache-and-network",
    }),

    recordLoanRepayment: builder.mutation({
      query: (payload) => ({
        url: ApiPrefixes["loanManagement"] + `/requests/payment?guard=staff`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: [
        { type: "upcoming-loan-repayment" },
        { type: "due-loan-repayment" },
        { type: "overdue-loan-repayment" },
        { type: "loan-request-breakdown-by-id" },
      ],
    }),
    searchLoanByMember: builder.query({
      query: (search) => ({
        url:
          ApiPrefixes["loanManagement"] +
          `/requests/member?guard=staff&search=${search}`,
      }),

      transformResponse: (responseData) => {
        return responseData.data;
      },
    }),

    getLoanBreakdownByLoanRequestId: builder.query({
      query: (requestId) => ({
        url:
          ApiPrefixes["loanManagement"] +
          `/requests/${requestId}/view?guard=staff`,
      }),

      transformResponse: (responseData) => {
        return responseData.data;
      },
      providesTags: (result, error, arg) => [
        { type: "loan-request-breakdown-by-id", id: arg },
      ],
    }),
  }),
});

export const {
  useGetDueRepaymentQuery,
  useGetOverdueRepaymentQuery,
  useGetUpcomingRepaymentQuery,
  useRecordLoanRepaymentMutation,
  useSearchLoanByMemberQuery,
  useLazySearchLoanByMemberQuery,
  useGetLoanBreakdownByLoanRequestIdQuery,
} = adminLoanRepaymentApi;
