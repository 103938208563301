import { toast } from "react-hot-toast";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { BiCopy } from "react-icons/bi";

export const CopyComp = ({ text, iconClassName = "" }) => {
  return (
    <CopyToClipboard
      text={text}
      onCopy={() => {
        toast.success(`${text} copied to clipboard`);
      }}
    >
      <BiCopy size={15} className={iconClassName} role="button" />
    </CopyToClipboard>
  );
};

export default CopyComp;
