import { ButtonComp, CardComp } from "../../../components";
import FineSettingComp from "./modules/FineSettingComp";
import { useNavigate } from "react-router-dom";

export default function FineSettings() {
  const navigate = useNavigate();

  const TableDropDown = [
    {
      name: "Edit",
      action: (data) => {},
      disabled: true,
    },
    {
      name: "Delete",
      action: (data) => {},
      disabled: true,
    },
  ];

  return (
    <section className="px-2 px-lg-4 min-vh-100 rounded-4">
      <CardComp cardBodyClassName={"py-0"} cardClassName={"pt-4"}>
        <h4 className=" text-black1 fw-bold">Fines List</h4>
      </CardComp>
      <div className="d-flex justify-content-end mb-3">
        <ButtonComp
          btnText={"Create new"}
          btnClassName="bg-primary1 text-white px-3 px-lg-5 fw-2 py-1 py-lg-3 rounded"
          onClick={() => navigate("new")}
        />
      </div>
      <FineSettingComp
        TableDropDown={TableDropDown}
        onSubmit={() => navigate("new")}
        emptyTable="No registration fee found"
      />
    </section>
  );
}
