import CustomSelect from "../../../../components/custom-select/custom-select";
import { usePaymentMethodsQuery } from "../../../../store/generalSelections/generalSelection";
import { Input } from "../../../../components/Ui/new-input";
import FallbackImage from "../../../../components/Common/ImageWrapper";
import CurrencyInput from "react-currency-input-field";

const RecordContributionForm = ({
  index,
  formik,
  fieldNames,
  handleRemove,
  formattedContributionCategories,
}) => {
  const { data: paymentMethod } = usePaymentMethodsQuery();
  const formattedPaymentMethod = paymentMethod?.data?.map((item) => {
    return {
      label: item.name,
      value: item.id,
    };
  });

  const handleInputChange = (fieldName, value) => {
    formik.setFieldValue(`formParts[${index}].${fieldName}`, value);
  };

  return (
    <section className={`flex gap-2 h-full pb-4 border-b `}>
      <div className=" h-full  flex flex-col gap-3 justify-center items-center ">
        <div className="h-10 w-10 rounded-full border bg-[#FAFAFA] flex justify-center items-center">
          {index + 1}
        </div>
        <div className=" h-[320px] lg:h-[140px] border-l-2 border-dashed" />
      </div>

      <section className="w-full grid grid-cols-1 lg:grid-cols-2 gap-x-10 gap-y-5 relative">
        <div>
          <CustomSelect
            label="Contribution name"
            options={formattedContributionCategories}
            value={formik.values[fieldNames.contribution_category_id]}
            onBlur={formik.handleBlur}
            onChange={(value) =>
              handleInputChange(
                fieldNames.contribution_category_id,
                value.value
              )
            }
          />

          {formik.errors.formParts &&
            formik.errors.formParts[index]?.contribution_category_id && (
              <p className=" text-red-500">
                {formik.errors.formParts[index].contribution_category_id}
              </p>
            )}
        </div>

        <div className="mt-2">
          <p className="font-[500] mb-1">Amount</p>
          <CurrencyInput
            id="input-example"
            name="input-name"
            placeholder="Please enter an amount"
            defaultValue={1000}
            decimalsLimit={2}
            prefix="NGN"
            // label="Amount"
            className="h-10 bg-white cursor-text w-full border pl-4 text-[rgba(33,35,55,0.75)] leading-[24px] text-[1rem] outline-none rounded-[5px] placeholder:font-circular_medium placeholder:text-[14px] placeholder:text-[#ACA2C3] focus:border-primary focus:ring-1 focus:ring-primary  disabled:opacity-50 mb-2"
            onValueChange={
              (value, name, values) => handleInputChange("amount", value)
              // console.log(value)
            }
          />

          {formik.errors.formParts &&
            formik.errors.formParts[index]?.amount && (
              <p className=" text-red-500">
                {formik.errors.formParts[index].amount}
              </p>
            )}
        </div>

        <div>
          <CustomSelect
            label="Payment Method"
            options={formattedPaymentMethod}
            value={formik.values[fieldNames.payment_type_id]}
            onChange={(value) =>
              handleInputChange(fieldNames.payment_type_id, value.value)
            }
          />
          {formik.errors.formParts &&
            formik.errors.formParts[index]?.payment_type_id && (
              <p className=" text-red-500">
                {formik.errors.formParts[index].payment_type_id}
              </p>
            )}
        </div>

        <div>
          <Input
            type="date"
            label="Payment date"
            value={formik.values[fieldNames.payment_date]}
            onBlur={formik.handleBlur}
            // touched={formik.handleTouched}
            onChange={(e) =>
              handleInputChange(fieldNames.payment_date, e.target.value)
            }
          />
          {formik.errors.formParts &&
            formik.errors.formParts[index]?.payment_date && (
              <p className=" text-red-500">
                {formik.errors.formParts[index].payment_date}
              </p>
            )}
        </div>
        <div>
          <Input
            type="text"
            label="Transaction Code"
            value={formik.values[fieldNames.transaction_code]}
            onChange={(e) =>
              handleInputChange("transaction_code", e.target.value)
            }
            onBlur={formik.handleBlur}
          />
          {formik.errors.formParts &&
            formik.errors.formParts[index]?.transaction_code && (
              <p className=" text-red-500">
                {formik.errors.formParts[index].transaction_code}
              </p>
            )}
        </div>

        {index !== 0 && (
          <button
            onClick={() => handleRemove(index)}
            // disabled={formik.isDirty}
            className="h-10 w-10 border absolute bottom-3 rounded-full -right-11 bg-[#8B313A]"
          >
            <FallbackImage src="/Icon/delete1.png" width={44} />
          </button>
        )}
      </section>
    </section>
  );
};

export default RecordContributionForm;
