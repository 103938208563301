import { ButtonComp, CardComp } from "../../../../components";

const BalanceCard = ({
  used,
  total,
  btnText,
  title,
  onClickAction = () => {},
  progressBgColor,
  progressBarColor,
  image,
}) => {
  const percentage = `${(used / total) * 100}%`;
  return (
    <CardComp
      cardClassName={"pt-4"}
      cardBodyClassName={
        "py-0 d-flex justify-content-between align-items-center w-100 px-1"
      }
    >
      <div className="d-flex align-items-center gap-2">
        <div className="">{image && <img src={image} alt={title} />}</div>
        <article>
          <p className="mb-0 h5">
            <span className="fw-bold">{used}/</span>
            <span className="text-blacklight">{total}</span>
          </p>
          <p className="mb-0 opacity-50">
            <small>{title}</small>
          </p>
          <div
            className="progress"
            style={{
              height: "10px",
              width: "190px",
              backgroundColor: progressBgColor,
            }}
          >
            <div
              className="progress-bar "
              role="progressbar"
              aria-label="Success example"
              style={{
                width: percentage ? percentage : "25%",
                background: progressBarColor,
              }}
              aria-valuenow="75"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </article>
      </div>
      {btnText && (
        <ButtonComp
          btnClassName={"px-4 py-2 text-white bg-primary1 rounded"}
          btnText={btnText}
          onClick={onClickAction}
        />
      )}
    </CardComp>
  );
};

export const DeliveryCard = ({ title, image, figure }) => {
  return (
    <CardComp
      cardClassName={"pt-4"}
      cardBodyClassName={"py-0 d-flex align-items-center gap-2"}
    >
      <div className="">{image && <img src={image} alt={title} />}</div>
      <article>
        <p className="mb-0 opacity-50">
          <small>{title}</small>
        </p>
        <p className="mb-0 h5">{figure}</p>
      </article>
    </CardComp>
  );
};

export default BalanceCard;
