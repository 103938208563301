import React from "react";
import { formatMoney } from "../../../../utils";

const ViewContribution = ({ data, handleClose }) => {
  console.log(data, "God Abeg");
  return (
    <div className="w-full py-10 px-5">
      <div className="border-b pb-2 w-full gap-10 flex items-center">
        <img
          src="/Icon/cancel.svg"
          role="button"
          onClick={handleClose}
          alt="close"
          className="close-img"
        />
        <p className="text-2xl font-bold">Secretariat Construction</p>
      </div>

      <div className="mt-5 w-full flex flex-col gap-4">
        <div className="w-full pb-2 border-b flex justify-between items-center">
          <p className="text-sm text-[#858585]">Threashold</p>
          <p className="text-sm font-bold text-[#1E1E1E]">
            {formatMoney(data?.target_amount)}
          </p>
        </div>
        <div className="w-full pb-2 border-b flex justify-between items-center">
          <p className="text-sm text-[#858585]">Amount remaining</p>
          <p className="text-sm font-bold text-[#1E1E1E]">
            {formatMoney(data?.amount_remaining)}
          </p>
        </div>
        <div className="w-full pb-2 border-b flex justify-between items-start">
          <p className="text-sm text-[#858585]">Branch</p>
          <div className="flex flex-col gap-2 max-w-[70%] ">
            <p className="text-sm font-bold text-[#1E1E1E]">
              {data?.branch?.name}
            </p>
            <p className="text-sm font-bold text-[#1E1E1E]">
              {data?.branch?.address}
            </p>
            <p className="text-sm font-bold text-[#1E1E1E]">
              {data?.branch?.phone}
            </p>
          </div>
        </div>
        <div className="w-full pb-2 border-b flex justify-between items-center">
          <p className="text-sm text-[#858585]">Status</p>
          <p className="text-sm font-bold text-[#1E1E1E]">
            {data?.status === 0 ? "NON-WITHDRAWABLE" : "WITHDRAWABLE"}
          </p>
        </div>
        <div className="w-full pb-2 border-b flex justify-between items-center">
          <p className="text-sm text-[#858585]">Payment bank</p>
          <p className="text-sm font-bold text-[#1E1E1E] capitalize">
            {data?.bank?.bank_name}
          </p>
        </div>
        <div className="w-full pb-2 border-b flex justify-between items-center">
          <p className="text-sm text-[#858585]">End date</p>
          <p className="text-sm font-bold text-[#1E1E1E]">{data?.end_date}</p>
        </div>
      </div>
    </div>
  );
};

export default ViewContribution;
