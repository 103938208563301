import { useFormikContext } from "formik";
import { ButtonComp } from "../../../../components";
import { toast } from "react-hot-toast";

export const personal_details = {
  first_name: "",
  last_name: "",
  occupation: "",
  dob: "",
  marital_id: "",
  next_of_kin_name: "",
  next_of_kin_phone: "",
  relationship_status_id: "",
  gender_id: "",
  title_id: "",
  email: "",
  address: "",
  phone: "",
};

export const FormikCheckBtn = ({
  formikFields,
  isDisabled = false,
  onBtnClick,
}) => {
  const { errors, values } = useFormikContext();
  return (
    <ButtonComp
      disabled={isDisabled}
      btnText={"Proceed"}
      onClick={() => {
        for (let index = 0; index < formikFields.length; index++) {
          const key = formikFields[index];
          if (errors[key] || !values[key]) {
            return toast.error(
              errors[key] || "All required fields not filled",
              {
                duration: 3000,
              }
            );
          }
        }
        onBtnClick();
      }}
      btnClassName={"w-100 bg-primary1 py-2 text-white border-0 rounded"}
      type={"button"}
    />
  );
};

export default FormikCheckBtn;
