import styled from "styled-components";

const CustomRadioComp = ({
  labelText,
  name,
  value,
  selectedColor = "#b13e4a",
  onValueChange = () => {},
}) => {
  return (
    <Wrapper selectedColor={selectedColor}>
      <label>
        <input
          type="radio"
          name={name}
          value={value}
          onChange={() => onValueChange()}
        />
        <div className="circle">
          <div className="inner-circle"></div>
        </div>
        <span>{labelText}</span>
      </label>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  label {
    display: flex;
    gap: 16px;
    align-items: center;
    cursor: pointer;
  }
  .circle {
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    border: 1px solid #979797;
  }
  input {
    display: none;
  }
  input:checked + .circle {
    border-color: ${(props) => props?.selectedColor};
    .inner-circle {
      width: 18px;
      height: 18px;
      border-radius: 100%;
      background-color: ${(props) => props?.selectedColor};
    }
  }
`;

export default CustomRadioComp;
