import React, { useState, useEffect } from "react";
import { BiChevronRight } from "react-icons/bi";
import {
  ButtonComp,
  CardComp,
  ModalCompAdvanceUpdate,
  MoneyInputComp,
  SelectComp2,
  TextInputComp,
} from "../../../components";
import { Formik, Form } from "formik";
import FilterFind from "../../../components/FilterFind";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { useEnums } from "../../../hooks/useEnums";
import NonmemberGuarantorForm from "../../Admin/LoanManagement/modules/NonmemberGuarantorForm";
import { useNavigate } from "react-router-dom";
import {
  useCreateMemberLoanRequestMutation,
  useGetMemberLoanTypesQuery,
} from "../../../store/member/member-loan";
import { useGetAllMembersAsMemberUserQuery } from "../../../store/selectableSlice";
import { useUser } from "../../../hooks";

export default function LoanApplication() {
  const enums = useEnums();
  const user = useUser();
  const navigate = useNavigate();
  const [loanTypeDetails, setLoanTYpeDetails] = useState({});
  const [guarantors, setGuarantors] = useState([]);
  const [nonMembersGuarantorDetails, setNonMemberGuarantorDetails] = useState(
    []
  );
  const [modalPage, setModalPage] = React.useState("");

  const { data: loanTypes, isLoading: isLoadingLoanTypes } =
    useGetMemberLoanTypesQuery();

  const formattedLoanTypes = loanTypes?.data?.configs?.map((item) => {
    return {
      label: item.name,
      value: item.id,
      max_guarantor: item.max_guarantor,
      min_guarantor: item.min_guarantor,
    };
  });

  useEffect(() => {
    if (loanTypeDetails?.max_guarantor) {
      const initialGuarantors = Array.from(
        { length: loanTypeDetails?.max_guarantor },
        (_, index) => ({
          label: `Guarantor ${index + 1}`,
          value: "",
        })
      );
      setGuarantors(initialGuarantors);
    }
  }, [loanTypeDetails?.max_guarantor]);

  const handleGuarantorChange = (index, value, setFieldValue) => {
    const updatedGuarantors = [...guarantors];
    updatedGuarantors[index].value = value;
    setGuarantors(updatedGuarantors);
    setFieldValue(`guarantor${index + 1}`, value);
  };

  const { data: membersList } = useGetAllMembersAsMemberUserQuery();

  const { allMembers } = React.useMemo(() => {
    const fetchedMembers = membersList || [];
    const formattedMembers = fetchedMembers.map((item, index) => {
      return {
        label: item.first_name + " " + item.last_name,
        value: item.member_id,
      };
    });
    return { allMembers: formattedMembers };
  }, [membersList]);

  const initialValues = {
    loan_type_id: "",
    repayment_plan: "",
    duration: "",
    duration_type: "",
    amount: "",
    ...guarantors?.reduce((acc, guarantor, index) => {
      return { ...acc, [`guarantor${index + 1}`]: guarantor.value };
    }, {}),
  };
  const validationSchema = Yup.object({
    loan_type_id: Yup.string().required("Required"),
    repayment_plan: Yup.string().required("Required"),
    duration: Yup.number().required("Required"),
    duration_type: Yup.string().required("Required"),
    amount: Yup.string().required("Required"),
  });

  const getMemberGuarantors = (values) => {
    return Object.keys(values)
      .filter((key) => key.startsWith("guarantor") && values[key])
      .map((key) => values[key]);
  };

  const filterNonMemberGuarantors = (guarantors) => {
    return guarantors.filter(
      (item) =>
        typeof item !== "string" || !item.includes("non_member_guarantor")
    );
  };

  const [createLoanRequest, { isLoading: isCreating }] =
    useCreateMemberLoanRequestMutation();

  const handleFormSubmit = async (values, { resetForm }) => {
    const { loan_type_id, repayment_plan, duration, duration_type, amount } =
      values;

    const member_guarantors = getMemberGuarantors(values);
    const formattedMemberGuarantors =
      filterNonMemberGuarantors(member_guarantors);

    const payload = {
      member_id: user?.member_id,
      loan_type_id,
      repayment_plan,
      duration,
      duration_type,
      amount,
      member_guarantors: formattedMemberGuarantors,
      non_member_guarantors: nonMembersGuarantorDetails,
    };

    const response = await createLoanRequest(payload);
    const { data, error } = response;
    if (error) {
      return toast.error(error?.data?.message || "An error occured");
    } else if (data?.status === false) {
      return toast.error(data?.message || "An error occured");
    } else {
      toast.success(data?.message || "Success");
      setNonMemberGuarantorDetails([]);
      setLoanTYpeDetails({});
      setGuarantors([]);
      resetForm();
    }
  };
  const useModal = [
    {
      name: "NonMemberGuarantor",
      component: (
        <NonmemberGuarantorForm
          Back={() => setModalPage()}
          setNonMemberGuarantorDetails={setNonMemberGuarantorDetails}
        />
      ),
    },
  ];

  return (
    <>
      <ModalCompAdvanceUpdate
        pageName={modalPage}
        arrayComp={useModal}
        handleClose={() => setModalPage()}
        centered
        size={"lg"}
      />
      <FilterFind
        back={true}
        backBtn={() => navigate(-1)}
        rightSide={false}
        title="Loan Application"
      />
      <div className="my-4 w-full bg-[#383838] flex items-center gap-4 rounded-[0.625rem]">
        <img
          src="/MemberSide/Icons/loan-svgrepo-com.png"
          alt="img"
          className="pl-5"
        />
        <h4 className=" flex-1 font-bold text-[2rem]  text-white ">
          Get suitable and affordable loan within <br /> 5 minutes
        </h4>
      </div>
      <CardComp cardClassName={"border-0 pt-4 pb-5"}>
        <h4 className="fw-bold ">New Application</h4>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {({ values, errors, touched, setFieldValue }) => (
            <Form>
              <CardComp cardClassName={"pb-5 pt-4"}>
                <div>
                  <div>
                    <div className="row gx-0 gy-3 justify-content-between">
                      <div className="col-12 col-lg-5">
                        <SelectComp2
                          LabelClassName={"fw-2"}
                          placeholder={"Select loan type"}
                          labelText={"Loan type"}
                          arrayComp={formattedLoanTypes}
                          isLoading={isLoadingLoanTypes}
                          selectedOption={values.loan_type_id}
                          setSelectedOption={(selectedOption) => {
                            setLoanTYpeDetails(selectedOption);
                            setFieldValue("loan_type_id", selectedOption.value);
                          }}
                          errors={
                            touched.loan_type_id && errors.loan_type_id
                              ? [errors.loan_type_id]
                              : []
                          }
                        />
                      </div>
                      <div className="col-12 col-lg-5">
                        <TextInputComp
                          LabelText={"Duration"}
                          borderColor="#A1A0A0"
                          LabelClassName={"fw-2"}
                          placeholder={"0"}
                          name="duration"
                          type="text"
                        />
                      </div>
                      <div className="col-12 col-lg-5">
                        <SelectComp2
                          LabelClassName={"fw-2"}
                          labelText={"Duration type"}
                          arrayComp={enums["DurationType"]}
                          selectedOption={values.duration_type}
                          setSelectedOption={(selectedOption) =>
                            setFieldValue("duration_type", selectedOption.value)
                          }
                          errors={
                            touched.duration_type && errors.duration_type
                              ? [errors.duration_type]
                              : []
                          }
                        />
                      </div>
                      <div className="col-12 col-lg-5">
                        <MoneyInputComp
                          LabelText={"Amount"}
                          borderColor="#A1A0A0"
                          LabelClassName={"fw-2"}
                          placeholder={"Enter amount"}
                          descClassName="text-danger"
                          name={"amount"}
                        />
                      </div>
                      {loanTypeDetails?.max_guarantor &&
                        guarantors?.map((guarantor, index) => (
                          <div className="col-12 col-lg-5" key={index}>
                            <SelectComp2
                              LabelClassName={"fw-2"}
                              labelText={guarantor.label}
                              placeholder={`Select guarantor ${index + 1}`}
                              arrayComp={[
                                ...allMembers,
                                {
                                  value: `non_member_guarantor_${index + 1}`,
                                  label: (
                                    <ButtonComp
                                      btnClassName={
                                        "w-100 bg-primary1 p-2  text-white border-0 rounded"
                                      }
                                      btnText={
                                        <div
                                          className="d-flex justify-content-between align-items-center"
                                          onClick={() => {
                                            // scroll(0, 0);
                                            setModalPage("NonMemberGuarantor");
                                          }}
                                        >
                                          Non - member
                                          <BiChevronRight size={20} />
                                        </div>
                                      }
                                    />
                                  ),
                                },
                              ]}
                              selectedOption={values[`guarantor${index + 1}`]}
                              setSelectedOption={(selectedOption) => {
                                handleGuarantorChange(
                                  index,
                                  selectedOption.value,
                                  setFieldValue
                                );
                              }}
                              errors={
                                touched[`guarantor${index + 1}`] &&
                                errors[`guarantor${index + 1}`]
                                  ? [errors[`guarantor${index + 1}`]]
                                  : []
                              }
                            />
                          </div>
                        ))}
                      <div className="col-12 col-lg-5">
                        <SelectComp2
                          LabelClassName={"fw-2"}
                          placeholder={"Select repayment plan"}
                          labelText={"Repayment plan"}
                          arrayComp={enums["RepaymentPlan"]}
                          selectedOption={values.repayment_plan}
                          setSelectedOption={(selectedOption) =>
                            setFieldValue(
                              "repayment_plan",
                              selectedOption.value
                            )
                          }
                          errors={
                            touched.repayment_plan && errors.repayment_plan
                              ? [errors.repayment_plan]
                              : []
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </CardComp>
              <div className="col-12 py-3"></div>
              <div className="d-flex justify-content-start">
                <div className="col-lg-4 col-xl-2">
                  <ButtonComp
                    type="submit"
                    btnText={"Submit application"}
                    onClick={() => {}}
                    btnClassName={
                      "w-100 bg-primary1 px-2 py-3 text-white border-0 rounded"
                    }
                    loading={isCreating}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
        {/* <Formik>
          <Form>
            <div className="row mb-5 gy-4 justify-content-between">
              <div className="col-lg-5">
                <div className="mb-4">
                  <TextInputComp labelText={"Amount"} />
                </div>
                <div className="mb-3">
                  <SelectComp2
                    labelText={"Repayment plan"}
                    placeholder="Select repayment plan"
                  />
                </div>
              </div>
              <div className="col-lg-5">
                <div className="mb-4">
                  <SelectComp2
                    labelText={"Loan purpose"}
                    placeholder="Consumption"
                  />
                </div>
                <div className="mb-4">
                  <SelectComp2
                    labelText={"Duration type"}
                    placeholder="Select repayment plan"
                  />
                </div>
                <div className="mb-3">
                  <SelectComp2
                    isMulti
                    labelText={" Guarantor"}
                    placeholder="Select guarantor"
                    arrayComp={[
                      { label: "First option", value: "First option" },
                      { label: "Second option", value: "Second option" },
                      { label: "Third option", value: "Third option" },
                      { label: "Another option", value: "Another option" },
                    ]}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center w-100">
              <div className="col-8 col-md-6 col-lg-4">
                <ButtonComp
                  btnText={"Submit"}
                  btnClassName={"bg-primary1 w-100  text-white py-2  rounded"}
                />
              </div>
            </div>
          </Form>
        </Formik> */}
      </CardComp>
    </>
  );
}
