import AdminLayout from "../../../Layout/AdminLayout";
import { PageTitle } from "../modules/PageHeaders";
import { ButtonComp, CardComp } from "../../../components";
import EmptyTable from "../../../components/Common/EmptyTable";
import EminutesList from "./modules/EminutesList";
import { useGetEminutesQuery } from "../../../store/admin/eminutesSlice";
import { useNavigate } from "react-router-dom";
import { useMemo, useState } from "react";
import { dateFromString } from "../../../utils";
import TablePagination from "../../../components/Table/Pagination";

export const EminuteHome = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const navigate = useNavigate();
  const { data = {}, error, isFetching } = useGetEminutesQuery(pageNumber);
  const { pagination, eminutes } = useMemo(() => {
    const { eminutes = [], pagination = {} } = data;
    const perPage = pagination?.perPage || 5;
    const items = eminutes.map((item, index) => {
      return {
        ...item,
        serialNumber: index + 1 + (pageNumber - 1) * perPage,
        date: dateFromString(item.created_at),
      };
    });
    return { eminutes: items, pagination };
  }, [data, pageNumber]);
  if (error) {
    console.log(error);
  }
  return (
    <section className="mx-2 mx-lg-4 min-vh-100">
      <div className="py-1"></div>
      <PageTitle title={"E-Minutes"} />
      <div className="row justify-content-between">
        <div className="col-12 col-lg-6">
          <CardComp cardClassName={"pt-4"} cardBodyClassName={"py-0"}>
            <h4 className=" text-black1 fw-bold">Create new E-minutes</h4>
            <p className="opacity-50">
              Record cooperative latest meetings, happenings and publish to
              cooperative members to read
            </p>
            <ButtonComp
              btnText={"Create message"}
              btnClassName={"bg-primary1 py-3 px-4 text-white border-0 rounded"}
              onClick={() => navigate("new")}
            />
          </CardComp>
        </div>
        <div className="col-12 col-lg-5">
          <h4 className=" text-black1 fw-bold">Recent E-Minutes</h4>
          <CardComp cardClassName={"pt-4"} cardBodyClassName={"py-0"}>
            <EmptyTable Message={"No record found"} />
          </CardComp>
        </div>
      </div>
      <div>
        <EminutesList isLoading={isFetching} tableData={eminutes} />
        {eminutes.length > 0 && (
          <div className="bg-white rounded py-3">
            <TablePagination
              marginTop={"0"}
              {...pagination}
              handlePageChange={setPageNumber}
            />
          </div>
        )}
      </div>
    </section>
  );
};
