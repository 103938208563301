import {
  ButtonComp,
  TextInputComp,
  MoneyInputComp,
  FormikSelectComp,
  CardComp,
} from "../../../../components";
import { Formik, Form } from "formik";
import { useCreateRegFeeMutation } from "../../../../store/admin/settings/regFeeSlice";
import { useGetFeeCategoriesQuery } from "../../../../store/selectableSlice";
import { toast } from "react-hot-toast";

export default function NewRegistrationFee() {
  const [createRegFee] = useCreateRegFeeMutation();
  const { data: feeCategories = [], error: errorFeeCat } =
    useGetFeeCategoriesQuery();
  if (errorFeeCat) {
    console.log(errorFeeCat.data);
  }
  const addNewRegFee = async (formikValues, { resetForm }) => {
    const reqBody = {
      ...formikValues,
    };
    const toastId = toast.loading("Submitting");
    const { data, error } = await createRegFee(reqBody);
    if (error) {
      console.log(error);
      return toast.error(error?.data?.message || "Error", { id: toastId });
    }
    if (data?.status === false) {
      return toast.error(data?.message || "Error", { id: toastId });
    }
    resetForm();
    return toast.success(data?.message || "Error", { id: toastId });
  };
  return (
    <>
      <div>
        <Formik
          initialValues={{ name: "", fee_category_id: "" }}
          onSubmit={addNewRegFee}
        >
          <Form>
            <div className="">
              <CardComp
                cardBodyClassName={"row gx-5 gy-3 justify-content-between"}
                cardClassName={"pt-4"}
              >
                <div className="col-12 col-lg-5">
                  <div className="mb-3">
                    <TextInputComp
                      LabelText={"Fee name"}
                      // borderColor="#A1A0A0"
                      LabelClassName={"fw-bold"}
                      placeholder={"Bus contribution | NGN 1,000.00"}
                      name="name"
                    />
                  </div>
                  <div className="mb-3">
                    <FormikSelectComp
                      fieldName={"fee_category_id"}
                      labelText={"Fee Category"}
                      labelClassName={"fw-bold"}
                      selectText="Select Fee Category"
                      options={[
                        ...feeCategories.map((item) => ({
                          label: item.name,
                          value: item.id,
                        })),
                      ]}
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-5">
                  <div className="mb-3">
                    <MoneyInputComp
                      LabelText={"Fee amount"}
                      LabelClassName={"fw-bold"}
                      placeholder={"Ifesowapo Cooperatives"}
                      name="amount"
                    />
                  </div>
                </div>
              </CardComp>
              <div className="">
                <div className="">
                  <ButtonComp
                    btnText={"Submit fee"}
                    type={"submit"}
                    btnClassName={
                      "bg-primary1 px-4 py-2 px-4 fw-3 text-white border-0 rounded"
                    }
                  />
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </>
  );
}
