import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ButtonComp,
  CardComp,
  DropDownComp2,
  TabsComp,
} from "../../../components";
import FallbackImage from "../../../components/Common/ImageWrapper";
import { formatMoney } from "../../../utils";
import {
  MemberContributionData,
  MemberLoanRepaymentData,
  RecentTransactions,
} from "../../../utils/dummbyData";
import {
  MemberContributionsHistory,
  MemberLoanRepaymentHeader,
  MemberLoanRepaymentHeader2,
  MemberUpcomingContributions,
} from "../../../utils/TableHeader";
import AllCompanyPrefix from "../../Admin/Configuration&Settings/modules/AllCompanyPrefix";
import ActivitiesRow from "../../Admin/Home/components/ActivitiesRow";
import CardInfo from "../../Admin/Home/components/CardInfo";
import PageWrapper from "../modules/PageWrapper";
import SearchBar from "../modules/SearchBar";

export default function MemberUpcomingContribution() {
  const navigate = useNavigate();
  const location = useLocation();

  const TableDropDown = [
    {
      name: "Pay now",
      action: (data) => {
        console.log(location);
        navigate(`${location?.pathname}/${data?.id}`);
      },
    },
    {
      name: "Record payment",
      action: (data) => {
        navigate(`/super_admin/staff_registration/suspend_staff`);
      },
    },
    {
      name: "Query transaction",
      action: (data) => {
        navigate(`/super_admin/staff_registration/change_branch`);
      },
    },
  ];

  return (
    <PageWrapper title={"Upcoming Contribution"} back>
      <section className="row mb-5 g-4">
        <div className="col-lg-6">
          <div className="bg-primary1 p-4 rounded-4 mb-5">
            <div className="w-100">
              <div className="col-12 col-md-8 bg-white  rounded-4  mb-3">
                <CardInfo
                  total={formatMoney(40, true, "₦")}
                  name={"Amount Contributed"}
                  percentage={"50%"}
                  image={"/MemberSide/Icons/dashboard1.svg"}
                  bgColor={"#A8E1C8"}
                  color={"#318C64"}
                />
              </div>
              <div>
                {/* <div className='border px-2 rounded bg-white'>
                                <DropDownComp2
                                dropLabelClassName={"border-0"}
                                DropDownText="All"
                                show
                                />
                            </div> */}
              </div>
            </div>
            <p className="text-white fw-0 col-lg-9 mb-2">
              Your contribution increases your loan chances, pay on time to
              access cooperative loan{" "}
            </p>
            {/* <ButtonComp
                            btnText={"Create contribution"}
                            onClick={()=>navigate( "/member/loan_management/application")}
                            btnClassName='text-white bg-default rounded col-8 col-lg-5 py-3'
                            BorderColor={"#fff"}
                            BorderWidth={"2px"}
                            /> */}
          </div>
        </div>
        <div className="col-lg-6">
          <section>
            <CardComp cardClassName={"border-0"}>
              <h3 className="fw-bold text-black1 mb-4">Recent Transactions</h3>
              <div>
                {RecentTransactions?.slice(undefined, 4)?.map((item, i) => (
                  <ActivitiesRow
                    key={i}
                    item={item}
                    itemPosition={i}
                    arrayLength={4}
                  />
                ))}
              </div>
            </CardComp>
          </section>
        </div>
      </section>
      <section>
        <CardComp cardClassName={"border-0 pt-3 px-3"}>
          <div className="d-flex justify-content-between align-items-center flex-wrap mb-5">
            <h4 className="fw-bold text-black1  mb-3 mb-lg-0">
              Upcoming contribution list
            </h4>
            <div className="col-lg-6">
              <SearchBar placeholder={"Search your query here"} />
            </div>
          </div>

          <div>
            <AllCompanyPrefix
              tableHeader={MemberUpcomingContributions(TableDropDown)}
              // tableData={[]}
              marginBottom={"6rem"}
              emptyMessage={
                <FallbackImage
                  // width='100px'
                  // height='100px'
                  src="/MemberSide/Icons/empty.svg"
                  className="my-4"
                />
              }
            />
          </div>
        </CardComp>
      </section>
    </PageWrapper>
  );
}
