import React from "react";
import {
  DropDownComp,
  ModalCompAdvanceUpdate,
  TableCompData,
  // ToolTips,
} from "../../../components";
import EmptyTable from "../../../components/Common/EmptyTable";
import LoanRepaymentRecord from "./LoanRepaymentRecord";
import { formatMoney } from "../../../utils";
import moment from "moment";
import { BsCalendar2Week, BsThreeDotsVertical } from "react-icons/bs";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import { useGetLoanBreakdownByLoanRequestIdQuery } from "../../../store/admin/loanRepayment";

export const SingleLoanRepaymentBreakdownByMember = () => {
  const { loanRequestId } = useParams();

  // eslint-disable-next-line no-unused-vars
  const { data, isLoading } =
    useGetLoanBreakdownByLoanRequestIdQuery(loanRequestId);

  const [modalPage, setModalPage] = React.useState("");
  const [loanRepaymentDetails, setLoanRepaymentDetails] = React.useState("");
  const navigate = useNavigate();
  const useModal = [
    {
      name: "Record Loan",
      component: (
        <LoanRepaymentRecord
          Back={() => setModalPage()}
          loanRepaymentDetails={loanRepaymentDetails}
        />
      ),
    },
  ];

  const TableDropDown = (row) => {
    const TableDropDown = [
      {
        name: "Record loan",
        action: (data) => {
          setLoanRepaymentDetails(row);
          setModalPage("Record Loan");
        },
      },
    ];
    return TableDropDown;
  };
  const column = [
    {
      name: "S/N",
      selector: (_, index) => <span className="text-muted">{index + 1}</span>,
      width: "70px",
    },

    {
      name: "DURATION",
      selector: (row) => (
        <p>
          {row?.repayment_type.charAt(0).toUpperCase() +
            row?.repayment_type.slice(1)}
        </p>
      ),
      minWidth: "120px",
      wrap: true,
    },
    {
      name: "LOAN AMOUNT",
      selector: (row) => formatMoney(row?.amount),
      minWidth: "200px",
      maxWidth: "250px",
    },
    {
      name: "DUE DATE",
      selector: (row) => {
        const selectedDateStr = row?.due_at;
        const selectedDate = moment(selectedDateStr, "YYYY-MM-DD");
        const today = moment();
        const daysDifference = selectedDate.diff(today, "days");
        const daysDifferenceString = daysDifference.toString();
        const formattedDifference = daysDifferenceString.startsWith("-")
          ? daysDifferenceString.substring(1)
          : daysDifferenceString;

        return row.status === "PENDING" ? (
          <div className="px-3 d-flex align-items-center">
            <span className="me-3">
              <BsCalendar2Week size={25} />
            </span>
            {moment(row?.due_at).format("DD-MMM-YYYY")}
            <div
              className={`ms-3 ${
                row.breakdown_status === "OVERDUE"
                  ? "bg-danger2"
                  : "bg-success2"
              } py-2 px-3 rounded-pill`}
            >
              {`${formattedDifference} days`}
            </div>
          </div>
        ) : (
          <div className="px-3 d-flex align-items-center">
            <span className="me-3">
              <BsCalendar2Week size={25} />
            </span>
            {moment(row?.due_at).format("DD-MMM-YYYY")}
          </div>
        );
      },
      width: "300px",
    },
    {
      name: "STATUS",
      selector: (row) => (
        <div
          className={`text-[0.75rem] ${
            row.breakdown_status === "OVERDUE" ? "bg-danger2" : "bg-success2"
          } py-2 px-3 rounded-pill`}
        >
          {" "}
          {row.breakdown_status}
        </div>
      ),
      minWidth: "200px",
      maxWidth: "250px",
    },
    {
      name: "ACTION",
      cell: (row) => (
        <div className="text-center">
          <DropDownComp
            dropLabelClassName={"bg-white border-0 px-3 py-1 rounded-4 text-2"}
            arrayComp={TableDropDown(row)}
            ItemClassName="pe-5 text-2"
            input="name"
            row={row}
            DropDownText={
              <span>
                <BsThreeDotsVertical size={20} />
              </span>
            }
          />
        </div>
      ),
      width: "100px",
    },
  ];

  return (
    <>
      <div className="space-y-6">
        <div className="space-x-2 w-full bg-white rounded-[10px] py-3 px-10 flex items-center col-lg-6 mb-3 mb-lg-0">
          <BsArrowLeft
            size={30}
            onClick={() => navigate(-1)}
            className=" pointer"
          />
          <h4 className=" text-2xl text-[#8B313A] font-bold ">
            {/* {`${member?.first_name} ${member?.last_name} `} */}
            {data?.loan_type?.name}
          </h4>
          <h4 className="font-bold mb-0 text-black1 text-[1.5rem]">
            Repayment
          </h4>
        </div>

        <div className=" flex-1 bg-white flex justify-between  items-center  rounded-[10px] p-[2.5rem] ">
          <div className="space-y-2 mr-2">
            <p className=" text-sm text-[#858585] font-normal  ">Loan Tenor</p>
            <div className="flex items-center space-x-2">
              <span className="text-[2rem] text-[#000] font-bold  ">
                {data?.duration || 0}
              </span>
              <p className=" text-lg text-[#858585] font-normal ">
                {data?.duration_type.charAt(0) +
                  data?.duration_type.slice(1).toLowerCase()}
              </p>
            </div>
          </div>

          <div className="border-r w-[1px]  h-[5rem]" />

          <div className="space-y-2 ml-2">
            <p className=" text-sm text-[#858585] font-normal  ">
              Loan Interest
            </p>
            <div className="flex items-center space-x-2">
              <span className="text-[2rem] text-[#000] font-bold  ">
                {`${data?.loan_type?.rate || 0}%`}
              </span>
              <p className=" text-lg text-[#858585] font-normal ">Annual</p>
            </div>
          </div>

          <div className="border-r w-[1px]  h-[5rem]" />

          <div className="space-y-4 ml-2">
            <p className=" text-sm text-[#858585] font-normal  ">
              Overdue repayment
            </p>
            <div className="flex items-center space-x-2">
              <span className="text-[2rem] text-[#FF4343] font-bold  ">
                {data?.overdue_repayment || 0}
              </span>
              <p className=" text-lg text-[#000] font-bold">Overdue</p>
            </div>
          </div>

          <div className="border-r w-[1px]  h-[5rem]" />

          <div className="space-y-4 ml-2">
            <p className=" text-sm text-[#858585] font-normal  ">
              Pending repayment
            </p>
            <div className="flex items-center space-x-2">
              <span className="text-[2rem] text-[#000] font-bold  ">
                {data?.pending_repayment || 0}
              </span>
              <p className=" text-lg text-[#000] font-bold ">Pending</p>
            </div>
          </div>
        </div>

        <div className="w-full bg-white flex justify-between overflow-x-auto items-center flex-wrap rounded-[10px] col-lg-6 mb-3 mb-lg-0">
          <p className="p-4 pb-0 text-base text-[#858585] font-normal ">
            Breakdown
          </p>
          <TableCompData
            columns={column}
            data={data?.breakdowns}
            message={
              isLoading ? (
                <div className=" py-4 ">
                  <h3>Loading...</h3>
                </div>
              ) : (
                <EmptyTable
                  Message={`There is currently no loan breakdown available}`}
                />
              )
            }
          />
        </div>
      </div>

      <ModalCompAdvanceUpdate
        pageName={modalPage}
        arrayComp={useModal}
        handleClose={() => setModalPage()}
        centered
        size={"lg"}
      />
    </>
  );
};
