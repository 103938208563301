import React, { PureComponent } from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';

const data = [
  { name: 'Group A', value: 400 },
  { name: 'Group B', value: 300 },
  { name: 'Group C', value: 300 },

];
const COLORS = ['#E95FCA', '#084CD1', '#3CE712'];

export const PieChartComp = () => {
  return (

    <div style={{ width: '100%', height: 300 }}>
      <ResponsiveContainer>
        <PieChart width={800} height={350}
        //   onMouseEnter={this.onPieEnter}
        >
          <Pie
            data={data}
            cx={120}
            cy={200}
            innerRadius={60}
            outerRadius={80}
            fill="#8884d8"
            paddingAngle={5}
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>

        </PieChart>
      </ResponsiveContainer>
    </div>

  );
}

