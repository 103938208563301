import { API, ApiPrefixes } from "../../api";
const BASE_URL = ApiPrefixes["memberManagement"];

export const memberExtendedApi = API.injectEndpoints({
  endpoints: (builder) => ({
    createMember: builder.mutation({
      query: (body) => ({
        url: `${BASE_URL}/create?guard=staff`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: [{ type: "Member", id: "LIST" }],
    }),
    updateMember: builder.mutation({
      query: (body) => ({
        url: `${BASE_URL}/${body.id}/update?guard=staff`,
        method: "PATCH",
        body: body,
      }),
      invalidatesTags: (result, err, arg) => [{ type: "Member", id: arg?.id }],
    }),
    getMembers: builder.query({
      query: (page = 1) => ({
        url: `${BASE_URL}?guard=staff&page=${page}&limit=20`,
      }),
      providesTags: (result = {}) => {
        if (!result.members) return [{ type: "Member", id: "LIST" }];
        return [
          { type: "Member", id: "LIST" },
          ...result?.members?.map(({ id }) => ({ type: "Member", id: id })),
        ];
      },
      transformResponse: (responseData) => {
        const { members = [], pagination = {} } = responseData.data;
        return {
          members: members,
          pagination,
        };
      },
    }),
    viewMember: builder.query({
      query: (memberId) => ({
        url: `${BASE_URL}/${memberId}/view?guard=staff`,
      }),
      transformResponse: (responseData) => responseData.data,
      providesTags: (result, error, arg) => [{ type: "Member", id: arg }],
    }),
    deleteMember: builder.mutation({
      query: (memberId) => ({
        url: `${BASE_URL}/${memberId}/delete?guard=staff`,
        method: "DELETE",
      }),
      invalidatesTags: (result, err, arg) => [{ type: "Member", id: arg }],
    }),
    unSuspendMember: builder.mutation({
      query: (memberId) => ({
        url: `${BASE_URL}/${memberId}/unsuspend?guard=staff`,
        method: "PUT",
      }),
      invalidatesTags: (result, err, arg) => [{ type: "Member", id: arg }],
    }),
    suspendMember: builder.mutation({
      query: (body) => ({
        url: `${BASE_URL}/${body.memberId}/suspend?guard=staff`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: (result, err, arg) => [{ type: "Member", id: arg?.id }],
    }),
    getPendingMembers: builder.query({
      query: (page = 1) =>
        `${BASE_URL}/pending/pull/all?guard=staff&page=${page}`,
      providesTags: (result = {}) => {
        if (!result.members) return [{ type: "Member", id: "LIST" }];
        return [
          { type: "Member", id: "LIST" },
          ...result?.members?.map(({ id }) => ({ type: "Member", id: id })),
        ];
      },
      transformResponse: (responseData) => {
        const { members = [], pagination = {} } = responseData.data;
        return {
          members: members,
          pagination,
        };
      },
    }),
    generateMemberId: builder.query({
      query: (branchId) => `${BASE_URL}/${branchId}/generate_code?guard=staff`,
      transformResponse: (responseData) => responseData.data,
    }),
    searchMembers: builder.query({
      query: ({ page = 1, search = "" }) =>
        `${BASE_URL}/search/all?guard=staff&page=${page}&search=${search}`,
      providesTags: (result = {}) => {
        if (!result.members) return [{ type: "Member", id: "LIST" }];
        return [
          { type: "Member", id: "LIST" },
          ...result?.members?.map(({ id }) => ({ type: "Member", id: id })),
        ];
      },
      transformResponse: (responseData) => {
        return responseData.data;
      },
    }),
    approveMember: builder.mutation({
      query: (memberId) => ({
        url: `${BASE_URL}/${memberId}/approve?guard=staff`,
        method: "PUT",
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Member", id: arg }],
    }),
    declineMember: builder.mutation({
      query: (memberId) => ({
        url: `${BASE_URL}/${memberId}/decline?guard=staff`,
        method: "PUT",
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Member", id: arg }],
    }),
    changeMemberBranch: builder.mutation({
      query: ({ memberId, reqBody }) => ({
        url: `${BASE_URL}/${memberId}/change_branch?guard=staff`,
        method: "PATCH",
        body: reqBody,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Member", id: arg.memberId },
      ],
    }),
  }),
});

export const {
  useCreateMemberMutation,
  useGetMembersQuery,
  useViewMemberQuery,
  useDeleteMemberMutation,
  useUnSuspendMemberMutation,
  useSuspendMemberMutation,
  useGetPendingMembersQuery,
  useGenerateMemberIdQuery,
  useSearchMembersQuery,
  useApproveMemberMutation,
  useDeclineMemberMutation,
  useUpdateMemberMutation,
  useChangeMemberBranchMutation,
} = memberExtendedApi;
