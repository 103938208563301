import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

const useRtkFetchError = ({ error }) => {
  const navigate = useNavigate();
  useEffect(() => {
    // console.log("checking error");
    if (error?.status === 401) {
      toast.error("log to continue");
      return navigate("/login");
    } else if (error) {
      toast.error(error?.data?.message || "an error occured");
    }
  }, [error, navigate]);
  //   return {}
};

export default useRtkFetchError;
