import { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ModalCompAdvance,
  PageSwitch,
  TableCompData,
  ButtonComp,
  CardComp,
} from "../../../components";
// import AdminLayout from "../../../Layout/AdminLayout";
import { SettingMemberRegistrationFeeHeader } from "../../../utils/TableHeader";
import ConfirmModal from "../modules/ConfirmModal";
import NewRegistrationFee from "./modules/NewRegistrationFee";
import EmptyTable from "../../../components/Common/EmptyTable";
import {
  useGetRegFeesQuery,
  useDeleteRegFeeMutation,
} from "../../../store/admin/settings/regFeeSlice";
import { dateFromString } from "../../../utils";
import { toast } from "react-hot-toast";
import TablePagination from "../../../components/Table/Pagination";
export default function SettingMemberRegistrationFee() {
  const [pageName, setPageName] = useState("Member Registration Fee");
  const navigate = useNavigate();
  const location = useLocation();
  const [modalToggle, setModalToggle] = useState(false);
  const [modalPage] = useState(0);
  const [deleteRegFee] = useDeleteRegFeeMutation();
  const [regFeeId, setRegFeeId] = useState();

  const [page, setPage] = useState(1);
  const { data = {}, error, isLoading } = useGetRegFeesQuery(page);
  const { regFees, pagination } = useMemo(() => {
    const { fees = [], pagination = {} } = data;
    const formattedFees = fees.map((fee, index) => ({
      ...fee,
      serialNumber: index + 1 + (page - 1) * 5,
      date: dateFromString(fee.created_at),
    }));
    return { regFees: formattedFees, pagination };
  }, [data, page]);

  const removeRegFee = async (regFeeId) => {
    if (!regFeeId) return;
    setModalToggle(false);
    setRegFeeId(null);
    const toastId = toast.loading("Deleting");
    const { data, error } = await deleteRegFee(regFeeId);
    if (error) {
      return toast.error(error?.data.message || "Error", { id: toastId });
    }
    if (data?.status === false) {
      return toast.error(data?.message || "Error", { id: toastId });
    }
    return toast.success(data?.message || "Deleted Succesfully", {
      id: toastId,
    });
  };

  if (error && error.status === 401) {
    navigate("/login");
    console.log(error);
  }
  // console.log(regFees);
  const useModal = [
    {
      name: "Suspend Modal",
      component: (
        <ConfirmModal
          title={"Confirm"}
          desc={"Are you sure you want to disable this bank account?"}
          setModalToggle={() => setModalToggle(false)}
          confimAction={() => removeRegFee(regFeeId)}
        />
      ),
    },
  ];
  const TableDropDown = [
    {
      name: "Edit",
      action: (data) => {
        navigate(`${location?.pathname}/${data?.regFeeId}`);
      },
    },
    {
      name: "Delete",
      action: (data) => {
        setRegFeeId(data.regFeeId);
        setModalToggle(true);
      },
    },
  ];

  const usePage = [
    {
      name: "Member Registration Fee",
      component: (
        <>
          <TableCompData
            columns={SettingMemberRegistrationFeeHeader(TableDropDown)}
            data={regFees || []}
            marginBottom={"72px"}
            message={
              isLoading ? (
                <div className="py-4">
                  <p>Loading</p>
                </div>
              ) : (
                <EmptyTable
                  Message={
                    "There are currently no member registration fee added"
                  }
                  btnText={"Add new fee"}
                  onClick={() => {
                    setPageName("New Registration Fee");
                  }}
                />
              )
            }
          />
          {!isLoading && regFees.length > 0 && (
            <TablePagination {...pagination} handlePageChange={setPage} />
          )}
        </>
      ),
    },
    {
      name: "New Registration Fee",
      component: <NewRegistrationFee onNext={() => {}} />,
    },
  ];

  return (
    <section className="px-2 px-lg-4 min-vh-100">
      <CardComp cardBodyClassName={"py-0"} cardClassName={"pt-4"}>
        <h4 className=" text-black1 fw-bold mb-0">{pageName}</h4>
      </CardComp>
      <div className="mb-4 text-end">
        {pageName === "Member Registration Fee" ? (
          <ButtonComp
            btnText={"Create New"}
            onClick={() => setPageName("New Registration Fee")}
            btnClassName="bg-primary1 py-2 px-4 rounded text-white border-0"
          />
        ) : (
          <ButtonComp
            btnText={"All Fees"}
            onClick={() => setPageName("Member Registration Fee")}
            btnClassName="bg-primary1  py-2 px-4 rounded text-white border-0"
          />
        )}
      </div>
      <PageSwitch pageArray={usePage} pageName={pageName} />
      <ModalCompAdvance
        modalBody={modalPage}
        show={modalToggle}
        arrayComp={useModal}
        handleClose={() => setModalToggle(false)}
        centered
        size={"md"}
      />
    </section>
  );
}
