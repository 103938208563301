// import { useNavigate } from "react-router-dom";
// import { BatchUploadComp } from "../../../components";
// import AdminLayout from "../../../Layout/AdminLayout";
import { MemberBatchUploadComp } from "../../../components/BatchUpload/member-batch-upload";

export const MemberBatchUpload = () => {
  // const navigate = useNavigate();

  //

  return <MemberBatchUploadComp />;
};
