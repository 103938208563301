import React from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useGetAllBankInfoQuery } from "../../../../store/admin/settings/societyBankSlice";
import { useCreateContributionCategoryMutation } from "../../../../store/admin/contribution";
import toast from "react-hot-toast";
import { Input } from "../../../../components/Ui/new-input";
import CustomSelect from "../../../../components/custom-select/custom-select";
import { customStyles } from "../../../../components/custom-select/custom-styles";
import { useGetBranchesQuery } from "../../../../store/admin/settings/branchSlice";

export default function CreateContributionModal() {
  const navigate = useNavigate();
  const { data: branches = [], isLoading: loadingBranches } =
    useGetBranchesQuery();

  const formik = useFormik({
    initialValues: {
      is_withdrawable: "",
      company_bank_id: "",
      threshold: 0,
      end_date: new Date(),
      start_date: new Date(),
      amount: "",
      branches: [],
    },
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      threshold: Yup.number("Required"),
      amount: Yup.number().required("Required"),
      company_bank_id: Yup.string().required("Required"),
      end_date: Yup.string().required("Required"),
      start_date: Yup.string().required("Required"),
      branches: Yup.array().of(
        Yup.object().shape({
          branch_id: Yup.number().required("Required"),
        })
      ),
    }),
  });
  const [createContributionCategory, { isLoading }] =
    useCreateContributionCategoryMutation();
  const { data } = useGetAllBankInfoQuery(1);
  const formattedCompanyBanks = data?.company_banks?.map((item) => {
    return {
      label: item.bank_name + " | " + item.account_name,
      value: item.bank_id,
    };
  });

  const handleFormSubmit = async (formikValues) => {
    const updatedValues = {
      ...formikValues,
      amount: parseInt(formikValues.amount),
      company_bank_id: parseInt(formikValues.company_bank_id),
    };

    const response = await createContributionCategory(updatedValues);
    if ("error" in response) {
      const { data } = response.error;
      return toast.error(data.message);
    }
    const { message, status } = response.data;
    if (!status) return toast.error(message);
    navigate(`/super_admin/contribution/contribution_history`);
    toast.success(message);
    formik.resetForm();
  };

  return (
    <div>
      <h4 className="mb-0 text-black1 fw-bold text-center">
        Create New Contribution
      </h4>
      <form className="w-full" onSubmit={formik.handleSubmit}>
        <section className="w-full grid grid-cols-1 lg:grid-cols-2 gap-x-10">
          <Input
            type="text"
            label="Contribution name"
            placeholder={"Input contribution name"}
            name="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            error={formik?.touched.name && formik.errors.name}
            errorMessage={formik.errors.name}
          />

          <CustomSelect
            options={branches?.map((branch) => ({
              value: branch.id,
              label: branch.name,
            }))}
            styles={customStyles}
            label={"Branch"}
            isLoading={loadingBranches}
            onChange={(selectedOptions) =>
              formik.setValues({
                ...formik.values,
                branches: selectedOptions.map((option) => ({
                  branch_id: option.value,
                })),
              })
            }
            isMulti
            placeholder="Select contribution branch"
          />
          {formik?.touched.branches && formik.errors.branches && (
            <span className="text-sm text-red-500 font-circular_book block w-full">
              {formik.errors.branches}
            </span>
          )}
          <Input
            type="number"
            label="Amount"
            placeholder={"#1,000,000"}
            name="amount"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.amount}
            error={formik?.touched.amount && formik.errors.amount}
            errorMessage={formik.errors.amount}
          />
          <Input
            type="number"
            label="Contribution threshold"
            placeholder={"#1,000,000"}
            name="threshold"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.threshold}
            error={formik?.touched.threshold && formik.errors.threshold}
            errorMessage={formik.errors.threshold}
          />
          <Input
            type="date"
            label="Start date"
            placeholder={"Select start date"}
            name="start_date"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.start_date}
            error={formik?.touched.start_date && formik.errors.start_date}
            errorMessage={formik.errors.start_date}
          />
          <Input
            type="date"
            label="End date"
            placeholder={"Select end date"}
            name="end_date"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.end_date}
            error={formik?.touched.end_date && formik.errors.end_date}
            errorMessage={formik.errors.end_date}
          />
          <CustomSelect
            options={formattedCompanyBanks}
            styles={customStyles}
            label={"Payment bank"}
            onChange={(value) => {
              formik.setFieldValue("company_bank_id", value.value);
            }}
            placeholder="Select bank to accept contribution"
          />
          {formik?.touched.bank_name && formik.errors.bank_name && (
            <span className="text-sm text-red-500 font-circular_book block w-full">
              {formik.errors.bank_name}
            </span>
          )}
          <CustomSelect
            options={[
              {
                label: "Withdrawable",
                value: 1,
              },
              {
                label: "Non-Withdrawable",
                value: 0,
              },
            ]}
            styles={customStyles}
            label={"Withdrawable status"}
            onChange={(value) => {
              formik.setFieldValue("is_withdrawable", value.value);
            }}
            placeholder="Select withdrawable status"
          />
          {formik?.touched.bank_name && formik.errors.bank_name && (
            <span className="text-sm text-red-500 font-circular_book block w-full">
              {formik.errors.bank_name}
            </span>
          )}
        </section>
        <div className="my-3 w-full flex justify-center ">
          <button className="w-[200px] h-[50px] rounded-[10px] bg-new-primary text-white">
            {isLoading ? "creating category..." : "Create category"}
          </button>
        </div>
      </form>
    </div>
  );
}
