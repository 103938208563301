import { BsChevronDown } from "react-icons/bs";
import styled from "styled-components";
import { DropDownComp, DropDownComp3, TabsComp } from "..";
import TopNavNotification from "./modules/TopNavNotification";
import { useDispatch } from "react-redux";
import { logout } from "../../store/auth/index.js";
import { useUser } from "../../hooks";

const NavBarStyle = styled.div`
  .huver {
    height: 10vh;
    // overflow:hidden;
  }
  @media (min-width: 992px) {
    .col-lg-9 {
      width: 70% !important;
    }
    .col-lg-3 {
      width: 30% !important;
    }import { useUser } from '../../hooks/useUser';

  }
`;

export const NavbarComp = ({ setHideSider, hideSider }) => {
  const dispatch = useDispatch();

  // loggedIn user data that has been stored in the store
  const user = useUser();

  const usePage = [
    {
      name: "Read",
      label: (
        <div className="d-flex">
          <div className="me-2 h50">Read</div>
          <div
            className="rounded-pill text-white h60 d-flex justify-content-center align-items-center"
            style={{ width: "20px", height: "20px", background: "#3E97B2" }}
          >
            10
          </div>
        </div>
      ),
      component: (
        <div>
          <div className="mb-4">
            <div
              style={{ background: "#E0E0E0" }}
              className="py-1 px-3 h60 mb-3"
            >
              Today, October 1, 2022
            </div>
            <TopNavNotification />
            <TopNavNotification />
          </div>
          <div>
            <div
              style={{ background: "#E0E0E0" }}
              className="py-1 px-3 h60 mb-3"
            >
              Yesterday, October 2, 2022
            </div>
            <TopNavNotification />
            <TopNavNotification />
          </div>
        </div>
      ),
    },
    {
      name: "Unread",
      label: (
        <div className="d-flex">
          <div className="me-2 h50">Unread</div>
          <div
            className="rounded-pill text-white h60 d-flex justify-content-center align-items-center"
            style={{ width: "20px", height: "20px", background: "#8B313A" }}
          >
            10
          </div>
        </div>
      ),
      component: <div></div>,
    },
  ];
  return (
    <NavBarStyle>
      <header id="header" className="bg-white py-3 ps-lg-5">
        <div className="d-flex justify-content-between px-4 align-items-center">
          {/* <div><img src="/Images/TopHeader/logo2.svg" alt="logo"/></div> */}
          <div className="d-flex align-items-center">
            <span className="pe-1 d-none d-md-inline">
              <DropDownComp3
                dropLabelClassName={"border-0"}
                Transform={`translate3d(-168.2px,55px,-5px)`}
                // Transform={`translate3d(-75.2px, 56px, 0px)`}
                DropDownText={
                  <img src="/Images/TopHeader/message.svg" alt="logo" />
                }
                drop="down"
                bodyComp={
                  <div style={{ width: "420px" }} className="text-black p-3">
                    <TabsComp
                      TabArray={usePage}
                      input="label"
                      lineBackground={"#E0A8AE"}
                      lineLeft={"0px"}
                      lineRight={"0px"}
                      lineBottom="24px"
                      tabClassName="col-6"
                    />
                  </div>
                }
              />
            </span>
            <span className=" d-inline d-md-none">
              <DropDownComp3
                dropLabelClassName={"border-0"}
                Transform={`translate3d(-88.2px,55px,-5px)`}
                // Transform={`translate3d(-75.2px, 56px, 0px)`}
                DropDownText={
                  <img src="/Images/TopHeader/message.svg" alt="logo" />
                }
                drop="down"
                bodyComp={
                  <div style={{ width: "320px" }} className="text-black p-3">
                    <TabsComp
                      TabArray={usePage}
                      input="label"
                      lineBackground={"#E0A8AE"}
                      lineLeft={"0px"}
                      lineRight={"0px"}
                      lineBottom="24px"
                      tabClassName="col-6"
                    />
                  </div>
                }
              />
            </span>
            <span className=" d-none d-md-inline">
              <DropDownComp3
                dropLabelClassName={"border-0"}
                Transform={`translate3d(-168.2px,55px,-5px)`}
                // Transform={`translate3d(-75.2px, 56px, 0px)`}
                DropDownText={
                  <img src="/Images/TopHeader/notification.svg" alt="logo" />
                }
                drop="down"
                bodyComp={
                  <div style={{ width: "450px" }} className="text-black p-3">
                    <TabsComp
                      TabArray={usePage}
                      input="label"
                      lineBackground={"#E0A8AE"}
                      lineLeft={"0px"}
                      lineRight={"0px"}
                      lineBottom="24px"
                      tabClassName="col-6"
                    />
                  </div>
                }
              />
            </span>
            <span className=" d-inline d-md-none">
              <DropDownComp3
                dropLabelClassName={"border-0"}
                Transform={`translate3d(-158.2px,55px,-5px)`}
                // Transform={`translate3d(-75.2px, 56px, 0px)`}
                DropDownText={
                  <img src="/Images/TopHeader/notification.svg" alt="logo" />
                }
                drop="end"
                bodyComp={
                  <div style={{ width: "320px" }} className="text-black p-3">
                    <TabsComp
                      TabArray={usePage}
                      input="label"
                      lineBackground={"#E0A8AE"}
                      lineLeft={"0px"}
                      lineRight={"0px"}
                      lineBottom="24px"
                      tabClassName="col-6"
                    />
                  </div>
                }
              />
            </span>
            <div className="">
              <DropDownComp
                dropLabelClassName={
                  "bg-white px-3 py-1 rounded-4 text-2 border-0"
                }
                arrayComp={[
                  {
                    name: <span className="h50">Profile</span>,
                    icon: "/Images/TopHeader/user.svg",
                  },
                  {
                    name: <span className="h50">My Logins</span>,
                    icon: "/Images/TopHeader/user.svg",
                  },
                  {
                    name: <span className="h50">Messages</span>,
                    icon: "/Images/TopHeader/user.svg",
                  },
                  {
                    name: <span className="h50">Settings</span>,
                    icon: "/Images/TopHeader/user.svg",
                  },
                  {
                    name: <span className="h50">Logout</span>,
                    icon: "/Images/TopHeader/user.svg",
                    action: () => {
                      dispatch(logout());
                    },
                  },
                ]}
                ItemClassName="pe-5 text-2"
                input="name"
                DropDownText={
                  <span>
                    <span className="pe-2">
                      <img
                        src={user?.photo}
                        style={{ height: 23, width: 23 }}
                        alt="logo"
                      />
                    </span>
                    <span className="fw-2 h50">
                      {`${user?.first_name}  ${user?.last_name}` || "John Doe"}
                    </span>
                    <span>
                      <BsChevronDown size={20} />
                    </span>
                  </span>
                }
              />
            </div>
          </div>
        </div>
      </header>
    </NavBarStyle>
  );
};
