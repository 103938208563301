import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PageWrapper from "../modules/PageWrapper";
import { BsChevronRight } from "react-icons/bs";
import LoanRepaymentTable from "./LoanRepaymentTable";
import { MemberLoanRepaymentHeader } from "../../../utils/TableHeader";
import LoanManagementCard from "../modules/LoanManagementCard";
import { useUser } from "../../../hooks";
import { useGetOverdueRepaymentLoanListQuery } from "../../../store/member/member-loan";

export default function LoanManagement() {
  const navigate = useNavigate();
  const user = useUser();
  const [overdueRepaymentPage, setOverdueRepaymentPage] = useState(1);

  const { data: overdueRepayments, isLoading: isLoadingOverdueRepayments } =
    useGetOverdueRepaymentLoanListQuery({
      page: overdueRepaymentPage,
      member_id: user?.member_id,
    });

  const MemberLateContributions = () => {
    return [
      {
        name: "Check your recently applied loan status",
        contributions: "Loan status",
        image: "/MemberSide/Icons/dashboard3.svg",
        percentage: "80%",
        bgColor: "#A8E1C8",
        color: "#318C64",
        onclick: "/member/loan_management/loan_status",
      },
      {
        name: `You have ${user?.pending_repayment || 0} pending loan repayment`,
        contributions: "Loan Repayment",
        image: "/MemberSide/Icons/dashboard5.svg",
        percentage: "80%",
        bgColor: "#E0A8AE ",
        color: "#8B313A",
        onclick: "/member/loan_management/loan_repayment",
      },
    ];
  };

  const TableDropDown1 = [
    {
      name: "Record payment",
      action: (data) => {
        // console.log(location);
        // navigate(`${location?.pathname}/${data?.id}`);
      },
    },
    {
      name: "Query transaction",
      action: (data) => {
        navigate(`/super_admin/staff_registration/change_branch`);
      },
    },
  ];

  return (
    <PageWrapper title={"Loan Management"}>
      <section className="mt-6 space-y-6 ">
        <div className="w-full flex justify-between items-center flex-wrap gap-12">
          <div className="flex-1">
            <LoanManagementCard />
          </div>
          <section className=" space-y-5 flex-1">
            {MemberLateContributions()?.map((item, i) => (
              <div
                onClick={() => navigate(item?.onclick)}
                className=" space-x-4 rounded-[0.625rem] shadow3 flex items-center pointer px-[2.42rem] py-[1.25rem] "
                key={i}
              >
                <img
                  src={item?.image}
                  alt="img"
                  className="w-[2.5rem] h-[2.5rem]"
                />

                <div className="w-full space-y-2 ">
                  <h4 className="">{item?.contributions}</h4>
                  <p className={`text-muted h50 font-1 ${item?.nameClassName}`}>
                    {item?.name}
                  </p>
                </div>
                <div>
                  <BsChevronRight size={20} />
                </div>
              </div>
            ))}
          </section>
        </div>
      </section>
      <section className="mt-[4rem] bg-white rounded-[1.25rem] ">
        <h4 className="font-bold text-base text-[#45464E] pt-[2rem] px-[2rem] ">
          Overdue Loans
        </h4>
        <LoanRepaymentTable
          name="overdue repayment"
          TableData={overdueRepayments?.data?.company_loan_requests || []}
          pagination={overdueRepayments?.data?.pagination}
          loading={isLoadingOverdueRepayments}
          setPageNumber={setOverdueRepaymentPage}
          column={MemberLoanRepaymentHeader(TableDropDown1)}
        />
      </section>
    </PageWrapper>
  );
}
