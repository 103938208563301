import React from "react";
import { TableCompData } from "../../../components";
import EmptyTable from "../../../components/Common/EmptyTable";
import TablePagination from "../../../components/Table/Pagination";

export default function LoanRepaymentTable({
  name,
  loading,
  setPageNumber,
  pagination,
  TableData,
  column,
}) {
  return (
    <>
      <main>
        <TableCompData
          marginBottom={"1rem"}
          columns={column}
          data={TableData}
          message={
            loading ? (
              <div className=" py-4 ">
                <h3>Loading...</h3>
              </div>
            ) : (
              <EmptyTable Message={`There are currently no ${name}`} />
            )
          }
        />
        {pagination && (
          <TablePagination
            marginTop={"0"}
            handlePageChange={setPageNumber}
            {...pagination}
          />
        )}
      </main>
    </>
  );
}
