import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { CardComp, PageTab } from "../../../components";
import BranchComp from "../../../components/Common/BranchComp";
// import AdminLayout from "../../../Layout/AdminLayout";
import AddNewBranch from "./modules/AddNewBranch";
import SingleStaff from "./modules/SingleStaff";
import { useGetSingleStaffQuery } from "../../../store/admin/staff.js";
import Loader from "../../../components/Ui/Loader";

export const ViewSingleStaff = () => {
  const [pageNumber, setPageNumber] = useState("Single Staff");
  const { id } = useParams();
  const { data: staffDetails, isLoading, refetch } = useGetSingleStaffQuery(id);

  const usePages = [
    {
      name: "Single Staff",
      component: isLoading ? (
        <CardComp cardClassName={"border-0 px-lg-4 mb-3 py-2"}>
          <Loader />
        </CardComp>
      ) : (
        <SingleStaff
          staffDetails={staffDetails}
          onNext={() => setPageNumber(`Add New Branch`)}
          downDetails={
            <div className="container">
              <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
                {staffDetails?.branches?.map((branch, index) => {
                  return <BranchComp branch={branch} key={index} />;
                })}
              </div>
            </div>
          }
        />
      ),
    },
    {
      name: "Add New Branch",
      component: (
        <AddNewBranch
          refetch={refetch}
          onBack={() => setPageNumber(`Single Staff`)}
        />
      ),
    },
  ];
  return (
    <div className="container mb-5">
      <PageTab PageArray={usePages} page={pageNumber} />
    </div>
  );
};
