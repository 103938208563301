import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ButtonComp, ModalCompAdvance } from "../../../components";
import FilterFind from "../../../components/FilterFind";
import Message from "../../../components/MessageModal";
import AdminLayout from "../../../Layout/AdminLayout";
import { EventManagementHeader } from "../../../utils/TableHeader";
import AllTables from "../modules/AllCompanyPrefix";
import { useGetEventsQuery } from "../../../store/admin/eventSlice";
import { useMemo } from "react";
import TablePagination from "../../../components/Table/Pagination";
import useEventActions from "./useEventActions";

export default function EventCalendar() {
  const [pageName, setPageName] = useState("Event Lists");
  const [pageNumber, setPageNumber] = useState(1);
  const navigate = useNavigate();
  const location = useLocation();
  const [modalToggle, setModalToggle] = useState(false);
  const [modalPage] = useState(0);

  const { removeEvent } = useEventActions();
  const [eventId, setEventId] = useState(null);

  const { data = {}, error } = useGetEventsQuery();
  if (error) {
    console.log(error);
  }
  const { event_calenders = [], pagination } = useMemo(() => {
    const { event_calendars = [], pagination } = data;
    const perPage = pagination?.perPage || 5;
    const items = event_calendars?.map((item, index) => {
      return {
        ...item,
        serialNumber: index + 1 + (pageNumber - 1) * perPage,
      };
    });
    return { event_calenders: items, pagination };
  }, [data, pageNumber]);

  const handleDelete = () => {
    removeEvent(eventId);
    setModalToggle(false);
  };

  const useModal = [
    {
      name: "Suspend Modal",
      component: (
        <Message
          title={"Confirm"}
          desc={"Are you sure you want to disable this bank account?"}
          onBack={() => setModalToggle()}
          onProceed={handleDelete}
        />
      ),
    },
  ];
  const TableDropDown = [
    {
      name: "Edit",
      action: (data) => {
        navigate(`${location?.pathname}/${data?.id}`);
      },
    },
    {
      name: "Delete",
      action: (data) => {
        setEventId(data.id);
        setModalToggle(true);
      },
    },
  ];

  return (
    <section className=" px-lg-3  min-vh-100 pb-5 mb-5">
      <FilterFind title={pageName} />
      <div className="text-end mb-3">
        <ButtonComp
          btnText={"Create New"}
          onClick={() => navigate("/super_admin/event_calendar/create")}
          btnClassName="bg-primary1  py-2 px-5 rounded text-white border-0"
        />
      </div>
      <div className="py-1"></div>
      <AllTables
        tableData={event_calenders}
        tableHeader={EventManagementHeader(TableDropDown)}
        emptyMessage={
          "Event lists is empty, your created events will appear here"
        }
        TableDropDown={TableDropDown}
        onSubmit={() => navigate("/super_admin/event_calendar/create")}
        marginBottom="70px"
      />
      {event_calenders.length > 0 && (
        <TablePagination handlePageChange={setPageNumber} {...pagination} />
      )}
      <ModalCompAdvance
        modalBody={modalPage}
        show={modalToggle}
        arrayComp={useModal}
        handleClose={() => setModalToggle(false)}
        centered
        size={"md"}
      />
    </section>
  );
}
