import React, { useState } from "react";
import { CardComp, TabsComp } from "../../../components";
import PageWrapper from "../modules/PageWrapper";
import LoanStatusTable from "./LoanStatusTable";
import {
  useGetApprovedMemberLoanListQuery,
  useGetDeclinedMemberLoanListQuery,
  useGetDisbursedMemberLoanListQuery,
  useGetPendingMemberLoanRequestsQuery,
} from "../../../store/member/member-loan";
import LoanManagementCard from "../modules/LoanManagementCard";
import { useUser } from "../../../hooks";

export default function MemberLoanStatus() {
  const user = useUser();
  const [pendingLoanPage, setPendingLoanPage] = useState(1);
  const [approvedLoanPage, setApprovedLoanPage] = useState(1);
  const [declinedLoanPage, setDeclinedLoanPage] = useState(1);
  const [disbursedLoanPage, setDisbursedLoanPage] = useState(1);

  
  const { data: pendingLoans, isLoading: isLoadingPendingLoans } =
    useGetPendingMemberLoanRequestsQuery({
      page: pendingLoanPage,
      member_id: user?.member_id,
    });

  const { data: approvedLoans, isLoading: isLoadingApproveLoans } =
    useGetApprovedMemberLoanListQuery({
      page: approvedLoanPage,
      member_id: user?.member_id,
    });

  const { data: declinedLoans, isLoading: isLoadingDeclineLoans } =
    useGetDeclinedMemberLoanListQuery({
      page: declinedLoanPage,
      member_id: user?.member_id,
    });

  const { data: disbursedLoans, isLoading: isLoadingDisburseLoans } =
    useGetDisbursedMemberLoanListQuery({
      page: disbursedLoanPage,
      member_id: user?.member_id,
    });

  const useTab = [
    {
      name: "Pending loan",
      component: (
        <LoanStatusTable
          name="pending loan"
          TableData={pendingLoans?.data?.company_loan_requests || []}
          pagination={pendingLoans?.data?.pagination}
          loading={isLoadingPendingLoans}
          setPageNumber={setPendingLoanPage}
        />
      ),
    },
    {
      name: "Approved loan",
      component: (
        <LoanStatusTable
          name="approved loan"
          TableData={approvedLoans?.data?.company_loan_requests || []}
          pagination={approvedLoans?.data?.pagination}
          loading={isLoadingApproveLoans}
          setPageNumber={setApprovedLoanPage}
        />
      ),
    },
    {
      name: "Declined loan",
      component: (
        <LoanStatusTable
          name="declined loan"
          TableData={declinedLoans?.data?.company_loan_requests || []}
          pagination={declinedLoans?.data?.pagination}
          loading={isLoadingDeclineLoans}
          setPageNumber={setDeclinedLoanPage}
        />
      ),
    },
    {
      name: "Disbursed loan",
      component: (
        <LoanStatusTable
          name="disbursed loan"
          TableData={disbursedLoans?.data?.company_loan_requests || []}
          pagination={disbursedLoans?.data?.pagination}
          loading={isLoadingDisburseLoans}
          setPageNumber={setDisbursedLoanPage}
        />
      ),
    },
  ];
  return (
    <PageWrapper title={"Loan Status"} back>
      <section className="mt-6 space-y-6 ">
        <div className="md:w-1/2">
          <LoanManagementCard />
        </div>

        <section className="space-y-4">
          <h4 className="font-bold text-base text-[#45464E] pt-[3rem] ">
            Loan Application Status
          </h4>
          <CardComp cardClassName={"border-0 pt-[2rem] px-3"}>
            <div>
              <TabsComp
                TabArray={useTab}
                input="name"
                justifyContent={"justify-content-start"}
                tabClassName="px-0"
                TabArrayClassName="px-4"
              />
            </div>
          </CardComp>
        </section>
      </section>
    </PageWrapper>
  );
}
