import AdminLayout from "../../../Layout/AdminLayout";
import { PageTitleWithNav } from "../modules/PageHeaders";
import EMinuteEditor from "./modules/EMinuteEditor";
import { useNavigate } from "react-router-dom";
import useEminuteAction from "./useAdminEminuteActions";
import { useState } from "react";

export const NewEminute = () => {
  const navigate = useNavigate();
  const initialFormValues = {
    title: "",
    date: "",
    content: "",
  };
  const [content, setContent] = useState(initialFormValues.content);
  const { createEMinute } = useEminuteAction();
  const handleSubmit = async (formikValues, { resetForm }) => {
    const reqBody = {
      ...formikValues,
      content: content,
      status: "UNPUBLISHED",
      receiver: "ALL",
    };
    createEMinute(reqBody, () => {
      resetForm();
      setContent("");
    });
  };
  return (
    <section className="mx-2 mx-lg-4 min-vh-100">
      <div className="py-1"></div>
      <PageTitleWithNav
        title={"New E-minutes"}
        action={() => {
          navigate(-1);
        }}
      />
      <div>
        <EMinuteEditor
          handleContentChange={setContent}
          initialValues={initialFormValues}
          handleSubmit={handleSubmit}
        />
      </div>
    </section>
  );
};
