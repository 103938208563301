import { useState } from "react";

const BorderedCard = ({ title, content }) => {
  const [show, setShow] = useState(true);
  return (
    <article
      style={{
        boxShadow:
          "-2px -2px 10px rgba(0, 0, 0, 0.05), 2px 2px 10px rgba(0, 0, 0, 0.05)",
        borderLeft: "8px solid #31778C",
        borderRadius: "10px",
        display: show ? "block" : "none",
      }}
      className="bg-white p-4"
    >
      <div className="d-flex justify-content-between align-items-start mb-2">
        <h5 className="mb-0 fw-bold">{title}</h5>
        <img
          src="/Icon/cancel.svg"
          role="button"
          alt="close"
          onClick={() => setShow(false)}
        />
      </div>
      <p className="mb-0">{content}</p>
    </article>
  );
};

export default BorderedCard;
