import {
  ButtonComp,
  TextInputComp,
  CardComp,
  FormikDatePicker,
  FormikTimePicker,
  FormikSelectComp,
} from "../../../../components";
import { Formik, Form } from "formik";
import { useGetEventAttendeesQuery } from "../../../../store/selectableSlice";
export default function EventForm({
  onNext = () => {},
  btnText,
  initialFormValues,
}) {
  const { data: eventAttendees = {}, error } = useGetEventAttendeesQuery();
  const attendees = Object.entries(eventAttendees).map(([key, value]) => ({
    label: key,
    value: value,
  }));
  if (error) {
    console.log(error);
  }
  return (
    <>
      <Formik initialValues={initialFormValues} onSubmit={onNext}>
        <Form>
          <CardComp
            cardClassName={"pt-4"}
            cardBodyClassName={"py-0 row gx-5 gy-2 justify-content-between"}
          >
            <div className="col-12 col-lg-5">
              <TextInputComp
                LabelText={"Event name"}
                borderColor="#A1A0A0"
                LabelClassName={"fw-bold"}
                placeholder={"Enter event name"}
                name="name"
              />
            </div>
            <div className="col-12 col-lg-5">
              <TextInputComp
                LabelText={"Event description"}
                borderColor="#A1A0A0"
                LabelClassName={"fw-bold"}
                placeholder={"Enter description"}
                name="description"
              />
            </div>
            <div className="col-12 col-lg-5">
              <FormikDatePicker
                labelClassName={"fw-bold"}
                labelText={"Start date"}
                fieldName={"start_date"}
              />
            </div>
            <div className="col-12 col-lg-5">
              <FormikDatePicker
                labelClassName={"fw-bold"}
                labelText={"End date"}
                fieldName={"end_date"}
              />
            </div>
            <div className="col-12 col-lg-5">
              <FormikSelectComp
                selectText={"Select who's attending this event"}
                labelClassName={"fw-bold"}
                options={attendees}
                labelText={"Event attendees"}
                fieldName={"attendees"}
              />
            </div>
            <div className="col-12 col-lg-5">
              <TextInputComp
                LabelText={"Event reminder count"}
                borderColor="#A1A0A0"
                LabelClassName={"fw-bold"}
                placeholder={"How many times do you want to be reminded?"}
                name="reminder_count"
              />
            </div>

            <div className="col-12 col-lg-5">
              <TextInputComp
                LabelText={"Venue address"}
                borderColor="#A1A0A0"
                LabelClassName={"fw-bold"}
                placeholder={"Select who’s attending this event"}
                name="address"
              />
            </div>
            <div className="col-12 col-lg-5">
              <FormikTimePicker
                labelText={"Event start time"}
                fieldName={"start_time"}
                labelClassName={"fw-bold"}
              />
            </div>
            <div className="col-12 col-lg-5">
              <TextInputComp
                LabelText={"Duration (Hours)"}
                borderColor="#A1A0A0"
                LabelClassName={"fw-bold"}
                placeholder={"How many hours is this event?"}
                name="duration"
              />
            </div>
          </CardComp>
          <div className="">
            <ButtonComp
              btnText={btnText || "Continue"}
              onClick={() => {}}
              btnClassName={"bg-primary1 py-2 px-4 text-white border-0 rounded"}
              type={"submit"}
            />
          </div>
        </Form>
      </Formik>
    </>
  );
}
