import React, { useState } from "react";
import { ButtonComp, ModalCompAdvanceUpdate } from "../../../components";
import { formatMoney } from "../../../utils";
import { useNavigate } from "react-router-dom";
import LoanCalculatorModal from "./LoanCalculator";
import { useUser } from "../../../hooks";

const LoanManagementCard = () => {
  const user = useUser();
  const navigate = useNavigate();
  const [modalPage, setModalPage] = useState("");

  const useModal = [
    {
      name: "loan_calculator",
      component: <LoanCalculatorModal setModalPage={setModalPage} />,
    },
  ];
  return (
    <>
      <ModalCompAdvanceUpdate
        pageName={modalPage}
        arrayComp={useModal}
        handleClose={() => setModalPage()}
        centered
        size={"md"}
      />
      <div className="bg-[#000000] p-4 rounded-4  w-full space-y-6">
        <div className="bg-white space-x-4 rounded-[0.625rem] shadow3 flex items-center pointer px-4 py-3  ">
          <img
            src="/MemberSide/Icons/dashboard2.svg"
            alt="img"
            className="w-[2.5rem] h-[2.5rem]"
          />

          <div className="w-full space-y- ">
            <h4 className="text-[#000] text-[1.375rem]  font-medium">
              {user?.loan_taken === 0
                ? "₦0.00"
                : formatMoney(user?.loan_taken, false, "₦")}
            </h4>
            <p
              className={` text-sm font-normal opacity-40 pb-2 pt-1 text-[#000]`}
            >
              Loan taken
            </p>
            <div
              className="progress w-full"
              style={{ height: "6px", background: "#E0A8AE" }}
            >
              <div
                className="progress-bar "
                role="progressbar"
                aria-label="Success example"
                style={{
                  width: `${user?.paid_percent}`,
                  background: "#8B313A",
                }}
                aria-valuenow="75"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
        </div>
        <div className="flex space-x-5 ">
          <ButtonComp
            btnText={"Apply for loan"}
            onClick={() => navigate("/member/loan_management/application")}
            btnClassName=" bg-primary1 rounded py-2  w-full text-white font-semibold text-base"
          />
          <ButtonComp
            btnText={"Loan calculator"}
            onClick={() => setModalPage("loan_calculator")}
            btnClassName=" bg-primary1 rounded py-2 w-full text-white font-semibold text-base"
          />
        </div>
      </div>
    </>
  );
};

export default LoanManagementCard;
