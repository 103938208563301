import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import toastNotify from "../modules/toastNotify";
import {
  useCreateGroupMutation,
  useUpdateGroupNameMutation,
  useAddMembersToGroupMutation,
} from "../../../store/admin/communicationSlice";

export const useCommunicationActions = () => {
  const navigate = useNavigate();
  const [createGroup] = useCreateGroupMutation();
  const [updateGroupName] = useUpdateGroupNameMutation();
  const [joinMembersToGroup] = useAddMembersToGroupMutation();

  const addGroup = async (groupName) => {
    const toastId = toast.loading("Adding new group");
    const { data, error } = await createGroup({ name: groupName });
    toastNotify(error, data, toastId, navigate);
  };

  const editGroup = async (body) => {
    const toastId = toast.loading("Updating group");
    const { data, error } = await updateGroupName(body);
    toastNotify(error, data, toastId, navigate);
  };

  const addMembersToGroup = async (group_id, members) => {
    const toastId = toast.loading("Updating group members");
    const { data, error } = await joinMembersToGroup({
      group_id: group_id,
      members: members,
    });
    toastNotify(error, data, toastId, navigate);
  };

  return {
    addGroup,
    editGroup,
    addMembersToGroup,
  };
};
