import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ButtonComp, PageSwitch } from "../../../components";
import AdminLayout from "../../../Layout/AdminLayout";
import { ContributionHistoryList } from "./modules/ContributionHistoryList";
import { useArchiveContributionCategoryMutation } from "../../../store/admin/contribution";
import { toast } from "react-hot-toast";

export default function Contribution_History() {
  const [categoryId, setCategoryId] = useState("");
  const [pageNumber] = useState("All Staffs");
  const navigate = useNavigate();
  const [modalToggle, setModalToggle] = useState(false);
  const [modalPage] = useState(0);
  const [archiveCategory, { isLoading: isArchiving }] =
    useArchiveContributionCategoryMutation();

  const handleArchiveContribution = async (categoryId) => {
    const response = await archiveCategory(categoryId);
    const { message, status } = response?.data;
    if (!status) {
      toast.error(message);
      setModalToggle(false);
    } else {
      toast.success(message);
      setModalToggle(false);
    }
  };
  const useModal = [
    {
      name: "Suspend Modal",
      component: (
        <div>
          <div className="py-3"></div>
          <div className="text-center">
            <h4 className="text-black text-center fw-bold">Confirm</h4>
            <p className="mb-5 text-black fw-2">
              Are you sure you want to move this contribution to archive
            </p>
            <div className="row justify-content-around">
              <div className="col-5">
                <ButtonComp
                  btnText={"No"}
                  btnClassName={" text-1 btn w-100 py-3"}
                  BorderColor={"#8B313A"}
                  onClick={() => setModalToggle(false)}
                />
              </div>
              <div className="col-5">
                <ButtonComp
                  loading={isArchiving}
                  btnText={"Yes"}
                  ButtonInlineStyle={{ background: "#FF4343" }}
                  btnClassName={"border-0 text-white  w-100 py-3"}
                  onClick={() => handleArchiveContribution(categoryId)}
                />
              </div>
            </div>
          </div>
          <div className="py-3"></div>
        </div>
      ),
    },
  ];

  const TableDropDown = (row) => {
    const dropdown = [
      {
        name: "Archive contribution",
        action: (data) => {
          setCategoryId(data?.id);
          setModalToggle(true);
        },
      },
      {
        name: "Contribution breakdown",
        action: (data) => {
          navigate(`/super_admin/contribution/contribution_history/${data.id}`);
        },
      },
    ];
    return dropdown;
  };

  const usePages = [
    {
      name: "All Staffs",
      component: (
        <ContributionHistoryList
          TableDropDown={TableDropDown}
          modalPage={modalPage}
          modalToggle={modalToggle}
          useModal={useModal}
          setModalToggle={setModalToggle}
          title="Contribution History"
        />
      ),
    },
  ];

  return <PageSwitch pageArray={usePages} pageName={pageNumber} />;
}
