// CustomSelect.js
import Select from "react-select";

const CustomSelect = (props) => {
  const { options, onChange, label, labelClass, ...customProps } = props;
  return (
    <div className="my-[10px]">
      <p className={`text-[#1E1E1E] font-medium mb-1  ${labelClass}`}>
        {label}
      </p>
      <Select
        options={options}
        onChange={onChange}
        {...customProps}
        // value={options[1]}
      />
    </div>
  );
};

export default CustomSelect;
