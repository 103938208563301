import {
  ButtonComp,
  SelectComp2,
  TextInputComp,
  MoneyInputComp,
  CardComp,
} from "../../../../components";
import { Formik, Form } from "formik";
import { useEditRegFeeMutation } from "../../../../store/admin/settings/regFeeSlice";
import { useGetFeeCategoriesQuery } from "../../../../store/selectableSlice";
import { toast } from "react-hot-toast";
import { useState } from "react";

export default function EditNewRegistrationFee({ onNext, initialValues }) {
  const { data: feeCategories = [] } = useGetFeeCategoriesQuery();
  const [editRegFee] = useEditRegFeeMutation();
  const [newAmount, setNewAmount] = useState(initialValues?.amount);
  const [fee_category_id, setCatId] = useState(initialValues?.fee_category_id);

  const updateRegFee = async (formikValues) => {
    const reqBody = {
      ...formikValues,
      amount: newAmount,
      fee_category_id: fee_category_id,
    };
    const toastId = toast.loading("Editing");
    const { data, error } = await editRegFee(reqBody);
    if (error) {
      return toast.error(error?.data.message || "Error", { id: toastId });
    }
    if (data?.status === false) {
      return toast.error(data.message || "Error", { id: toastId });
    }
    return toast.success(data.message || "Success", { id: toastId });
  };
  return (
    <>
      <Formik initialValues={initialValues} onSubmit={updateRegFee}>
        <Form>
          <div>
            <CardComp
              cardBodyClassName={"row py-0 gx-5 gy-3 justify-content-between"}
              cardClassName={"pt-4"}
            >
              <div className="col-12 col-lg-5">
                <div className="mb-3">
                  <TextInputComp
                    LabelText={"Fee name"}
                    LabelClassName={"fw-bold"}
                    placeholder={"Input fee name"}
                    name="name"
                  />
                </div>

                <div className="mb-3">
                  <SelectComp2
                    LabelClassName={"fw-bold"}
                    labelText={"Fee status"}
                    placeholder={initialValues?.fee_category_name}
                    arrayComp={[
                      ...feeCategories.map((fee) => ({
                        value: fee.id,
                        label: fee.name,
                      })),
                    ]}
                    setSelectedOption={({ value }) => {
                      setCatId(value);
                    }}
                    selectedOption={initialValues?.fee_category_id}
                  />
                </div>
              </div>
              <div className="col-12 col-lg-5">
                <div className="mb-3">
                  <MoneyInputComp
                    LabelText={"Fee amount"}
                    LabelClassName={"fw-bold"}
                    placeholder={"Ifesowapo Cooperatives"}
                    defaultValue={initialValues.amount}
                    handleChange={(value) => setNewAmount(value)}
                  />
                </div>
              </div>
            </CardComp>

            <div className="col-12 py-2"></div>
            <div className="">
              <div className="">
                <ButtonComp
                  btnText={"Update fee"}
                  type={"submit"}
                  btnClassName={
                    "bg-primary1  py-2 px-4 rounded text-white border-0"
                  }
                />
              </div>
            </div>
          </div>
        </Form>
      </Formik>
    </>
  );
}
