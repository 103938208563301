import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";
import { routeList } from "./routeList";
import { AnimatePresence } from "framer-motion";
import { AuthGuard } from "../utils";
// import AdminLayout from "../Layout/AdminLayout";
import Dashboard from "../Layout/Dashboard";
import { Login } from "../pages";
import { ForgetPassword } from "../pages/ForgetPassword";
import { ResetPassword } from "../pages/ResetPassword";

export const AppRouter = () => {
  return (
    <>
      <AnimatePresence exitBeforeEnter>
        <AuthGuard>
          <Routes>
            <Route path="/" element={<Navigate to="/login" replace />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forget_password" element={<ForgetPassword />} />
            <Route path="/reset_password" element={<ResetPassword />} />
            <Route
              path="/settings/"
              element={<Navigate to="/settings/general" replace />}
            />

            <Route path="" element={<Dashboard />}>
              {routeList?.map((route, index) => {
                const Comp = route.component;
                if (route.children) {
                  return route.protected ? (
                    <Route
                      key={index}
                      path={route.path}
                      element={<ProtectedRoute />}
                    >
                      {route.children.map((childRoute, index) => {
                        const ChildComp = childRoute.component;
                        return (
                          <Route
                            key={childRoute.path + index}
                            index={childRoute.index}
                            path={childRoute.path}
                            element={<ChildComp />}
                          />
                        );
                      })}
                    </Route>
                  ) : (
                    <Route key={index} path={route.path} element={<Comp />}>
                      {route.children.map((childRoute, index) => {
                        const ChildComp = childRoute.component;
                        return (
                          <Route
                            key={childRoute.path + index}
                            index={childRoute.index}
                            path={childRoute.path}
                            element={<ChildComp />}
                          />
                        );
                      })}
                    </Route>
                  );
                } else {
                  return (
                    <Route
                      key={index}
                      exact={route.exact}
                      path={route.path}
                      element={<Comp />}
                    />
                  );
                }
              })}
            </Route>
          </Routes>
        </AuthGuard>
      </AnimatePresence>
    </>
  );
};
