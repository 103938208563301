import { CardComp } from "../../../components";
import EditNewRegistrationFee from "./modules/EditNewRegistrationFee";
import { useNavigate, useParams } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import { useGetSingleRegFeeQuery } from "../../../store/admin/settings/regFeeSlice";
import { useMemo } from "react";
import Loader from "../../../components/Ui/Loader";

export default function SettingEditNewRegistrationFee() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data = {}, error, isLoading } = useGetSingleRegFeeQuery(id);
  if (error & (error?.status === 401)) {
    navigate("/login");
  }
  const initialValues = useMemo(() => {
    return {
      id: data?.id,
      name: data?.name || "",
      fee_category_id: data?.fee_category_id || "",
      fee_category_name: data?.fee_category?.name || "",
      amount: data?.amount || "",
    };
  }, [data]);

  return (
    <section className="px-2 px-lg-4 min-vh-100">
      <div className="py-1"></div>
      <CardComp
        cardBodyClassName={"py-0"}
        cardClassName={"border-0 rounded-10 px-lg-4 mb-3 pt-4"}
      >
        <div className="d-flex ">
          <BsArrowLeft
            size={30}
            onClick={() => navigate(-1)}
            className="me-3 pointer"
          />
          <h4 className="fw-bold mb-0 text-black1">
            Edit{" "}
            <span style={{ color: "var(--primary)" }} className="">
              Form Fee
            </span>
          </h4>
        </div>
      </CardComp>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <EditNewRegistrationFee initialValues={initialValues} />
        </>
      )}
    </section>
  );
}
