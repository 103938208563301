import { ButtonComp } from "../../../../components";
import FallbackImage from "../../../../components/Common/ImageWrapper";

export default function ListMessage({
  message,
  onClickEdit,
  btnText = "Make default",
}) {
  return (
    <div className="bg-white px-3 p-3 rounded-4 mb-4">
      <div className="d-flex gap-2 justify-content-between">
        <div className=" h50 opacity-50 text-start mb-3">{message}</div>
        <div className="" onClick={onClickEdit}>
          <FallbackImage src="/Icon/edit1.svg" className="mb-3" />
        </div>
      </div>
      <div className="d-flex">
        <ButtonComp
          btnText={btnText}
          btnClassName={"bg-primary1 text-white rounded px-4 py-2 me-5"}
        />
        <FallbackImage src="/Icon/delete.svg" className="mb-0" />
      </div>
    </div>
  );
}
