import React from 'react'
import styled from 'styled-components'

const SrcollStyle = styled.div`
overflow-y: scroll;
    height: 84vh;
    scrollbar-width: none;

}
::-webkit-scrollbar {
    width: 2px;
  }
  /* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
`
export const ScrollComp =({children})=> {
  return (
    <SrcollStyle>{children}</SrcollStyle>
  )
}
