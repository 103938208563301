import styled from "styled-components";
import { PageTitle } from "../modules/PageHeaders";
import BorderedCard from "./modules/BorderedCard";
import {
  TableCompData,
  ModalCompAdvanceUpdate,
  ButtonComp,
} from "../../../components";
import { CommunicationGroupListHeader } from "../../../utils/TableHeader";
import EmptyTable from "../../../components/Common/EmptyTable";
import { useState, useMemo } from "react";
import GroupForm from "./modules/GroupForm";
import { useCommunicationActions } from "./useCommunicationActions";
import { useGetGroupsNamesQuery } from "../../../store/admin/communicationSlice";
import { useNavigate, useLocation } from "react-router-dom";
import TablePagination from "../../../components/Table/Pagination";

export const GroupManagement = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [modalName, setModalName] = useState();
  const [initialGroupName, setInitialGroupName] = useState("");
  const [groupId, setGroupId] = useState(null);
  const { addGroup, editGroup } = useCommunicationActions();
  const [pageNumber, setPageNumber] = useState(1);
  const { data: groupsData = {}, isLoading } =
    useGetGroupsNamesQuery(pageNumber);
  const { groups, pagination } = useMemo(() => {
    const { pagination = {}, communication_groups = [] } = groupsData;
    const perPage = pagination.perPage || 5;
    const items = communication_groups.map((item, index) => ({
      ...item,
      serialNumber: index + 1 + (pageNumber - 1) * perPage,
      totalMembers: item?.members.length,
    }));
    return {
      groups: items,
      pagination: pagination,
    };
  }, [groupsData, pageNumber]);
  const createGroup = (groupName) => {
    setModalName();
    addGroup(groupName);
  };
  const updateGroupName = (newGroupName) => {
    setModalName();
    editGroup({ id: groupId, name: newGroupName });
  };
  const modalItems = [
    {
      name: "Create Group",
      component: (
        <GroupForm
          onBack={() => setModalName()}
          title={"Create New Group"}
          onProceed={createGroup}
        />
      ),
    },
    {
      name: "Edit Group",
      component: (
        <GroupForm
          onBack={() => setModalName()}
          title={"Edit Group Name"}
          onProceed={updateGroupName}
          initialValue={initialGroupName}
          submitText="Update group name"
        />
      ),
    },
  ];

  const TableDropDown = [
    {
      name: "View",
      action: (data) => {
        navigate(`${location.pathname}/${data?.id}?name=${data?.name}`);
      },
    },
    {
      name: "Edit",
      action: (data) => {
        setModalName("Edit Group");
        setGroupId(data?.id);
        setInitialGroupName(data?.name);
      },
    },
  ];

  return (
    <>
      <Wrapper className="mx-2 mx-lg-4">
        <PageTitle title={"Group Management"} />
        <BorderedCard
          title={"Duis aute irure dolor"}
          content={
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          }
        />
        <div className="py-2"></div>
        <div className="d-flex align-items-center justify-content-between gap-p flex-wrap mb-3 mt-4">
          <h5 className="fw-bold">Group List</h5>
          {groups.length > 0 && (
            <ButtonComp
              btnClassName={"bg-primary1 px-3 py-2 text-white rounded"}
              btnText={"Create a new group"}
              onClick={() => setModalName("Create Group")}
            />
          )}
        </div>
        <TableCompData
          columns={CommunicationGroupListHeader(TableDropDown)}
          data={groups}
          marginBottom={"5rem"}
          message={
            isLoading ? (
              <p className="py-4">Loading...</p>
            ) : (
              <EmptyTable
                Message={
                  "Group lists is empty, your created groups will appear here"
                }
                btnText={"Create Group"}
                onClick={() => setModalName("Create Group")}
              />
            )
          }
        />
        {groups.length > 0 && (
          <TablePagination {...pagination} handlePageChange={setPageNumber} />
        )}
        <ModalCompAdvanceUpdate
          centered={true}
          arrayComp={modalItems}
          pageName={modalName}
        />
      </Wrapper>
    </>
  );
};

const Wrapper = styled.section`
  .modal-content {
    border-radius: 30px;
  }
`;

export default GroupManagement;
