import { API } from "../api";
const BASE_URL = "/cooperatives/eminutes";

const eminutesExtendedApi = API.injectEndpoints({
  endpoints: (builder) => ({
    getEminutes: builder.query({
      query: (page = 1) => `${BASE_URL}?guard=staff&page=${page}`,
      transformResponse: (responseData) => {
        const { eminutes = [], pagination = {} } = responseData.data;
        return {
          eminutes: eminutes,
          pagination,
        };
      },
      providesTags: (result = {}) => {
        if (!result?.eminutes) return [{ type: "AdminEminute", id: "LIST" }];
        return [
          { type: "AdminEminute", id: "LIST" },
          ...result?.eminutes?.map(({ id }) => ({
            type: "AdminEminute",
            id: id,
          })),
        ];
      },
    }),
    getSingleEminute: builder.query({
      query: (id) => `${BASE_URL}/${id}?guard=staff`,
      transformResponse: (responseData) => responseData.data,
      providesTags: (result, error, arg) => [{ type: "AdminEminute", id: arg }],
    }),
    addEminute: builder.mutation({
      query: (body) => ({
        url: `${BASE_URL}/add?guard=staff`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: [{ type: "AdminEminute", id: "LIST" }],
    }),
    editEminute: builder.mutation({
      query: (body) => ({
        url: `${BASE_URL}/${body.id}/edit?guard=staff`,
        method: "PATCH",
        body: body,
      }),
      invalidatesTags: (result, err, arg) => [
        { type: "AdminEminute", id: arg.id },
      ],
    }),
    toggleEminuteStatus: builder.mutation({
      query: (id) => ({
        url: `${BASE_URL}/${id}/toggle-status?guard=staff`,
        method: "PUT",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "AdminEminute", id: arg },
      ],
    }),
    deleteEminute: builder.mutation({
      query: (id) => ({
        url: `${BASE_URL}/${id}/delete?guard=staff`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "AdminEminute", id: arg },
      ],
    }),
  }),
});

export const {
  useGetEminutesQuery,
  useAddEminuteMutation,
  useToggleEminuteStatusMutation,
  useGetSingleEminuteQuery,
  useEditEminuteMutation,
  useDeleteEminuteMutation,
} = eminutesExtendedApi;
