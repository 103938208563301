/* eslint-disable no-restricted-globals */
import { useParams, useNavigate, useLocation } from "react-router-dom";
import AdminLayout from "../../../Layout/AdminLayout";
import CardInfo from "../Home/components/CardInfo";
import { ViewMemberCards } from "../../../utils";
import { useViewMemberQuery } from "../../../store/admin/memberManagement/memberSlice";
import MemberAndStaffProfile from "../modules/MemberAndStaffProfile";
import MemberAndStaffProfileFigures from "../modules/MemberAndStaffProfileFigures";
import Loader from "../../../components/Ui/Loader";
import useRtkFetchError from "../../../components/Common/useRtkFetchError";

export const ViewSingleMember = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { id } = params;
  const { data: memberDetails = {}, error, isLoading } = useViewMemberQuery(id);

  useRtkFetchError({ error: error });

  return (
    <section className="mx-2 mx-lg-4">
      {isLoading ? (
        <div className="bg-white">
          <Loader />
        </div>
      ) : (
        <>
          <MemberAndStaffProfile
            fullname={`${memberDetails?.first_name ?? ""} ${
              memberDetails?.last_name ?? ""
            }`}
            memberId={memberDetails?.member_no}
            phone={memberDetails?.phone}
            email={memberDetails?.email}
            homeStreet={memberDetails?.address}
            homeState={memberDetails?.city?.name}
            photo={memberDetails?.photo}
            memberDetails={memberDetails}
          />
          {/* <MemberAndStaffProfileFigures
            onClickButton={() => {
              navigate(`${location?.pathname}/history`);
            }}
            btnText={"View history"}
            downDetails={
              <div className="row g-5">
                {ViewMemberCards()?.map((item, i) => (
                  <div className="col-lg-4" key={i}>
                    {" "}
                    <CardInfo
                      total={item?.total}
                      name={item?.name}
                      key={i}
                      image={item?.image}
                      bgColor={item?.bgColor}
                      color={item?.color}
                      CardClassName={"shadow"}
                      percentage={"50%"}
                    />
                  </div>
                ))}
              </div>
            }
          /> */}
        </>
      )}
    </section>
  );
};
