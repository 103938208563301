import {
  TextInputComp,
  FormikDatePicker,
  FormikSelectComp,
} from "../../../../components";
import {
  useGetTitlesQuery,
  useGetMaritalStatusesQuery,
  useGetGendersQuery,
  useGetRelationshipStatusesQuery,
} from "../../../../store/selectableSlice";

export default function PersonalDetails({ updateImgFile, errorImg, imgFile }) {
  const { data: genders = [] } = useGetGendersQuery();
  const { data: titles = [] } = useGetTitlesQuery();
  const { data: maritalStatuses = [] } = useGetMaritalStatusesQuery();
  const { data: relationshipStatuses = [] } = useGetRelationshipStatusesQuery();
  return (
    <div>
      <div className="row gx-5 gy-3 justify-content-between">
        <div className="col-12 col-lg-5">
          <FormikSelectComp
            labelClassName={"fw-bold"}
            fieldName={"title_id"}
            labelText={"Title"}
            options={titles.map((title) => ({
              value: title.id,
              label: title.name,
            }))}
          />
        </div>
        <div className="col-12 col-lg-5">
          <TextInputComp
            LabelText={"Member ID"}
            LabelClassName={"fw-bold"}
            placeholder={"4929482"}
            name="member_no"
          />
        </div>
        <div className="col-12 col-lg-5">
          <TextInputComp
            LabelText={"First name"}
            LabelClassName={"fw-bold"}
            placeholder={"First name"}
            name="first_name"
          />
        </div>
        <div className="col-12 col-lg-5">
          <TextInputComp
            LabelText={"Middle name"}
            LabelClassName={"fw-bold"}
            placeholder={"Middle name"}
            name="other_name"
          />
        </div>
        <div className="col-12 col-lg-5">
          <TextInputComp
            LabelText={"Last name"}
            LabelClassName={"fw-bold"}
            placeholder={"Last name"}
            name="last_name"
          />
        </div>
        <div className="col-12 col-lg-5">
          <TextInputComp
            LabelText={"Email"}
            LabelClassName={"fw-bold"}
            placeholder={"email@gmail.com"}
            name="email"
          />
        </div>
        <div className="col-12 col-lg-5">
          <TextInputComp
            LabelText={"Address"}
            LabelClassName={"fw-bold"}
            placeholder={"Address"}
            name="address"
          />
        </div>
        <div className="col-12 col-lg-5">
          <TextInputComp
            LabelText={"Phone Number"}
            LabelClassName={"fw-bold"}
            placeholder={"09012345678"}
            name="phone"
          />
        </div>
        <div className="col-12 col-lg-5">
          <FormikSelectComp
            labelClassName={"fw-bold"}
            fieldName={"gender_id"}
            selectText="Select Gender"
            labelText={"Gender"}
            options={genders.map((gender) => ({
              value: gender.id,
              label: gender.name,
            }))}
          />
        </div>
        <div className="col-12 col-lg-5">
          <FormikDatePicker
            fieldName={"dob"}
            labelText={"Date of birth"}
            labelClassName={"fw-bold"}
          />
        </div>
        <div className="col-12 col-lg-5">
          <TextInputComp
            LabelText={"Next of kin name"}
            LabelClassName={"fw-bold"}
            placeholder={"Next of kin"}
            name="next_of_kin_name"
          />
        </div>
        <div className="col-12 col-lg-5">
          <TextInputComp
            LabelText={"Next of kin phone"}
            LabelClassName={"fw-bold"}
            placeholder={"Next of kin"}
            name="next_of_kin_phone"
          />
        </div>
        <div className="col-12 col-lg-5">
          <FormikSelectComp
            labelClassName={"fw-bold"}
            fieldName={"relationship_status_id"}
            labelText={"Next of kin relationship"}
            options={relationshipStatuses.map((relationship) => ({
              value: relationship.id,
              label: relationship.name,
            }))}
          />
        </div>
        <div className="col-12 col-lg-5">
          <TextInputComp
            LabelText={"Occupation"}
            LabelClassName={"fw-bold"}
            placeholder={"Civil servant"}
            name="occupation"
          />
        </div>
        <div className="col-12 col-lg-5">
          <FormikSelectComp
            labelClassName={"fw-bold"}
            fieldName={"marital_id"}
            labelText={"Marital Status"}
            options={maritalStatuses.map((marital) => ({
              value: marital.id,
              label: marital.name,
            }))}
          />
        </div>
        <div className="col-12 col-lg-5">
          <label
            className="fw-2"
            htmlFor="imgFile"
            style={{ display: "block" }}
          >
            Profile image
            <img
              src="/FileUploader/upload.svg"
              className="d-block mt-2"
              width={"80"}
              height={"80"}
            />
            <input
              onChange={updateImgFile}
              name="file"
              type="file"
              accept="image/png, image/jpeg, image/jpg"
              style={{ display: "none" }}
              id="imgFile"
            />
          </label>{" "}
          {imgFile && <small className="mt-2">{imgFile?.name}</small>}
          {errorImg && <small className="text-danger mt-2">{errorImg}</small>}
        </div>
      </div>
    </div>
  );
}
