import styled from "styled-components";

const Profile = ({ barProgress, barPercent, memberData }) => {
  return (
    <Wrapper barProgress={barProgress} className="">
      <div className="row gx-0 gy-0 justify-content-between">
        <div className="bg-white p-4 d-flex gap-2 col-12 col-lg-6 crd">
          <img className="profile-img" alt={""} src={memberData.photo} />
          <div>
            <h4 className="mb-0 fw-bold mb-0 text-black1">
              {memberData.first_name} {memberData.last_name}
            </h4>
            <p className="opacity-40">Member ID: {memberData.member_no}</p>
            <p className="opacity-40 mb-0">
              {memberData.phone || ""} | {memberData.email || ""}
            </p>
          </div>
        </div>
        <div className="bg-white p-4 col-12 col-lg-5 d-flex gap-2 crd">
          <div>
            <div className="circular-progress">
              <span className="progress-value">
                {Math.ceil(barPercent) || 0}%
              </span>
            </div>
          </div>
          <div>
            <h4 className="mb-2 fw-bold mb-0 text-black1">Punctuality Stats</h4>
            <p className="mb-0">
              Lorem ipsum dolor sit amet consectetur. Erat et est gravida
              convallis molestie.
            </p>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 1.5rem;
  .profile-img {
    max-width: 100px;
  }
  .crd {
    background: #ffffff;
    border-radius: 10px;
  }
  .circular-progress {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-image: ${(props) =>
      props?.barProgress
        ? `conic-gradient(#ff4343 ${props.barProgress || 0}deg, #e0a8ae 0deg)`
        : `conic-gradient(#ff4343 0deg, #e0a8ae 0deg)`};
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .circular-progress::before {
    content: "";
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #ffffff;
  }
  .progress-value {
    position: relative;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: #ff4343;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }
`;

export default Profile;
