import React from "react";
import { BsArrowLeft } from "react-icons/bs";
import { RiSearch2Line } from "react-icons/ri";
import { ButtonComp } from "../Ui";

export default function FilterFind({
  title,
  back,
  backBtn,
  rightSide = "search",
}) {

  return (
    <div className="mb-3">
      <div className="card border-0 px-lg-3">
        <div className="card-body">
          <div className="row justify-content-between align-items-center mb-0 flex-wrap">
            <div className="flex items-center col-lg-6 ">
              {back && (
                <BsArrowLeft
                  size={30}
                  onClick={() => backBtn()}
                  className="me-3 pointer"
                />
              )}
              <h4 className="fw-bolder mb-0 text-black1">{title}</h4>
            </div>
            {rightSide && (
              <div className="col-lg-6">
                {rightSide === "search" ? (
                  <div className="input-group  search me-3">
                    <span className="input-group-text bg-black2 px-4 py-0 my-0">
                      <RiSearch2Line size={25} color={"#fff"} />
                    </span>
                    <input
                      type="text"
                      className="form-control py-3 bg-gary3"
                      placeholder="Search your query here"
                    />
                  </div>
                ) : (
                  <ButtonComp
                    btnText={"Download format"}
                    btnClassName={
                      "px-4 py-3 bg-primary1 rounded text-white h6 border-0"
                    }
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
