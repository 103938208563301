import React from "react";
import styledComponents from "styled-components";
import ForgetPasswordComp from "../components/Modules/ForgetPassword";

export const ForgetPassword = () => {
  return (
    <Style>
      <ForgetPasswordComp />
    </Style>
  );
};

const Style = styledComponents.div`
// height:100vh;

img{
    //  width:100%;
     filter: brightness(0.5);
}
.carousel-caption{
  text-align:left;
}
.carousel-indicators{
display:none;
}


h6.background {
  position: relative;
  z-index: 1;
  
  &:before {
      border-top: 1px solid #6B6B6B;
      content:"";
      margin: 0 auto; /* this centers the line to the full width specified */
      position: absolute; /* positioning must be absolute here, and relative positioning must be applied to the parent */
      top: 50%; left: 0; right: 0; bottom: 0;
      width: 95%;
      z-index: -1;
  }

  span { 
      /* to hide the lines from behind the text, you have to set the background color the same as the container */ 
      background: #fff; 
      padding: 0 15px; 
  }
}
@media only screen and (max-width: 991px) {
  .carousel-inner{
    height:50vh;
  }
}
@media only screen and (max-width: 491px) {
  .carousel-inner{
    height:30vh;
  }
  p{
    font-size:14px;
  }
}
`;
