import { API } from "../api";
const BASE_URL = "/cooperatives/attendance";

const attendanceExtendedApi = API.injectEndpoints({
  endpoints: (builder) => ({
    markAttendance: builder.mutation({
      query: (body) => ({
        url: `${BASE_URL}/add?guard=staff`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "AdminAttendance", id: "LIST" }],
    }),
    getUnmarkedMembersAttendance: builder.query({
      query: ({ page, limit, date }) =>
        `${BASE_URL}/unmarked-members?guard=staff&page=${page}&limit=${limit}&date=${date}`,
      transformResponse: (responseData) => responseData?.data,
      providesTags: (result, error, arg) => {
        return [{ type: "AdminAttendance", id: "LIST" }];
      },
    }),
    getMarkedMembersAttendance: builder.query({
      query: ({ page, limit, year, month }) =>
        `${BASE_URL}/marked-members?guard=staff&page=${page}&limit=${limit}&year=${year}&month=${month}`,
      transformResponse: (responseData) => responseData?.data,
      providesTags: (result, error, arg) => {
        return [{ type: "AdminAttendance", id: "LIST" }];
      },
    }),
    getAttendanceByMemberId: builder.query({
      query: ({ member_id, page = 1 }) =>
        `${BASE_URL}/member/${member_id}?guard=staff&page=${page}`,
      transformResponse: (responseData) => {
        if (!responseData?.data) {
          return { company_attendances: [], pagination: {} };
        }
        const { company_attendances = [], pagination = {} } =
          responseData?.data;
        return { company_attendances, pagination };
      },
      providesTags: (result, error, arg) => {
        if (!result?.company_attendances)
          return [{ type: "AdminAttendance", id: "LIST" }];
        return [
          { type: "AdminAttendance", id: "LIST" },
          ...result.company_attendances.map(({ attendanceId }) => ({
            type: "AdminAttendance",
            id: attendanceId,
          })),
        ];
      },
    }),
    getAttendanceById: builder.query({
      query: ({ id, page = 1 }) => `${BASE_URL}/${id}/guard=staff&page=${page}`,
      transformResponse: (respnseData) => respnseData?.data,
    }),
  }),
});

export const {
  useMarkAttendanceMutation,
  useGetAttendanceQuery,
  useGetAttendanceByMemberIdQuery,
  useGetAttendanceByIdQuery,
  useGetUnmarkedMembersAttendanceQuery,
  useGetMarkedMembersAttendanceQuery,
} = attendanceExtendedApi;
