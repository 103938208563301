import { API, ApiPrefixes } from "../api";

export const staffApi = API.injectEndpoints({
  endpoints: (builder) => ({
    getStaffList: builder.query({
      query: (page) => ({
        url:
          ApiPrefixes["staffManagement"] + `?guard=staff&page=${page}&limit=20`,
      }),
      providesTags: (result = {}) => {
        if (!result?.staffs) return [{ type: "Staff", id: "LIST" }];
        return [
          { type: "Staff", id: "LIST" },
          ...result?.staffs?.map(({ id }) => ({ type: "Staff", id: id })),
        ];
      },
      transformResponse: (responseData) => {
        const { staffs = [], pagination = {} } = responseData.data;
        return {
          staffs: staffs,
          pagination,
        };
      },
    }),

    searchStaffList: builder.query({
      query: ({ search, page }) => ({
        url:
          ApiPrefixes["staffManagement"] +
          `/search/all?guard=staff&page=${page}&limit=20&search=${search}`,
      }),
      providesTags: (result = {}) => {
        if (!result.staffs) return [{ type: "Staff", id: "LIST" }];
        return [
          { type: "Staff", id: "LIST" },
          ...result?.staffs?.map(({ id }) => ({ type: "Staff", id: id })),
        ];
      },
      transformResponse: (responseData) => {
        return responseData.data;
      },
    }),

    getSingleStaff: builder.query({
      query: (staffId) => ({
        url: ApiPrefixes["staffManagement"] + `/${staffId}/view?guard=staff`,
      }),
      transformResponse: (responseData) => responseData.data,
      providesTags: (result, error, arg) => [
        { type: "Staff", id: arg },
        "single-staff",
      ],
    }),

    createStaff: builder.mutation({
      query: (body) => ({
        url:
          ApiPrefixes["staffManagement"] +
          `/create?guard=staff&page=1&limit=20`,

        method: "POST",
        body: body,
      }),
      invalidatesTags: (result, err, arg) => [{ type: "Staff", id: arg }],
    }),

    suspendStaff: builder.mutation({
      query: (credential) => ({
        url:
          ApiPrefixes["staffManagement"] +
          `/${credential.staffId}/suspend?guard=staff`,
        method: "PUT",
        body: credential.payload,
      }),
      invalidatesTags: (result, err, arg) => [{ type: "Staff", id: arg?.id }],
    }),

    unSuspendStaff: builder.mutation({
      query: (staffId) => ({
        url:
          ApiPrefixes["staffManagement"] + `/${staffId}/unsuspend?guard=staff`,
        method: "PUT",
      }),
      invalidatesTags: (result, err, arg) => [{ type: "Staff", id: arg }],
    }),

    deleteStaff: builder.mutation({
      query: (credential) => ({
        url:
          ApiPrefixes["staffManagement"] +
          `/${credential.staffId}/delete?guard=staff`,
        method: "DELETE",
        body: credential.payload,
      }),
      invalidatesTags: (result, err, arg) => [{ type: "Staff", id: arg }],
    }),

    assignBranch: builder.mutation({
      query: (credential) => ({
        url:
          ApiPrefixes["staffManagement"] +
          `/${credential.staffId}/assign?guard=staff`,
        method: "PATCH",
        body: credential.payload,
      }),
      invalidatesTags: (result, err, arg) => [
        { type: "Staff", id: arg },
        "single-staff",
      ],
    }),

    changeBranch: builder.mutation({
      query: (credential) => ({
        url:
          ApiPrefixes["staffManagement"] +
          `/${credential.staffId}/change_branch?guard=staff`,
        method: "PATCH",
        body: credential.payload,
      }),
      invalidatesTags: (result, err, arg) => [{ type: "Staff", id: arg }],
    }),

    updateBranch: builder.mutation({
      query: (credential) => ({
        url:
          ApiPrefixes["staffManagement"] +
          `/${credential.staffId}/update?guard=staff`,
        method: "PATCH",
        body: credential.payload,
      }),
      invalidatesTags: (result, err, arg) => [{ type: "Staff", id: arg?.id }],
    }),
  }),
});

export const {
  useGetStaffListQuery,
  useSearchStaffListQuery,
  useGetSingleStaffQuery,
  useCreateStaffMutation,
  useSuspendStaffMutation,
  useUnSuspendStaffMutation,
  useDeleteStaffMutation,
  useAssignBranchMutation,
  useChangeBranchMutation,
  useUpdateBranchMutation,
} = staffApi;
