import React from "react";
import { Spinner } from "react-bootstrap";
import styled from "styled-components";

const Style = styled.div`
    
    /* height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center; */
`;

export const Loading = ({height}) => {
    return (
        <Style>
            <div
                className="d-flex justify-content-center align-items-center "
            style={{ height:height?height: "100vh" }}
            >
               <Spinner animation="border" />
            </div>
        </Style>
    );
};
// export const MainLoading = ({height}) => {
//     return (
//         <Style>
//             <div
//                 className="d-flex justify-content-center align-items-center vh-100"
//             style={{ height:height?height: "100vh" }}
//             >
//                 <div className="animate__animated animate__bounce animate__infinite	infinite">
//                     <img src={Logo} alt="logo"/>
//                 </div>
//             </div>
//         </Style>
//     );
// };
