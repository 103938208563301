import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import styledComponents from "styled-components";

const Loader = () => {
  return (
    <FullScreenContainer>
      <div className="loader-container">
        <div className="spinner-border text-primary" role="status">
          {/* <span className="sr-only">Loading...</span> */}
        </div>
      </div>
    </FullScreenContainer>
  );
};

export default Loader;

const FullScreenContainer = styledComponents.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
