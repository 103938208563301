import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import CustomSelect from "../custom-select/custom-select";
import { customStyles } from "../custom-select/custom-styles";
import { useGetBanksQuery } from "../../store/selectableSlice";
import { useValidateBankAccountMutation } from "../../store/admin/settings/company-bank";
import { useEditBankInfoMutation } from "../../store/admin/settings/societyBankSlice";
import { Input } from "../Ui/new-input";

const EditBankInfo = ({ setModalPage, bankInfo }) => {
  const [checked, setChecked] = useState(false);
  const { data: banks = [], isLoading: isLoadingBanks } = useGetBanksQuery();

  const [validateAccount, { isLoading: isValidating }] =
    useValidateBankAccountMutation();

  const stringifyFormikValues = (values) => {
    const stringifiedValues = {};
    Object.keys(values).forEach((key) => {
      stringifiedValues[key] = String(values[key]);
    });
    return stringifiedValues;
  };

  const formik = useFormik({
    initialValues: {
      bank_name: "",
      account_name: "",
      account_number: "",
    },
    onSubmit: (values) => {
      const stringifiedValues = stringifyFormikValues(values);
      handleSubmit(stringifiedValues);
    },
    validationSchema: Yup.object({
      bank_name: Yup.string().required("Bank name is required"),
      account_name: Yup.string().required("Account name is required"),
      account_number: Yup.string()
        .required("Account Number is required")
        .test(
          "len",
          "Account number must be exactly 10 digits",
          (val) => val && val.length === 10 && !isNaN(val)
        ),
    }),
  });

  async function handleValidation(value) {
    const response = await validateAccount(value);
    if ("error" in response) return toast.error(response?.data?.errors);

    const { message, status, data } = response?.data;
    if (status) {
      toast.success(message);
      formik.setValues({
        ...formik.values,
        account_name: data.account_name,
      });
    }
    if (!status) {
      toast.error(message);
      formik.setValues({
        ...formik.values,
        account_name: "",
      });
    }
  }

  useEffect(() => {
    const value = formik.values.account_number;

    if (value?.toString()?.length === 10) {
      handleValidation({
        account_number: value,
        bank_code: formik.values.bank_code,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.bank_name, formik.values.account_number]);

  const [editingBankInfo, { isLoading: isEditingBankInfo }] =
    useEditBankInfoMutation();

  async function handleSubmit(values) {
    const response = await editingBankInfo({ ...values, id: bankInfo.bank_id });

    if ("error" in response) {
      const { message } = response.error.data;
      return toast.error(message);
    }
    const { message, status } = response?.data;
    if (status) {
      toast.success(message);
      setModalPage();
    }
    if (!status) {
      toast.error(message);
    }
  }

  function selectBank(title) {
    const selectedBank = banks.find((bank) => bank.title === title);
    if (selectedBank) {
      // Set values in Formik
      formik.setValues({
        ...formik.values,
        bank_name: selectedBank.title,
        bank_code: selectedBank.code,
        account_number: bankInfo.account_number,
      });
    }
  }

  useEffect(() => {
    if (bankInfo) {
      selectBank(bankInfo.bank_name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankInfo, banks]);

  return (
    <form className="w-full flex flex-col" onSubmit={formik.handleSubmit}>
      <div className="my-2">
        <CustomSelect
          options={[
            ...banks.map((bank) => ({
              value: bank.code,
              label: bank.title,
            })),
          ]}
          value={{
            value: formik.values.bank_code,
            label: formik.values.bank_name,
          }}
          styles={customStyles}
          label={"Branching"}
          isLoading={isLoadingBanks}
          onChange={(value) => {
            formik.setFieldValue("bank_code", value.value);
            formik.setFieldValue("bank_name", value.label);
          }}
          placeholder="Select contribution branch"
        />
        {formik?.touched.bank_name && formik.errors.bank_name && (
          <span className="text-sm text-red-500 font-circular_book block w-full">
            {formik.errors.bank_name}
          </span>
        )}
      </div>
      <Input
        type="number"
        label="Account number"
        placeholder={"Input account number"}
        name="account_number"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.account_number}
        error={formik?.touched.account_number && formik.errors.account_number}
        errorMessage={formik.errors.account_number}
      />
      <Input
        label="Account name"
        placeholder={"Ifesowapo Cooperatives"}
        name="account_name"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.account_name}
        error={formik?.touched.account_name && formik.errors.account_name}
        errorMessage={formik.errors.account_name}
        readOnly
        loading={isValidating}
      />
      <div className="flex gap-2 items-center w-full">
        <input
          type="checkbox"
          name=""
          id=""
          className="accent-new-primary h-6 w-6"
          onChange={() => setChecked(!checked)}
        />
        <span className="text-sm text-[#858585]">
          Check the box to confirm bank information and cooperative information
          are the same
        </span>
      </div>
      <div className="w-full my-4 flex justify-center">
        <button
          className="w-[200px] h-[50px] rounded-[10px] bg-new-primary text-white font-semibold disabled:opacity-60"
          disabled={!checked}
        >
          {isEditingBankInfo ? "Submitting bank info..." : "Submit bank info"}
        </button>
      </div>
    </form>
  );
};

export default EditBankInfo;
