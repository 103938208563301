import React from "react";
import { BsArrowLeft, BsDot } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import {
  ButtonComp,
  CardComp,
  TextInputComp,
  FormikTextAreaComp,
} from "../../../components";
import { Formik, Form } from "formik";
import {
  useGetSingleStaffQuery,
  useSuspendStaffMutation,
} from "../../../store/admin/staff.js";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import Loader from "../../../components/Ui/Loader";

export const SuspendStaff = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    data: staffDetails,
    isLoading,
    isFetching,
  } = useGetSingleStaffQuery(id);

  const initialValues = {
    staff_name: "",
    reason: "",
  };

  const validationSchema = Yup.object({
    staff_name: Yup.string("Required"),
    reason: Yup.string().required("Please enter the reason for the suspension"),
  });

  const [handleSuspendStaff, { isLoading: isSuspending }] =
    useSuspendStaffMutation();

  const handleFormSubmit = async (values, { resetForm }) => {
    const credentials = {
      payload: values,
      staffId: id,
    };

    const response = await handleSuspendStaff(credentials);
    const { message, status } = response?.data;
    navigate("/super_admin/staff_registration/view_staff");
    resetForm();

    if (!status) {
      toast.error(message);
    } else {
      toast.success(message);
    }
  };

  return (
    <>
      {isLoading || isFetching ? (
        <CardComp cardClassName={"border-0 px-lg-4 mb-3 py-2"}>
          <Loader />
        </CardComp>
      ) : (
        <>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
          >
            <Form className=" min-vh-100  d-flex justify-content-center">
              <div className=" px-lg-4 py-4 rounded ">
                <CardComp cardClassName={"border-0 px-lg-4 mb-3 py-2"}>
                  <div className="d-flex ">
                    <BsArrowLeft
                      onClick={() =>
                        navigate("/super_admin/staff_registration/view_staff")
                      }
                      size={30}
                      className="me-3 pointer"
                    />
                    <h4 className="fw-bold mb-0 text-black1">
                      Staff suspension
                    </h4>
                  </div>
                </CardComp>

                <CardComp cardClassName={"border-0 px-lg-4 mb-3 py-4"}>
                  <div className="row gx-6 mb-4">
                    <div className="col-lg-6">
                      <div className="col-lg-12 border-lg-end mb-4">
                        <TextInputComp
                          labelText={"Staff Name"}
                          placeholder={"Alabi Adeola Martins"}
                          LabelClassName="fw-bold"
                          name="staff_name"
                          value={`${staffDetails?.first_name} ${staffDetails?.last_name}`}
                        />
                      </div>
                      <div className="col-lg-12 border-lg-end mb-5">
                        <FormikTextAreaComp
                          rows={10}
                          fieldName={"reason"}
                          labelClassName={"fw-bold"}
                          LabelText={"Reason for staff suspension"}
                          placeholder={"Enter message"}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 ">
                      <CardComp cardClassName="border-0 shadow py-4">
                        <h5 className="text-black mb-4 fw-bold text-center">
                          Instructions
                        </h5>
                        <div className="d-flex align-items-center mb-3">
                          <span>
                            <BsDot color="#8B313A" size={40} />
                          </span>
                          <p>
                            Select the date corresponding to the attendance
                            schedule to mark members attendance{" "}
                          </p>
                        </div>
                        <div className="d-flex align-items-center mb-3">
                          <span>
                            <BsDot color="#8B313A" size={40} />
                          </span>
                          <p>
                            Select the date corresponding to the attendance
                            schedule to mark members attendance{" "}
                          </p>
                        </div>
                        <div className="d-flex align-items-center mb-3">
                          <span>
                            <BsDot color="#8B313A" size={40} />
                          </span>
                          <p>
                            Select the date corresponding to the attendance
                            schedule to mark members attendance{" "}
                          </p>
                        </div>
                      </CardComp>
                    </div>
                  </div>
                </CardComp>
                <div className="col-lg-12 border-lg-end">
                  <ButtonComp
                    loading={isSuspending}
                    type="submit"
                    btnText={"Submit"}
                    btnClassName={
                      "px-5 text-white bg-primary1 col-6 col-md-3 border-0 py-3 h6 rounded-3"
                    }
                  />
                </div>
              </div>
            </Form>
          </Formik>
          <div className="my-5"></div>
          {/* <ModalCompAdvance
            modalBody={modalPage}
            show={modalToggle}
            arrayComp={useModal}
            handleClose={() => setModalToggle(false)}
            centered
            size={"md"}
          /> */}
        </>
      )}
    </>
  );
};
