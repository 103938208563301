import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import styled from "styled-components";

export default function SwiperComp({ Data = [], Page = {} }) {
  return (
    <SwiperStyle className="position-relative">
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={false}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        {Data?.map((item, index) => (
          <SwiperSlide key={index}>
            {Page(item)}
            <Style2>
              <div className="backgroundWrapper"></div>
            </Style2>
          </SwiperSlide>
        ))}
      </Swiper>
    </SwiperStyle>
  );
}

const SwiperStyle = styled.span`
  .swiper {
    height: 100%;
    // background-image:${(props) => `url("${props?.Background}")`};

    // filter: brightness(0.8);
  }
`;

const Style2 = styled.div`
  .backgroundWrapper::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: ${(props) => `url("${props?.Background}")`};
    pointer-events: none;
    z-index: 2;
  }
`;
