import styled from "styled-components";
import {
  ButtonComp,
  SelectComp2,
  TextInputComp,
  CardComp,
} from "../../../../components";
import { RiArrowDropDownLine } from "react-icons/ri";
// import { IoIosClose } from "react-icons/io";
import { useGetRolePermissionsQuery } from "../../../../store/selectableSlice";
import { Formik, Form } from "formik";
import { useEffect, useRef, useState } from "react";
function RoleForm({ onNext }) {
  const { data: permissions = [], error } = useGetRolePermissionsQuery();
  if (error) {
    console.log(error);
  }
  console.log(permissions);
  return (
    <Wrapper>
      <Formik>
        <Form>
          <CardComp
            cardClassName={"pt-4 mb-4"}
            cardBodyClassName={"py-0 row gx-5 gy-3 justify-content-between"}
          >
            <div className="col-12 col-lg-5">
              <div className="">
                <TextInputComp
                  LabelText={"Role"}
                  borderColor="#A1A0A0"
                  LabelClassName={"fw-bold"}
                  placeholder={"Role name"}
                  name="name"
                />
              </div>
            </div>
            <div className="col-12 col-lg-5">
              <div className="">
                <SelectComp2
                  LabelClassName={"fw-bold"}
                  labelText={"Role Permissions"}
                  arrayComp={[{ value: "", label: "Select Location" }]}
                />
              </div>
              <div className="permissions">
                {permissions.map(({ name, sub_menu = [] }) => {
                  return (
                    <PermissionDropdown
                      key={name}
                      name={name}
                      sub_menu={sub_menu}
                    />
                  );
                })}
              </div>
            </div>
            <div className="col-12 py-1"></div>
          </CardComp>
          <div className="">
            <ButtonComp
              btnText={"Submit Role"}
              onClick={() => {}}
              btnClassName={"bg-primary1 py-3 px-4 text-white border-0 rounded"}
            />
          </div>
        </Form>
      </Formik>
    </Wrapper>
  );
}

const PermissionDropdown = ({ name, sub_menu }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [boxHeight, setBoxHeight] = useState("0px");
  const itemsRef = useRef(null);
  useEffect(() => {
    const itemsHeight = itemsRef.current.getBoundingClientRect().height;
    if (isOpen) {
      setBoxHeight(`${itemsHeight}px`);
    } else {
      setBoxHeight("0px");
    }
  }, [isOpen]);
  return (
    <article>
      <div
        className="permission-header d-flex justify-content-between px-4 py-2"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h3 className="d-flex gap-2">
          <span>{name[0]}</span>
          {name}{" "}
        </h3>
        <span className={`${isOpen ? "open" : ""}`}>
          <RiArrowDropDownLine size={"30px"} />
        </span>
      </div>
      <div className="items-box" style={{ height: boxHeight }}>
        <ul ref={itemsRef} className="items">
          {sub_menu?.map(({ name }) => {
            return (
              <li key={name}>
                <span></span>
                {name}
              </li>
            );
          })}
        </ul>
      </div>
    </article>
  );
};

const Wrapper = styled.div`
  .permissions {
    background-color: #ffffff;
    max-width: 389px;
  }
  ul {
    list-style: none;
  }
  .items-box {
    overflow: hidden;
    transition: all 400ms;
  }
  .items {
    padding-block: 0.5rem;
  }
  .permission-header {
    background-color: #fafafa;
    h3 {
      font-size: 14px;
      margin: 0;
      padding: 0;
    }
  }
  span.open {
    transform: rotate(180deg);
  }
`;

export default RoleForm;
