import React from "react";
import { TableCompData } from "../../../../components";
import EmptyTable from "../../../../components/Common/EmptyTable";
import { LoanRepaymentHeader } from "../../../../utils/TableHeader";
import TablePagination from "../../../../components/Table/Pagination";

export default function LoanRepaymentComp({
  dateClassName,
  getTableDropDown,
  TableData,
  name,
  loading,
  setPageNumber,
  pagination,
}) {
  return (
    <>
      <main>
        <div className="mb-5">
          <TableCompData
            marginBottom={"1rem"}
            columns={LoanRepaymentHeader(dateClassName, getTableDropDown)}
            data={TableData}
            message={
              loading ? (
                <div className=" py-4 ">
                  <h3>Loading...</h3>
                </div>
              ) : (
                <EmptyTable Message={`There are currently no ${name}`} />
              )
            }
          />
          {TableData?.length > 0 && (
            <TablePagination
              marginTop={"0"}
              handlePageChange={setPageNumber}
              {...pagination}
            />
          )}
        </div>
      </main>
      <div className="py-4"></div>
    </>
  );
}
