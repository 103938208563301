import { Form, Dropdown, DropdownButton } from "react-bootstrap";
// import { BsFillFilterCircleFill } from "react-icons/bs";
import styledComponents from "styled-components";
import FallbackImage from "../Common/ImageWrapper";
import Select from "react-select";
import { useField } from "formik";

export const FormikSelectComp = ({
  options = [],
  fieldName,
  labelText,
  isRequired = false,
  selectText = "Select",
  desc = "",
  labelClassName,
  backgroundColor = "#fafafa",
}) => {
  const [field, meta] = useField(fieldName);
  return (
    <SelectCompStyle className="w-100" backgroundColor={backgroundColor}>
      <Form.Group className="w-100">
        {labelText && (
          <Form.Label>
            <p className={`mb-0 ${labelClassName}`}>
              {labelText}
              {isRequired && <span className={"text-danger"}>*</span>}
            </p>
          </Form.Label>
        )}
        <Form.Select {...field}>
          <option className="" value={""}>
            {selectText}
          </option>
          {options.map((option, index) => (
            <option value={option.value} key={index}>
              {option.label}
            </option>
          ))}
        </Form.Select>
        {desc && <Form.Text className="text-muted">{desc}</Form.Text>}
        {meta.touched &&
          meta.error && ( // Display validation error if touched and error exists
            <small className="text-danger mt-2">{meta.error.toString()}</small>
          )}
      </Form.Group>
    </SelectCompStyle>
  );
};

export const SelectComp = ({
  LabelText,
  labelText,
  selectClassName,
  LabelClassName,
  arrayComp = [],
  selectText,
  input,
  inputValue,
  required,
  desc,
  errors,
  borderColor,
  borderWidth,
  backgroundColor = "#fafafa",
  ...props
}) => {
  return (
    <SelectCompStyle
      className="w-100"
      borderColor={borderColor}
      borderWidth={borderWidth}
      backgroundColor={backgroundColor}
    >
      <Form.Group className="w-100">
        {(LabelText || labelText) && (
          <Form.Label>
            <p className={`mb-0 ${LabelClassName}`}>
              {LabelText || labelText}
              {required && <span className={"text-danger"}>*</span>}
            </p>
          </Form.Label>
        )}
        <Form.Select className={` ${selectClassName}`} {...props}>
          <option className="" value={""}>
            {selectText}
          </option>
          {arrayComp?.length > 0 &&
            arrayComp?.map((item, index) => (
              <option
                key={index}
                label={
                  <FallbackImage
                    className="rounded-circle"
                    width="24px"
                    height="24px"
                    src={`https://i.pinimg.com/736x/d2/ba/96/d2ba96ad6d2744eeafa93bb2414b63df--latina-sexy-women.jpg`}
                  />
                }
                value={input ? item[inputValue] : item}
              >
                {" "}
                {input ? item[input] : item}
              </option>
            ))}
        </Form.Select>
        {desc && <small>{desc}</small>}
        {errors?.map((error, i) => (
          <small className="text-danger mt-2" key={i}>
            {error}
          </small>
        ))}
      </Form.Group>
    </SelectCompStyle>
  );
};
export const SelectComp2 = ({
  LabelText,
  labelText,
  selectClassName,
  LabelClassName,
  arrayComp = [],
  selectText,
  input,
  inputValue,
  required,
  desc,
  errors,
  borderColor,
  borderWidth,
  selectedOption = {},
  setSelectedOption = {},
  placeholder,
  isMulti,
  defaultMenuIsOpen,
  backgroundColor = "#fafafa",
  isLoading,
  ...props
}) => {
  return (
    <SelectCompStyle
      className="w-100"
      borderColor={borderColor}
      borderWidth={borderWidth}
    >
      <Form.Group className="w-100">
        {(LabelText || labelText) && (
          <Form.Label>
            <p className={`mb-0 ${LabelClassName}`}>
              {LabelText || labelText}
              {required && <span className={"text-danger"}>*</span>}
            </p>
          </Form.Label>
        )}
        <Select
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              // borderColor: console.log(baseStyles),
              backgroundColor: backgroundColor || "#fafafa",
            }),
          }}
          defaultValue={"Select member" || selectedOption}
          onChange={setSelectedOption}
          options={arrayComp}
          isSearchable={false}
          isMulti={isMulti ? true : false}
          classNamePrefix="px-5"
          defaultMenuIsOpen={defaultMenuIsOpen}
          placeholder={placeholder}
          isLoading={isLoading}
          {...props}
          // defaultInputValue="HEllo"
        />
        {desc && <small>{desc}</small>}
        {errors?.map((error, i) => (
          <small className="text-danger mt-2" key={i}>
            {error}
          </small>
        ))}
      </Form.Group>
    </SelectCompStyle>
  );
};

export const SelectCompWithFormattedLabel = ({
  LabelText,
  labelText,
  selectClassName,
  LabelClassName,
  arrayComp = [],
  selectText,
  input,
  inputValue,
  required,
  desc,
  errors,
  borderColor,
  borderWidth,
  selectedOption = null,
  setSelectedOption = null,
  placeholder,
  isMulti,
  defaultMenuIsOpen,
  fieldName,
  setOption,
  ...props
}) => {
  const handleChange = (selectedOption) => {
    setOption(selectedOption);
  };

  const formatOptionLabel = ({ photo, name, userId }) => (
    <div className="d-flex">
      <FallbackImage
        className="rounded-circle me-3"
        width="24px"
        height="24px"
        src={photo}
      />
      {` ${name} | ID: ${userId}`}
    </div>
  );

  return (
    <SelectCompStyle
      className={`w-100`}
      borderColor={borderColor}
      borderWidth={borderWidth}
    >
      <Form.Group className="w-100">
        {(LabelText || labelText) && (
          <Form.Label>
            <p className={`mb-0 ${LabelClassName}`}>
              {LabelText || labelText}
              {required && <span className={"text-danger"}>*</span>}
            </p>
          </Form.Label>
        )}
        <Select
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              backgroundColor: "#fafafa",
            }),
          }}
          defaultValue={selectedOption || "Select member"}
          options={arrayComp}
          isSearchable={false}
          isMulti={isMulti ? true : false}
          classNamePrefix="px-5"
          defaultMenuIsOpen={defaultMenuIsOpen}
          placeholder={placeholder}
          onChange={handleChange}
          formatOptionLabel={formatOptionLabel}
        />
        {desc && <small>{desc}</small>}
        {errors?.map((error, i) => (
          <small className="text-danger mt-2" key={i}>
            {error}
          </small>
        ))}
      </Form.Group>
    </SelectCompStyle>
  );
};

const SelectCompStyle = styledComponents.div`
select{
  border-width: ${(props) =>
    props?.borderWidth ? props?.borderWidth : "1px"} !important;
    border-color: ${(props) =>
      props?.borderColor ? props?.borderColor : "#A1A0A0"} !important;
    
}
select {
  background-color: ${(props) =>
    props?.backgroundColor ? props?.backgroundColor : "#FAFAFA"} !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.px-5__control{
  padding-top: 0.2rem!important;
    padding-bottom: 0.2rem!important;
}

`;

export const DropDownComp = ({
  arrayComp = [],
  DropDownText,
  input,
  row,
  show,
  dropLabelClassName = "",
  drop,
  ItemClassName = "",
  borderColor,
}) => {
  return (
    <DropDownStyle Hide={show} BorderColor={borderColor}>
      <Dropdown className="border-0 p-0">
        <Dropdown.Toggle
          variant=""
          id="dropdown-basic"
          className={` p-0 ${dropLabelClassName}`}
          drop={drop}
          style={{ borderColor: "red !important" }}
        >
          {DropDownText}
        </Dropdown.Toggle>

        {arrayComp.length > 0 && (
          <Dropdown.Menu className="border-0 py-2s px-2 shadow ">
            {arrayComp.map((item, index) => (
              <Dropdown.Item
                disabled={item?.disabled}
                className={`mb-1  ${ItemClassName} ${
                  item?.aClassName
                } flex items-center space-x-1 ${
                  item?.disabled && "opacity-75"
                }`}
                key={index}
                onClick={() => item?.action && item?.action(row)}
              >
                {item?.icon && (
                  <span className="pe-2 text-white">
                    <img src={item?.icon} alt="" className="text-white" />
                  </span>
                )}
                {item[input]}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        )}
        {arrayComp.length <= 0 && (
          <Dropdown.Menu className="border-0 py-3 px-2">
            <Dropdown.Item className="border-0" disabled>
              Nothing Yet
            </Dropdown.Item>
          </Dropdown.Menu>
        )}
      </Dropdown>
    </DropDownStyle>
  );
};

export const DropDownComp2 = ({
  arrayComp,
  DropDownText,
  DropDownBody,
  input,
  show,
  dropLabelClassName,
  drop,
}) => {
  return (
    <DropDownStyle Hide={show}>
      <Dropdown className="border-0 p-0">
        <Dropdown.Toggle
          variant=""
          id="dropdown-basic"
          className={` p-0 ${dropLabelClassName}`}
          drop={drop}
        >
          {DropDownText || DropDownBody}
        </Dropdown.Toggle>

        <Dropdown.Menu className="border-0 py-3 px-2 shadow">
          {arrayComp}
        </Dropdown.Menu>
      </Dropdown>
    </DropDownStyle>
  );
};

const DropDownStyle = styledComponents.div`
.dropdown-toggle::after {
  display:${(prop) => (prop.Hide ? prop.Hide : "none")};
}
.dropdown-item::focus {
   background: red !important;
  color: white !important;
}
a:focus,a:active,a:hover{
   background: #8B313A !important;
  color: white !important;
}

.dropdown-toggle{
  border-color:${(props) =>
    props?.BorderColor ? props?.BorderColor : "inherit"} !important;
}

`;

export const DropDownComp3 = ({
  autoClose,
  Transform = null,
  arrayComp = [],
  bodyComp,
  DropDownText,
  input,
  row,
  show,
  dropLabelClassName = "",
  ItemClassName2 = "",
  drop,
  ItemClassName = "",
  borderColor,
}) => {
  return (
    <DropDown3Style Hide={show} BorderColor={borderColor} Transform={Transform}>
      <DropdownButton
        autoClose={autoClose}
        drop={drop}
        variant=""
        className={dropLabelClassName}
        id="dropdown-item-button"
        title={DropDownText}
      >
        {arrayComp?.map((item, i) => (
          <div key={i}>
            <Dropdown.Item
              className={!item?.select ? ItemClassName : ItemClassName2}
              as="button"
            >
              {item?.name}
            </Dropdown.Item>
          </div>
        ))}
        {bodyComp}
      </DropdownButton>
    </DropDown3Style>
  );
};
const DropDown3Style = styledComponents.div`
display:inline-block;
.dropdown-toggle::after {
  display:${(prop) => (prop.Hide ? prop.Hide : "none")};
}
.dropdown-item::focus {
   background: red !important;
  color: white !important;
}
a:focus,a:active,a:hover{
   background: #8B313A !important;
  color: white !important;
}
.dropdown-menu{
  border:none;
  top: 18px !important;
  padding:10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  // transform: translate3d(-181px, 41.5px, 0px) !important;
}
.dropdown-toggle{
  border-color:${(props) =>
    props?.BorderColor ? props?.BorderColor : "inherit"} !important;
}

.dropdown-item{
  padding:0;
}
button{
  border:none;
}

@media only screen and (max-width: 400px) {
  .dropdown-menu.show{
    transform: ${(props) => props?.Transform} !important;
    // transform: translate3d(-172px, 59.5px, -11px)!important;
  }
}

`;
