// import { createSelector } from "@reduxjs/toolkit";
import { API, ApiPrefixes } from "../../api";
const BASE_URL = ApiPrefixes["companyBank"];

export const companyBankApi = API.injectEndpoints({
  endpoints: (builder) => ({
    validateBankAccount: builder.mutation({
      query: (body) => ({
        url: `${BASE_URL}/validate-account?guard=staff`,
        method: "POST",
        body: body,
      }),
      //   invalidatesTags: [{ type: "Branch", id: "LIST" }],
    }),
  }),
});

export const { useValidateBankAccountMutation } = companyBankApi;
