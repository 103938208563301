import React from "react";
import { ButtonComp, FormikTextAreaComp } from "../../../../components";
import { Form, Formik } from "formik";
import { useDeclineLoanRequestMutation } from "../../../../store/admin/loan";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { AiOutlineClose } from "react-icons/ai";

const DeclineLoanModal = ({ requestId, setModalPage }) => {
  const [isDeclineRequest, { isLoading: isDecliningLoans }] =
    useDeclineLoanRequestMutation();

  const handleDeclineLoanRequest = async (values, { resetForm }) => {
    const payload = { ...values, requestId: requestId };
    const response = await isDeclineRequest(payload);

    if ("error" in response) {
      const { data } = response?.error;
      toast.error(data.message);
      return;
    }
    const { message, status } = response.data;
    if (!status) return toast.error(message);
    toast.success(message);
    setModalPage();
    resetForm();
  };
  return (
    <div className="relative p-4">
      <Formik
        initialValues={{ decline_reason: "" }}
        validationSchema={Yup.object({
          decline_reason: Yup.string().required("Required"),
        })}
        onSubmit={handleDeclineLoanRequest}
      >
        <Form className="">
          <AiOutlineClose
            onClick={() => setModalPage()}
            className="absolute top-10 left-5"
          />
          <h4 className="border-b fw-bold py-2 mb-4 text-[1.5rem] !text-primary text-center w-full ">
            Decline loan request
          </h4>
          <FormikTextAreaComp
            rows={7}
            fieldName={"decline_reason"}
            labelClassName={"fw-2"}
            LabelText={"Reason for loan decline"}
            placeholder={"Input reason for declining the loan"}
          />
          <div className="row justify-content-around pt-8">
            <div className="col-5">
              <ButtonComp
                btnText={"No"}
                btnClassName={" text-1 btn w-100 py-2"}
                BorderColor={"#8B313A"}
                onClick={() => setModalPage()}
              />
            </div>

            <div className="col-5">
              <ButtonComp
                loading={isDecliningLoans}
                type="submit"
                btnText={"Yes"}
                ButtonInlineStyle={{ backgroundColor: "#FF4343" }}
                btnClassName={"border-0 text-white  w-100 py-2 rounded"}
              />
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default DeclineLoanModal;
