import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CardComp, ModalCompAdvanceUpdate } from "../../../components";
import { SettingSocietyBankInformationHeader } from "../../../utils/TableHeader";
import AllBankInfo from "./modules/AllBankInfo";
import ConfirmModal from "../modules/ConfirmModal";
import {
  useDeleteBankInfoMutation,
  useGetAllBankInfoQuery,
} from "../../../store/admin/settings/societyBankSlice";
import { toast } from "react-hot-toast";
import CreateBankInfo from "../../../components/society-banking/create-bank-info-modal";
import EditBankInfo from "../../../components/society-banking/edit-bank-info-modal";

export default function SocietyBankInfo() {
  const navigate = useNavigate();
  const [modalPage, setModalPage] = useState("");
  const { error: errorBankInfo } = useGetAllBankInfoQuery();
  const [bankInfoId, setBankInfoId] = useState(null);
  const [bankInfo, setBankInfo] = useState(null);

  const [deleteBankInfo] = useDeleteBankInfoMutation();

  const removeBankInfo = async (id) => {
    const toastId = toast.loading("Deleting");
    try {
      const payload = await deleteBankInfo(id).unwrap();
      // to be updated to check payload status for success or failed request
      if (payload.status === false) {
        const errMsg =
          payload.message || payload.data.message || "An error occured";
        return toast.error(errMsg, { id: toastId });
      }
      toast.success("Bank info delete successfuuly", { id: toastId });
    } catch (error) {
      if (error.status === 401) {
        toast.error("Login to continue", { id: toastId });
        navigate("/login");
      }
      toast.error(error?.message || error?.data.message || "An error occured", {
        id: toastId,
      });
    } finally {
      setModalPage(false);
    }
  };

  const useModal = [
    {
      name: "Suspend Modal",
      component: (
        <ConfirmModal
          title={"Confirm"}
          desc={"Are you sure you want to disable this bank account?"}
          setModalToggle={() => setModalPage()}
          confimAction={() => removeBankInfo(bankInfoId)}
        />
      ),
    },
    {
      name: "Create Bank Information",
      component: <CreateBankInfo setModalPage={setModalPage} />,
    },
    {
      name: "Edit Bank Information",
      component: (
        <EditBankInfo bankInfo={bankInfo} setModalPage={setModalPage} />
      ),
    },
  ];
  const TableDropDown = [
    {
      name: "Edit account",
      action: (data) => {
        // console.log(data, "dgdggdgdg");
        setBankInfo(data); // update to match the bank info id to be deleted
        setModalPage("Edit Bank Information");
      },
    },
    {
      name: "Delete account",
      action: (data) => {
        setBankInfoId(data?.bank_id); // update to match the bank info id to be deleted
        setModalPage("Suspend Modal");
      },
    },
  ];

  if (errorBankInfo && errorBankInfo.status === 401) {
    navigate("/login");
  }

  return (
    <section className="px-2 px-lg-4 min-vh-100">
      <div className="py-1"></div>
      <CardComp cardBodyClassName={"py-0"} cardClassName={"pt-4"}>
        <h4 className=" text-black1 fw-bold">
          List of Society Bank Information
        </h4>
      </CardComp>
      <div className="w-full rounded-[20px] py-10 bg-white">
        <div className="w-full flex justify-end pr-10">
          <button
            className="w-[200px] bg-new-primary h-[50px] rounded-[10px] text-white"
            onClick={() => setModalPage("Create Bank Information")}
          >
            Create new account
          </button>
        </div>
        <AllBankInfo
          tableColumns={SettingSocietyBankInformationHeader(TableDropDown)}
          pageToggle={() => navigate("new")}
        />
      </div>

      <ModalCompAdvanceUpdate
        pageName={modalPage}
        arrayComp={useModal}
        handleClose={() => setModalPage()}
        centered
        size={"md"}
      />
    </section>
  );
}
