import React, { useState } from "react";
import { PageSwitch } from "../../../components";
import AllStaffs from "./modules/AllStaffs";

export const ViewStaff = () => {
  const [modalToggle, setModalToggle] = useState(false);
  const [modalPage] = useState(0);

  const usePages = [
    {
      name: "All Staffs",
      component: (
        <AllStaffs
          modalPage={modalPage}
          modalToggle={modalToggle}
          setModalToggle={setModalToggle}
        />
      ),
    },
  ];

  return <PageSwitch pageArray={usePages} pageName={"All Staffs"} />;
};
