import { TableCompData } from "../../../../components";
import EmptyTable from "../../../../components/Common/EmptyTable";
import { SettingBranchHeader } from "../../../../utils/TableHeader";
import {
  useGetBranchesQuery,
  selectBranchesData,
} from "../../../../store/admin/settings/branchSlice";
import { useSelector } from "react-redux";

export default function AllBranch({ TableDropDown, onSubmit }) {
  const { isFetching, isError, error } = useGetBranchesQuery();
  const branches = useSelector(selectBranchesData);
  if (isError) {
    console.log(error);
    return <p>An error occurred</p>;
  }

  return (
    <div>
      <div className="mb-5">
        <TableCompData
          columns={SettingBranchHeader(TableDropDown)}
          data={branches}
          marginBottom={"2rem"}
          message={
            isFetching ? (
              <div className="py-4">
                <p>Looading Branches</p>
              </div>
            ) : (
              <EmptyTable
                Message={"This cooperative has no branch(es)"}
                btnText={"Create branch"}
                onClick={onSubmit}
              />
            )
          }
        />
      </div>
    </div>
  );
}
