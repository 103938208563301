import AddGroupMembers from "./modules/AddGroupMembers";
import BorderedCard from "./modules/BorderedCard";
import { CardComp, ButtonComp } from "../../../components";
import styled from "styled-components";

export const AddMembersToGroup = () => {
  return (
    <>
      <Wrapper className="mx-2 mx-lg-4">
        <CardComp
          cardClassName={"pt-4"}
          cardBodyClassName={
            "py-0 d-flex justify-content-between align-items-center"
          }
        >
          <h5 className="fw-bold">Create Group</h5>
          <ButtonComp
            btnText={"View group list"}
            ButtonInlineStyle={{ borderRadius: "10px" }}
            btnClassName={"bg-primary1 px-3 py-2 text-white rounded"}
            BorderColor={"#8B313A"}
            // onClick={() => onBack()}
          />
        </CardComp>
        <BorderedCard
          title={"Duis aute irure dolor"}
          content={
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          }
        />
        <AddGroupMembers marginTop={"1.5rem"} />
      </Wrapper>
    </>
  );
};

const Wrapper = styled.section``;

export default AddMembersToGroup;
