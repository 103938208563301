import { GoPrimitiveDot } from "react-icons/go";
import "react-calendar/dist/Calendar.css";
import CalendarComp from "../../../components/Common/CalendarComp";
import { ButtonComp, CardComp, PlainTable } from "../../../components";
import { AttendanceHeader } from "../../../utils/TableHeader";
import FilterFind from "../../../components/FilterFind";
import { useGetAttendanceStatusesQuery } from "../../../store/selectableSlice";
import EmptyTable from "../../../components/Common/EmptyTable";
import useAdminAttendanceActions from "./useAdminAttendanceActions";
import { useNavigate } from "react-router-dom";
import { useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { useGetUnmarkedMembersAttendanceQuery } from "../../../store/admin/attendanceSlice";
import { dateFromString } from "../../../utils";

export default function AttendanceHome() {
  const navigate = useNavigate();
  const { newAttendance, ismarkingAttendance } = useAdminAttendanceActions();

  const { data: attendance_statuses = [] } = useGetAttendanceStatusesQuery();
  const todayDate = dateFromString(new Date(), true);
  const {
    data: unmarkedAttendance = {},
    isFetching: isFetchingAttendance,
    error,
  } = useGetUnmarkedMembersAttendanceQuery({
    page: 1,
    limit: 1000,
    date: todayDate,
  });

  const { allMembers = [] } = useMemo(() => {
    const { company_attendances = [] } = unmarkedAttendance;
    const formattedMembers = company_attendances.map((item, index) => {
      return {
        name: item.first_name + " " + item.last_name,
        photo: item.photo,
        serialNumber: index + 1,
        id: item.id,
        email: item.email,
      };
    });
    return { allMembers: formattedMembers };
  }, [unmarkedAttendance]);
  if (error) {
    toast.error(error.data?.message || "An error occured");
  }
  if (error?.status === 401) {
    navigate("/login");
  }

  const [attendance, setAttendance] = useState([]);

  const submitAttendance = () => {
    newAttendance({ attendance, date: todayDate });
  };

  const markAttendance = (member_id, attendancePoint) => {
    setAttendance((prev) => {
      const member = prev.find(
        (item) => String(member_id) === String(item.member_id)
      );
      if (!member) {
        return [
          ...prev,
          { member_id: member_id, attendance_status_id: attendancePoint },
        ];
      }
      return prev.map((item) => {
        if (String(member_id) === String(item.member_id)) {
          return {
            member_id: member_id,
            attendance_status_id: attendancePoint,
          };
        }
        return item;
      });
    });
  };

  return (
    <section className="mx-lg-4 min-vh-100 pb-5 mb-5">
      <FilterFind title={"Attendance Marking"} />
      <CardComp>
        <div className="py-4"></div>

        <div className="row g-5 mb-5">
          <div className="col-lg-5">
            <CalendarComp />
          </div>
          <div className="col-lg-7">
            <div className="ms-lg-5">
              <div style={{ background: "" }} className="p-4 rounded-4 shadow">
                <h6 className="text-center mb-4 fw-3">Instructions</h6>
                <div className="d-flex align-items-center mb-3">
                  <GoPrimitiveDot color="#8B313A" className="me-3" />
                  <p className="mb-0">
                    Select the date corresponding to the attendance schedule to
                    mark members attendance
                  </p>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <GoPrimitiveDot color="#8B313A" className="me-3" />
                  <p className="mb-0">
                    Select the date corresponding to the attendance schedule to
                    mark members attendance
                  </p>
                </div>
                <div className="d-flex align-items-center">
                  <GoPrimitiveDot color="#8B313A" className="me-3" />
                  <p className="mb-0">
                    Select the date corresponding to the attendance schedule to
                    mark members attendance
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <PlainTable
            columns={AttendanceHeader(markAttendance, attendance_statuses)}
            data={allMembers}
            marginBottom={"10px"}
            cellPadding={"0px"}
            message={
              isFetchingAttendance ? (
                <p className="py-4 text-center">Loading...</p>
              ) : (
                <EmptyTable Message={"No unmarked attendance for today"} />
              )
            }
          />
        </div>
      </CardComp>
      {allMembers.length > 0 && (
        <div className="text-start mb-3">
          <ButtonComp
            btnText={"Submit attendance"}
            btnClassName={"px-5 py-3 h6 text-white bg-primary1 rounded"}
            onClick={submitAttendance}
            disabled={ismarkingAttendance}
          />
        </div>
      )}
    </section>
  );
}
