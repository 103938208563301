import React from "react";
import {
  ButtonComp,
  CardComp,
  MoneyInputComp,
  SelectComp2,
  TextInputComp,
} from "../../../components";
import FilterFind from "../../../components/FilterFind";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useEditLoanTypeMutation } from "../../../store/admin/loan";
import toast from "react-hot-toast";
import { useEnums } from "../../../hooks/useEnums";

export default function LoanTypeEdit({ setPageName, detailsToEdit }) {
  const enums = useEnums();

  const initialValues = {
    name: detailsToEdit?.name,
    rate: detailsToEdit?.rate,
    interest_calculation_mode: detailsToEdit?.interest_calculation_mode,
    min_guarantor: detailsToEdit?.min_guarantor,
    max_guarantor: detailsToEdit?.max_guarantor,
    min_loan_amount: detailsToEdit?.min_loan_amount,
    max_loan_amount: detailsToEdit?.max_loan_amount,
  };
  const validationSchema = Yup.object({
    name: Yup.string().required("Required"),
    rate: Yup.string().required("Required"),
    interest_calculation_mode: Yup.string().required("Required"),
    min_guarantor: Yup.string().required("Required"),
    max_guarantor: Yup.string().required("Required"),
    min_loan_amount: Yup.string().required("Required"),
    max_loan_amount: Yup.string().required("Required"),
  });

  const [createLoanType, { isLoading }] = useEditLoanTypeMutation();
  const handleFormSubmit = async (values, { resetForm }) => {
    const response = await createLoanType({
      payload: values,
      loanTypeId: detailsToEdit?.id,
    });
    const { data, error } = response;
    if (error) {
      return toast.error(error?.data?.message || "An error occured");
    } else if (data?.status === false) {
      return toast.error(data?.message || "An error occured");
    } else {
      toast.success(data?.message || "Success");
      resetForm();
      setPageName("loan_type");
    }
  };

  return (
    <section className="px-lg-3 min-vh-100 pb-5 mb-3">
      <FilterFind
        back={true}
        backBtn={() => setPageName("loan_type")}
        rightSide={false}
        title={`Edit ${detailsToEdit?.name}`}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {({ values, errors, touched, setFieldValue }) => (
          <Form>
            <CardComp cardClassName={"pb-5"}>
              <div className="py-2"></div>

              <div>
                <div>
                  <div className="row gx-0 gy-3 justify-content-between">
                    <div className="col-12">
                      <TextInputComp
                        LabelText={"Loan type name"}
                        borderColor="#A1A0A0"
                        LabelClassName={"fw-2"}
                        placeholder={"Enter the new loan type name"}
                        name="name"
                        type="text"
                      />
                    </div>
                    <div className="col-12 col-lg-5">
                      <TextInputComp
                        LabelText={"Loan interest"}
                        borderColor="#A1A0A0"
                        LabelClassName={"fw-2"}
                        placeholder={"Enter the new loan type name"}
                        name="rate"
                        type="text"
                      />
                    </div>
                    <div className="col-12 col-lg-5">
                      <SelectComp2
                        LabelClassName={"fw-2"}
                        labelText={"Interest calculation mode"}
                        arrayComp={enums["InterestCalculationMode"]}
                        selectedOption={values.interest_calculation_mode}
                        setSelectedOption={(selectedOption) =>
                          setFieldValue(
                            "interest_calculation_mode",
                            selectedOption.value
                          )
                        }
                        errors={
                          touched.interest_calculation_mode &&
                          errors.interest_calculation_mode
                            ? [errors.interest_calculation_mode]
                            : []
                        }
                      />
                    </div>
                    <div className="col-12 col-lg-5">
                      <TextInputComp
                        LabelText={"Minimum guarantor"}
                        borderColor="#A1A0A0"
                        LabelClassName={"fw-2"}
                        placeholder={"Enter minimum guarantor"}
                        name="min_guarantor"
                        type="number"
                      />
                    </div>
                    <div className="col-12 col-lg-5">
                      <TextInputComp
                        LabelText={"Maximum guarantor"}
                        borderColor="#A1A0A0"
                        LabelClassName={"fw-2"}
                        placeholder={"Enter maximum guarantor"}
                        name="max_guarantor"
                        type="number"
                      />
                    </div>

                    <div className="col-12 col-lg-5">
                      <MoneyInputComp
                        LabelText={"Minimum loan amount"}
                        borderColor="#A1A0A0"
                        LabelClassName={"fw-2"}
                        placeholder={"Enter minimum loan amount"}
                        descClassName="text-danger"
                        name={"min_loan_amount"}
                      />
                    </div>

                    <div className="col-12 col-lg-5">
                      <MoneyInputComp
                        LabelText={"Maximum loan amount"}
                        borderColor="#A1A0A0"
                        LabelClassName={"fw-2"}
                        placeholder={"Enter maximum loan amount"}
                        descClassName="text-danger"
                        name={"max_loan_amount"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </CardComp>
            <div className="col-12 py-3"></div>
            <div className="d-flex justify-content-start">
              <div className="col-lg-4 col-xl-2">
                <ButtonComp
                  type="submit"
                  btnText={"Update"}
                  btnClassName={
                    "w-100 bg-primary1 py-3 !text-white border-0 rounded"
                  }
                  loading={isLoading}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </section>
  );
}
