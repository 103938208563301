import { Formik, Form } from "formik";
import {
  SelectComp2,
  TextInputComp,
  RadioButtonComp,
  ButtonComp,
  CardComp,
} from "../../../../components";
import {
  selectBranchesData,
  useGetBranchesQuery,
} from "../../../../store/admin/settings/branchSlice";
import { useSelector } from "react-redux";
import { useState } from "react";

const PrefixForm = ({
  initialValues,
  handleFormSubmit,
  setBranchId,
  isSubmitting = false,
}) => {
  const { isLoading: isLoadingBranches } = useGetBranchesQuery();
  const branches = useSelector(selectBranchesData);

  const [selectedOption, setSelectedOption] = useState({
    value: initialValues.branch_id,
    label: initialValues.branch_name || "Select branch",
  });

  return (
    <div>
      <Formik initialValues={initialValues} onSubmit={handleFormSubmit}>
        <Form>
          <CardComp
            cardClassName={"pt-4"}
            cardBodyClassName={"py0-0 row gx-5 gy-3 justify-content-between"}
          >
            <div className="col-12 col-lg-5">
              <div className="mb-3">
                <SelectComp2
                  LabelClassName={"fw-2"}
                  labelText={"Branch Name"}
                  setSelectedOption={(optionSelected) => {
                    setSelectedOption(optionSelected);
                    setBranchId(optionSelected.value);
                  }}
                  arrayComp={[
                    ...branches.map((branch) => ({
                      value: branch.id,
                      label: branch.name,
                    })),
                  ]}
                  isLoading={isLoadingBranches}
                  value={selectedOption}
                />
              </div>
              <div className="mb-3">
                <TextInputComp
                  LabelText={"Prefix Name"}
                  borderColor="#A1A0A0"
                  LabelClassName={"fw-2"}
                  placeholder={"Enter Prefix"}
                  name={"name"}
                />
              </div>
            </div>
            <div className="col-12 col-lg-5">
              <div className="mb-3">
                <TextInputComp
                  LabelText={"Membership Numeric Count"}
                  borderColor="#A1A0A0"
                  LabelClassName={"fw-2"}
                  placeholder={"001"}
                  name={"count"}
                  type={"number"}
                />
              </div>
              <div>
                <RadioButtonComp
                  LabelText={<span className="ms-3">Autogenerate count</span>}
                  backgroundColor=" #979797"
                  fieldName={"count"}
                />
              </div>
            </div>
          </CardComp>
          <div className="">
            <ButtonComp
              btnText={"Submit prefix"}
              type={"submit"}
              disabled={isLoadingBranches || isSubmitting}
              loading={isLoadingBranches || isSubmitting}
              btnClassName={"bg-primary1 py-2 px-4 rounded text-white border-0"}
            />
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default PrefixForm;
