import React from "react";
import {
  ButtonComp,
  FormikDatePicker,
  MoneyInputComp,
  SelectComp2,
} from "../../../components";
import { Form, Formik } from "formik";
import { AiOutlineClose } from "react-icons/ai";
import * as Yup from "yup";
import { useEnums } from "../../../hooks/useEnums";
import { useGetAllBankInfoQuery } from "../../../store/admin/settings/societyBankSlice";
import { useRecordLoanRepaymentMutation } from "../../../store/admin/loanRepayment";
import toast from "react-hot-toast";
import { PaystackButton } from "react-paystack";

const config = {
  reference: new Date().getTime().toString(),
  email: "user@example.com",
  amount: 20000, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
  publicKey: "pk_test_69503f337ce58c28be91c44aab7db1fee0be467f",
};

export default function LoanRepaymentRecord({ Back, loanRepaymentDetails }) {
  const handlePaystackSuccessAction = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    console.log(reference);
  };

  // you can call this function anything
  const handlePaystackCloseAction = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log("closed");
  };

  const componentProps = {
    ...config,
    text: "Pay with Paystack",
    onSuccess: (reference) => handlePaystackSuccessAction(reference),
    onClose: handlePaystackCloseAction,
  };
  const enums = useEnums();
  const { data, isLoading: isLoadingBank } = useGetAllBankInfoQuery(1);
  const bankOptions = data?.company_banks?.map((item) => {
    return {
      label: item.bank_name,
      value: item.bank_name,
    };
  });

  const initialValues = {
    bank: "",
    amount: loanRepaymentDetails.amount,
    payment_method: "",
    payment_date: "",
  };
  const validationSchema = Yup.object({
    bank: Yup.string().required("Required"),
    amount: Yup.string().required("Required"),
    payment_date: Yup.string().required("Required"),
    payment_method: Yup.string().required("Required"),
  });

  const [recordRepayment, { isLoading }] = useRecordLoanRepaymentMutation();

  const handleFormSubmit = async (values, { resetForm }) => {
    const payload = {
      ...values,
      breakdown_id: loanRepaymentDetails.id,
      loan_request_id: loanRepaymentDetails.loan_request_id,
    };
    console.log(payload);
    const response = await recordRepayment(payload);
    const { data, error } = response;
    if (error) {
      return toast.error(error?.data?.message || "An error occured");
    } else if (data?.status === false) {
      return toast.error(data?.message || "An error occured");
    } else {
      toast.success(data?.message || "Success");
      resetForm();
      Back();
    }
  };

  return (
    <>
      <section className="w-full px-4">
        <div className="flex mb-6 py-3 justify-center items-center relative border-b  ">
          <AiOutlineClose
            size={20}
            onClick={Back}
            className=" pointer absolute top-7 left-4  "
          />
          <h4 className="fw-bold mb-0 text-black1 text-[1.5rem]">
            Loan Record
          </h4>
        </div>

        <div className="w-full">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
            className="w-full"
          >
            {({ values, errors, touched, setFieldValue }) => (
              <Form className="w-full space-y-4">
                <div className="grid md:grid-cols-2 gap-x-8 gap-y-4">
                  <div className="">
                    <SelectComp2
                      LabelClassName={"fw-3"}
                      placeholder={"Select bank tranferred to"}
                      labelText={"Bank Paid to"}
                      arrayComp={bankOptions}
                      isLoading={isLoadingBank}
                      selectedOption={values.bank}
                      setSelectedOption={(selectedOption) => {
                        setFieldValue("bank", selectedOption.value);
                      }}
                      errors={touched.bank && errors.bank ? [errors.bank] : []}
                    />
                  </div>
                  <div className="">
                    <SelectComp2
                      LabelClassName={"fw-3"}
                      placeholder={"Select payment type"}
                      labelText={"Payment Method"}
                      arrayComp={enums["PaymentMethod"]}
                      selectedOption={values.payment_method}
                      setSelectedOption={(selectedOption) => {
                        setFieldValue("payment_method", selectedOption.value);
                      }}
                      errors={
                        touched.payment_method && errors.payment_method
                          ? [errors.payment_method]
                          : []
                      }
                    />
                  </div>
                  <div className="">
                    <MoneyInputComp
                      LabelText={"Amount"}
                      borderColor="#A1A0A0"
                      LabelClassName={"fw-3"}
                      placeholder={"Enter amount"}
                      descClassName="text-danger"
                      name={"amount"}
                      disabled
                    />
                  </div>
                  <div>
                    <FormikDatePicker
                      fieldName={"payment_date"}
                      labelText={"Payment date"}
                      labelClassName={"fw-3"}
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-center  pb-4">
                  <div className="w-[12rem]">
                    {values.payment_method === "PAYSTACK" ? (
                      <PaystackButton
                        className="w-100 bg-primary1 py-[10px] text-white border-0 rounded fw-3"
                        {...componentProps}
                      />
                    ) : (
                      <ButtonComp
                        loading={isLoading}
                        type={"submit"}
                        btnText={"Record loan"}
                        btnClassName={
                          "w-100 bg-primary1 py-[10px] text-white border-0 rounded fw-3"
                        }
                      />
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          {/* <PaystackButton className="bg-[red]" {...componentProps} /> */}
        </div>
      </section>
    </>
  );
}
