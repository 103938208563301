import { createSelector } from "@reduxjs/toolkit";
import { API, ApiPrefixes } from "../../api";
const BASE_URL = ApiPrefixes["adminBranchSettings"];

export const branchExtentedApi = API.injectEndpoints({
  endpoints: (builder) => ({
    getBranches: builder.query({
      query: () => ({
        url: `${BASE_URL}?guard=staff`,
      }),
      transformResponse: (responseData) => responseData.data,
      providesTags: (result = [], error, arg) => {
        return [
          { type: "Branch", id: "LIST" },
          ...result.map(({ id }) => ({ type: "Branch", id })),
        ];
      },
    }),
    addBranch: builder.mutation({
      query: (body) => ({
        url: `${BASE_URL}/add?guard=staff`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: [{ type: "Branch", id: "LIST" }],
    }),
    editBranch: builder.mutation({
      query: (body) => ({
        url: `${BASE_URL}/${body.branchId}/edit?guard=staff`,
        method: "PATCH",
        body: body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Branch", id: arg.branchId },
      ],
    }),
    deleteBranch: builder.mutation({
      query: (branchId) => ({
        url: `${BASE_URL}/${branchId}/delete?guard=staff`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Branch", id: arg }],
    }),
  }),
});

export const {
  useGetBranchesQuery,
  useEditBranchMutation,
  useAddBranchMutation,
  useDeleteBranchMutation,
} = branchExtentedApi;

const selectGetBranchesResult =
  branchExtentedApi.endpoints.getBranches.select();

export const selectBranchesData = createSelector(
  selectGetBranchesResult,
  (result) => {
    if (!result.data) return [];
    return result.data.map((item, index) => {
      return {
        serialNumber: index + 1,
        name: item.name,
        id: item.id,
        address: item.address,
        phone: item.phone,
        email: item.company.email || "no email",
        branchId: item.id,
      };
    });
  }
);

// /settings/branches/:branchId/edit?guard=staff
