import React from "react";
import { BsChevronRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { CardComp } from "../../../../components";

export default function CardInfo({
  name = "",
  item = {},
  contribution = "",
  total = "",
  percentage = "",
  image = "",
  bgColor = "",
  color = "transparent",
  CardClassName,
  arrow,
  onclick,
}) {
  const navigate = useNavigate();

  return (
    <CardCompStyled>
      <CardComp cardClassName={`pt-3 pb-0 border-0 ${CardClassName}`}>
        <div
          onClick={() => onclick && navigate(onclick)}
          className="d-flex align-items-center pointer"
        >
          <div className="me-2">{image && <img src={image} alt="" />}</div>
          <div className="w-100">
            <h4 className="mb-1 font-1">{total || contribution}</h4>
            <p className={`text-muted h50 font-1 ${item?.nameClassName}`}>
              {name}
            </p>
            {percentage && (
              <div
                className="progress col-10"
                style={{ height: "10px", background: bgColor }}
              >
                <div
                  className="progress-bar "
                  role="progressbar"
                  aria-label="Success example"
                  style={{
                    width: percentage ? percentage : "25%",
                    background: color,
                  }}
                  aria-valuenow="75"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            )}
          </div>
          {arrow && (
            <div>
              <BsChevronRight size={20} />
            </div>
          )}
        </div>
      </CardComp>
    </CardCompStyled>
  );
}

const CardCompStyled = styled.div`
  .card:hover {
    transform: scale(1.1);
  }
  .card {
    transition: 1s all;
  }
`;
