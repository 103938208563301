import { TableCompData } from "../../../../components";
import EmptyTable from "../../../../components/Common/EmptyTable";
import { useGetAllBankInfoQuery } from "../../../../store/admin/settings/societyBankSlice";
import { useMemo, useState } from "react";
import { dateFromString } from "../../../../utils";
import TablePagination from "../../../../components/Table/Pagination";

const AllBankInfo = ({ tableColumns, pageToggle }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const { data = {}, isLoading } = useGetAllBankInfoQuery(pageNumber);
  const { company_banks, pagination } = useMemo(() => {
    const { company_banks = [], pagination = {} } = data;
    const perPage = pagination?.perPage ?? 5;
    const formattedBanks = company_banks.map((bank, index) => {
      return {
        ...bank,
        serialNumber: index + 1 + (pageNumber - 1) * perPage,
        date: dateFromString(bank.created_at),
      };
    });
    return {
      company_banks: formattedBanks,
      pagination: pagination,
    };
  }, [data, pageNumber]);

  return (
    <div>
      <TableCompData
        columns={tableColumns}
        marginBottom={"0px"}
        data={company_banks}
        message={
          isLoading ? (
            <p className="py-4">Loading</p>
          ) : (
            <EmptyTable
              Message={"There are no currently bank info"}
              btnText={"Add new bank info"}
              onClick={() => pageToggle()}
            />
          )
        }
      />
      {company_banks.length > 0 && (
        <TablePagination {...pagination} handlePageChange={setPageNumber} />
      )}
    </div>
  );
};

export default AllBankInfo;
