// import { useSelector } from "react-redux";
// import { selectEnums } from "../store/auth";
import { storage } from "../utils/storage";

export const useEnums = () => {
  // const enums = useSelector(selectEnums);
  const enums = storage["localStorage"].get("enums");

  // console.log(enums, "from enums");
  return enums;
};
