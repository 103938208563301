import { useNavigate } from "react-router-dom";
import { PageTitle, PageTitleWithNav } from "../../Admin/modules/PageHeaders";
export default function PageWrapper({
  title,
  back,
  children,
  mainClassName = "",
}) {
  const navigate = useNavigate();
  return (
    <div>
      <main
        className={`mx-2 mx-lg-4 animate__animated  animate__fadeIn mb-5 mt-4 ${mainClassName}`}
      >
        <section className="mb-0">
          {back ? (
            <PageTitleWithNav title={title} action={() => navigate(-1)} />
          ) : (
            <PageTitle title={title} />
          )}
        </section>
        {children}
      </main>
    </div>
  );
}
