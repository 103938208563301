import ReactPaginate from "react-paginate";
import styled from "styled-components";
function TablePagination({
  count,
  currentPage,
  perPage,
  total,
  totalPages,
  handlePageChange,
  marginTop,
  marginBottom,
}) {
  const prevPage = currentPage - 1;
  const beginning = 1 + prevPage * perPage;
  const end = prevPage * perPage + count;
  const handlePageClick = (event) => {
    const selectedPageNumber = event.selected + 1;
    handlePageChange(selectedPageNumber);
  };

  return (
    <Wrapper marginTop={marginTop} marginBottom={marginBottom}>
      <p className="m-0">
        Showing {beginning} - {end} items out of {total} results found
      </p>
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={totalPages}
        previousLabel="<"
        renderOnZeroPageCount={null}
        activeLinkClassName="current"
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "2rem")};
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : "0rem"};
  padding-inline: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem 2rem;
  color: #c7c7c7;
  ul {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 0;
    margin: 0;
  }
  a {
    color: #404b52;
    background: #f5f5f5;
    border: 1px solid #eeeeee;
    border-radius: 4px;
    padding: 6px 10px;
  }
  .current {
    background-color: rgba(56, 56, 56, 0.5);
    border-radius: 4px;
    color: white;
  }
`;

export default TablePagination;
