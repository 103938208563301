/* eslint-disable no-restricted-globals */
import React, { useState } from "react";
import { BiChevronRight } from "react-icons/bi";
import {
  ButtonComp,
  CardComp,
  ModalCompAdvanceUpdate,
  MoneyInputComp,
  SelectComp2,
  SelectCompWithFormattedLabel,
  TextInputComp,
} from "../../../../components";
import FallbackImage from "../../../../components/Common/ImageWrapper";
import FilterFind from "../../../../components/FilterFind";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useGetMembersQuery } from "../../../../store/admin/memberManagement/memberSlice";
import { useEnums } from "../../../../hooks/useEnums";
import {
  useCreateLoanRequestMutation,
  useGetSelectableLoanTypesQuery,
} from "../../../../store/admin/loan";
import NonmemberGuarantorForm from "./NonmemberGuarantorForm";
import toast from "react-hot-toast";

export default function NewApplicationForm({ onNext }) {
  const [selectedMember, setSelectedMember] = useState("");
  const [loanTypeDetails, setLoanTYpeDetails] = useState({});
  const [guarantors, setGuarantors] = useState([]);
  const [nonMembersGuarantorDetails, setNonMemberGuarantorDetails] = useState(
    []
  );
  const [modalPage, setModalPage] = React.useState("");
  const { data: membersList } = useGetMembersQuery(1);
  const enums = useEnums();

  const { data: loanTypes, isLoading: isLoadingLoanTypes } =
    useGetSelectableLoanTypesQuery();

  const formattedLoanTypes = loanTypes?.data?.map((item) => {
    return {
      label: item.name,
      value: item.id,
      max_guarantor: item.max_guarantor,
      min_guarantor: item.min_guarantor,
    };
  });

  React.useEffect(() => {
    if (loanTypeDetails?.max_guarantor) {
      const initialGuarantors = Array.from(
        { length: loanTypeDetails?.max_guarantor },
        (_, index) => ({
          label: `Guarantor ${index + 1}`,
          value: "",
        })
      );
      setGuarantors(initialGuarantors);
    }
  }, [loanTypeDetails?.max_guarantor]);

  const handleGuarantorChange = (index, value, setFieldValue) => {
    const updatedGuarantors = [...guarantors];
    updatedGuarantors[index].value = value;
    setGuarantors(updatedGuarantors);
    setFieldValue(`guarantor${index + 1}`, value);
  };

  const { allMembers } = React.useMemo(() => {
    const fetchedMembers = membersList?.members || [];
    const formattedMembers = fetchedMembers.map((item, index) => {
      return {
        label: item.first_name + " " + item.last_name,
        name: item.first_name + " " + item.last_name,
        photo: item.photo,
        userId: item.member_no,
        value: item.id,
      };
    });
    return { allMembers: formattedMembers };
  }, [membersList]);

  const initialValues = {
    loan_type_id: "",
    repayment_plan: "",
    duration: "",
    duration_type: "",
    amount: "",
    ...guarantors?.reduce((acc, guarantor, index) => {
      return { ...acc, [`guarantor${index + 1}`]: guarantor.value };
    }, {}),
  };
  const validationSchema = Yup.object({
    loan_type_id: Yup.string().required("Required"),
    repayment_plan: Yup.string().required("Required"),
    duration: Yup.number().required("Required"),
    duration_type: Yup.string().required("Required"),
    amount: Yup.string().required("Required"),
  });
  const getMemberGuarantors = (values) => {
    return Object.keys(values)
      .filter((key) => key.startsWith("guarantor") && values[key])
      .map((key) => values[key]);
  };

  const filterNonMemberGuarantors = (guarantors) => {
    return guarantors.filter(
      (item) =>
        typeof item !== "string" || !item.includes("non_member_guarantor")
    );
  };

  const [createLoanRequest, { isLoading: isCreating }] =
    useCreateLoanRequestMutation();

  const handleFormSubmit = async (values, { resetForm }) => {
    const member_id = selectedMember.value;
    const { loan_type_id, repayment_plan, duration, duration_type, amount } =
      values;

    const member_guarantors = getMemberGuarantors(values);
    const formattedMemberGuarantors =
      filterNonMemberGuarantors(member_guarantors);

    const payload = {
      member_id,
      loan_type_id,
      repayment_plan,
      duration,
      duration_type,
      amount,
      member_guarantors: formattedMemberGuarantors,
      non_member_guarantors: nonMembersGuarantorDetails,
    };

    const response = await createLoanRequest(payload);
    const { data, error } = response;
    if (error) {
      return toast.error(error?.data?.message || "An error occured");
    } else if (data?.status === false) {
      return toast.error(data?.message || "An error occured");
    } else {
      toast.success(data?.message || "Success");
      setNonMemberGuarantorDetails([]);
      setLoanTYpeDetails({});
      setGuarantors([]);
      setSelectedMember("");
      resetForm();
    }
  };

  const useModal = [
    {
      name: "NonMemberGuarantor",
      component: (
        <NonmemberGuarantorForm
          Back={() => setModalPage()}
          setNonMemberGuarantorDetails={setNonMemberGuarantorDetails}
        />
      ),
    },
  ];
  return (
    <section className="px-lg-3 min-vh-100 pb-5 mb-3">
      <ModalCompAdvanceUpdate
        pageName={modalPage}
        arrayComp={useModal}
        handleClose={() => setModalPage()}
        centered
        size={"lg"}
      />
      <FilterFind rightSide={false} title="New Application" />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {({ values, errors, touched, setFieldValue }) => (
          <Form>
            <CardComp cardClassName={"pb-5 pt-4"}>
              {selectedMember && (
                <div
                  className={`card col-12 col-lg-5 px-3 mb-4 py-4  shadow w-fit col`}
                  style={{ border: "none" }}
                >
                  <div
                    className={`card-body py-0 d-flex w-100  align-items-center gap-4 w-100 `}
                  >
                    <FallbackImage
                      className="rounded-circle  w-[56px] h-[56px] "
                      src={selectedMember.photo}
                    />
                    <div className=" d-flex gx-0 gap-5 align-items-center">
                      <div>
                        <p className="mb-0 fw-2">
                          <small>{selectedMember.name}</small>
                        </p>
                        <p className="mb-0 opacity-50">{`Member ID: ${selectedMember.userId}`}</p>
                      </div>
                      <div>
                        <p className="mb-2 fw-2  ">
                          <small style={{ color: "#858585" }}>
                            Credit score
                          </small>
                        </p>
                        <p
                          style={{
                            width: "80px",
                            height: "2.5rem",
                            borderRadius: "20px",
                            background: "#FF4343",
                            textAlign: "center",
                            display: "grid",
                            placeItems: "center",
                          }}
                        >
                          47%
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div>
                <div>
                  <div className="row gx-0 gy-3 justify-content-between">
                    {!selectedMember && (
                      <div className="col-12 col-lg-5">
                        <SelectCompWithFormattedLabel
                          setOption={setSelectedMember}
                          labelText="Member name"
                          LabelClassName="fw-2"
                          arrayComp={allMembers}
                        />
                      </div>
                    )}
                    <div className="col-12 col-lg-5">
                      <SelectComp2
                        LabelClassName={"fw-2"}
                        placeholder={"Select loan type"}
                        labelText={"Loan type"}
                        arrayComp={formattedLoanTypes}
                        isLoading={isLoadingLoanTypes}
                        selectedOption={values.loan_type_id}
                        setSelectedOption={(selectedOption) => {
                          setLoanTYpeDetails(selectedOption);

                          setFieldValue("loan_type_id", selectedOption.value);
                        }}
                        errors={
                          touched.loan_type_id && errors.loan_type_id
                            ? [errors.loan_type_id]
                            : []
                        }
                      />
                    </div>
                    <div className="col-12 col-lg-5">
                      <TextInputComp
                        LabelText={"Duration"}
                        borderColor="#A1A0A0"
                        LabelClassName={"fw-2"}
                        placeholder={"0"}
                        name="duration"
                        type="text"
                      />
                    </div>
                    <div className="col-12 col-lg-5">
                      <SelectComp2
                        LabelClassName={"fw-2"}
                        labelText={"Duration type"}
                        arrayComp={enums["DurationType"]}
                        selectedOption={values.duration_type}
                        setSelectedOption={(selectedOption) =>
                          setFieldValue("duration_type", selectedOption.value)
                        }
                        errors={
                          touched.duration_type && errors.duration_type
                            ? [errors.duration_type]
                            : []
                        }
                      />
                    </div>
                    <div className="col-12 col-lg-5">
                      <MoneyInputComp
                        LabelText={"Amount"}
                        borderColor="#A1A0A0"
                        LabelClassName={"fw-2"}
                        placeholder={"Enter amount"}
                        descClassName="text-danger"
                        name={"amount"}
                      />
                    </div>
                    {loanTypeDetails?.max_guarantor &&
                      guarantors?.map((guarantor, index) => (
                        <div className="col-12 col-lg-5" key={index}>
                          <SelectComp2
                            LabelClassName={"fw-2"}
                            labelText={guarantor.label}
                            placeholder={`Select guarantor ${index + 1}`}
                            arrayComp={[
                              ...allMembers,
                              {
                                value: `non_member_guarantor_${index + 1}`,
                                label: (
                                  <ButtonComp
                                    btnClassName={
                                      "w-100 bg-primary1 p-2  text-white border-0 rounded"
                                    }
                                    btnText={
                                      <div
                                        className="d-flex justify-content-between align-items-center"
                                        onClick={() => {
                                          scroll(0, 0);
                                          setModalPage("NonMemberGuarantor");
                                        }}
                                      >
                                        Non - member
                                        <BiChevronRight size={20} />
                                      </div>
                                    }
                                  />
                                ),
                              },
                            ]}
                            selectedOption={values[`guarantor${index + 1}`]}
                            setSelectedOption={(selectedOption) =>
                              handleGuarantorChange(
                                index,
                                selectedOption.value,
                                setFieldValue
                              )
                            }
                            errors={
                              touched[`guarantor${index + 1}`] &&
                              errors[`guarantor${index + 1}`]
                                ? [errors[`guarantor${index + 1}`]]
                                : []
                            }
                          />
                        </div>
                      ))}
                    <div className="col-12 col-lg-5">
                      <SelectComp2
                        LabelClassName={"fw-2"}
                        placeholder={"Select repayment plan"}
                        labelText={"Repayment plan"}
                        arrayComp={enums["RepaymentPlan"]}
                        selectedOption={values.repayment_plan}
                        setSelectedOption={(selectedOption) =>
                          setFieldValue("repayment_plan", selectedOption.value)
                        }
                        errors={
                          touched.repayment_plan && errors.repayment_plan
                            ? [errors.repayment_plan]
                            : []
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </CardComp>
            <div className="col-12 py-3"></div>
            <div className="d-flex justify-content-start">
              <div className="col-lg-4 col-xl-2">
                <ButtonComp
                  type="submit"
                  btnText={"Submit application"}
                  onClick={() => {}}
                  btnClassName={
                    "w-100 bg-primary1 px-2 py-3 text-white border-0 rounded"
                  }
                  loading={isCreating}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </section>
  );
}
