import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { Provider } from "react-redux";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store";
import { Toaster } from "react-hot-toast";
import LogRocket from "logrocket";

const root = ReactDOM.createRoot(document.getElementById("root"));
LogRocket.init("merlotek/uniquecoop");
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          success: {
            style: {
              background: "green",
              color: "white",
              fontSize: "16px",
            },
          },
          error: {
            style: {
              background: "red",
              color: "white",
              fontSize: "16px",
            },
          },
        }}
      />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
