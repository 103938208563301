import React from "react";
import SearchLoanRepaymentByMember from "./SearchLoanRepaymentByMember";
import AllLoanRepaymentByMember from "./AllLoanRepaymentByMember";
import { useLocation } from "react-router-dom";

const LoanRepaymentByMemberSearchPage = () => {
  const location = useLocation();
  return (
    <div>
      {location.hash === "#search_by_member" || !location.hash ? (
        <SearchLoanRepaymentByMember hash={"#all_loan"} />
      ) : null}
      {location.hash === "#all_loan" ? (
        <AllLoanRepaymentByMember hash={"#search_by_member"} />
      ) : null}
    </div>
  );
};

export default LoanRepaymentByMemberSearchPage;
