import { API, ApiPrefixes } from "../api";

export const memberLoanApi = API.injectEndpoints({
  endpoints: (builder) => ({
    getPendingMemberLoanRequests: builder.query({
      query: ({ page, member_id }) => ({
        url:
          ApiPrefixes["membersLoan"] +
          `/pending/${member_id}?guard=member&page=${page}&limit=20`,
      }),
      providesTags: (result, error, arg) => [
        { type: "member-pending-loans-list", id: arg },
      ],
    }),

    getApprovedMemberLoanList: builder.query({
      query: ({ page, member_id }) => ({
        url:
          ApiPrefixes["membersLoan"] +
          `/approved/${member_id}?guard=member&page=${page}&limit=20`,
      }),
      // providesTags: "approved-loans-list",
      providesTags: (result, error, arg) => [
        { type: "member-approved-loans-list", id: arg },
      ],
    }),

    getDeclinedMemberLoanList: builder.query({
      query: ({ page, member_id }) => ({
        url:
          ApiPrefixes["membersLoan"] +
          `/declined/${member_id}?guard=member&page=${page}&limit=20`,
      }),
      // providesTags: "declined-loans-list",
      providesTags: (result, error, arg) => [
        { type: "member-declined-loans-list", id: arg },
      ],
    }),

    getDisbursedMemberLoanList: builder.query({
      query: ({ page, member_id }) => ({
        url:
          ApiPrefixes["membersLoan"] +
          `/disbursed/${member_id}?guard=member&page=${page}&limit=20`,
      }),
      // providesTags: "disbursed-loans-list",
      providesTags: (result, error, arg) => [
        { type: "member-disbursed-loans-list", id: arg },
      ],
    }),

    createMemberLoanRequest: builder.mutation({
      query: (payload) => ({
        url: ApiPrefixes["membersLoan"] + `/add?guard=member`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: [{ type: "member-pending-loans-list" }],
    }),

    loanCalculator: builder.mutation({
      query: (payload) => ({
        url: ApiPrefixes["membersLoan"] + `/calculator?guard=member`,
        method: "POST",
        body: payload,
      }),
    }),

    getMemberLoanTypes: builder.query({
      query: (page) => ({
        url: ApiPrefixes["membersLoan"] + `/types?guard=member`,
      }),

      providesTags: (result, error, arg) => [
        { type: "loan-types-list", id: arg },
      ],
    }),

    getUpcomingRepaymentLoanList: builder.query({
      query: ({ page, member_id }) => ({
        url:
          ApiPrefixes["membersLoan"] +
          `/repayments/upcoming/${member_id}?guard=member&page=${page}&limit=20`,
      }),
      providesTags: (result, error, arg) => [
        { type: "member-upcoming-repayment-list", id: arg },
      ],
    }),
    getDueRepaymentLoanList: builder.query({
      query: ({ page, member_id }) => ({
        url:
          ApiPrefixes["membersLoan"] +
          `/repayments/due/${member_id}?guard=member&page=${page}&limit=20`,
      }),
      providesTags: (result, error, arg) => [
        { type: "member-due-repayment-list", id: arg },
      ],
    }),
    getOverdueRepaymentLoanList: builder.query({
      query: ({ page, member_id }) => ({
        url:
          ApiPrefixes["membersLoan"] +
          `/repayments/overdue/${member_id}?guard=member&page=${page}&limit=20`,
      }),
      providesTags: (result, error, arg) => [
        { type: "member-overdue-repayment-list", id: arg },
      ],
    }),
  }),
});

export const {
  useGetPendingMemberLoanRequestsQuery,
  useGetApprovedMemberLoanListQuery,
  useGetDeclinedMemberLoanListQuery,
  useGetDisbursedMemberLoanListQuery,
  useCreateMemberLoanRequestMutation,
  useGetMemberLoanTypesQuery,
  useLoanCalculatorMutation,
  useGetUpcomingRepaymentLoanListQuery,
  useGetDueRepaymentLoanListQuery,
  useGetOverdueRepaymentLoanListQuery,
} = memberLoanApi;
