import { TableCompData, ButtonComp } from "../../../../components";
import EmptyTable from "../../../../components/Common/EmptyTable";
import {
  SettingCompanyPrefixHeader,
  ViewStaffData,
} from "../../../../utils/TableHeader";
import { PageTitle } from "../../modules/PageHeaders";
export default function AllCompanyPrefix({
  TableDropDown,
  onSubmit,
  tableHeader,
  tableData,
  emptyMessage,
  btnText,
  marginBottom,
  isFetching,
}) {
  return (
    <div>
      <div className="mb-4 text-end">
        <ButtonComp
          btnText={"Create Prefix"}
          onClick={onSubmit}
          btnClassName="bg-primary1  py-2 px-4 rounded text-white border-0"
        />
      </div>
      <div className="mb-4">
        <PageTitle title={"Cooperative Prefix"} />
      </div>
      <div className="mb-5">
        <TableCompData
          columns={
            tableHeader
              ? tableHeader
              : SettingCompanyPrefixHeader(TableDropDown)
          }
          data={tableData ? tableData : ViewStaffData}
          marginBottom={marginBottom}
          message={
            isFetching ? (
              <div className="py-4">
                <h3>Loading...</h3>
              </div>
            ) : (
              <EmptyTable
                Message={emptyMessage || "No prefix has been created"}
                btnText={btnText}
                onClick={onSubmit}
              />
            )
          }
        />
      </div>
    </div>
  );
}
