import { toast } from "react-hot-toast";

const toastNotify = (error, data, toastId, navigate) => {
  if (error && error.status === 401) {
    console.log(error);
    toast.error("Login to continue", {
      id: toastId,
    });
    return navigate("/login");
  }
  if (error) {
    return toast.error(error?.data.message || "An error occured", {
      id: toastId,
    });
  }
  if (data.status === false) {
    return toast.error(data.message || "An error occured", { id: toastId });
  }
  return toast.success(data.message || "success", { id: toastId });
};

export default toastNotify;
