import { useState } from "react";
import { ButtonComp, CardComp } from "../../../components";
import FallbackImage from "../../../components/Common/ImageWrapper";
import { ColorList } from "../../../utils";
import { PageTitle } from "../modules/PageHeaders";

export default function ConfigurationHome() {
  const [changeColor, setChangeColor] = useState(false);
  return (
    <section className="mx-2 mx-lg-4 min-vh-100 mb-3">
      <PageTitle title={"Portal Settings"} />
      <CardComp cardClassName={"pt-4"} cardBodyClassName={"py-0"}>
        <div className="row gx-0 justify-content-between">
          <div className="col-lg-8 bg-white2 p-4 rounded mb-3 mb-lg-0">
            <div className="d-flex align-items-center gap-4 flex-wrap flex-lg-nowrap">
              <div className="">
                <div className="position-relative">
                  <FallbackImage
                    width="100px"
                    height="100px"
                    src="/Staff/avatar.svg"
                  />
                  <div
                    className="position-absolute"
                    style={{ bottom: "0px", right: "-20px" }}
                  >
                    <img src="/Icon/addImage.svg" alt="" />
                  </div>
                </div>
              </div>
              <div className="">
                <h4 className="fw-bold">Ifesowapo Cooperative</h4>
                <p className="mb-0">Ilupeju Avenue, Lagos, Nigeria</p>
                <p>(+234) 8140307009 | ifesowapocs@uniquecoop.com</p>
              </div>
              <div className="text-center">
                <img src="/Icon/edit.svg" alt="" />
              </div>
            </div>
          </div>
          <div className="col-lg-3 py-3 px-4 bg-white2 rounded text-center d-flex flex-column align-items-center">
            <h6>Theme</h6>
            <div
              className="bg-primary1 col-5  rounded mb-4"
              style={{ width: "50px", height: "50px" }}
            ></div>
            <div className="col-8">
              <ButtonComp
                btnText={"Change"}
                onClick={() => setChangeColor(true)}
                btnClassName={
                  "bg-primary1 text-white w-100 rounded py-1 px-4 fw-2"
                }
              />
            </div>
          </div>
        </div>
        {changeColor && (
          <div
            className={`${
              changeColor && `animate__backInRight animate__animated`
            }`}
          >
            <div className="my-4">
              <hr />
            </div>
            <div>
              <h6 className="fw-3 font-2 mb-4">Change Theme</h6>
              <div className="d-flex flex-wrap justify-content-center justify-content-lg-start mb-5">
                {ColorList?.map((item, i) => (
                  <div
                    className="position-relative me-5 mb-4"
                    style={{
                      width: "100px",
                      height: "100px",
                      borderRadius: "10px",
                      background: item,
                    }}
                  >
                    {i === 0 && (
                      <div className="position-absolute end-0">
                        <img src="/Icon/tick.svg" alt="" />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </CardComp>
      {changeColor && (
        <ButtonComp
          btnText={"Update Profile"}
          btnClassName={"bg-primary1 text-white rounded py-2 px-4 fw-2"}
        />
      )}
    </section>
  );
}
