import { useCallback } from "react";
import { Form, Spinner } from "react-bootstrap";
import { useDropzone } from "react-dropzone";

export const ReusableFileUploaderComp = ({
  bodyText,
  LabelText,
  labelClassName,
  handleUpload,
  errors,
  required,
  loading,
}) => {
  //   const [loading, setLoading] = useState();
  const onDrop = useCallback((acceptedFiles) => {
    // console.log(acceptedFiles)
    handleUpload(acceptedFiles[0]);
    // Do something with the files
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //   console.log(loading);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const Loader = () => {
    return (
      <Spinner size="sm" animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  };

  return (
    <div>
      <div>
        {LabelText && (
          <Form.Label className={labelClassName}>
            {LabelText}

            {required && <span className="text6">*</span>}
          </Form.Label>
        )}
      </div>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {
          <div className="d-inline rounded border-2 pointer">
            {isDragActive && <div className="">{bodyText}</div>}
            <div className="d-flex justify-content-start align-items-center">
              {bodyText}
              {loading && <Loader />}
            </div>
          </div>
        }
      </div>
      {/* {errors?.map((error, i) => (
        <small className="text-danger mt-2" key={i}>
          {error}
        </small>
      ))} */}
    </div>
  );
};
