import { CardComp } from "../../../components";
import { BsArrowLeft } from "react-icons/bs";

export const PageTitle = ({ title }) => {
  return (
    <CardComp cardClassName={"pt-4"} cardBodyClassName={"py-0"}>
      <h4 className="font-bold text-base text-[#45464E]">{title}</h4>
    </CardComp>
  );
};

export const PageTitleWithNav = ({ title, highlightText, action }) => {
  return (
    <CardComp
      cardBodyClassName={"py-0"}
      cardClassName={"border-0 rounded-10 px-lg-4 mb-3 pt-4"}
    >
      <div className="d-flex ">
        <BsArrowLeft size={30} onClick={action} className="me-3 pointer" />
        <h4 className="font-bold text-base text-[#45464E]">
          {title}{" "}
          <span style={{ color: "var(--primary)" }} className="">
            {highlightText}
          </span>
        </h4>
      </div>
    </CardComp>
  );
};
