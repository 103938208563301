import React from "react";
import { BsArrowLeft } from "react-icons/bs";
import { ButtonComp, CardComp, FormikSelectComp } from "../../../../components";
import { useSelector } from "react-redux";
import {
  selectBranchesData,
  useGetBranchesQuery,
} from "../../../../store/admin/settings/branchSlice";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { useAssignBranchMutation } from "../../../../store/admin/staff.js";
import { toast } from "react-hot-toast";

export default function AddNewBranch({ onBack, refetch }) {
  const { id } = useParams();
  const { isLoading: isLoadingBranches } = useGetBranchesQuery();
  const branches = useSelector(selectBranchesData);

  const initialValues = {
    branches: [{ branch_id: "" }],
  };

  const validationSchema = Yup.object({
    branches: Yup.array().of(
      Yup.object({
        branch_id: Yup.string().required("Required"),
      })
    ),
  });

  const handleAddBranch = (formik) => {
    formik.push({ branch_id: "" });
  };

  const handleRemoveBranch = (arrayHelpers, index) => {
    if (arrayHelpers.form.values.branches.length === 1) {
      // Do not remove the last item
      return;
    }
    arrayHelpers.remove(index);
  };

  const [assignBranch, { isLoading }] = useAssignBranchMutation();

  const handleFormSubmit = async (formikValues, { resetForm }) => {
    const updatedValues = {
      payload: formikValues,
      staffId: id,
    };

    const response = await assignBranch(updatedValues);
    if ("error" in response) {
      const { data } = response?.error;
      toast.error(data.message);
      return;
    }
    const { message, status } = response?.data;
    if (!status) {
      toast.error(message);
    }
    toast.success(message);
    refetch();
    onBack();
  };

  return (
    <section className="py-5 px-5 min-vh-100">
      <CardComp cardClassName={"border-0 px-lg-4 mb-3 py-2"}>
        <div className="d-flex ">
          <BsArrowLeft onClick={onBack} size={30} className="me-3 pointer" />
          <h4 className="fw-bold mb-0 text-black1">Add new branch</h4>
        </div>
      </CardComp>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {(formik) => (
          <Form className="my-4">
            <CardComp>
              <div className=" row g-5 rounded">
                <FieldArray name="branches">
                  {(arrayHelpers) => (
                    <>
                      {formik?.values?.branches?.map((branch, index) => (
                        <div className="col-lg-6" key={index}>
                          <div className="d-flex align-items-end gap-4">
                            <FormikSelectComp
                              labelClassName={"fw-2"}
                              fieldName={`branches[${index}].branch_id`}
                              labelText={"Branch"}
                              options={branches.map((branch) => ({
                                value: branch.id,
                                label: branch.name,
                              }))}
                            />
                            <button
                              disable={
                                formik?.values?.branches.length === 1
                                  ? true
                                  : false
                              }
                              onClick={() =>
                                handleRemoveBranch(arrayHelpers, index)
                              }
                              className={`py-1 px-1 rounded-5 bg-primary1`}
                            >
                              <img src="/Images/trash.png" alt="delete" />
                            </button>
                          </div>
                        </div>
                      ))}
                      <div className="col-lg-6 d-flex align-items-end">
                        <div className="d-flex align-items-end w-100">
                          <div className="col-10">
                            <ButtonComp
                              loading={false} // Replace with your loading state
                              onClick={() => handleAddBranch(arrayHelpers)}
                              btnText={"Add New"}
                              btnClassName={
                                "bg-primary1 text-white py-2 border-0 px-4 py-2 rounded"
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </FieldArray>
              </div>
            </CardComp>
            <div className="col-lg-6 d-flex align-items-end">
              <div className="d-flex align-items-end w-100">
                <div className="col-10">
                  <ButtonComp
                    loading={isLoading} // Replace with your loading state
                    type="submit"
                    disabled={isLoadingBranches}
                    btnText={"Submit"}
                    btnClassName={
                      "bg-primary1 text-white py-2 border-0 w-100 rounded"
                    }
                  />
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </section>
  );
}
