import { API } from "../api";
const BASE_URL = "/cooperatives/communications/groups";
const BASE_URL_2 = "/cooperatives/communications/messages";

const communicationExtendedApi = API.injectEndpoints({
  endpoints: (builder) => ({
    getGroupsNames: builder.query({
      query: (page = 1) => `${BASE_URL}?guard=staff&page=${page}`,
      transformResponse: (responseData) => responseData.data,
      providesTags: (result = {}) => {
        if (!result?.communication_groups)
          return [{ type: "CommGroupMgt", id: "LIST" }];
        return [
          { type: "CommGroupMgt", id: "LIST" },
          ...result.communication_groups.map(({ id }) => ({
            type: "CommGroupMgt",
            id: id,
          })),
        ];
      },
    }),
    getGroupById: builder.query({
      query: (groupId) => `${BASE_URL}/${groupId}?guard=staff`,
      transformResponse: (responseData) => responseData.data,
      providesTags: (result, err, arg) => [{ type: "CommGroupMgt", id: arg }],
    }),
    createGroup: builder.mutation({
      query: (body) => ({
        url: `${BASE_URL}/add?guard=staff`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: [{ type: "CommGroupMgt", id: "LIST" }],
    }),
    updateGroupName: builder.mutation({
      query: (body) => ({
        url: `${BASE_URL}/${body.id}/edit?guard=staff`,
        method: "PATCH",
        body: body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "CommGroupMgt", id: arg.id },
      ],
    }),
    getGroupMembers: builder.query({
      query: ({ page = 1, groupId }) =>
        `${BASE_URL}/members/${groupId}?guard=staff&page=${page}`,
      transformResponse: (responseData) => responseData.data,
      providesTags: (result, error, arg) => {
        if (!result?.communication_group_members)
          return [
            { type: "CommGroupMgt", id: "LIST" },
            { type: "CommGroupMgt", id: "MemberList" },
          ];
        return [
          { type: "CommGroupMgt", id: "LIST" },
          ...result?.communication_group_members.map(({ group_id }) => ({
            type: "CommGroupMgt",
            id: group_id,
          })),
        ];
      },
    }),
    addMembersToGroup: builder.mutation({
      query: (body) => ({
        url: `${BASE_URL}/members/add?guard=staff`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "CommGroupMgt", id: arg.group_id },
        { type: "CommGroupMgt", id: "MemberList" },
      ],
    }),
    createMessage: builder.mutation({
      query: (body) => ({
        url: `${BASE_URL_2}/${body.endpoint}?guard=staff`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: [{ type: "CommGroupMessage", id: "LIST" }],
    }),
    sendDraftedMessage: builder.mutation({
      query: (messageId) => ({
        url: `${BASE_URL_2}/${messageId}?guard=staff`,
      }),
      invalidatesTags: [{ type: "CommGroupMessage", id: "LIST" }],
    }),
    getCommunicationMessages: builder.query({
      query: ({ page, status }) =>
        `${BASE_URL_2}?guard=staff&page=&${page || 1}&status=${
          status || "DRAFTED"
        }&limit=20`,
      transformResponse: (responseData) => responseData.data,
      providesTags: () => {
        return [{ type: "CommGroupMessage", id: "LIST" }];
      },
    }),
  }),
});

export const {
  useCreateGroupMutation,
  useGetGroupsNamesQuery,
  useUpdateGroupNameMutation,
  useGetGroupMembersQuery,
  useGetGroupByIdQuery,
  useAddMembersToGroupMutation,
  useCreateMessageMutation,
  useSendDraftedMessageMutation,
  useGetCommunicationMessagesQuery,
} = communicationExtendedApi;
