import React from "react";
import { TableCompData } from "../../../../components";
import EmptyTable from "../../../../components/Common/EmptyTable";
import { LoanStatusHeader } from "../../../../utils/TableHeader";
import TablePagination from "../../../../components/Table/Pagination";

export default function AllLoanStatus({
  name,
  loading,
  setPageNumber,
  pagination,
  ButtonArray,
  TableData,
}) {
  return (
    <>
      <main>
        <div className="mb-5">
          <TableCompData
            marginBottom={"1rem"}
            columns={LoanStatusHeader(ButtonArray)}
            data={TableData}
            message={
              loading ? (
                <div className=" py-4 ">
                  <h3>Loading...</h3>
                </div>
              ) : (
                <EmptyTable
                  Message={`There are currently no ${name}`}
                  // btnText={"Add Staff"}
                />
              )
            }
          />
          {TableData?.length > 0 && (
            <TablePagination
              marginTop={"0"}
              handlePageChange={setPageNumber}
              {...pagination}
            />
          )}
        </div>
      </main>
    </>
  );
}
