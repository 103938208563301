import React from "react";
import {
  ButtonComp,
  MoneyInputComp,
  SelectComp2,
  TextInputComp,
} from "../../../components";
import { Form, Formik } from "formik";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { AiOutlineClose } from "react-icons/ai";
import { useEnums } from "../../../hooks/useEnums";
import {
  useGetMemberLoanTypesQuery,
  useLoanCalculatorMutation,
} from "../../../store/member/member-loan";
import { storage } from "../../../utils/storage";
import { useNavigate } from "react-router-dom";

const LoanCalculatorModal = ({ setModalPage }) => {
  const enums = useEnums();
  const navigate = useNavigate();
  const { data: loanTypes, isLoading: isLoadingLoanTypes } =
    useGetMemberLoanTypesQuery();

  const formattedLoanTypes = loanTypes?.data?.configs?.map((item) => {
    return {
      label: item.name,
      value: item.id,
      max_guarantor: item.max_guarantor,
      min_guarantor: item.min_guarantor,
    };
  });

  const [calculateLoan, { isLoading }] = useLoanCalculatorMutation();

  const handleLoanCalculation = async (values, { resetForm }) => {
    const response = await calculateLoan(values);
    const { data, error } = response;
    if (error) {
      return toast.error(error?.data?.message || "An error occured");
    } else if (data?.status === false) {
      return toast.error(data?.message || "An error occured");
    } else {
      toast.success(data?.message || "Successful");
      storage["localStorage"].set("loan_calculator_result", data?.data);
      setModalPage();
      resetForm();
      navigate("/member/loan_management/calculation");
    }
  };

  return (
    <div className="relative px-4 pb-4">
      <Formik
        initialValues={{
          loan_type_id: null,
          repayment_plan: "",
          duration_type: "",
          amount: null,
          duration: null,
        }}
        validationSchema={Yup.object({
          loan_type_id: Yup.string().required("Required"),
          amount: Yup.string().required("Required"),
          duration: Yup.string().required("Required"),
          duration_type: Yup.string().required("Required"),
          repayment_plan: Yup.string().required("Required"),
        })}
        onSubmit={handleLoanCalculation}
      >
        {({ values, errors, touched, setFieldValue }) => (
          <Form className=" space-y-4">
            <AiOutlineClose
              onClick={() => setModalPage()}
              className="absolute top-4 left-7"
            />
            <h4 className="border-b fw-bold py-2 mb-4 text-[1.5rem] !text-primary text-center w-full ">
              Loan Disbursement
            </h4>
            <SelectComp2
              LabelClassName={"fw-2"}
              placeholder={"Select loan type"}
              labelText={"Loan type"}
              arrayComp={formattedLoanTypes}
              isLoading={isLoadingLoanTypes}
              selectedOption={values.loan_type_id}
              setSelectedOption={(selectedOption) => {
                setFieldValue("loan_type_id", selectedOption.value);
              }}
              errors={
                touched.loan_type_id && errors.loan_type_id
                  ? [errors.loan_type_id]
                  : []
              }
            />
            <MoneyInputComp
              LabelText={"Loan amount"}
              borderColor="#A1A0A0"
              LabelClassName={"fw-2"}
              placeholder={"Enter amount"}
              descClassName="text-danger"
              name={"amount"}
            />

            <TextInputComp
              LabelText={"Duration"}
              borderColor="#A1A0A0"
              LabelClassName={"fw-2"}
              placeholder={"0"}
              name="duration"
              type="text"
            />
            <SelectComp2
              LabelClassName={"fw-2"}
              labelText={"Duration type"}
              arrayComp={enums["DurationType"]}
              selectedOption={values.duration_type}
              setSelectedOption={(selectedOption) =>
                setFieldValue("duration_type", selectedOption.value)
              }
              errors={
                touched.duration_type && errors.duration_type
                  ? [errors.duration_type]
                  : []
              }
            />

            <SelectComp2
              LabelClassName={"fw-2"}
              placeholder={"Select  repayment scheme"}
              labelText={"Repayment scheme"}
              arrayComp={enums["RepaymentPlan"]}
              isLoading={isLoadingLoanTypes}
              selectedOption={values?.repayment_plan}
              setSelectedOption={(selectedOption) => {
                setFieldValue("repayment_plan", selectedOption?.value);
              }}
              errors={
                touched?.repayment_plan && errors?.repayment_plan
                  ? [errors?.repayment_plan]
                  : []
              }
            />

            <div className="row justify-content-around pt-8">
              <ButtonComp
                loading={isLoading}
                type="submit"
                btnText={"Calculate loan interest"}
                ButtonInlineStyle={{ backgroundColor: "#8B313A" }}
                btnClassName={"border-0 text-white  w-100 py-2 rounded"}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default LoanCalculatorModal;
