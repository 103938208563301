import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import {
  useApproveMemberMutation,
  useDeclineMemberMutation,
  useSuspendMemberMutation,
  useDeleteMemberMutation,
  useUnSuspendMemberMutation,
  useUpdateMemberMutation,
  useChangeMemberBranchMutation,
} from "../../../store/admin/memberManagement/memberSlice";
const useMemberRegAction = () => {
  const navigate = useNavigate();
  const [declineMember] = useDeclineMemberMutation();
  const [approveMember] = useApproveMemberMutation();
  const [suspend_member] = useSuspendMemberMutation();
  const [delete_member] = useDeleteMemberMutation();
  const [un_suspend_member] = useUnSuspendMemberMutation();
  const [editMember] = useUpdateMemberMutation();
  const [changeBranch, { isLoading: isChangingBranch }] =
    useChangeMemberBranchMutation();

  const toastNotify = (error, data, toastId) => {
    if (error && error.status === 401) {
      console.log(error);
      toast.error("Login to continue", {
        id: toastId,
      });
      return navigate("/login");
    }
    if (error) {
      return toast.error(error?.data.message || "An error occured", {
        id: toastId,
      });
    }
    if (data.status === false) {
      return toast.error(data.message || "An error occured", { id: toastId });
    }
    return toast.success(data.message || "success", { id: toastId });
  };

  const rejectMember = async (memberId) => {
    const toastId = toast.loading("Declining");
    const { data, error } = await declineMember(memberId);
    toastNotify(error, data, toastId);
  };

  const acceptMember = async (memberId) => {
    const toastId = toast.loading("Approving");
    const { data, error } = await approveMember(memberId);
    toastNotify(error, data, toastId);
  };

  const suspendMember = async (body) => {
    const toastId = toast.loading("Suspending member");
    const { data, error } = await suspend_member(body);
    toastNotify(error, data, toastId);
  };

  const unSuspendMember = async (memberId) => {
    const toastId = toast.loading("Un Suspending member");
    const { data, error } = await un_suspend_member(memberId);
    toastNotify(error, data, toastId);
  };

  const deleteMember = async (memberId) => {
    const toastId = toast.loading("Removing member");
    const { data, error } = await delete_member(memberId);
    toastNotify(error, data, toastId);
  };

  const updateMember = async (body) => {
    const toastId = toast.loading("Updating member");
    const { data, error } = await editMember(body);
    toastNotify(error, data, toastId);
  };

  const changeMemberBranch = async (memberId, reqBody) => {
    const toastId = toast.loading("changing member branch");
    const { data, error } = await changeBranch({ memberId, reqBody });
    toastNotify(error, data, toastId);
  };
  return {
    rejectMember,
    acceptMember,
    suspendMember,
    deleteMember,
    unSuspendMember,
    updateMember,
    changeMemberBranch,
    isChangingBranch,
  };
};

export default useMemberRegAction;
