import React, { useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  ButtonComp,
  DropDownComp,
  ModalCompAdvanceUpdate,
  TableCompData,
} from "../../../../components";
import FilterFind from "../../../../components/FilterFind";
import EmptyTable from "../../../../components/Common/EmptyTable";
import {
  useDeleteLoanTypeMutation,
  useGetLoanTypesQuery,
} from "../../../../store/admin/loan";
import { formatMoney } from "../../../../utils";
import Message from "../../../../components/MessageModal";
import toast from "react-hot-toast";
import TablePagination from "../../../../components/Table/Pagination";

const LoanTypePage = ({ setPageName, setDetailsToEdit, detailsToEdit }) => {
  const [modalPage, setModalPage] = useState("");
  const [pageNumber, setPageNumber] = useState(1);

  const [deleteLoanType, { isLoading: isDeleting }] =
    useDeleteLoanTypeMutation();

  const handleDeleteLoanType = async () => {
    const response = await deleteLoanType({ loanTypeId: detailsToEdit?.id });
    const { data, error } = response;
    if (error) {
      return toast.error(error?.data?.message || "An error occured");
    } else if (data?.status === false) {
      return toast.error(data?.message || "An error occured");
    } else {
      toast.success(data?.message || "Success");
      setModalPage();
    }
  };

  const useModal = [
    {
      name: "delete_loan_type_modal",
      component: (
        <Message
          title={"Confirm Action"}
          loading={isDeleting}
          desc={"Are  you sure you want to delete this loan type?"}
          onBack={() => setModalPage()}
          onProceed={() => handleDeleteLoanType()}
        />
      ),
    },
  ];
  const TableDropDown = (row) => {
    const dropdown = [
      {
        name: "Edit",
        action: (data) => {
          setPageName("edit_loan_type");
          setDetailsToEdit(data);
        },
      },
      {
        name: "Delete",
        action: (data) => {
          setModalPage("delete_loan_type_modal");
          setDetailsToEdit(data);
        },
      },
    ];
    return dropdown;
  };
  const column = [
    {
      name: "S/N",
      selector: (row, index) => <span className="text-muted">{index + 1}</span>,
      width: "60px",
    },
    {
      name: "Loan name",
      selector: (row) => row?.name,
    },
    {
      name: "Loan interest",
      selector: (row) => <div>{`${row.rate}%`}</div>,
      width: "130px",
    },
    {
      name: "Guarantor count",
      selector: (row) => (
        <div>{`${row?.min_guarantor} min - ${row?.max_guarantor} max`}</div>
      ),
      width: "150px",
    },
    {
      name: "Loan amount",
      selector: (row) => (
        <div>{`${formatMoney(row?.min_loan_amount)} min - ${formatMoney(
          row?.max_loan_amount
        )} max`}</div>
      ),
    },

    {
      name: "Action",
      cell: (row) => (
        <div className="text-center">
          <DropDownComp
            dropLabelClassName={"bg-white border-0 px-3 py-1 rounded-4 text-2"}
            arrayComp={TableDropDown(row)}
            ItemClassName="pe-5 text-2"
            input="name"
            row={row}
            DropDownText={
              <span>
                <BsThreeDotsVertical size={20} />
              </span>
            }
          />
        </div>
      ),
      width: "80px",
    },
  ];

  const { data, isLoading } = useGetLoanTypesQuery(pageNumber);
  const tableData = data?.data?.configs || [];
  const pagination = data?.data.pagination || {};

  return (
    <section className="mx-lg-4 min-vh-100 space-y-6">
      <FilterFind title={"Loan type"} />

      <div className="">
        <TableCompData
          marginBottom={"1rem"}
          columns={column}
          data={tableData}
          message={
            isLoading ? (
              <p className="py-4">Loading</p>
            ) : (
              <EmptyTable
                Message={"No loan type available"}
                btnText={"Create loan type"}
                onClick={() => setPageName("create_loan_type")}
              />
            )
          }
        />
      </div>
      <div className=" w-[12rem] ">
        <ButtonComp
          btnText="Create Loan Type"
          onClick={() => setPageName("create_loan_type")}
          btnClassName=" w-full bg-primary1 py-3 px-4 rounded !text-white border-0"
        />
      </div>
      {tableData?.length > 0 && (
        <TablePagination
          marginTop={"0"}
          handlePageChange={setPageNumber}
          {...pagination}
        />
      )}

      <ModalCompAdvanceUpdate
        pageName={modalPage}
        arrayComp={useModal}
        handleClose={() => setModalPage()}
        centered
        size={"md"}
      />
    </section>
  );
};

export default LoanTypePage;
