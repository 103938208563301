import { ButtonComp } from "../../../../components";
import { useGetBranchesQuery } from "../../../../store/admin/settings/branchSlice";
import { useAddPrefixMutation } from "../../../../store/admin/settings/companyPrefixSlice";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-hot-toast";
import PrefixForm from "./PrefixForm";
import { PageTitle } from "../../modules/PageHeaders";

export default function CreateCompanyPrefix({ onNext }) {
  const navigate = useNavigate();
  const { error } = useGetBranchesQuery();

  const [branchId, setBranchId] = useState("");
  const [addNewPrefix, { isLoading: isSubmittingForm }] =
    useAddPrefixMutation();
  const initialValues = {
    count: "",
    name: "",
    branch_id: "",
  };

  const handleFormSubmit = async (
    formikValues,
    { setSubmitting, resetForm }
  ) => {
    const reqBody = {
      ...formikValues,
      branch_id: branchId,
      count: Number(formikValues.count),
    };
    const toastId = toast.loading("Processing");
    try {
      setSubmitting(true);
      const payload = await addNewPrefix(reqBody).unwrap();
      if (payload.status === false) {
        toast.error(payload.message, {
          id: toastId,
        });
      } else {
        toast.success(payload.message, {
          id: toastId,
        });
        resetForm();
      }
    } catch (error) {
      console.log("error", error);
      toast.error(error?.data?.message || "An error occured", { id: toastId });
    } finally {
      setSubmitting(false);
    }
  };

  if (error && error.status === 401) {
    return navigate("/login");
  }
  return (
    <>
      <div>
        <div className="mb-4 text-end">
          <ButtonComp
            btnText={"All Prefixes"}
            onClick={onNext}
            btnClassName="bg-primary1 py-2 px-4 rounded text-white border-0"
          />
        </div>
        <div className="mb-4">
          <PageTitle title={"Cooperative Prefix"} />
        </div>
        <PrefixForm
          initialValues={initialValues}
          handleFormSubmit={handleFormSubmit}
          setBranchId={setBranchId}
          isSubmitting={isSubmittingForm}
        />
      </div>
    </>
  );
}
