import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CardComp, TabsComp } from "../../../components";
import PageWrapper from "../modules/PageWrapper";
import LoanRepaymentTable from "./LoanRepaymentTable";
import { MemberLoanRepaymentHeader } from "../../../utils/TableHeader";
import LoanManagementCard from "../modules/LoanManagementCard";
import { useUser } from "../../../hooks";
import {
  useGetDueRepaymentLoanListQuery,
  useGetOverdueRepaymentLoanListQuery,
  useGetUpcomingRepaymentLoanListQuery,
} from "../../../store/member/member-loan";

export default function MemberLoanRepayment() {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useUser();
  const [dueRepaymentPage, setDueRepaymentPage] = useState(1);
  const [upcomingRepaymentPage, setUpcomingRepaymentPage] = useState(1);
  const [overdueRepaymentPage, setOverdueRepaymentPage] = useState(1);

  const { data: upcomingRepayments, isLoading: isLoadingUpcomingRepayments } =
    useGetUpcomingRepaymentLoanListQuery({
      page: upcomingRepaymentPage,
      member_id: user?.member_id,
    });

  const { data: dueRepayments, isLoading: isLoadingDueRepayments } =
    useGetDueRepaymentLoanListQuery({
      page: dueRepaymentPage,
      member_id: user?.member_id,
    });

  const { data: overdueRepayments, isLoading: isLoadingOverdueRepayments } =
    useGetOverdueRepaymentLoanListQuery({
      page: overdueRepaymentPage,
      member_id: user?.member_id,
    });

  const cardData = [
    {
      name: `You have ${user?.overdue_repayment} overdue repaymnet`,
      contributions: "Overdue Repayment",
      image: "/MemberSide/Icons/dashboard3.svg",
    },
    {
      name: `You have ${user?.pending_repayment} upcoming repayment`,
      contributions: "Upcoming Repayment",
      image: "/MemberSide/Icons/dashboard5.svg",
    },
  ];

  const TableDropDown = [
    {
      name: "Pay now",
      action: (data) => {
        navigate(`${location?.pathname}/${data?.id}`);
      },
    },
    {
      name: "Record payment",
      action: (data) => {
        navigate(`/super_admin/staff_registration/suspend_staff`);
      },
    },
    {
      name: "Query transaction",
      action: (data) => {
        navigate(`/super_admin/staff_registration/change_branch`);
      },
    },
  ];
  const TableDropDown1 = [
    {
      name: "Record payment",
      action: (data) => {
        navigate(`${location?.pathname}/${data?.id}`);
      },
    },
    {
      name: "Query transaction",
      action: (data) => {
        navigate(`/super_admin/staff_registration/change_branch`);
      },
    },
  ];
  const useTab = [
    {
      name: "Upcoming repayment",
      component: (
        <LoanRepaymentTable
          name="upcoming repayment"
          TableData={upcomingRepayments?.data?.company_loan_requests || []}
          pagination={upcomingRepayments?.data?.pagination}
          loading={isLoadingUpcomingRepayments}
          setPageNumber={setUpcomingRepaymentPage}
          column={MemberLoanRepaymentHeader(TableDropDown)}
        />
      ),
    },
    {
      name: "Due repayment",
      component: (
        <LoanRepaymentTable
          name="due repayment"
          TableData={dueRepayments?.data?.company_loan_requests || []}
          pagination={dueRepayments?.data?.pagination}
          loading={isLoadingDueRepayments}
          setPageNumber={setDueRepaymentPage}
          column={MemberLoanRepaymentHeader(TableDropDown1)}
        />
      ),
    },
    {
      name: "Overdue repayment",
      component: (
        <LoanRepaymentTable
          name="overdue repayment"
          TableData={overdueRepayments?.data?.company_loan_requests || []}
          pagination={overdueRepayments?.data?.pagination}
          loading={isLoadingOverdueRepayments}
          setPageNumber={setOverdueRepaymentPage}
          column={MemberLoanRepaymentHeader(TableDropDown1)}
        />
      ),
    },
  ];

  return (
    <PageWrapper title={"Loan Repayment"} back>
      <section className="mt-6 space-y-6 ">
        <section className="mt-6 space-y-6 ">
          <div className="w-full flex justify-between items-center flex-wrap gap-12">
            <div className="flex-1">
              <LoanManagementCard />
            </div>
            <section className=" space-y-5 flex-1">
              {cardData?.map((item, i) => (
                <div
                  className=" space-x-4 rounded-[0.625rem] shadow3 flex items-center px-[2.42rem] py-[1.25rem] "
                  key={i}
                >
                  <img
                    src={item?.image}
                    alt="img"
                    className="w-[2.5rem] h-[2.5rem]"
                  />

                  <div className="w-full space-y-2 ">
                    <h4 className="">{item?.contributions}</h4>
                    <p
                      className={`text-muted h50 font-1 ${item?.nameClassName}`}
                    >
                      {item?.name}
                    </p>
                  </div>
                </div>
              ))}
            </section>
          </div>
        </section>

        <section className="space-y-4">
          <h4 className="font-bold text-base text-[#45464E] pt-[3rem] ">
            Loan Repayment History
          </h4>
          <CardComp cardClassName={"border-0 pt-[2rem] px-3"}>
            <div>
              <TabsComp
                TabArray={useTab}
                input="name"
                justifyContent={"justify-content-start"}
                tabClassName="px-0"
                TabArrayClassName="px-4"
              />
            </div>
          </CardComp>
        </section>
      </section>
    </PageWrapper>
  );
}
