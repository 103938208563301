import Cookie from "js-cookie";
// import { CookieAttributes } from "js-cookie";

// if (!window) {
//   return;
// }

export const storage = {
  localStorage: {
    set: (key, value) => {
      try {
        localStorage.setItem(key, JSON.stringify(value));
      } catch (error) {
        console.error(`Error setting item in localStorage: ${error}`);
      }
    },
    get: (key) => {
      try {
        const value = localStorage.getItem(key);
        return value ? JSON.parse(value) : null;
      } catch (error) {
        console.error(`Error parsing JSON from localStorage: ${error}`);
        return null;
      }
    },
    remove: (key) => {
      try {
        localStorage.removeItem(key);
      } catch (error) {
        console.error(`Error removing item from localStorage: ${error}`);
      }
    },
  },
  cookieStorage: {
    set: (key, value, options) => {
      try {
        Cookie.set(key, value, options);
      } catch (error) {
        console.error(`Error setting cookie: ${error}`);
      }
    },
    get: (key) => {
      try {
        return Cookie.get(key) || "";
      } catch (error) {
        console.error(`Error getting cookie: ${error}`);
        return "";
      }
    },
    remove: (key, options) => {
      try {
        Cookie.remove(key, options);
      } catch (error) {
        console.error(`Error removing cookie: ${error}`);
      }
    },
  },
};
