import { ButtonComp, ModalCompAdvanceUpdate } from "../../../components";
import CalendarComp from "../../../components/Common/CalendarComp";
import FallbackImage from "../../../components/Common/ImageWrapper";
import ListMessage from "./modules/listMessage";
import {
  useGetBirthdayMessagesQuery,
  useGetBirthdaysQuery,
} from "../../../store/admin/automatedBirthdaySlice";
import { PageTitle } from "../modules/PageHeaders";
import { useState } from "react";
import MessageForm from "./modules/MessageForm";
import useAutomatedBirthdayAction from "./useAutomatedBirthdayAction";

export default function AutomatedBirthdayHome() {
  const { addBirthMessage, editBirthMessage } = useAutomatedBirthdayAction();
  const { data: birthdayMessages = [] } = useGetBirthdayMessagesQuery(1);
  const { data: birthdays = {} } = useGetBirthdaysQuery();
  const { automated_birthdays = [] } = birthdays;
  const [modalName, setModalName] = useState();
  const { automated_birthday_messages = [] } = birthdayMessages;
  const defaultMessage = automated_birthday_messages[0];
  const [initialMessage, setInitialMsg] = useState("");
  const [messageId, setMsgId] = useState(null);

  const initiateEdit = (item) => {
    setInitialMsg(item?.message);
    setMsgId(item?.id);
    setModalName("Edit Message");
  };

  const newMsg = (message) => {
    if (!message.length > 3) return;
    addBirthMessage({ message: message });
    setModalName();
  };

  const patchMsg = (message) => {
    if (!message.length > 3) return;
    editBirthMessage({ message: message, id: messageId });
    setModalName();
  };

  const useModal = [
    {
      name: "Create Message",
      component: (
        <MessageForm
          onProceed={newMsg}
          title={"Create Message"}
          onBack={() => setModalName()}
        />
      ),
    },
    {
      name: "Edit Message",
      component: (
        <MessageForm
          onProceed={patchMsg}
          title={"Edit Message"}
          onBack={() => setModalName()}
          initialMessage={initialMessage}
        />
      ),
    },
  ];

  return (
    <>
      <section className="px-3 px-lg-5 min-vh-100 pb-5 mb-5">
        <div className="mt-1">
          <PageTitle title={"Automated Birthday"} />
        </div>
        <div className="row gx-0 px-0 px-lg-0 justify-content-between">
          <div className=" col-lg-6 bg-white px-lg-4 py-5 d-flex justify-content-start flex-column align-items-center rounded-4">
            <h5 className="text-center mb-3 fw-bold">Birthday calendar</h5>
            <div className="col-lg-10 mb-3">
              <CalendarComp />
            </div>
            <div className="w-100">
              <h5 className="text-center mb-3 fw-bold">Members</h5>
              <div className="d-flex justify-content-center">
                <div className="col-12 col-lg-10 text-center py-4 rounded-4 shadow">
                  {automated_birthdays.length === 0 ? (
                    <>
                      <div className="py-2"></div>
                      <FallbackImage
                        src="/Icon/emptyevent.svg"
                        className="mb-3"
                      />
                      <p>No upcoming birthdays</p>
                      <div className="py-2"></div>
                    </>
                  ) : (
                    <div className="px-4 d-flex flex-column gap-4">
                      {automated_birthdays?.map((item, i) => (
                        <div
                          key={i}
                          className="d-flex  align-items-center justify-content-between fw-2 flex-wrap"
                        >
                          <div className="d-flex align-items-center  mb-lg-0">
                            <FallbackImage
                              src={item?.photo}
                              className="rounded-circle me-3"
                              width="32px"
                              height="32px"
                            />
                            <p className="h60 mb-0">
                              {item?.user?.first_name} {item?.user?.first_name}
                            </p>
                          </div>
                          <div
                            className="rounded-pill h60 px-3 py-1"
                            style={{
                              background: "rgba(49, 119, 140, 0.1)",
                              color: "#31778C",
                            }}
                          >
                            {item?.user_type}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-5">
            <div className="bg-white p-3 rounded-4 mb-4">
              <h4 className="fw-bold text-black1 mb-3">
                Create custom birthday messages
              </h4>
              <ButtonComp
                btnText={"Create message"}
                btnClassName={"bg-primary1 text-white rounded px-4 py-2"}
                onClick={() => setModalName("Create Message")}
              />
            </div>
            <div>
              <h5 className=" mb-3 fw-bold text-black1">Default message</h5>
              {/*  */}
              <ListMessage
                message={defaultMessage?.message}
                onClickEdit={() => initiateEdit(defaultMessage)}
                btnText="Change default"
              />
              <div>
                <hr style={{ color: "#E0E0E0", borderWidth: "3px" }} />
              </div>
              <h5 className=" mb-3 fw-bold text-black1">Custom messages</h5>
              <div className="listMessage">
                {birthdayMessages.length === 0 ? (
                  <div className="d-flex justify-content-center">
                    <div className="bg-white col-12 text-center py-4 rounded-4 shadow">
                      <FallbackImage
                        src="/Icon/nomessage.svg"
                        className="mb-3"
                      />
                      <p>No custom messages</p>
                    </div>
                  </div>
                ) : (
                  <>
                    {automated_birthday_messages.map((item, i) => (
                      <ListMessage
                        key={i}
                        message={item?.message}
                        onClickEdit={() => initiateEdit(item)}
                      />
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <ModalCompAdvanceUpdate
        arrayComp={useModal}
        centered={true}
        pageName={modalName}
        handleClose={() => setModalName()}
        close={true}
      />
    </>
  );
}
