import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import {
  useCreateBirthdayMessageMutation,
  useUpdateBirthdayMessageMutation,
} from "../../../store/admin/automatedBirthdaySlice";
const useAutomatedBirthdayAction = () => {
  const navigate = useNavigate();
  const [createBirthdayMessage] = useCreateBirthdayMessageMutation();
  const [updateBirthdayMessage] = useUpdateBirthdayMessageMutation();

  const toastNotify = (error, data, toastId) => {
    if (error && error.status === 401) {
      console.log(error);
      toast.error("Login to continue", {
        id: toastId,
      });
      return navigate("/login");
    }
    if (error) {
      return toast.error(error?.data.message || "An error occured", {
        id: toastId,
      });
    }
    if (data.status === false) {
      return toast.error(data.message || "An error occured", { id: toastId });
    }
    return toast.success(data.message || "success", { id: toastId });
  };

  const addBirthMessage = async (body) => {
    const toastId = toast.loading("Adding message");
    const { data, error } = await createBirthdayMessage(body);
    toastNotify(error, data, toastId);
  };

  const editBirthMessage = async (body) => {
    const toastId = toast.loading("Updating message");
    const { data, error } = await updateBirthdayMessage(body);
    toastNotify(error, data, toastId);
  };
  return {
    addBirthMessage,
    editBirthMessage,
  };
};

export default useAutomatedBirthdayAction;
