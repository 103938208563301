import { API, ApiPrefixes } from "./api";
const BASE_URL = ApiPrefixes["generalSelectables"];
const BASE_URL_2 = ApiPrefixes["adminSelectables"];

export const selectableExtendedApi = API.injectEndpoints({
  endpoints: (builder) => ({
    getBanks: builder.query({
      query: () => `${BASE_URL}/all_banks?guard=staff`,
      transformResponse: (responseData) => responseData.data,
    }),
    getFeeCategories: builder.query({
      query: () => `${BASE_URL}/fee_category?guard=staff`,
      transformResponse: (responseData) => responseData.data,
    }),
    getTitles: builder.query({
      query: () => `${BASE_URL}/titles?guard=staff`,
      transformResponse: (responseData) => responseData.data,
    }),
    getMaritalStatuses: builder.query({
      query: () => `${BASE_URL}/marital_statuses?guard=staff`,
      transformResponse: (responseData) => responseData.data,
    }),
    getGenders: builder.query({
      query: () => `${BASE_URL}/genders?guard=staff`,
      transformResponse: (responseData) => responseData.data,
    }),
    getPaymentMethods: builder.query({
      query: () => `${BASE_URL}/payment_methods?guard=staff`,
      transformResponse: (responseData) => responseData.data,
    }),
    getCountries: builder.query({
      query: () => `${BASE_URL}/countries?guard=staff`,
      transformResponse: (responseData) => responseData.data,
    }),
    getRoles: builder.query({
      query: () => `/cooperatives/selectables/roles?guard=staff`,
      transformResponse: (responseData) => responseData.data,
    }),
    getBranchesSelectable: builder.query({
      query: () => `${BASE_URL_2}/branches?guard=staff`,
      transformResponse: (responseData) => responseData.data,
    }),
    getCurrencies: builder.query({
      query: () => `${BASE_URL}/currencies?guard=staff`,
      transformResponse: (responseData) => responseData.data,
    }),
    getCities: builder.query({
      query: () => `${BASE_URL}/cities?guard=staff`,
      transformResponse: (responseData) => responseData.data,
    }),
    getRolePermissions: builder.query({
      query: () => `${BASE_URL}/role_permission?guard=staff`,
      transformResponse: (responseData) => responseData.data,
    }),
    getAllMembersAndStaffs: builder.query({
      query: () => `/cooperatives/selectables/staffsAndMembers?guard=staff`,
      transformResponse: (responseData) => {
        if (!responseData?.data) return [];
        return responseData.data.map((member, index) => ({
          ...member,
          serialNumber: index + 1,
        }));
      },
    }),
    getAttendanceStatuses: builder.query({
      query: () => `${BASE_URL}/attendance_statuses?guard=staff`,
      transformResponse: (responseData) => responseData.data,
    }),
    getRelationshipStatuses: builder.query({
      query: () => `${BASE_URL}/relationship_statuses?guard=staff`,
      transformResponse: (responseData) => responseData.data,
    }),
    getEventAttendees: builder.query({
      query: () => `${BASE_URL}/event_status?guard=staff`,
      transformResponse: (responseData) => responseData.data,
    }),
    getCommunicationMessageSendingModes: builder.query({
      query: () => `${BASE_URL}/communication_message_status?guard=staff`,
      transformResponse: (responseData) => responseData.data,
    }),
    getCommunicationMessageRecipientTypes: builder.query({
      query: () => `${BASE_URL}/receipt_type?guard=staff`,
      transformResponse: (responseData) => responseData.data,
    }),
    getAllMembers: builder.query({
      query: () => `/cooperatives/selectables/all-members?guard=staff`,
      transformResponse: (responseData) => responseData.data,
    }),
    getAllMembersAsMemberUser: builder.query({
      query: () => `/members/selectables/all-members?guard=member`,
      transformResponse: (responseData) => responseData.data,
    }),
  }),
});

export const {
  useGetBanksQuery,
  useGetFeeCategoriesQuery,
  useGetBranchesSelectableQuery,
  useGetCitiesQuery,
  useGetCountriesQuery,
  useGetCurrenciesQuery,
  useGetGendersQuery,
  useGetMaritalStatusesQuery,
  useGetPaymentMethodsQuery,
  useGetRolePermissionsQuery,
  useGetRolesQuery,
  useGetTitlesQuery,
  useGetAllMembersAndStaffsQuery,
  useGetAttendanceStatusesQuery,
  useGetRelationshipStatusesQuery,
  useGetEventAttendeesQuery,
  useGetCommunicationMessageSendingModesQuery,
  useGetCommunicationMessageRecipientTypesQuery,
  useGetAllMembersQuery,
  useGetAllMembersAsMemberUserQuery,
} = selectableExtendedApi;
