import { API } from "../api";

export const authApi = API.injectEndpoints({
  endpoints: (builder) => ({
    userLogin: builder.mutation({
      query: (body) => ({
        url: "/onboard/cooperatives/login",
        method: "POST",
        body,
      }),
    }),
    getCoop: builder.query({
      query: ({ domain_url }) => ({
        url: `/onboard/getCooperative?domain_url=${domain_url}`,
        method: "GET",
      }),
    }),
    getCurrentUserProfile: builder.query({
      query: (body) => ({
        url: "/cooperatives/profile?guard=staff",
        method: "GET",
        body,
      }),
    }),
    getCurrentMemberProfile: builder.query({
      query: (body) => ({
        url: "/members/profile?guard=member",
        method: "GET",
        body,
      }),
    }),
  }),
});

export const {
  useUserLoginMutation,
  useGetCoopQuery,
  useGetCurrentUserProfileQuery,
  useLazyGetCurrentMemberProfileQuery,
  useLazyGetCurrentUserProfileQuery,
  useGetCurrentMemberProfileQuery,
} = authApi;
