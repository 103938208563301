/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import {
  DropDownComp,
  DropDownComp3,
  SidebarAdmin,
  TabsComp,
} from "../components";
import { sideBarData, sideBarMemberData } from "../utils";
import { useDispatch } from "react-redux";
import { logout } from "../store/auth/index.js";
import TopNavNotification from "../components/Ui/modules/TopNavNotification";
import { GrMenu } from "react-icons/gr";
import { BsChevronDown } from "react-icons/bs";
import { useUser, useUserType } from "../hooks";

export default function AdminLayout({ children }) {
  const [hideSider, setHideSider] = useState(false);
  const dispatch = useDispatch();

  const userType = useUserType();

  // loggedIn user data that has been stored in the store
  const user = useUser();

  const usePage = [
    {
      name: "Read",
      label: (
        <div className="d-flex">
          <div className="me-2 h50">Read</div>
          <div
            className="rounded-pill text-white h60 d-flex justify-content-center align-items-center"
            style={{ width: "20px", height: "20px", background: "#3E97B2" }}
          >
            10
          </div>
        </div>
      ),
      component: (
        <div>
          <div className="mb-4">
            <div
              style={{ background: "#E0E0E0" }}
              className="py-1 px-3 h60 mb-3"
            >
              Today, October 1, 2022
            </div>
            <TopNavNotification />
            <TopNavNotification />
          </div>
          <div>
            <div
              style={{ background: "#E0E0E0" }}
              className="py-1 px-3 h60 mb-3"
            >
              Yesterday, October 2, 2022
            </div>
            <TopNavNotification />
            <TopNavNotification />
          </div>
        </div>
      ),
    },
    {
      name: "Unread",
      label: (
        <div className="d-flex">
          <div className="me-2 h50">Unread</div>
          <div
            className="rounded-pill text-white h60 d-flex justify-content-center align-items-center"
            style={{ width: "20px", height: "20px", background: "#8B313A" }}
          >
            10
          </div>
        </div>
      ),
      component: <div></div>,
    },
  ];

  return (
    <div className="w-screen">
      {/* <!-- ===== Page Wrapper Start ===== --> */}
      <div className="flex h-screen w-full overflow-hidden">
        {/* <!-- ===== Sidebar Start ===== --> */}
        <div className="w-fit border">
          <SidebarAdmin
            hideSider={hideSider}
            setHideSider={setHideSider}
            sideBarData={
              userType?.includes("member") ? sideBarMemberData : sideBarData
            }
          />
        </div>
        {/* <!-- ===== Sidebar End ===== --> */}

        {/* <!-- ===== Content Area Start ===== --> */}
        <div
          onMouseEnter={() => setHideSider(false)}
          className=" min-[1200px]:ml-[300px] relative flex flex-1 w-full flex-col overflow-y-auto overflow-x-hidden bg-[#F8F8F8] "
        >
          {/* <!-- ===== Header Start ===== --> */}
          <header className="sticky top-0 z-[10] flex w-full bg-white ">
            <div className=" flex-grow px-4 py-2 shadow-[rgba(1,41,112,0.1)_0px_0px_20px] md:px-6 2xl:px-11  flex justify-between w-full items-center">
              <div>
                <button
                  onClick={() => setHideSider(!hideSider)}
                  className="z-10 block rounded-sm border bg-white p-1.5 shadow-sm  xl:hidden "
                >
                  <GrMenu />
                </button>
              </div>

              <div className="flex items-center  ">
                <span className="pe-1 d-none d-md-inline">
                  <DropDownComp3
                    dropLabelClassName={"border-0"}
                    Transform={`translate3d(-168.2px,55px,-5px)`}
                    DropDownText={
                      <img src="/Images/TopHeader/message.svg" alt="logo" />
                    }
                    drop="down"
                    bodyComp={
                      <div
                        style={{ width: "420px" }}
                        className="text-black p-3"
                      >
                        <TabsComp
                          TabArray={usePage}
                          input="label"
                          lineBackground={"#E0A8AE"}
                          lineLeft={"0px"}
                          lineRight={"0px"}
                          lineBottom="24px"
                          tabClassName="col-6"
                        />
                      </div>
                    }
                  />
                </span>
                <span className=" d-inline d-md-none">
                  <DropDownComp3
                    dropLabelClassName={"border-0"}
                    Transform={`translate3d(-88.2px,55px,-5px)`}
                    // Transform={`translate3d(-75.2px, 56px, 0px)`}
                    DropDownText={
                      <img src="/Images/TopHeader/message.svg" alt="logo" />
                    }
                    drop="down"
                    bodyComp={
                      <div
                        style={{ width: "320px" }}
                        className="text-black p-3"
                      >
                        <TabsComp
                          TabArray={usePage}
                          input="label"
                          lineBackground={"#E0A8AE"}
                          lineLeft={"0px"}
                          lineRight={"0px"}
                          lineBottom="24px"
                          tabClassName="col-6"
                        />
                      </div>
                    }
                  />
                </span>
                <span className=" d-none d-md-inline">
                  <DropDownComp3
                    dropLabelClassName={"border-0"}
                    Transform={`translate3d(-168.2px,55px,-5px)`}
                    // Transform={`translate3d(-75.2px, 56px, 0px)`}
                    DropDownText={
                      <img
                        src="/Images/TopHeader/notification.svg"
                        alt="logo"
                      />
                    }
                    drop="down"
                    bodyComp={
                      <div
                        style={{ width: "450px" }}
                        className="text-black p-3"
                      >
                        <TabsComp
                          TabArray={usePage}
                          input="label"
                          lineBackground={"#E0A8AE"}
                          lineLeft={"0px"}
                          lineRight={"0px"}
                          lineBottom="24px"
                          tabClassName="col-6"
                        />
                      </div>
                    }
                  />
                </span>
                <span className=" d-inline d-md-none">
                  <DropDownComp3
                    dropLabelClassName={"border-0"}
                    Transform={`translate3d(-158.2px,55px,-5px)`}
                    // Transform={`translate3d(-75.2px, 56px, 0px)`}
                    DropDownText={
                      <img
                        src="/Images/TopHeader/notification.svg"
                        alt="logo"
                      />
                    }
                    drop="end"
                    bodyComp={
                      <div
                        style={{ width: "320px" }}
                        className="text-black p-3"
                      >
                        <TabsComp
                          TabArray={usePage}
                          input="label"
                          lineBackground={"#E0A8AE"}
                          lineLeft={"0px"}
                          lineRight={"0px"}
                          lineBottom="24px"
                          tabClassName="col-6"
                        />
                      </div>
                    }
                  />
                </span>

                <DropDownComp
                  dropLabelClassName={
                    "bg-white px-3 py-1 rounded-4 text-2 border-0"
                  }
                  arrayComp={[
                    {
                      name: <span className="h50">Profile</span>,
                      icon: "/Images/TopHeader/user.svg",
                    },
                    {
                      name: <span className="h50">My Logins</span>,
                      icon: "/Images/TopHeader/user.svg",
                    },
                    {
                      name: <span className="h50">Messages</span>,
                      icon: "/Images/TopHeader/user.svg",
                    },
                    {
                      name: <span className="h50">Settings</span>,
                      icon: "/Images/TopHeader/user.svg",
                    },
                    {
                      name: <span className="h50">Logout</span>,
                      icon: "/Images/TopHeader/user.svg",
                      action: () => {
                        dispatch(logout());
                      },
                    },
                  ]}
                  ItemClassName="pe-5 text-2"
                  input="name"
                  DropDownText={
                    <span className="flex space-x-2 ">
                      <span className="pe-2">
                        <img
                          src={user?.photo}
                          style={{ height: 23, width: 23 }}
                          alt="logo"
                        />
                      </span>
                      <span className="fw-2 h50">
                        {`${user?.name}` || "John Doe"}
                      </span>
                      <span>
                        <BsChevronDown size={20} />
                      </span>
                    </span>
                  }
                />
              </div>
            </div>
          </header>
          {/* <NavbarComp setHideSider={setHideSider} hideSider={hideSider} /> */}
          {/* <!-- ===== Header End ===== --> */}

          {/* <!-- ===== Main Content Start ===== --> */}
          <main className="min-h-screen flex w-full">
            <div className="mx-auto max-w-screen-2xl w-full p-4 md:p-6 2xl:p-10">
              {children}
            </div>
          </main>
          {/* <!-- ===== Main Content End ===== --> */}
        </div>
        {/* <!-- ===== Content Area End ===== --> */}
      </div>
      {/* <!-- ===== Page Wrapper End ===== --> */}
    </div>
    // <div>
    //   {/* <SidebarAdmin
    //     hideSider={hideSider}
    //     setHideSider={setHideSider}
    //     sideBarData={sideBarData}
    //   /> */}

    //   <main id="main" className="">
    //     <div className="w-100  mb-3">
    //       {/* <NavbarComp setHideSider={setHideSider} hideSider={hideSider} /> */}
    //     </div>

    //     <div className=" w-100  maincontent mx-auto  mb-5">{children}</div>
    //   </main>
    // </div>
  );
}

// const SideBarStyle = styled.div`
//   .main {
//     overflow: hidden;
//     background: #f1f1f1 !important;
//     min-height: 92vh;
//     // margin-left: 295px;
//   }
//   #main {
//     /* margin-top: 60px; */
//     /* padding: 0px 30px; */
//     transition: all 0.3s;
//     background: #f1f1f1 !important;
//     min-height: 100vh;
//     overflow: hidden;
//   }
//   @media (max-width: 1199px) {
//     #main {
//       margin-left: 0;
//     }
//     .maincontent {
//       padding: 20px;
//     }
//   }
//   @media (max-width: 480px) {
//     .maincontent {
//       padding: 5px;
//     }
//   }
// `;
