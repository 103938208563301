import { Modal } from "react-bootstrap";
import styledComponents from "styled-components";

const Styled = styledComponents.div`
.modal-header {
    display:none !important;
}

`;

export const ModalCompAdvance = ({
  show,
  handleClose,
  modalTitle,
  modalBody = 0,
  close,
  size,
  centered,
  designClass,
  arrayComp = [],
}) => {
  // console.log(arrayComp[0],'arrayComp')
  return (
    <Styled>
      <div>
        <Modal
          show={show}
          onHide={handleClose}
          centered={centered}
          size={size}
          aria-labelledby={"example-modal-sizes-title-sm"}
        >
          {close && <Modal.Header closeButton></Modal.Header>}
          {modalTitle && <Modal.Title>{modalTitle}</Modal.Title>}
          <Modal.Body className={`${designClass} px-lg-4`}>
            {arrayComp[modalBody]?.component}
          </Modal.Body>
          {/* <Modal.Footer>
            
          </Modal.Footer> */}
        </Modal>
      </div>
    </Styled>
  );
};

export const ModalCompAdvanceUpdate = ({
  show,
  handleClose,
  modalTitle,
  close,
  size,
  centered,
  designClass,
  arrayComp = [],
  pageName
}) => {
  // console.log(arrayComp[0],'arrayComp')
  return (
    <Styled>
      <div>
        <Modal
          show={pageName}
          onHide={handleClose}
          centered={centered}
          size={size}
          // aria-labelledby={"example-modal-sizes-title-sm"}
        >
          {close && <Modal.Header closeButton></Modal.Header>}
          {modalTitle && <Modal.Title>{modalTitle}</Modal.Title>}
          <Modal.Body className={`${designClass} px-lg-4`}>
            {arrayComp?.find((item)=>item?.name===pageName)?.component}
          </Modal.Body>
          {/* <Modal.Footer>
              
            </Modal.Footer> */}
        </Modal>
      </div>
    </Styled>
  );
};

export const PageSwitch = ({ pageArray = [], pageNumber, pageName }) => {
  return pageNumber
    ? pageArray[pageNumber]?.component
    : pageArray.find((item) => item?.name === pageName)?.component;
};
