import { API } from "../../api";


export const memberDashboardExtendedApi = API.injectEndpoints({
    endpoints: (builder) => ({
        memberDashboard: builder.query({
            query: () => {
                return {
                    url: "/members/dashboard?guard=member",
                    method: "GET",
                };
            }
        })
    })
})


export const {
    useMemberDashboardQuery
} = memberDashboardExtendedApi;
